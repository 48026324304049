import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer-btn" }
const _hoisted_3 = {
  key: 2,
  class: "tooltiptext"
}
const _hoisted_4 = {
  key: 1,
  class: "tooltiptext"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass({
          container: true,
          tootip:
            !_ctx.user?.role.permissions.pharmacy.update &&
            _ctx.$route.name === 'EditPharmacy'
        })
      }, [
        (_ctx.$route.name === 'CallerAuthentication')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              variant: _ctx.isSaveForm ? 'filled' : 'notBordered',
              title: _ctx.isSaveForm ? _ctx.$t('finish') : _ctx.$t('discard'),
              disabled: _ctx.disabledButton(),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (
            _ctx.isSaveForm
              ? _ctx.$emit('finishAuthentication')
              : _ctx.$emit('closeDiscardAuthentication')
          ))
            }, null, 8, ["variant", "title", "disabled"]))
          : _createCommentVNode("", true),
        (
            _ctx.$route.name !== 'CallerAuthentication' &&
            _ctx.$route.name !== 'ViewOrder'
          )
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              variant: _ctx.getVariant(),
              title: _ctx.$t('save'),
              disabled: _ctx.disabledButton(),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submitForm')))
            }, null, 8, ["variant", "title", "disabled"]))
          : _createCommentVNode("", true),
        (
            !_ctx.user?.role.permissions.pharmacy.update &&
            _ctx.$route.name === 'EditPharmacy'
          )
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('enoughPermissions')), 1))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.$route.name === 'EditPharmacy')
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            variant: _ctx.disabledMakeServiceRequest ? 'disabledLong' : 'wideBordered',
            title: 
          _ctx.disabledMakeServiceRequest
            ? _ctx.$t('seriviceRequested')
            : _ctx.$t('makeServiceRequest')
        ,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('openModalServiceRequest')))
          }, null, 8, ["variant", "title"]))
        : _createCommentVNode("", true),
      (_ctx.$route.name === 'ViewOrder' && _ctx.paymentInfo)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            class: _normalizeClass({
          paymentInfo__button: !_ctx.paymentNotificationWasSuccessfullyResend,
          'button-wasSuccessfully': _ctx.paymentNotificationWasSuccessfullyResend
        }),
            variant: "filled",
            title: 
          _ctx.paymentNotificationWasSuccessfullyResend
            ? _ctx.$t('paymentNotificationWasSuccessfullyResend')
            : _ctx.$t('sendPaymentNotification')
        ,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('resendPaymentNotificationResult')))
          }, null, 8, ["class", "title"]))
        : _createCommentVNode("", true),
      (_ctx.$route.name === 'ViewOrder')
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 2,
            variant: "redBordered",
            title: _ctx.$t('cancelPaymentRequest'),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('cancelPayment')))
          }, null, 8, ["title"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass({
          container: true,
          tootip:
            !_ctx.user?.role.permissions.pharmacy.update &&
            _ctx.$route.name === 'EditPharmacy'
        })
      }, [
        (
            _ctx.$route.name !== 'CallerAuthentication' &&
            _ctx.$route.name !== 'ViewOrder'
          )
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              variant: _ctx.getVariantButtonCleanFields(),
              title: _ctx.$t('cleanFields'),
              disabled: _ctx.disabledButton(),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('cleanForm')))
            }, null, 8, ["variant", "title", "disabled"]))
          : _createCommentVNode("", true),
        (
            !_ctx.user?.role.permissions.pharmacy.update &&
            _ctx.$route.name === 'EditPharmacy'
          )
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('enoughPermissions')), 1))
          : _createCommentVNode("", true)
      ], 2)
    ])
  ]))
}