
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { getDatabase, ref, onValue } from 'firebase/database';

import Loading from '@/views/Loading.vue';
import IssuePayment from './IssuePayment/IssuePayment.vue';
import DiscardAuthentication from './DiscardAuthentication/DiscardAuthentication.vue';

import {
  authenticationNameInfo,
  authenticationSsnAndAddressInfo
} from '@/constants';

import {
  OrderPayment,
  UpdateIssuePaymentFormData
} from '@/store/modules/orders/types';

import { getTotalSum } from '@/utils/getTotalSum';

@Options({
  name: 'PaymentDetails',
  props: ['userId', 'reference'],
  emits: ['clearAllForms'],
  components: {
    Loading,
    IssuePayment,
    DiscardAuthentication
  },
  data() {
    return {
      formData: {
        consultation: false,
        deliveryMethod: '',
        orderType: [],
        orderNumber: '',
        comment: '',
        callReason: []
      },
      loading: false,
      issuePayment: false,
      prescription: false,
      other: false,
      prescriptionDrugs: false,
      selfCare: false,
      newIssuePaymentFormData: {},
      authenticationNameInfo,
      authenticationSsnAndAddressInfo,
      showModalDiscardAuthentication: false,
      isSaveForm: false,
      openButton: false,
      noTotalSum: false,
      realTimeValue: {}
    };
  },
  computed: {
    ...mapState('orders', ['orderPayment']),
    ...mapState('account', ['user']),
    ...mapState('pharmacy', ['pharmacy'])
  },
  methods: {
    ...mapActions('orders', ['getOrderCreate', 'getIssuePaymentPush']),
    changeIssuePayment() {
      this.issuePayment = !this.issuePayment;
    },
    changePrescriptionDrugs() {
      this.prescriptionDrugs = !this.prescriptionDrugs;
    },
    changeSelfCare() {
      this.selfCare = !this.selfCare;
    },
    updateDeliveryMethod(method: string) {
      this.deliveryMethod = method;
      this.formData.deliveryMethod = method;
    },
    getTitleSaveForm() {
      if (this.issuePayment) {
        return 'saveFormAndSendPayment';
      } else {
        return 'saveForm';
      }
    },
    getButtonPayment() {
      switch (this.realTimeValue.paymentInfo.status) {
        case 'pending':
          return 'paymentPending';
        case 'failed':
          return 'paymentFailed';
        case 'cancelled':
          return 'cancelled';
        default:
          return 'paymentCompleted';
      }
    },
    updateIssuePaymentFormData(formData: UpdateIssuePaymentFormData) {
      this.newIssuePaymentFormData = {
        ...formData,
        userId: this.userId,
        paymentInfo: {
          reference: formData.paymentInfo.reference,
          totalSum: +getTotalSum(formData.paymentInfo.totalSum),
          comment: formData.paymentInfo.comment
        }
      };

      delete this.newIssuePaymentFormData.reference;
      delete this.newIssuePaymentFormData.totalSum;
    },
    async submitHandler(data: OrderPayment) {
      if (
        this.issuePayment &&
        (!this.newIssuePaymentFormData.paymentInfo.totalSum ||
          +this.newIssuePaymentFormData.paymentInfo.totalSum < 0.01)
      ) {
        this.noTotalSum = true;
      } else {
        this.loading = true;
        const payload = {
          ...data,
          userId: this.userId,
          callReason: [
            ...(data.other ? ['other'] : []),
            ...(data.prescription ? ['prescription'] : [])
          ],
          orderType: [
            ...(data.selfCare ? ['selfCare'] : []),
            ...(data.prescriptionDrugs ? ['prescriptionDrugs'] : [])
          ]
        };

        delete payload.selfCare;
        delete payload.prescriptionDrugs;
        delete payload.prescription;
        delete payload.other;

        await this.getOrderCreate(payload);

        if (this.issuePayment) {
          this.sendPayment();
          this.noTotalSum = false;
        }

        this.isSaveForm = true;
        this.loading = false;
      }
    },
    async fetchPaymentStatus() {
      const database = getDatabase();

      const pharmacyId = this.user.pharmacyId;
      const orderId = this.orderPayment.orderId;

      const baseUrl = `/orders/${pharmacyId}/${orderId}`;

      const dbRef = ref(database, baseUrl);

      onValue(dbRef, (snapshot) => {
        this.realTimeValue = snapshot.val();

        if (this.realTimeValue.paymentInfo.status) {
          this.openButton = true;
        }
      });
    },
    async sendPayment() {
      this.loading = true;

      const payload = {
        ...this.newIssuePaymentFormData,
        sendPush: true,
        orderId: this.orderPayment.orderId
      };

      await this.getIssuePaymentPush(payload);
      this.fetchPaymentStatus();

      this.loading = false;

      if (!this.orderPushNotification) {
        this.authenticationFailed = true;
      } else {
        this.authenticationFailed = false;
      }
    },
    saveForm() {
      this.loading = true;
      if (!this.isSaveForm) {
        this.$formkit.submit('orderPayment-form');
      }
      this.loading = false;
    },
    finishAuthentication() {
      this.$formkit.reset('orderPayment-form');
      this.$emit('clearAllForms');
    },
    closeDiscardAuthentication() {
      this.showModalDiscardAuthentication = true;
    },
    discardAuthentication() {
      this.showModalDiscardAuthentication = false;
      this.$emit('clearAllForms');
    }
  },
  watch: {
    newIssuePaymentFormData() {
      if (
        this.newIssuePaymentFormData.paymentInfo.totalSum ||
        +this.newIssuePaymentFormData.paymentInfo.totalSum > 0.01
      ) {
        this.noTotalSum = false;
      } else {
        this.noTotalSum = true;
      }
    }
  }
})
export default class PaymentDetails extends Vue {}
