import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "passwordInput" }
const _hoisted_2 = { class: "passwordInput__inputs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormKit, {
        modelValue: _ctx.modelValue,
        name: _ctx.name,
        label: _ctx.label,
        type: _ctx.showPassword ? 'text' : 'password',
        validation: _ctx.validation,
        validationMessages: _ctx.validationMessages,
        help: _ctx.help
      }, null, 8, ["modelValue", "name", "label", "type", "validation", "validationMessages", "help"])
    ]),
    _createElementVNode("div", {
      class: "passwordInput__icon",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass({
          fa: true,
          'fa-eye-slash': !_ctx.showPassword,
          'fa-eye': _ctx.showPassword
        })
      }, null, 2)
    ])
  ]))
}