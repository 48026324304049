import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/CloseModal.svg'


const _hoisted_1 = { class: "reserveLocker" }
const _hoisted_2 = { class: "reserveLocker__header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "reserveLocker__body" }
const _hoisted_5 = {
  key: 0,
  class: "block__ssn"
}
const _hoisted_6 = {
  class: "label-ssn",
  for: "ssn"
}
const _hoisted_7 = { class: "reserveLocker__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Modal, { onClose: _ctx.closeModal }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.isReservations
              ? _ctx.$t('newBookingPharmacyPickup')
              : _ctx.$t('reserveLocker')), 1),
            _createElementVNode("img", {
              src: _imports_0,
              alt: "CloseModal",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_FormKit, {
              type: "form",
              id: "createReservation-form",
              modelValue: _ctx.formData,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData) = $event)),
              actions: false,
              config: _ctx.validationRules,
              onSubmit: _ctx.submitHandler
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Input, {
                  type: "tel",
                  name: "phoneNumber",
                  label: _ctx.$t('phoneNumber'),
                  validation: [['required'], ['matches', /^\+[0-9]{10,12}$/]],
                  "validation-visibility": "dirty",
                  validationMessages: {
              matches: _ctx.$t('validation.phoneNumber'),
              required: _ctx.$t('validation.required')
            }
                }, null, 8, ["label", "validationMessages"]),
                _createVNode(_component_Checkbox, {
                  class: _normalizeClass({
              disabledCheckbox: _ctx.pharmacy.strongAuthentication === 'off'
            }),
                  name: "is_drug",
                  value: _ctx.formData.is_drug,
                  title: _ctx.$t('isDrug'),
                  disabled: _ctx.pharmacy.strongAuthentication === 'off',
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.is_drug = !_ctx.formData.is_drug))
                }, null, 8, ["class", "value", "title", "disabled"]),
                (_ctx.formData.is_drug)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('ssn')), 1),
                      _createVNode(_component_Input, {
                        modelValue: _ctx.formData.ssn,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.ssn) = $event)),
                        type: "text",
                        name: "ssn"
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue", "config", "onSubmit"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Button, {
              variant: "filled",
              title: _ctx.$t('reserve'),
              onClick: _ctx.submitForm
            }, null, 8, ["title", "onClick"]),
            _createVNode(_component_Button, {
              variant: "notBordered",
              title: _ctx.$t('cancel'),
              onClick: _ctx.closeModal
            }, null, 8, ["title", "onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["onClose"])
  ], 64))
}