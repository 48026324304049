
import { Options, Vue } from 'vue-class-component';
import { mapActions } from 'vuex';

import Loading from '@/views/Loading.vue';

import { options } from './configs';
import { LangOptions, Option } from '@/types';

@Options({
  components: {
    Loading
  },
  data() {
    return {
      loading: null,
      langsDropdown: {
        selectedOption: {},
        options
      }
    };
  },
  created() {
    this.langsDropdown.selectedOption = this.isLangDefault;
  },
  computed: {
    isLogin() {
      return this.$route.name === 'Login';
    },
    isForgotPassword() {
      return this.$route.name === 'ForgotPassword';
    },
    isWelcomeToPharmacy() {
      return this.$route.name === 'WelcomeToPharmacy';
    },
    isAppRedirect() {
      return this.$route.name === 'AppRedirect';
    },
    isLangDefault() {
      return options.find((i: LangOptions) => {
        return i.value === this.$i18n.locale;
      });
    }
  },
  methods: {
    ...mapActions('account', ['langChange']),
    async selectOption(option: Option) {
      this.langsDropdown.selectedOption = option;
      const lang = this.langsDropdown.selectedOption.value;
      this.$i18n.locale = lang;
      localStorage.setItem('locale', lang);

      if (
        !this.isLogin &&
        !this.isForgotPassword &&
        !this.isWelcomeToPharmacy &&
        !this.isAppRedirect
      ) {
        this.loading = true;
        await this.langChange({ lang });
        this.loading = false;
      }
    }
  }
})
export default class LangChange extends Vue {}
