import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/BackTo.svg'
import _imports_1 from '@/assets/icons/BackToGreen.svg'


const _hoisted_1 = {
  key: 0,
  class: "link-img",
  src: _imports_0,
  alt: "BackTo"
}
const _hoisted_2 = {
  key: 1,
  class: "link-img",
  src: _imports_1,
  alt: "BackTo"
}
const _hoisted_3 = { class: "link-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      link: true,
      linkForgotPassword: _ctx.$route.name === 'ForgotPassword'
    }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('backTo')))
  }, [
    (_ctx.$route.name === 'ForgotPassword')
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.$route.name !== 'ForgotPassword')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
  ], 2))
}