import { Option, TranslationFunction } from '@/types';

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('nameUpperCase'),
    value: 'name'
  },
  {
    label: $t('emailUpperCase'),
    value: 'email'
  },
  {
    label: $t('roleUpperCase'),
    value: 'role'
  },
  {
    label: $t('keyUpperCase'),
    value: 'id'
  }
];
