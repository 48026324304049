import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/Logout.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "logout",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logoutUser && _ctx.logoutUser(...args)))
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('logout')), 1)
    ])
  ], 64))
}