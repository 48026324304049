import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "orderPayment" }
const _hoisted_2 = { class: "orderPayment__actionsTaken" }
const _hoisted_3 = { class: "orderPayment__title" }
const _hoisted_4 = { class: "orderPayment__title-text" }
const _hoisted_5 = { class: "orderPayment__checkboxes" }
const _hoisted_6 = { class: "orderPayment__order" }
const _hoisted_7 = { class: "orderPayment__title" }
const _hoisted_8 = { class: "orderPayment__title-text" }
const _hoisted_9 = { class: "orderPayment__checkboxes" }
const _hoisted_10 = {
  key: 0,
  class: "isOrder"
}
const _hoisted_11 = { class: "orderPayment__checkboxes" }
const _hoisted_12 = { class: "orderPayment-title" }
const _hoisted_13 = {
  key: 0,
  class: "tooltiptext"
}
const _hoisted_14 = { class: "orderPayment__numberAndComment" }
const _hoisted_15 = { class: "comment" }
const _hoisted_16 = {
  class: "label-comment",
  for: "comment"
}
const _hoisted_17 = { class: "orderPayment__container" }
const _hoisted_18 = { class: "orderPayment-title" }
const _hoisted_19 = { class: "orderPayment__deliveryMethod-radio" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 0,
  class: "orderPayment__container"
}
const _hoisted_22 = { class: "orderPayment-title" }
const _hoisted_23 = {
  key: 1,
  class: "noTotalSum"
}
const _hoisted_24 = { class: "orderPayment__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Radio = _resolveComponent("Radio")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_IssuePayment = _resolveComponent("IssuePayment")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_DiscardAuthentication = _resolveComponent("DiscardAuthentication")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormKit, {
        type: "form",
        id: "orderPayment-form",
        modelValue: _ctx.formData,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formData) = $event)),
        actions: false,
        onSubmit: _ctx.submitHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('orderPayment')), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Checkbox, {
                name: "prescription",
                value: _ctx.prescription,
                title: _ctx.$t('prescription'),
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.prescription = !_ctx.prescription))
              }, null, 8, ["value", "title"]),
              _createVNode(_component_Checkbox, {
                name: "other",
                value: _ctx.other,
                title: _ctx.$t('other'),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.other = !_ctx.other))
              }, null, 8, ["value", "title"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('medicationDelivery')), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Checkbox, {
                name: "prescriptionDrugs",
                value: _ctx.prescriptionDrugs,
                title: _ctx.$t('prescriptionDrugs'),
                onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changePrescriptionDrugs()))
              }, null, 8, ["value", "title"]),
              _createVNode(_component_Checkbox, {
                name: "selfCare",
                value: _ctx.selfCare,
                title: _ctx.$t('selfCare'),
                onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeSelfCare()))
              }, null, 8, ["value", "title"])
            ]),
            (_ctx.prescriptionDrugs || _ctx.selfCare)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('medicalAdviceUpperCase')), 1),
                    _createElementVNode("div", {
                      class: _normalizeClass({ tooltip: !_ctx.formData.consultation })
                    }, [
                      _createVNode(_component_Checkbox, {
                        name: "consultation",
                        value: _ctx.formData.consultation,
                        title: _ctx.$t('medicalAdviceGiven'),
                        onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.formData.consultation = !_ctx.formData.consultation))
                      }, null, 8, ["value", "title"]),
                      (!_ctx.formData.consultation)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('rememberAboutMedicalAdvice')), 1))
                        : _createCommentVNode("", true)
                    ], 2)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_Input, {
                      class: "input-reference",
                      type: "text",
                      name: "orderNumber",
                      value: _ctx.formData.orderNumber,
                      label: _ctx.$t('orderNumber')
                    }, null, 8, ["value", "label"]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('internalNote')), 1),
                      _withDirectives(_createElementVNode("textarea", {
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.comment) = $event)),
                        id: "comment",
                        class: "input-comment",
                        maxlength: "250"
                      }, "              ", 512), [
                        [_vModelText, _ctx.formData.comment]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('deliveryMethodUpperCase')), 1),
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_Radio, {
                        name: 'pharmacy',
                        title: _ctx.$t('pharmacy'),
                        value: _ctx.formData.deliveryMethod === 'pharmacy',
                        onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateDeliveryMethod('pharmacy')))
                      }, null, 8, ["title", "value"]),
                      _createVNode(_component_Radio, {
                        name: 'pickupbox',
                        title: _ctx.$t('pickupBox'),
                        value: _ctx.formData.deliveryMethod === 'pickupbox',
                        onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateDeliveryMethod('pickupbox')))
                      }, null, 8, ["title", "value"]),
                      _createVNode(_component_Radio, {
                        name: 'homeDelivery',
                        value: _ctx.formData.deliveryMethod === 'homeDelivery',
                        title: _ctx.$t('homeDelivery'),
                        onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateDeliveryMethod('homeDelivery')))
                      }, null, 8, ["value", "title"])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onSubmit"]),
      (_ctx.prescriptionDrugs || _ctx.selfCare)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            (_ctx.pharmacy.payments)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t('issuePaymentUpperCase')), 1),
                  _createVNode(_component_Checkbox, {
                    name: "issuePayment",
                    value: _ctx.issuePayment,
                    title: _ctx.$t('issuePayment'),
                    onChange: _ctx.changeIssuePayment
                  }, null, 8, ["value", "title", "onChange"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_IssuePayment, {
        issuePayment: _ctx.issuePayment,
        orderPayment: _ctx.orderPayment,
        "onUpdate:issuePaymentFormData": _ctx.updateIssuePaymentFormData
      }, null, 8, ["issuePayment", "orderPayment", "onUpdate:issuePaymentFormData"]),
      (_ctx.noTotalSum)
        ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.$t('fieldIsIncorrectly')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_Button, {
          class: _normalizeClass({ disabledBackgroundLong: _ctx.issuePayment }),
          variant: _ctx.isSaveForm ? 'disabledBackground' : 'filled',
          title: _ctx.$t(_ctx.getTitleSaveForm()),
          onClick: _ctx.saveForm
        }, null, 8, ["class", "variant", "title", "onClick"]),
        (_ctx.openButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "authCheck",
              variant: _ctx.getButtonPayment(),
              title: _ctx.$t(_ctx.getButtonPayment())
            }, null, 8, ["variant", "title"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Footer, {
        isSaveForm: _ctx.isSaveForm,
        onCloseDiscardAuthentication: _ctx.closeDiscardAuthentication,
        onFinishAuthentication: _ctx.finishAuthentication
      }, null, 8, ["isSaveForm", "onCloseDiscardAuthentication", "onFinishAuthentication"]),
      _createVNode(_component_DiscardAuthentication, {
        reference: _ctx.reference,
        showModalDiscardAuthentication: _ctx.showModalDiscardAuthentication,
        onDiscardAuthentication: _ctx.discardAuthentication,
        onCloseModal: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showModalDiscardAuthentication = false))
      }, null, 8, ["reference", "showModalDiscardAuthentication", "onDiscardAuthentication"])
    ])
  ], 64))
}