import Login from '@/pages/Auth/Login.vue';
import ForgotPassword from '@/pages/Auth/ForgotPassword.vue';
import ChangePharmacy from '@/pages/Auth/ChangePharmacy.vue';
import SignicatVerify from '@/pages/Auth/SignicatVerify.vue';
import VerifiedUser from '@/pages/Auth/VerifiedUser.vue';
import AuthenticationRegister from '@/pages/Auth/AuthenticationRegister.vue';
import StrongAuthenticationRegister from '@/pages/Auth/StrongAuthenticationRegister.vue';
import WelcomeToPharmacy from '@/pages/Auth/WelcomeToPharmacy.vue';
import AppRedirect from '@/pages/Auth/AppRedirect.vue';
import PaymentSuccess from '@/pages/Auth/PaymentSuccess.vue';
import PaymentFailed from '@/pages/Auth/PaymentFailed.vue';
import PickupPinQR from '@/pages/Auth/PickupPinQR.vue';
import PageNotFound from '@/views/NotFound.vue';
import LocationNotAllowed from '@/views/LocationNotAllowed.vue';
import EditPharmacy from '@/pages/EditPharmacy/EditPharmacy.vue';
import CallerAuthentication from '@/pages/CallerAuthentication/CallerAuthentication.vue';
import AuthenticationHistory from '@/pages/AuthenticationHistory/AuthenticationHistory.vue';
import OrdersHistory from '@/pages/OrdersHistory/OrdersHistory.vue';
import ViewOrder from '@/pages/OrdersHistory/ViewOrder/ViewOrder.vue';
import PickupBoxes from '@/pages/PickupBoxes/PickupBoxes.vue';
import Lockers from '@/pages/PickupBoxes/Lockers/Lockers.vue';
import InfoPickupBox from '@/pages/PickupBoxes/InfoPickupBox/InfoPickupBox.vue';
import Reservations from '@/pages/PickupBoxes/Reservations/Reservations.vue';
import APIReservations from '@/pages/PickupBoxes/APIReservations/APIReservations.vue';
import DeliveryHistory from '@/pages/PickupBoxes/DeliveryHistory/DeliveryHistory.vue';
import TemperatureLog from '@/pages/PickupBoxes/TemperatureLog/TemperatureLog.vue';
import Alarms from '@/pages/PickupBoxes/Alarms/Alarms.vue';
import DeliveryStatistics from '@/pages/PickupBoxes/DeliveryStatistics/DeliveryStatistics.vue';
import Profile from '@/pages/Profile/Profile.vue';
import CapacityPickupBox from '@/pages/PickupBoxes/InfoPickupBox/CapacityPickupBox/CapacityPickupBox.vue';
import EditPickupBox from '@/pages/PickupBoxes/InfoPickupBox/EditPickupBox/EditPickupBox.vue';
import Users from '@/pages/Users/Users.vue';
import InfoUser from '@/pages/Users/InfoUser/InfoUser.vue';
import AddUser from '@/pages/Users/AddUser/AddUser.vue';
import CreateUser from '@/pages/Users/CreateUser/CreateUser.vue';
import LoginInformation from '@/pages/Users/LoginInformation/LoginInformation.vue';
import CallbackRequests from '@/pages/CallbackRequests/CallbackRequests.vue';
import CustomersFeedback from '@/pages/CustomersFeedback/CustomersFeedback.vue';
import Statistics from '@/pages/CustomersFeedback/Statistics/Statistics.vue';
import Customers from '@/pages/CustomersFeedback/Customers/Customers.vue';
import SettingsCustomersFeedback from '@/pages/CustomersFeedback/SettingsCustomersFeedback/SettingsCustomersFeedback.vue';
import General from '@/pages/CustomersFeedback/SettingsCustomersFeedback/General/General.vue';
import Options from '@/pages/CustomersFeedback/SettingsCustomersFeedback/Options/Options.vue';
import Report from '@/pages/CustomersFeedback/SettingsCustomersFeedback/Report/Report.vue';
import Grade from '@/pages/CustomersFeedback/SettingsCustomersFeedback/Grade/Grade.vue';
import Contact from '@/pages/CustomersFeedback/SettingsCustomersFeedback/Contact/Contact.vue';
import Thanks from '@/pages/CustomersFeedback/SettingsCustomersFeedback/Thanks/Thanks.vue';

import { getCurrentUser } from '@/firebase/firebaseInit';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import 'firebase/compat/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: PickupBoxes,
    children: [],
    meta: {
      title: 'Home',
      requiresAuth: true
    }
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login',
      requiresAuth: false
    }
  },
  {
    path: '/verify/:businessId/:deliveryId',
    name: 'SignicatVerify',
    component: SignicatVerify,
    meta: {
      title: 'Signicat Verify',
      requiresAuth: false
    }
  },
  {
    path: '/verified',
    name: 'VerifiedUser',
    component: VerifiedUser,
    meta: {
      title: 'Verified User',
      requiresAuth: false
    }
  },
  {
    path: '/pickup-pin-qr',
    name: 'PickupPinQR',
    component: PickupPinQR,
    meta: {
      title: 'Pickup Pin QR',
      requiresAuth: false
    }
  },
  {
    path: '/authentication/register/verified',
    name: 'AuthenticationRegister',
    component: AuthenticationRegister,
    meta: {
      title: 'Authentication Register',
      requiresAuth: false
    }
  },
  {
    path: '/authentication/re-register/verified',
    name: 'StrongAuthenticationRegister',
    component: StrongAuthenticationRegister,
    meta: {
      title: 'Strong Authentication Register',
      requiresAuth: false
    }
  },
  {
    path: '/link/pharmacy-id/:id',
    name: 'WelcomeToPharmacy',
    component: WelcomeToPharmacy,
    meta: {
      title: 'Welcome to Pharmacy',
      requiresAuth: false
    }
  },
  {
    path: '/link/apps',
    name: 'AppRedirect',
    component: AppRedirect,
    meta: {
      title: 'App Redirect',
      requiresAuth: false
    }
  },
  {
    path: '/payments/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
    meta: {
      title: 'Payment Success',
      requiresAuth: false
    }
  },
  {
    path: '/payments/failure',
    name: 'PaymentFailed',
    component: PaymentFailed,
    meta: {
      title: 'Payment Failed',
      requiresAuth: false
    }
  },
  {
    path: '/auth/changePharmacy',
    name: 'ChangePharmacy',
    component: ChangePharmacy,
    meta: {
      title: 'Change Pharmacy',
      requiresAuth: false
    }
  },
  {
    path: '/auth/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password',
      requiresAuth: false
    }
  },
  {
    path: '/404',
    component: PageNotFound,
    name: 'NotFound',
    meta: {
      title: 'Page Not Found'
    }
  },
  {
    path: '/locationNotAllowed',
    component: LocationNotAllowed,
    name: 'LocationNotAllowed',
    meta: {
      title: 'Location Not Allowed',
      requiresAuth: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  },
  {
    path: '/pharmacy/:id',
    name: 'EditPharmacy',
    component: EditPharmacy,
    meta: {
      title: 'Edit Pharmacy',
      requiresAuth: true
    }
  },
  {
    path: '/profile/',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Profile',
      requiresAuth: true
    }
  },
  {
    path: '/callerAuthentication',
    name: 'CallerAuthentication',
    component: CallerAuthentication,
    meta: {
      title: 'Caller Authentication',
      requiresAuth: true
    }
  },
  {
    path: '/authenticationHistory',
    name: 'AuthenticationHistory',
    component: AuthenticationHistory,
    meta: {
      title: 'Authentication History',
      requiresAuth: true
    }
  },
  {
    path: '/ordersHistory',
    name: 'OrdersHistory',
    component: OrdersHistory,
    meta: {
      title: 'Orders History',
      requiresAuth: true
    }
  },
  {
    path: '/viewOrder/:id',
    name: 'ViewOrder',
    component: ViewOrder,
    meta: {
      title: 'View Order',
      requiresAuth: true
    }
  },
  {
    path: '/pickup-boxes',
    name: 'PickupBoxes',
    component: PickupBoxes,
    children: [
      {
        path: ':id/:boxId/info-lockers',
        name: 'Lockers',
        component: Lockers
      },
      {
        path: ':id/info-pickup-boxes',
        name: 'InfoPickupBox',
        component: InfoPickupBox
      },
      {
        path: ':id/reservations',
        name: 'Reservations',
        component: Reservations
      },
      {
        path: ':id/apiReservations',
        name: 'APIReservations',
        component: APIReservations
      },
      {
        path: ':id/delivery-history',
        name: 'DeliveryHistory',
        component: DeliveryHistory
      },
      {
        path: ':id/temperature-log',
        name: 'TemperatureLog',
        component: TemperatureLog
      },
      {
        path: ':id/alarms',
        name: 'Alarms',
        component: Alarms
      },
      {
        path: ':id/delivery-statistics',
        name: 'DeliveryStatistics',
        component: DeliveryStatistics
      }
    ],
    meta: {
      title: 'Pick-up Boxes',
      requiresAuth: true
    }
  },
  {
    path: '/:id/capacity-pickup-box/:boxId',
    name: 'CapacityPickupBox',
    component: CapacityPickupBox,
    meta: {
      title: 'Capacity Pickup Box',
      requiresAuth: true
    }
  },
  {
    path: '/:id/edit-pickup-box/:boxId',
    name: 'EditPickupBox',
    component: EditPickupBox,
    meta: {
      title: 'Edit Pickup Box',
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      title: 'Users',
      requiresAuth: true
    }
  },
  {
    path: '/user/:id',
    name: 'InfoUser',
    component: InfoUser,
    meta: {
      title: 'Info User',
      requiresAuth: true
    }
  },
  {
    path: '/user/add',
    name: 'AddUser',
    component: AddUser,
    meta: {
      title: 'Add User',
      requiresAuth: true
    }
  },
  {
    path: '/user/create',
    name: 'CreateUser',
    component: CreateUser,
    meta: {
      title: 'Create User',
      requiresAuth: true
    }
  },
  {
    path: '/user/:id/login-info',
    name: 'LoginInformation',
    component: LoginInformation,
    meta: {
      title: 'Login Information',
      requiresAuth: true
    }
  },
  {
    path: '/callbackRequests',
    name: 'CallbackRequests',
    component: CallbackRequests,
    meta: {
      title: 'Callback Requests',
      requiresAuth: true
    }
  },
  {
    path: '/customersFeedback',
    name: 'CustomersFeedback',
    component: CustomersFeedback,
    children: [
      {
        path: '/statistics',
        name: 'Statistics',
        component: Statistics
      },
      {
        path: '/customers',
        name: 'Customers',
        component: Customers
      }
    ],
    meta: {
      title: 'Customers Feedback',
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'SettingsCustomersFeedback',
    component: SettingsCustomersFeedback,
    children: [
      {
        path: ':id/general',
        name: 'General',
        component: General
      },
      {
        path: ':id/options',
        name: 'Options',
        component: Options
      },
      {
        path: ':id/report',
        name: 'Report',
        component: Report
      },
      {
        path: ':id/grade',
        name: 'Grade',
        component: Grade
      },
      {
        path: ':id/contact',
        name: 'Contact',
        component: Contact
      },
      {
        path: ':id/thanks',
        name: 'Thanks',
        component: Thanks
      }
    ],
    meta: {
      title: 'Settings Customers Feedback',
      requiresAuth: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export const isCypressEnv = (): boolean =>
  Object.prototype.hasOwnProperty.call(window, 'Cypress');

router.beforeEach(async (to, from, next) => {
  document.title = `${to.meta.title} | Remomedi`;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !(await getCurrentUser()) && !isCypressEnv()) {
    next('/auth/login');
  } else {
    next();
  }
});

export default router;
