import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/CloseModal.svg'


const _hoisted_1 = { class: "openQRCode" }
const _hoisted_2 = { class: "openQRCode__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.showQRCode)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "openQRCode__header-img",
                src: _imports_0,
                alt: "Close Modal",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
              })
            ]),
            _createElementVNode("form", null, [
              _createVNode(_component_Input, {
                type: "text",
                value: _ctx.idActiveDelivery,
                title: _ctx.$t('kolliID'),
                disabled: ""
              }, null, 8, ["value", "title"])
            ]),
            _createVNode(_component_qrcode_vue, {
              value: _ctx.idActiveDelivery,
              size: 300,
              level: "H"
            }, null, 8, ["value"])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}