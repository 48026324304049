import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/CloseModal.svg'


const _hoisted_1 = { class: "serviceRequest" }
const _hoisted_2 = { class: "serviceRequest__header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "serviceRequest__body" }
const _hoisted_5 = {
  class: "label-description",
  for: "description"
}
const _hoisted_6 = { class: "serviceRequest__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.showModalServiceRequest)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('descriptionServiceRequest')), 1),
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_FormKit, {
                type: "form",
                id: "description-form",
                modelValue: _ctx.formData,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData) = $event)),
                actions: false,
                onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('saveServiceRequest', _ctx.formData)))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('description')), 1),
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.description) = $event)),
                    id: "description",
                    class: "input-description",
                    maxlength: "250"
                  }, "          ", 512), [
                    [_vModelText, _ctx.formData.description]
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Button, {
                variant: "filled",
                title: _ctx.$t('save'),
                onClick: _ctx.submitHandler
              }, null, 8, ["title", "onClick"]),
              _createVNode(_component_Button, {
                variant: "redBordered",
                title: _ctx.$t('cancel'),
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('closeModal')))
              }, null, 8, ["title"])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}