import { Option, TranslationFunction } from '../../types';

export const searchActions = ($t: TranslationFunction): Option[] => [
  {
    value: 'firstName',
    label: $t('firstName')
  },
  {
    value: 'lastName',
    label: $t('lastName')
  },
  {
    value: 'phoneNumber',
    label: $t('phoneNumber')
  }
];

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('consentGiven'),
    value: 'consentGiven'
  },
  {
    label: $t('userUpperCase'),
    value: 'userNAME'
  },
  {
    label: $t('phoneUpperCase'),
    value: 'phoneNumber'
  },
  {
    label: $t('ssn'),
    value: 'ssn'
  },
  {
    label: $t('timestampUpperCase'),
    value: 'timestamp'
  }
];

export const columnsDow = ($t: TranslationFunction): Option[] => [
  {
    label: $t('userUpperCase'),
    value: 'userNAME'
  },
  {
    label: $t('phoneUpperCase'),
    value: 'phoneNumber'
  },
  {
    label: $t('timestampUpperCase'),
    value: 'timestamp'
  }
];
