import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "viewOrder" }
const _hoisted_2 = { class: "viewOrder__link" }
const _hoisted_3 = { class: "viewOrder__header" }
const _hoisted_4 = {
  key: 0,
  class: "payment"
}
const _hoisted_5 = { class: "viewOrder__main" }
const _hoisted_6 = {
  key: 0,
  class: "info"
}
const _hoisted_7 = {
  key: 1,
  class: "info"
}
const _hoisted_8 = { class: "info" }
const _hoisted_9 = {
  key: 2,
  class: "info"
}
const _hoisted_10 = {
  key: 3,
  class: "info"
}
const _hoisted_11 = {
  key: 4,
  class: "info"
}
const _hoisted_12 = { class: "info" }
const _hoisted_13 = {
  key: 1,
  class: "content"
}
const _hoisted_14 = {
  key: 5,
  class: "info"
}
const _hoisted_15 = {
  key: 0,
  class: "cancelPaymentRequest"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Title = _resolveComponent("Title")!
  const _component_ClientInfo = _resolveComponent("ClientInfo")!
  const _component_PaymentInfo = _resolveComponent("PaymentInfo")!
  const _component_OpenConfirmation = _resolveComponent("OpenConfirmation")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Link, {
          title: _ctx.$t('backToOrdersHistory'),
          onBackTo: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backTo()))
        }, null, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Title, {
          title: _ctx.$t('viewOrder')
        }, null, 8, ["title"]),
        (_ctx.viewOrder?.paymentInfo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("p", {
                class: _normalizeClass({
            paymentTitle: true,
            paymentPending: _ctx.viewOrder.paymentInfo.status === 'pending',
            paymentFailed:
              _ctx.viewOrder.paymentInfo.status === 'failed' ||
              _ctx.viewOrder.paymentInfo.status === 'cancelled',
            paymentCompleted:
              _ctx.viewOrder.paymentInfo.status === 'completed' ||
              _ctx.viewOrder.paymentInfo.status === 'done'
          })
              }, _toDisplayString(_ctx.getPaymentStatus(_ctx.viewOrder.paymentInfo.status)), 3)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          (_ctx.viewOrder?.callReason?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('reasonForTransaction')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.getString(_ctx.viewOrder?.callReason)), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.viewOrder?.orderType)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('orderPayment')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.getString(_ctx.viewOrder?.orderType)), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('medicalAdviceGiven')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.getConsultation(_ctx.viewOrder?.consultation)), 1)
          ]),
          (_ctx.viewOrder?.orderNumber)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('orderNumber')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.viewOrder?.orderNumber), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.viewOrder?.comment)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('internalNote')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.viewOrder?.comment), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.viewOrder?.deliveryMethod)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('deliveryMethod')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.getDeliveryMethod(_ctx.viewOrder.deliveryMethod)), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('deliveryContentMatchesOrder')), 1),
            (!_ctx.viewOrder?.confirmed)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass({ confirm: !_ctx.viewOrder?.confirmed }),
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openConfirmation && _ctx.openConfirmation(...args)))
                }, _toDisplayString(_ctx.$t('confirm')), 3))
              : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('confirmedDy')) + " " + _toDisplayString(_ctx.viewOrder?.confirmedUserName), 1),
                  _createElementVNode("span", null, " (" + _toDisplayString(_ctx.dateTime(_ctx.viewOrder?.confirmedTime)) + ")", 1)
                ]))
          ]),
          (_ctx.viewOrder?.handler)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('handler')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.viewOrder.handler), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_ClientInfo, {
          viewOrder: _ctx.viewOrder,
          pharmacyId: _ctx.pharmacyId
        }, null, 8, ["viewOrder", "pharmacyId"])
      ]),
      _createVNode(_component_PaymentInfo, {
        paymentInfo: _ctx.viewOrder?.paymentInfo
      }, null, 8, ["paymentInfo"])
    ]),
    _createVNode(_component_OpenConfirmation, {
      showOpenConfirmation: _ctx.showOpenConfirmation,
      onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showOpenConfirmation = false)),
      onConfirmRequest: _ctx.confirmRequest
    }, null, 8, ["showOpenConfirmation", "onConfirmRequest"]),
    (_ctx.viewOrder?.paymentInfo && _ctx.viewOrder?.paymentInfo.status === 'pending')
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode(_component_Footer, {
            paymentNotificationWasSuccessfullyResend: 
        _ctx.paymentNotificationWasSuccessfullyResend
      ,
            paymentInfo: _ctx.viewOrder.paymentInfo,
            onCancelPayment: _ctx.cancelPayment,
            onResendPaymentNotificationResult: _ctx.resendPaymentNotificationResult
          }, null, 8, ["paymentNotificationWasSuccessfullyResend", "paymentInfo", "onCancelPayment", "onResendPaymentNotificationResult"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}