import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "profile" }
const _hoisted_2 = { class: "profile__container" }
const _hoisted_3 = { class: "profile__container-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Title = _resolveComponent("Title")!
  const _component_Input = _resolveComponent("Input")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ChangeLang = _resolveComponent("ChangeLang")!
  const _component_ChangePassword = _resolveComponent("ChangePassword")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Title, {
      title: _ctx.$t('profile')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormKit, {
        type: "form",
        id: "profile-form",
        modelValue: _ctx.formData,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData) = $event)),
        actions: false,
        onSubmit: _ctx.submitHandler
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoFromUser, (el) => {
            return (_openBlock(), _createBlock(_component_Input, {
              key: el.id,
              type: el.type,
              name: el.name,
              label: _ctx.$t(el.label),
              validation: el.validation,
              validationMessages: {
            required: _ctx.$t('validation.required')
          },
              "data-testid": el.dataTestId
            }, null, 8, ["type", "name", "label", "validation", "validationMessages", "data-testid"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoRoleAndPin, (el) => {
            return (_openBlock(), _createBlock(_component_Input, {
              key: el.id,
              type: el.type,
              name: el.name,
              label: _ctx.$t(el.label),
              disabled: _ctx.disabledInput(el.name),
              validation: el.validation,
              validationMessages: {
            required: _ctx.$t('validation.required'),
            length: _ctx.$t('validation.length')
          },
              "data-testid": el.dataTestId
            }, null, 8, ["type", "name", "label", "disabled", "validation", "validationMessages", "data-testid"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onSubmit"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Button, {
          variant: "filled",
          title: _ctx.$t('save'),
          onClick: _ctx.submitForm
        }, null, 8, ["title", "onClick"]),
        _createVNode(_component_Button, {
          variant: "notBordered",
          title: _ctx.$t('cleanFields'),
          onClick: _ctx.cleanForm
        }, null, 8, ["title", "onClick"])
      ]),
      _createVNode(_component_ChangeLang),
      _createVNode(_component_ChangePassword)
    ])
  ]))
}