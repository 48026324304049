
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import { Params } from '@/store/modules/users/types';

import { dateTimeResult } from '@/utils/tableFunctions';

@Options({
  name: 'ClientInfo',
  props: ['viewOrder', 'pharmacyId'],
  components: {
    Loading
  },
  data() {
    return {
      dataSSN: '',
      loading: false,
      showDecodedSSN: false,
      consentGiven: true
    };
  },
  computed: {
    ...mapState('orders', ['decodedSSN'])
  },
  methods: {
    ...mapActions('orders', ['getOrdersHistoryDecodedSSN']),
    async initSSN() {
      const params: Params = {
        pharmacyId: this.pharmacyId,
        orderId: this.viewOrder.id
      };

      this.showDecodedSSN = !this.showDecodedSSN;

      if (this.showDecodedSSN) {
        this.loading = true;

        await this.getOrdersHistoryDecodedSSN(params);

        this.dataSSN = this.decodedSSN?.ssn;
        this.loading = false;
      }
    },
    dateTimeResult
  }
})
export default class ClientInfo extends Vue {}
