import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "report" }
const _hoisted_2 = { class: "report__translations" }
const _hoisted_3 = { class: "report__translations" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ShowSettings = _resolveComponent("ShowSettings")!
  const _component_TitleOptions = _resolveComponent("TitleOptions")!
  const _component_Input = _resolveComponent("Input")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormKit, {
        type: "form",
        id: "report-form",
        modelValue: _ctx.formData,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData) = $event)),
        actions: false,
        onSubmit: _ctx.submitHandler
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ShowSettings, {
            value: _ctx.formData.show,
            selectedPosition: _ctx.formData.position,
            "onUpdate:selectedPosition": _ctx.updateSelectedPosition,
            onSelectOption: _ctx.selectShowSettings
          }, null, 8, ["value", "selectedPosition", "onUpdate:selectedPosition", "onSelectOption"]),
          _createVNode(_component_TitleOptions, {
            title: _ctx.$t('titleUpperCase')
          }, null, 8, ["title"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_FormKit, {
              type: "group",
              name: "title_text"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.translations, (translation, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "container",
                    key: index
                  }, [
                    _createElementVNode("span", null, _toDisplayString(translation.label), 1),
                    _createVNode(_component_Input, {
                      type: "text",
                      name: translation.value,
                      value: _ctx.formData.title_text[translation.value]
                    }, null, 8, ["name", "value"])
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_TitleOptions, {
            title: _ctx.$t('hintTextUpperCase')
          }, null, 8, ["title"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_FormKit, {
              type: "group",
              name: "hint_text"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.translations, (translation, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "container",
                    key: index
                  }, [
                    _createElementVNode("span", null, _toDisplayString(translation.label), 1),
                    _createVNode(_component_Input, {
                      type: "text",
                      name: translation.value,
                      value: _ctx.formData.hint_text[translation.value]
                    }, null, 8, ["name", "value"])
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onSubmit"]),
      _createVNode(_component_Button, {
        variant: "filled",
        title: _ctx.$t('save'),
        onClick: _ctx.submitForm
      }, null, 8, ["title", "onClick"])
    ])
  ], 64))
}