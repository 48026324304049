import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/Add.svg'


const _hoisted_1 = { class: "addOption" }
const _hoisted_2 = {
  key: 0,
  class: "addOption__true"
}
const _hoisted_3 = { class: "addOption__true-title" }
const _hoisted_4 = {
  key: 0,
  class: "tooltiptext"
}
const _hoisted_5 = {
  key: 1,
  class: "addOption__false"
}
const _hoisted_6 = { class: "addOption__false-title" }
const _hoisted_7 = { class: "addOption__dropdown" }
const _hoisted_8 = { class: "title-options" }
const _hoisted_9 = {
  key: 0,
  class: "error-message"
}
const _hoisted_10 = {
  key: 1,
  class: "error-message"
}
const _hoisted_11 = { class: "addOption__infoTemplates" }
const _hoisted_12 = { class: "addOption__infoTemplates-translations" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Input = _resolveComponent("Input")!
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({ tootip: _ctx.numberActiveOptions === 3 }),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showAddOption && _ctx.showAddOption(...args)))
    }, [
      (!_ctx.openAddOption)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[4] || (_cache[4] = _createElementVNode("img", {
              src: _imports_0,
              alt: "Add"
            }, null, -1)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('addOption')), 1),
            (_ctx.numberActiveOptions === 3)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('addNewOption')), 1))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('doNotSaveOption')), 1)
          ]))
    ], 2),
    (_ctx.conditionShowAddOption())
      ? (_openBlock(), _createBlock(_component_FormKit, {
          key: 0,
          type: "form",
          id: "option-form",
          name: "newOption",
          modelValue: _ctx.formDataOption,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formDataOption) = $event)),
          actions: false,
          onSubmit: _ctx.submitForm
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Checkbox, {
                name: "enabled",
                value: _ctx.formDataOption.enabled,
                title: _ctx.$t('active'),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.formDataOption.enabled = !_ctx.formDataOption.enabled))
              }, null, 8, ["value", "title"]),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('position')), 1),
              _createVNode(_component_Dropdown, {
                class: _normalizeClass({
            'dropdown-positions': true,
            'dropdown-positions-error':
              _ctx.formDataOption.position === null && !_ctx.error
          }),
                selectedOption: _ctx.selectedPositions.selectedOption,
                options: _ctx.selectedPositions.options,
                onSelectOption: _cache[2] || (_cache[2] = (option) => _ctx.selectPositions(option))
              }, null, 8, ["class", "selectedOption", "options"]),
              (_ctx.formDataOption.position === null && !_ctx.error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('validation.required')), 1))
                : _createCommentVNode("", true),
              (_ctx.error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.error), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_Input, {
                type: "text",
                name: "title",
                value: _ctx.formDataOption.title,
                validation: 'required',
                validationMessages: {
            required: _ctx.$t('validation.required')
          }
              }, null, 8, ["value", "validationMessages"]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_FormKit, {
                  type: "group",
                  name: "translations"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.translations, (translation, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "container",
                        key: index
                      }, [
                        _createElementVNode("span", null, _toDisplayString(translation.label), 1),
                        _createVNode(_component_Input, {
                          type: "text",
                          name: translation.value,
                          value: _ctx.formDataOption.translations[translation.value],
                          validation: 'required',
                          validationMessages: {
                  required: _ctx.$t('validation.required')
                }
                        }, null, 8, ["name", "value", "validationMessages"])
                      ]))
                    }), 128))
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "onSubmit"]))
      : _createCommentVNode("", true)
  ]))
}