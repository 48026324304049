import { Option, TranslationFunction } from '@/types';

export const searchActions = ($t: TranslationFunction): Option[] => [
  {
    value: 'phoneNumber',
    label: $t('mobilePhone')
  },
  {
    value: 'deliveryId',
    label: $t('kolliID')
  }
];

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('mobilePhoneUpperCase'),
    value: 'phoneNumber'
  },
  {
    label: $t('kolliIDUpperCase'),
    value: 'deliveryIds'
  },
  {
    label: $t('lockerIDUpperCase'),
    value: 'lockerId'
  },
  {
    label: $t('reservedUpperCase'),
    value: 'reservedTime'
  },
  {
    label: $t('reservationMadeByUpperCase'),
    value: 'reservedUserName'
  },
  {
    label: $t('deliveredUpperCase'),
    value: 'deliveryTime'
  },
  {
    label: $t('deliveredByUpperCase'),
    value: 'deliveryUserName'
  },
  {
    label: $t('assignedTimeByUpperCase'),
    value: 'assignedTime'
  },
  {
    label: $t('descriptionUpperCase'),
    value: 'descriptionText'
  }
];
