import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "optionsInputs" }
const _hoisted_2 = { class: "optionsInputs__title" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "options__dropdown-block" }
const _hoisted_5 = {
  key: 0,
  class: "error-message"
}
const _hoisted_6 = { class: "options__infoTemplates" }
const _hoisted_7 = { class: "options__infoTemplates__translations" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Input = _resolveComponent("Input")!
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('optionsUpperCase')), 1)
    ]),
    _createVNode(_component_FormKit, {
      type: "group",
      name: "options"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "optionsInputs-container",
            key: index
          }, [
            _createVNode(_component_FormKit, {
              type: "group",
              name: index
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Checkbox, {
                    name: 'enabled_' + index,
                    value: option.enabled,
                    title: _ctx.$t('active'),
                    checked: option.enabled,
                    onChange: ($event: any) => (option.enabled = !option.enabled)
                  }, null, 8, ["name", "value", "title", "checked", "onChange"]),
                  _createElementVNode("p", {
                    class: _normalizeClass({
                'title-options': true,
                'title-options-error': _ctx.positionConflict(index) && option.enabled
              })
                  }, _toDisplayString(_ctx.$t('position')), 3),
                  _createVNode(_component_Dropdown, {
                    class: _normalizeClass({
                'dropdown-positions': true,
                'dropdown-disabled': !option.enabled
              }),
                    selectedOption: _ctx.selectedPositions.selectedOption[index],
                    options: _ctx.selectedPositions.options,
                    onSelectOption: (option) => _ctx.selectPositions(index, option),
                    disabled: !option.enabled
                  }, null, 8, ["class", "selectedOption", "options", "onSelectOption", "disabled"]),
                  (_ctx.positionConflict(index) && option.enabled)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('theSamePositions')), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", {
                    class: _normalizeClass({
                'options__infoTemplates-name': true,
                'option-disabled': !option.enabled
              })
                  }, _toDisplayString(option.translations[_ctx.userAppLanguage]), 3),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_FormKit, {
                      type: "group",
                      name: "translations"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.translations, (translation, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "container",
                            key: index
                          }, [
                            _createElementVNode("span", null, _toDisplayString(translation.label), 1),
                            _createVNode(_component_Input, {
                              class: _normalizeClass({ 'input-disabled': !option.enabled }),
                              type: "text",
                              name: translation.value,
                              value: option.translations[translation.value],
                              disabled: _ctx.disabledInput(option)
                            }, null, 8, ["class", "name", "value", "disabled"])
                          ]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["name"])
          ]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}