import Vuex, { StoreOptions } from 'vuex';
import { account } from './modules/account/index';
import { RootState } from './types';
import { entityNames } from './modules/entityNames/index';
import { pharmacy } from './modules/pharmacy/index';
import { users } from './modules/users/index';
import { roles } from './modules/roles/index';
import { callerAuthentication } from './modules/callerAuthentication/index';
import { orders } from './modules/orders/index';

const store: StoreOptions<RootState> = {
  state: {
    welcomeMessage: 'Hello'
  },
  mutations: {},
  actions: {},
  modules: {
    account,
    entityNames,
    pharmacy,
    users,
    roles,
    callerAuthentication,
    orders
  }
};

export default new Vuex.Store<RootState>(store);
