import { Option, OptionWithTestId, TranslationFunction } from '@/types';

export const defaultFormData = {
  firstName: '',
  lastName: '',
  email: '',
  role: {
    boxAccess: true,
    webToolAccess: true,
    name: ''
  },
  pharmacyAccesses: [],
  pharmacistPin: ''
};

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('firstNameUpperCase'),
    value: 'firstName'
  },
  {
    label: $t('lastNameUpperCase'),
    value: 'lastName'
  },
  {
    label: $t('emailUpperCase'),
    value: 'email'
  },
  {
    label: $t('pharmacyNameUpperCase'),
    value: 'pharmacyId'
  },
  {
    label: $t('roleUpperCase'),
    value: 'role'
  },
  {
    label: $t('userIdUpperCase'),
    value: 'id'
  }
];

export const accesses = ($t: TranslationFunction): OptionWithTestId[] => [
  {
    name: 'webToolAccess',
    title: $t('webToolAccess'),
    testId: 'checkWebToolAccess'
  },
  {
    name: 'boxAccess',
    title: $t('boxAccess'),
    testId: 'checkBoxAccess'
  }
];
