import { MutationTree } from 'vuex';
import { AccountState, UserProfile } from '@/store/modules/account/types';

export enum AccountMutations {
  loaded_true = 'loaded_true',
  logging_out = 'logging_out',
  login_request = 'login_request',
  login_error = 'login_error',
  login_set_user = 'login_set_user',
  login_success = 'login_success',
  authentication_signicat = 'authentication_signicat',
  result_pickup_pin = 'result_pickup_pin',
  result_pickup_pin_strong_redirect = 'result_pickup_pin_strong_redirect',
  reset_password_error = 'reset_password_error',
  logout = 'logout',
  logoutToken = 'logoutToken',
  update_admin_profile = 'update_admin_profile'
}

export const mutations: MutationTree<AccountState> = {
  [AccountMutations.loaded_true](state) {
    state.status.loaded = true;
  },
  [AccountMutations.logging_out](state) {
    state.status = {
      ...state.status,
      loggingOut: true
    };
  },
  [AccountMutations.login_request](state) {
    state.status.loggingIn = true;
    state.logoutMsg = null;
  },
  [AccountMutations.login_error](state, message: string) {
    state.status.loggedIn = false;
    state.status.loaded = true;
    state.status.error = true;
    state.status.message = message;
    state.userCreds.password = '';
    state.user = null;
  },
  [AccountMutations.login_set_user](state, user: UserProfile | null) {
    state.user = user;
  },
  [AccountMutations.login_success](state) {
    state.status.loggingIn = true;
    state.status.loaded = true;
    state.status.sidebar = true;
    state.userCreds.password = '';
    state.userCreds.email = '';
  },
  [AccountMutations.reset_password_error](state, message: string) {
    state.status.loggedIn = false;
    state.status.loaded = true;
    state.status.error = true;
    state.status.message = message;
    state.userCreds.password = '';
    state.user = null;
  },
  [AccountMutations.logout](state, logoutMsg: string) {
    state.status = {
      loaded: true,
      sidebar: false,
      loggingOut: false,
      loggingIn: false,
      loggedIn: true,
      error: null,
      message: null
    };
    state.user = null;
    state.token = '';
    state.userCreds = {
      email: '',
      password: ''
    };
    state.logoutMsg = logoutMsg;
  },
  [AccountMutations.logoutToken](state, logoutMsg: string) {
    state.status = {
      loaded: true,
      sidebar: false,
      loggingOut: false,
      loggingIn: false,
      loggedIn: true,
      error: null,
      message: null
    };
    state.user = null;
    state.token = '';
    state.userCreds = {
      email: '',
      password: ''
    };
    state.logoutMsg = logoutMsg;
  },
  [AccountMutations.update_admin_profile](state, { user }) {
    state.user = user;
  },
  [AccountMutations.authentication_signicat](
    state,
    { authenticationSignicat }
  ) {
    state.authenticationSignicat = authenticationSignicat;
  },
  [AccountMutations.result_pickup_pin](state, { resultPickupPin }) {
    state.resultPickupPin = resultPickupPin;
  },
  [AccountMutations.result_pickup_pin_strong_redirect](
    state,
    { resultPickupPinStrongRedirect }
  ) {
    state.resultPickupPinStrongRedirect = resultPickupPinStrongRedirect;
  }
};
