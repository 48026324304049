export const lockers = [
  {
    code: 0,
    label: 'small'
  },
  {
    code: 1,
    label: 'medium'
  },
  {
    code: 2,
    label: 'large'
  },
  {
    code: 'fridge',
    label: 'fridge'
  }
];

export const kolliID = [
  {
    type: 'text',
    name: 'deliveryId',
    label: 'kolliID',
    validation: 'required'
  },
  {
    type: 'text',
    name: 'additionalDeliveryId1',
    label: 'kolliID'
  },
  {
    type: 'text',
    name: 'additionalDeliveryId2',
    label: 'kolliID'
  }
];
