export const infoFromUser = [
  {
    type: 'text',
    name: 'firstName',
    label: 'firstName',
    autocomplete: 'off',
    validation: 'required',
    dataTestId: 'inputFirstName'
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'lastName',
    autocomplete: 'off',
    validation: 'required',
    dataTestId: 'inputLastName'
  }
];

export const allInfoFromUser = [
  {
    type: 'text',
    name: 'firstName',
    label: 'firstName',
    validation: 'required',
    dataTestId: 'inputFirstName'
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'lastName',
    validation: 'required',
    dataTestId: 'inputLastName'
  },
  {
    type: 'email',
    name: 'email',
    label: 'email',
    validation: 'required',
    dataTestId: 'inputEmail'
  }
];

export const allStatus = {
  free: 'Green',
  reserved: 'Orange',
  delivered: 'Blue',
  expired: 'Red',
  removed: 'Red',
  inactive: 'Gray'
};

export const authenticationNameInfo = [
  {
    type: 'text',
    name: 'firstName',
    label: 'firstName'
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'lastName'
  }
];

export const authenticationSsnAndAddressInfo = [
  {
    type: 'text',
    name: 'ssn',
    label: 'socialSecurityNumber'
  },
  {
    type: 'text',
    name: 'homeAddress',
    label: 'homeAddress'
  }
];
