import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "issuePayment"
}
const _hoisted_2 = {
  key: 0,
  class: "totalSum-error"
}
const _hoisted_3 = { class: "comment" }
const _hoisted_4 = {
  class: "label-comment",
  for: "comment"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_ctx.issuePayment)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_FormKit, {
          type: "form",
          id: "issuePayment-form",
          modelValue: _ctx.issuePaymentFormData,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.issuePaymentFormData) = $event)),
          actions: false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormKit, {
              type: "group",
              name: "paymentInfo"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Input, {
                  class: "input-reference",
                  name: "reference",
                  value: _ctx.issuePaymentFormData.paymentInfo.reference,
                  type: "text",
                  label: _ctx.$t('reference')
                }, null, 8, ["value", "label"]),
                _createVNode(_component_Input, {
                  class: _normalizeClass({
            'input-totalSum': !_ctx.totalSumIncorrect,
            'input-totalSum-incorrect': _ctx.totalSumIncorrect
          }),
                  name: "totalSum",
                  value: _ctx.issuePaymentFormData.paymentInfo.totalSum,
                  type: "number",
                  label: _ctx.$t('totalSum'),
                  min: "0.01",
                  validation: [['required'], ['min:0.01']],
                  validationMessages: {
            required: _ctx.$t('validation.required'),
            min: _ctx.$t('minimal_payment_amount')
          }
                }, null, 8, ["class", "value", "label", "validationMessages"]),
                (_ctx.totalSumIncorrect)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('minimal_payment_amount')), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('customerReceiptMessage')), 1),
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.issuePaymentFormData.paymentInfo.comment) = $event)),
                    id: "comment",
                    class: "input-comment",
                    maxlength: "250"
                  }, "          ", 512), [
                    [_vModelText, _ctx.issuePaymentFormData.paymentInfo.comment]
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
    : _createCommentVNode("", true)
}