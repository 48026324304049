
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

@Options({
  name: 'SignicatVerify',
  components: {
    Loading
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState('account', ['authenticationSignicat', 'status'])
  },
  created() {
    this.show();
  },
  methods: {
    ...mapActions('account', ['getAuthenticationSignicat']),
    async show() {
      this.loading = true;
      const { businessId, deliveryId } = this.$route.params;

      await this.getAuthenticationSignicat({ businessId, deliveryId });

      if (this.authenticationSignicat?.authLink) {
        const a = document.createElement('a');
        a.href = this.authenticationSignicat.authLink;
        a.click();
        a.remove();
      }

      this.loading = false;
    }
  }
})
export default class SignicatVerifyComponent extends Vue {}
