
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

@Options({
  name: 'Footer',
  props: [
    'disabledMakeServiceRequest',
    'isSaveForm',
    'paymentNotificationWasSuccessfullyResend',
    'paymentInfo'
  ],
  computed: {
    ...mapState('account', ['user'])
  },
  methods: {
    getVariant() {
      if (
        !this.user?.role.permissions.pharmacy.update &&
        this.$route.name === 'EditPharmacy'
      ) {
        return 'disabled';
      } else {
        return 'filled';
      }
    },
    disabledButton() {
      if (
        !this.user?.role.permissions.pharmacy.update &&
        this.$route.name === 'EditPharmacy'
      ) {
        return true;
      } else {
        return false;
      }
    },
    getVariantButtonCleanFields() {
      switch (this.$route.name) {
        case 'EditPharmacy':
          if (!this.user?.role.permissions.pharmacy.update) {
            return 'disabledNotBordered';
          } else {
            return 'notBordered';
          }
        case 'InfoUser':
          if (this.user?.role.permissions.user.update) {
            return 'notBordered';
          }
          break;
        default:
          return '';
      }
    }
  }
})
export default class FooterComponent extends Vue {}
