
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import { columnsLoginInfo } from './configs';
import { Params } from '@/store/modules/users/types';
import router from '@/router';

@Options({
  name: 'LoginInfo',
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      params: null,
      columns: columnsLoginInfo(this.$t)
    };
  },
  created() {
    this.show();
  },
  computed: {
    ...mapState('users', ['loginInfo'])
  },
  methods: {
    ...mapActions('users', ['getLoginInfo']),
    async show(params: Params) {
      this.loading = true;
      const { id } = this.$route.params;

      let defaultParams = {
        pharmacyId: id,
        limit: 20
      };

      if (params) {
        defaultParams = {
          ...defaultParams,
          ...(params.lastTimestamp !== undefined && {
            lastTimestamp: params.lastTimestamp
          }),
          ...(params.limit !== undefined && { limit: params.limit })
        };
      }

      await this.getLoginInfo(defaultParams);
      this.loading = false;
    },
    changePage() {
      const lastTimestamp = this.loginInfo[this.loginInfo.length - 1].timestamp;

      this.params = {
        ...this.params,
        lastTimestamp
      };
    },
    backTo() {
      router.push({ name: 'Users' });
    }
  },
  watch: {
    params() {
      this.show(this.params);
    }
  }
})
export default class LoginInformation extends Vue {}
