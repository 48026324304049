export const generalOptions = [
  {
    value: 'screenTimeout',
    label: 'screenTimeoutUpperCase'
  },
  {
    value: 'color',
    label: 'colorUpperCase'
  },
  {
    value: 'photo',
    label: 'photoUpperCase'
  }
];
