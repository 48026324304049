import { RootState } from '@/store/types';
import { OrderPaymentState } from './types';
import { GetterTree } from 'vuex';

export const getters: GetterTree<OrderPaymentState, RootState> = {
  status: (state) => state.status,
  orderPushNotification: (state) => state.orderPushNotification,
  orderPayment: (state) => state.orderPayment,
  feedbackStatistics: (state) => state.feedbackStatistics,
  ordersHistory: (state) => state.ordersHistory,
  viewOrder: (state) => state.viewOrder,
  decodedSSN: (state) => state.decodedSSN
};
