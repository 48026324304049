import { Option, OptionWithIMG, TranslationFunction } from '@/types';

export const checkboxes = [
  { name: 'showOnlyWithContact', value: false, title: 'showOnlyWithContact' },
  { name: 'showOnlyWithOption', value: false, title: 'showOnlyWithOption' },
  { name: 'showOnlyWithReport', value: false, title: 'showOnlyWithReport' },
  { name: 'showOnlyWithGrade', value: false, title: 'showOnlyWithGrade' }
];

export const activeOptionsFace = ($t: TranslationFunction): OptionWithIMG[] => [
  {
    label: $t('happy'),
    value: 'GOOD',
    img: require('@/assets/icons/HappyPressed.png')
  },
  {
    label: $t('ok'),
    value: 'OK',
    img: require('@/assets/icons/OkPressed.png')
  },
  {
    label: $t('angry'),
    value: 'BAD',
    img: require('@/assets/icons/AngryPressed.png')
  }
];

export const activeOptionsGrade = [
  {
    label: 1,
    value: 1
  },
  {
    label: 2,
    value: 2
  },
  {
    label: 3,
    value: 3
  },
  {
    label: 4,
    value: 4
  },
  {
    label: 5,
    value: 5
  },
  {
    label: 6,
    value: 6
  },
  {
    label: 7,
    value: 7
  },
  {
    label: 8,
    value: 8
  },
  {
    label: 9,
    value: 9
  },
  {
    label: 10,
    value: 10
  }
];

export const activeOptionsGradeType = ($t: TranslationFunction): Option[] => [
  {
    label: $t('higher'),
    value: 'higher'
  },
  {
    label: $t('lower'),
    value: 'lower'
  },
  {
    label: $t('same'),
    value: 'same'
  }
];

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('contactUpperCase'),
    value: 'contact'
  },
  {
    label: $t('reportUpperCase'),
    value: 'report'
  },
  {
    label: $t('optionsUpperCase'),
    value: 'options'
  },
  {
    label: $t('gradeUpperCase'),
    value: 'grade'
  },
  {
    label: $t('timestampUpperCase'),
    value: 'timestamp'
  }
];
