import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/icons/Noutoapteekk.jpg'


const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "login__content" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "login__content-head" }
const _hoisted_5 = { class: "login__content-head-lang" }
const _hoisted_6 = { class: "login__content-form" }
const _hoisted_7 = { class: "form" }
const _hoisted_8 = { class: "form-title" }
const _hoisted_9 = { class: "form-inputs" }
const _hoisted_10 = { class: "form-action" }
const _hoisted_11 = { class: "form-error" }
const _hoisted_12 = {
  class: "error",
  role: "alert"
}
const _hoisted_13 = { class: "form-buttons" }
const _hoisted_14 = { class: "login-microsoft" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LangChange = _resolveComponent("LangChange")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "login__image" }, [
      _createElementVNode("div", { class: "container" }, [
        _createElementVNode("img", {
          class: "image",
          src: _imports_0,
          alt: "Noutoapteekk"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_LangChange)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('login')), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Input, {
                type: "email",
                name: "email",
                label: _ctx.$t('email'),
                value: _ctx.email,
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                forName: "email",
                validation: "required|email",
                validationMessages: {
                  required: _ctx.$t('validation.required'),
                  email: _ctx.$t('validation.email')
                },
                onChange: _ctx.handleChange
              }, null, 8, ["label", "value", "modelValue", "validationMessages", "onChange"]),
              _createVNode(_component_Input, {
                type: "password",
                name: "password",
                label: _ctx.$t('password'),
                value: _ctx.password,
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                onChange: _ctx.handleChange,
                forName: "password",
                validation: "required",
                validationMessages: {
                  required: _ctx.$t('validation.required')
                }
              }, null, 8, ["label", "value", "modelValue", "onChange", "validationMessages"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_ctx.forgotPassword === false)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "forgot-password",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openForgotPassword && _ctx.openForgotPassword(...args)))
                  }, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('forgotPassword')), 1)
                  ]))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t(_ctx.status.message || 'error')), 1)
              ], 512), [
                [_vShow, _ctx.status.error]
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_Button, {
                id: "login",
                class: "button-log",
                variant: "logIn",
                type: "button",
                onClick: _ctx.signIn,
                title: _ctx.$t('login'),
                "data-testid": "loginButton"
              }, null, 8, ["onClick", "title"]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_Button, {
                  id: "authenticateUsingProvider",
                  class: "button-log",
                  variant: "blackButton",
                  type: "button",
                  title: _ctx.$t('loginWithMicrosoft'),
                  "data-testid": "loginloginWithMicrosoftButton"
                }, null, 8, ["title"])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}