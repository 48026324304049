
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import {
  langsEn,
  langsFi,
  langsSv,
  langsNo,
  optionsLanguageEn,
  optionsLanguageFi,
  optionsLanguageSv,
  optionsLanguageNo
} from '@/services/languageService';
import { Option } from '@/types';

@Options({
  name: 'ChangeLang',
  components: {
    Loading
  },
  data() {
    return {
      formData: {
        lang: ''
      },
      langsDropdown: {
        selectedOption: {
          label: '',
          value: ''
        },
        options: []
      },
      loading: false
    };
  },
  computed: {
    ...mapState('account', ['user'])
  },
  methods: {
    ...mapActions('account', ['changeLang', 'getUserProfile']),
    initLanguage() {
      if (this.user?.appLanguage === 'en') {
        this.langsDropdown.options = optionsLanguageEn;
      }
      if (this.user?.appLanguage === 'fi') {
        this.langsDropdown.options = optionsLanguageFi;
      }
      if (this.user?.appLanguage === 'sv') {
        this.langsDropdown.options = optionsLanguageSv;
      }
      if (this.user?.appLanguage === 'no') {
        this.langsDropdown.options = optionsLanguageNo;
      }
    },
    selectLanguage(option: Option) {
      this.langsDropdown.selectedOption = option;
    },
    async submitHandler() {
      this.loading = true;

      const payload = {
        lang: this.langsDropdown.selectedOption.value
      };

      await this.changeLang(payload);
      await this.getUserProfile();
      this.$i18n.locale = this.langsDropdown.selectedOption.value;
      this.$formkit.reset('lang-form');
      this.loading = false;
    },
    submitForm() {
      this.$formkit.submit('lang-form');
    }
  },
  watch: {
    user() {
      if (this.user && this.user.appLanguage) {
        this.initLanguage();
        const langs = {
          en: langsEn,
          fi: langsFi,
          sv: langsSv,
          no: langsNo
        };

        const language = this.user.appLanguage as keyof typeof langs;

        this.langsDropdown.selectedOption = {
          label: langs[language][language],
          value: this.user.appLanguage
        };
      }
    }
  }
})
export default class ChangeLang extends Vue {}
