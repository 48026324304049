
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import FeedbackChartsDays from './FeedbackChartsDays/FeedbackChartsDays.vue';
import FeedbackMainTable from './FeedbackMainTable/FeedbackMainTable.vue';
import CustomersFeedbackWeek from './CustomersFeedbackWeek/CustomersFeedbackWeek.vue';

import router from '@/router';

import {
  columnsTemplates,
  activeOptions,
  defaultDataLineChart,
  defaultDataBarChart
} from '../configs';

import { InputFileEvent, Option } from '@/types';

@Options({
  name: 'Statistics',
  props: ['pharmacy'],
  components: {
    Loading,
    FeedbackChartsDays,
    FeedbackMainTable,
    CustomersFeedbackWeek
  },
  data() {
    return {
      pharmacyId: '',
      loading: false,
      getOptionIntervals: {
        selectedOption: {
          label: `${30} ${this.$t('minutes')}`,
          value: 30
        },
        options: activeOptions(this.$t)
      },
      getOptionTemplatesCharts: {
        selectedOption: [],
        options: {}
      },
      dataLineChart: defaultDataLineChart,
      dataBarChart: defaultDataBarChart,
      selectedTemplatesString: '',
      selectedInterval: 30,
      hourInterval: 0,
      columnsTemplates: columnsTemplates(this.$t),
      showBlockWeekCharts: false,
      showDropdownSettingTemplates: false,
      params: {
        pharmacyId: '',
        startTime: new Date().setHours(0, 0, 0, 0).valueOf(),
        endTime: new Date().getTime(),
        minutesInterval: 30,
        chartFields: ''
      }
    };
  },
  created() {
    if (this.user) {
      this.pharmacyId = this.user?.pharmacyId;
      this.params = {
        pharmacyId: this.pharmacyId,
        startTime: this.params.startTime,
        endTime: this.params.endTime,
        minutesInterval: this.selectedInterval,
        chartFields: this.selectedTemplatesString
      };

      this.initOptionTemplatesCharts();
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('orders', ['feedbackStatistics']),
    today() {
      const todayDate = new Date().getTime();

      return todayDate;
    },
    firstDay(): number {
      const firstDate = new Date().setHours(0, 0, 0, 0).valueOf();

      return firstDate;
    },
    isDateCorrect() {
      if (this.params && this.params.startTime && this.params.endTime) {
        return this.params.endTime >= this.params.startTime;
      }
      return true;
    }
  },
  updated() {
    this.getOptionTemplatesCharts.selectedOption = this.optionTemplatesCharts;
  },
  methods: {
    ...mapActions('orders', ['getFeedbackStatistics']),
    initOptionTemplatesCharts() {
      this.optionTemplatesCharts = Object.keys(
        this.pharmacy.feedbackTemplate.options_screen.options
      ).map((key: string) => {
        const language = this.user?.appLanguage;

        const translations =
          this.pharmacy?.feedbackTemplate.options_screen.options[key]
            ?.translations;

        if (Object.keys(translations).includes(language)) {
          if (translations[language]) {
            return { value: key, label: translations?.[language] };
          } else {
            return {
              value: key,
              label: translations?.['en']
            };
          }
        } else {
          return key;
        }
      });

      this.getOptionTemplatesCharts.options = { ...this.optionTemplatesCharts };

      this.getSelectedTemplates(this.optionTemplatesCharts);
    },
    getSelectedTemplates(options: Option[]) {
      const selectedTemplates = options.map((i: Option) => i.value);
      this.selectedTemplatesString = selectedTemplates.join('__');
    },
    async initData() {
      this.loading = true;

      const defaultParams = { ...this.params };

      if (
        defaultParams.startTime &&
        defaultParams.endTime &&
        this.isDateCorrect
      ) {
        await this.getFeedbackStatistics(defaultParams);
        this.initChartsData();

        this.loading = false;
      }
    },
    initChartsData() {
      if (this.feedbackStatistics?.labels?.length && this.loading) {
        this.dataLineChart = {
          ...this.feedbackStatistics.chartDatasets,
          datasets: [
            {
              ...this.feedbackStatistics.chartDatasets.datasets[0],
              label: this.$t('happy'),
              data: Object.values(
                this.feedbackStatistics.chartDatasets.datasets[0].data
              )
            },
            {
              ...this.feedbackStatistics.chartDatasets.datasets[1],
              label: this.$t('ok'),
              data: Object.values(
                this.feedbackStatistics.chartDatasets.datasets[1].data
              )
            },
            {
              ...this.feedbackStatistics.chartDatasets.datasets[2],
              label: this.$t('angry'),
              data: Object.values(
                this.feedbackStatistics.chartDatasets.datasets[2].data
              )
            }
          ],
          labels: this.feedbackStatistics.labels
        };

        this.dataBarChart = {
          ...this.feedbackStatistics.diagramDatasets,
          datasets: [
            {
              ...this.feedbackStatistics.diagramDatasets.datasets[0],
              label: this.$t('numberOfUsers'),
              data: Object.values(
                this.feedbackStatistics.diagramDatasets.datasets[0].data
              )
            }
          ],
          labels: this.feedbackStatistics.labels
        };
      }
    },
    selectOptionTemplatesCharts(option: Option) {
      this.getOptionTemplatesCharts.selectedOption = option;
    },
    selectOptionIntervals(option: Option) {
      this.getOptionIntervals.selectedOption = option;
    },
    changeDate(e: InputFileEvent) {
      const { name, value } = e.target;

      this.params = {
        ...this.params,
        [name]: value
      };
    },
    closeTimePanel() {
      this.initChartsData();
    },
    changeItemTemplates(option: Option) {
      this.getOptionTemplates.selectedOption = option;
    },
    showWeekCharts() {
      this.showBlockWeekCharts = !this.showBlockWeekCharts;
    },
    goToSettings() {
      router.push({ name: 'SettingsCustomersFeedback' });
    }
  },
  watch: {
    params() {
      if (this.isDateCorrect) {
        this.dataLineChart = { ...defaultDataLineChart };
        this.dataBarChart = { ...defaultDataBarChart };

        this.initData(this.params);
      }
    },
    pharmacy() {
      if (this.pharmacy) {
        this.pharmacyId = this.user?.pharmacyId;
        this.params = {
          pharmacyId: this.pharmacyId,
          startTime: this.params.startTime,
          endTime: this.params.endTime,
          minutesInterval: this.selectedInterval,
          chartFields: this.selectedTemplatesString
        };

        this.initOptionTemplatesCharts();
      }
    },
    'getOptionTemplatesCharts.selectedOption': {
      immediate: true,
      handler(option: (Option | string)[]) {
        this.optionTemplatesCharts = option;
        this.getSelectedTemplates(this.optionTemplatesCharts);
      }
    },
    selectedTemplatesString() {
      if (this.feedbackStatistics?.labels?.length) {
        this.params = {
          ...this.params,
          chartFields: this.selectedTemplatesString
        };
      }
    },
    selectedInterval() {
      if (this.feedbackStatistics?.labels?.length) {
        this.params = {
          ...this.params,
          minutesInterval: this.selectedInterval
        };
      }
    },
    'getOptionIntervals.selectedOption': {
      immediate: true,
      handler(option: (Option | string)[]) {
        this.optionIntervals = option;
        this.selectedInterval = this.optionIntervals.value;

        if (this.optionIntervals.value === 60) {
          this.selectedInterval = 60;
          this.hourInterval = 60;
        }
      }
    }
  }
})
export default class Statistics extends Vue {}
