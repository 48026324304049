
import { Options, Vue } from 'vue-class-component';
import { mapState, mapActions } from 'vuex';

import LangChange from '@/views/Language.vue';
import Loading from '@/views/Loading.vue';

import router from '@/router';
import { InputFileEvent } from '@/types';

import { goToPickupBox } from '@/utils/goToPickupBox';
import { goToEditPharmacy } from '@/utils/goToEditPharmacy';

@Options({
  components: {
    Loading,
    LangChange
  },
  data() {
    return {
      email: null,
      password: null,
      loading: false,
      forgotPassword: false,
      pharmacyLoading: true,
      boxId: ''
    };
  },
  computed: {
    ...mapState('account', ['status', 'user']),
    ...mapState('pharmacy', ['pharmacy'])
  },
  created() {
    this.$i18n.locale = 'fi';
  },
  methods: {
    ...mapActions('account', ['login', 'logout', 'loginWithMicrosoft']),
    ...mapActions('pharmacy', ['getPharmacy']),
    handleChange(e: InputFileEvent) {
      this[e.target.name] = e.target.value;
    },
    async signIn() {
      this.forgotPassword = false;

      if (this.email && this.password) {
        this.loading = true;

        await this.login({
          email: this.email,
          password: this.password
        });

        this.loading = false;

        if (
          !this.user.pharmacyAccesses ||
          this.user.pharmacyAccesses?.length <= 1
        ) {
          this.getTabs();
        }
      }

      this.$i18n.locale = localStorage.getItem('locale') || 'en';
    },
    async getTabs() {
      this.loading = true;
      this.pharmacyLoading = true;

      if (this.user?.role.name !== 'janitor') {
        await this.getPharmacy(this.user?.pharmacyId);
      }

      this.boxId = this.pharmacy?.pickupBoxes[0];
      this.pharmacyLoading = false;

      if (!this.pharmacyLoading && this.user) {
        if (this.user?.role.name === 'janitor') {
          return this.goToProfile();
        }
        if (this.pharmacy?.remomediServices.pickupbox && this.boxId) {
          return goToPickupBox(
            this.user?.pharmacyId,
            this.pharmacy?.pickupBoxes[0]
          );
        } else {
          return this.pharmacy?.remomediServices.mobileApp
            ? router.push({ name: 'CallerAuthentication' })
            : goToEditPharmacy(this.user.pharmacyId);
        }
      }

      this.loading = false;
    },
    goToProfile() {
      router.push({
        name: 'Profile'
      });
    },
    openForgotPassword() {
      this.forgotPassword = true;
      router.push({ name: 'ForgotPassword' });
    },
    loginAD() {
      this.loginWithMicrosoft();
    }
  }
})
export default class LoginComponent extends Vue {}
