import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/Fridge.svg'


const _hoisted_1 = { class: "modules" }
const _hoisted_2 = { class: "module-block" }
const _hoisted_3 = { class: "module" }
const _hoisted_4 = { class: "moduleId" }
const _hoisted_5 = { class: "id" }
const _hoisted_6 = { class: "moduleInfo" }
const _hoisted_7 = ["onClick", "data-testid"]
const _hoisted_8 = { class: "locker-name" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 1,
  src: _imports_0,
  alt: ""
}
const _hoisted_11 = {
  key: 1,
  class: "createReservation"
}
const _hoisted_12 = { class: "createReservation__selectedLockers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ReserveLocker = _resolveComponent("ReserveLocker")!
  const _component_ChooseDelivery = _resolveComponent("ChooseDelivery")!
  const _component_InfoLocker = _resolveComponent("InfoLocker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.positions, (moduleIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "container",
            key: moduleIndex
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.modules[moduleIndex].id), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modules[moduleIndex].lockers, (locker, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass({
                locker: true,
                [_ctx.boxesNames[locker.type]?.value]: true,
                fridge: locker.fridge,
                active: _ctx.activeLocker === `${_ctx.modules[moduleIndex].id} - ${index}`
              }),
                    onClick: ($event: any) => (
                _ctx.chooseLocker(
                  _ctx.modules[moduleIndex].id,
                  index,
                  _ctx.boxesNames[locker.type],
                  locker.temperature
                )
              ),
                    "data-testid": 'moduleInfo' + index
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("img", {
                        class: "statusImg",
                        src: 
                    require(`@/assets/icons/${_ctx.getStatusReservations(
                      locker,
                      _ctx.allStatus
                    )}.svg`)
                  ,
                        alt: "status"
                      }, null, 8, _hoisted_9),
                      _createElementVNode("span", null, _toDisplayString(_ctx.modules[moduleIndex].id) + " - " + _toDisplayString(index) + " " + _toDisplayString(_ctx.$t(_ctx.boxesNames[locker.type]?.label)), 1)
                    ]),
                    (_ctx.multipleLockers === true)
                      ? (_openBlock(), _createBlock(_component_Checkbox, {
                          key: 0,
                          class: "checkbox-lockerId",
                          name: `${_ctx.modules[moduleIndex].id}-${index}`,
                          value: 
                  _ctx.selectedLockers.includes(
                    `${_ctx.modules[moduleIndex].id}-${index}`
                  )
                ,
                          disabled: _ctx.disabledLocker(),
                          onChange: _ctx.handleCheckbox
                        }, null, 8, ["name", "value", "disabled", "onChange"]))
                      : _createCommentVNode("", true),
                    (locker.fridge)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_7))
                }), 128))
              ])
            ])
          ]))
        }), 128))
      ])
    ]),
    (_ctx.multipleLockers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", null, _toDisplayString(_ctx.selectedLockers.length) + " " + _toDisplayString(_ctx.$t('lockersSelected')), 1)
          ]),
          _createVNode(_component_Button, {
            class: "createReservation__button",
            variant: _ctx.selectedLockers.length ? 'filled' : 'disabled',
            title: _ctx.$t('createReservation'),
            disabled: !_ctx.selectedLockers.length === true,
            onClick: _ctx.closeModalReservation
          }, null, 8, ["variant", "title", "disabled", "onClick"]),
          _createVNode(_component_Button, {
            class: "assignReservation-button",
            variant: _ctx.selectedLockers.length ? 'filled' : 'disabledLong',
            title: _ctx.$t('assignReservation'),
            disabled: !_ctx.selectedLockers.length === true,
            onClick: _ctx.assignReservation
          }, null, 8, ["variant", "title", "disabled", "onClick"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showModalReserveLocker)
      ? (_openBlock(), _createBlock(_component_ReserveLocker, {
          key: 2,
          onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModalReserveLocker = false)),
          selectedLockers: _ctx.selectedLockers,
          activePickupBox: _ctx.activePickupBox
        }, null, 8, ["selectedLockers", "activePickupBox"]))
      : _createCommentVNode("", true),
    (_ctx.showModalChooseDelivery)
      ? (_openBlock(), _createBlock(_component_ChooseDelivery, {
          key: 3,
          showModalChooseDelivery: _ctx.showModalChooseDelivery,
          activeBoxId: _ctx.activeBoxId,
          selectedLockers: _ctx.selectedLockers,
          onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModalChooseDelivery = false))
        }, null, 8, ["showModalChooseDelivery", "activeBoxId", "selectedLockers"]))
      : _createCommentVNode("", true),
    (!_ctx.multipleLockers)
      ? (_openBlock(), _createBlock(_component_InfoLocker, {
          key: 4,
          order: _ctx.order,
          activeLocker: _ctx.activeLocker,
          lockerData: _ctx.lockerData,
          activePickupBox: _ctx.activePickupBox,
          onUpdateLockerDataCallback: _ctx.updateLockerDataCallback
        }, null, 8, ["order", "activeLocker", "lockerData", "activePickupBox", "onUpdateLockerDataCallback"]))
      : _createCommentVNode("", true)
  ], 64))
}