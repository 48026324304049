
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import {
  defaultDataLineChartWeek,
  defaultDataBarChartWeek,
  optionsLineChart,
  optionsBarChart
} from './configs';

import { Option } from '@/types';
import { DataLineChart, Label } from '@/store/modules/pharmacy/types';

@Options({
  name: 'CustomersFeedbackWeek',
  props: ['pharmacy'],
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      params: null,
      todayDate: null,
      firstDate: null,
      selectedTemplatesString: '',
      selectedInterval: 30,
      getOptionTemplatesCharts: {
        selectedOption: [],
        options: {}
      },
      dataLineChartWeek: defaultDataLineChartWeek,
      dataBarChartWeek: defaultDataBarChartWeek,
      optionsLineChart: optionsLineChart,
      optionsBarChart: optionsBarChart,
      height: 200,
      width: 500
    };
  },
  created() {
    this.show();
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('orders', ['feedbackStatisticsWeek']),
    styleLineChart() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
        position: 'relative'
      };
    },
    styleBarChart() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
        position: 'relative'
      };
    }
  },
  updated() {
    this.getOptionTemplatesCharts.selectedOption = this.optionTemplatesCharts;
  },
  methods: {
    ...mapActions('orders', ['getFeedbackStatisticsWeek']),
    show() {
      this.loading = true;

      if (this.pharmacy) {
        this.initOptionTemplatesCharts();
        this.initRequest();
      }
    },
    initOptionTemplatesCharts() {
      this.optionTemplatesCharts = Object.keys(
        this.pharmacy.feedbackTemplate.options_screen.options
      ).map((key: string) => {
        const language = this.user?.appLanguage;

        const translations =
          this.pharmacy?.feedbackTemplate.options_screen.options[key]
            ?.translations;

        if (Object.keys(translations).includes(language)) {
          if (translations[language]) {
            return { value: key, label: translations?.[language] };
          } else {
            return {
              value: key,
              label: translations?.['en']
            };
          }
        } else {
          return key;
        }
      });

      this.getOptionTemplatesCharts.options = { ...this.optionTemplatesCharts };
      this.getOptionTemplatesCharts.selectedOption = [
        ...this.optionTemplatesCharts
      ];

      this.getSelectedTemplates(this.optionTemplatesCharts);
    },
    getSelectedTemplates(options: Option[]) {
      const selectedTemplates = options.map((i: Option) => i.value);
      this.selectedTemplatesString = selectedTemplates.join('__');
    },
    getDefaultDate() {
      const yesterday = new Date(Date.now() - 86400000);
      this.todayDate = yesterday.setHours(23, 59, 59, 999);

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      this.firstDate = today.getTime() - 7 * 24 * 60 * 60 * 1000;
    },
    initRequest() {
      this.getDefaultDate();

      this.params = {
        pharmacyId: this.pharmacy.id,
        startTime: this.firstDate,
        endTime: this.todayDate,
        minutesInterval: this.selectedInterval,
        chartFields: this.selectedTemplatesString
      };
    },
    getDayOfWeek(date: string) {
      const dayOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      const dateParts = date.split('.');
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];
      const dateObject = new Date(`${year}-${month}-${day}`);

      return dayOfWeek[dateObject.getDay()];
    },
    getCount(labels: Array<[string]>, date: string) {
      return labels.filter(([labelDate]: [string]) => labelDate === date)
        .length;
    },
    async initData() {
      const defaultParams = { ...this.params };

      if (defaultParams.startTime && defaultParams.endTime) {
        this.loading = true;

        await this.getFeedbackStatisticsWeek(defaultParams);
        this.initChartsData();

        this.loading = false;
      }
    },

    getLabelsNoRepetitions(labels: Label[]) {
      const result: Array<string[]> = [];

      labels.map(([date, dayName]) => {
        const days: Record<string, string> = {
          Sunday: this.$t('sunday'),
          Monday: this.$t('monday'),
          Tuesday: this.$t('tuesday'),
          Wednesday: this.$t('wednesday'),
          Thursday: this.$t('thursday'),
          Friday: this.$t('friday'),
          Saturday: this.$t('saturday')
        };

        dayName = days[dayName];

        const label = [date, dayName];
        result.push(label);
      });

      return result;
    },
    getData(datasets: DataLineChart, indexData: number, labels: Label[]) {
      const result: Array<number> = [];

      let index = 0;
      labels.forEach((label: Label) => {
        const count = label[2];
        let sum = 0;
        for (let i = 0; i < count; i++) {
          sum += datasets[indexData].data[index + i];
        }

        result.push(sum);
        index += count;
      });

      return result;
    },
    initChartsData() {
      if (this.feedbackStatisticsWeek?.labels?.length && this.loading) {
        const labels: Label[] = [];

        this.feedbackStatisticsWeek.labels.forEach((label: Label) => {
          const date = label[0];
          if (!labels.some((l: Label) => l[0] === date)) {
            const dayOfWeek = this.getDayOfWeek(date);
            const count = this.getCount(
              this.feedbackStatisticsWeek.labels,
              date
            );
            labels.push([date, dayOfWeek, count]);
          }
        });

        this.dataLineChartWeek = {
          ...this.feedbackStatisticsWeek.chartDatasets,
          datasets: [
            {
              ...this.feedbackStatisticsWeek.chartDatasets.datasets[0],
              label: this.$t('happy'),
              data: this.getData(
                this.feedbackStatisticsWeek.chartDatasets.datasets,
                0,
                labels
              )
            },
            {
              ...this.feedbackStatisticsWeek.chartDatasets.datasets[1],
              label: this.$t('ok'),
              data: this.getData(
                this.feedbackStatisticsWeek.chartDatasets.datasets,
                1,
                labels
              )
            },
            {
              ...this.feedbackStatisticsWeek.chartDatasets.datasets[2],
              label: this.$t('angry'),
              data: this.getData(
                this.feedbackStatisticsWeek.chartDatasets.datasets,
                2,
                labels
              )
            }
          ],
          labels: this.getLabelsNoRepetitions(labels)
        };

        this.dataBarChartWeek = {
          ...this.feedbackStatisticsWeek.diagramDatasets,
          datasets: [
            {
              ...this.feedbackStatisticsWeek.diagramDatasets.datasets[0],
              label: this.$t('numberOfUsers'),
              data: this.getData(
                this.feedbackStatisticsWeek.diagramDatasets.datasets,
                0,
                labels
              )
            }
          ],
          labels: this.getLabelsNoRepetitions(labels)
        };
      }
    },
    selectOptionTemplatesCharts(option: Option) {
      this.getOptionTemplatesCharts.selectedOption = option;
    },
    closeTimePanel() {
      this.initChartsData();
    },
    changeItemTemplates(option: Option) {
      this.getOptionTemplates.selectedOption = option;
    }
  },
  watch: {
    user() {
      if (this.user) {
        this.pharmacyId = this.user?.pharmacyId;

        this.getDefaultDate();

        this.params = {
          pharmacyId: this.pharmacyId,
          startTime: this.firstDate,
          endTime: this.todayDate,
          minutesInterval: this.selectedInterval,
          chartFields: this.selectedTemplatesString
        };

        this.initData(this.params);
      }
    },
    params() {
      this.dataLineChartWeek = { ...defaultDataLineChartWeek };
      this.dataBarChartWeek = { ...defaultDataBarChartWeek };

      this.initData(this.params);
    },
    'getOptionTemplatesCharts.selectedOption': {
      immediate: true,
      handler(option: (Option | string)[]) {
        this.optionTemplatesCharts = option;
        this.getSelectedTemplates(this.optionTemplatesCharts);
      }
    },
    selectedTemplatesString() {
      if (this.feedbackStatisticsWeek?.labels?.length) {
        this.params = {
          ...this.params,
          chartFields: this.selectedTemplatesString
        };
      }
    }
  }
})
export default class CustomersFeedbackWeek extends Vue {}
