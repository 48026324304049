import router from '@/router';

export function goToPickupBox(id: string, boxId: string): void {
  router.push({
    name: 'Lockers',
    params: {
      id,
      boxId
    }
  });
}
