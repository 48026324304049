import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "apiReservations" }
const _hoisted_2 = { class: "apiReservations__table" }
const _hoisted_3 = {
  key: 1,
  class: "apiReservations__no-table"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Table = _resolveComponent("Table")!
  const _component_AssignDelivery = _resolveComponent("AssignDelivery")!
  const _component_QuickReservation = _resolveComponent("QuickReservation")!
  const _component_PickedUpReservation = _resolveComponent("PickedUpReservation")!
  const _component_DeleteReservation = _resolveComponent("DeleteReservation")!
  const _component_InfoReservation = _resolveComponent("InfoReservation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.data.length && !_ctx.loading)
          ? (_openBlock(), _createBlock(_component_Table, {
              key: 0,
              data: _ctx.data,
              columns: _ctx.columns,
              idActiveDelivery: _ctx.idActiveDelivery,
              changeSort: _ctx.changeSort,
              selectedField: "deliveryId",
              onAssignDeliveryTable: _ctx.assignDeliveryTable,
              onQuickReservation: _ctx.quickReservation,
              onDeleteReservationTable: _ctx.deleteReservationTable,
              onPickedUpReservation: _ctx.pickedUpReservation,
              onGoToItem: _ctx.infoReservation,
              onSortReservedTime: _ctx.sortReservedTime
            }, null, 8, ["data", "columns", "idActiveDelivery", "changeSort", "onAssignDeliveryTable", "onQuickReservation", "onDeleteReservationTable", "onPickedUpReservation", "onGoToItem", "onSortReservedTime"]))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.data.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('noReservations')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_AssignDelivery, {
      showModalAssign: _ctx.showModalAssign,
      idDelivery: _ctx.idDelivery,
      onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModalAssign = false)),
      onAssignDeliveryRequest: _ctx.assignDeliveryRequest
    }, null, 8, ["showModalAssign", "idDelivery", "onAssignDeliveryRequest"]),
    (_ctx.showModalQuickReservation)
      ? (_openBlock(), _createBlock(_component_QuickReservation, {
          key: 0,
          showModalQuickReservation: _ctx.showModalQuickReservation,
          activeReservations: _ctx.activeReservations,
          onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModalQuickReservation = false))
        }, null, 8, ["showModalQuickReservation", "activeReservations"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PickedUpReservation, {
      showModalPickedUpReservation: _ctx.showModalPickedUpReservation,
      idDelivery: _ctx.idDelivery,
      onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModalPickedUpReservation = false)),
      onPickedUpReservationRequest: _ctx.pickedUpReservationRequest
    }, null, 8, ["showModalPickedUpReservation", "idDelivery", "onPickedUpReservationRequest"]),
    _createVNode(_component_DeleteReservation, {
      showModalDestroy: _ctx.showModalDestroy,
      idDelivery: _ctx.idDelivery,
      onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModalDestroy = false)),
      onDestroyReservationRequest: _ctx.destroyReservationRequest
    }, null, 8, ["showModalDestroy", "idDelivery", "onDestroyReservationRequest"]),
    _createVNode(_component_InfoReservation, {
      showModal: _ctx.showModal,
      onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModal = false)),
      activeReservations: _ctx.activeReservations,
      showDecodedSsn: _ctx.showDecodedSsn,
      onGetDecodedSsn: _ctx.getDecodedSsn
    }, null, 8, ["showModal", "activeReservations", "showDecodedSsn", "onGetDecodedSsn"])
  ], 64))
}