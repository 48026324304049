import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/Hyphen.svg'


const _hoisted_1 = { class: "alarms" }
const _hoisted_2 = { class: "alarms__header" }
const _hoisted_3 = {
  key: 1,
  class: "alarms__header-notActivePickupBox"
}
const _hoisted_4 = { class: "alarms__header-chooseDate" }
const _hoisted_5 = { class: "chooseDate-inputs" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = { class: "input" }
const _hoisted_8 = {
  key: 0,
  class: "date-error"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "alarms__noReserve"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Table = _resolveComponent("Table")!
  const _component_AddAlarmComment = _resolveComponent("AddAlarmComment")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.activePickupBox)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 0,
              class: "alarms__header-dropdown",
              selectedOption: _ctx.selectedPickupBox,
              options: _ctx.pickupBoxOptions,
              onSelectOption: _ctx.changePickupBox,
              "data-testid": "selectedPickupBox"
            }, null, 8, ["selectedOption", "options", "onSelectOption"]))
          : _createCommentVNode("", true),
        (!_ctx.activePickupBox)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('noPickupBox')), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            class: "getPDF-button",
            variant: "bordered",
            title: _ctx.$t('downloadPDF'),
            onClick: _ctx.getPDF
          }, null, 8, ["title", "onClick"]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Calendar, {
                type: "date",
                format: "DD/MM/YYYY",
                value: _ctx.firstDay,
                name: "startTime",
                message: _ctx.$t('errorDate'),
                onChange: _ctx.changeDateStartTime,
                "data-testid": "calendarStartTime"
              }, null, 8, ["value", "message", "onChange"])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              class: "chooseDate-img",
              src: _imports_0,
              alt: "Hyphen"
            }, null, -1)),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Calendar, {
                type: "date",
                format: "DD/MM/YYYY",
                value: _ctx.today,
                name: "endTime",
                onChange: _ctx.changeDateEndTime,
                "data-testid": "calendarEndTime"
              }, null, 8, ["value", "onChange"])
            ])
          ]),
          (!_ctx.isDateCorrect)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('errorDate')), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.isDatePresent && _ctx.alarmsData.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_Table, {
              data: _ctx.alarmsData,
              columns: _ctx.columns,
              onAddAlarmComment: _ctx.addAlarmComment
            }, null, 8, ["data", "columns", "onAddAlarmComment"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isDatePresent && !_ctx.alarmsData.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('noData')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_AddAlarmComment, {
      showModal: _ctx.showModal,
      activeAlarm: _ctx.activeAlarm,
      onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = false)),
      onSaveAlarmComment: _ctx.saveAlarmComment
    }, null, 8, ["showModal", "activeAlarm", "onSaveAlarmComment"])
  ], 64))
}