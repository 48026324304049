import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/CloseModal.svg'


const _hoisted_1 = { class: "infoReservation" }
const _hoisted_2 = { class: "infoReservation__header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "infoReservation__body" }
const _hoisted_5 = {
  key: 0,
  class: "info"
}
const _hoisted_6 = {
  key: 1,
  class: "info"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "info" }
const _hoisted_9 = {
  key: 2,
  class: "info"
}
const _hoisted_10 = {
  key: 3,
  class: "info"
}
const _hoisted_11 = {
  key: 4,
  class: "info"
}
const _hoisted_12 = { class: "info" }
const _hoisted_13 = {
  key: 5,
  class: "info"
}
const _hoisted_14 = {
  key: 6,
  class: "info"
}
const _hoisted_15 = {
  key: 7,
  class: "info"
}
const _hoisted_16 = {
  key: 8,
  class: "info"
}
const _hoisted_17 = {
  key: 9,
  class: "info"
}
const _hoisted_18 = {
  key: 10,
  class: "info"
}
const _hoisted_19 = {
  key: 11,
  class: "info"
}
const _hoisted_20 = {
  key: 12,
  class: "info"
}
const _hoisted_21 = {
  key: 13,
  class: "info"
}
const _hoisted_22 = {
  key: 14,
  class: "info"
}
const _hoisted_23 = {
  key: 15,
  class: "info"
}
const _hoisted_24 = {
  key: 16,
  class: "info"
}
const _hoisted_25 = { class: "infoReservation__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.showModal)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('infoReservation')), 1),
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.activeDelivery.phoneNumber)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('mobilePhone')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.phoneNumber), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.deliveryStatus)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('deliveryStatus')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.activeDelivery.deliveryStatus)), 1),
                    (_ctx.activeDelivery.deliveryStatus === 'expired')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, "(" + _toDisplayString(_ctx.$t(_ctx.getDeliveryStatus())) + ")", 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('ssn')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.dataSsn || '************'), 1),
                (!_ctx.dataSsn)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      class: "ssn_btn",
                      variant: "notBordered",
                      title: _ctx.$t('show'),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSsn()))
                    }, null, 8, ["title"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.activeDelivery.expiredTime)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('timeExpired')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.expiredTime)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.deliveryId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('kolliID')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.getIds(
                _ctx.activeDelivery.deliveryId,
                _ctx.activeDelivery.additionalDeliveryIds
              )), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.fulfillment_order_id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('fulfillment_order_id')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.fulfillment_order_id), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('includesDrug')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.getIsDrud(_ctx.activeDelivery.is_drug)), 1)
              ]),
              (_ctx.activeDelivery.lockerId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('lockerID')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.lockerId.join(', ')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.order_id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('order_id')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.order_id), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.deliveryTime || _ctx.activeDelivery.deliveryUserName)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('delivered')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.deliveryUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.deliveryTime)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.deleteTime || _ctx.activeDelivery.deleteUserName)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('deleted')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.deleteUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.deleteTime)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.destroyTime || _ctx.activeDelivery.destroyUserName)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('destroy')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.destroyUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.destroyTime)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.reservedUserName || _ctx.activeDelivery.reservedTime)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('reserved')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.reservedUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.reservedTime)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.assignedUserName || _ctx.activeDelivery.assignedTime)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('assignedUserName')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.assignedUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.assignedTime)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.givenOutUserName)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('givenOutUserName')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.givenOutUserName), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.pickupTime)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('pickupTime')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.pickupTime)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.removedUserName || _ctx.activeDelivery.removedTime)
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('removed')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.removedUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.removedTime)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.resendSMSCounter)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('resendSMSCounter')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.resendSMSCounter), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeDelivery.resendSMSTimestamps)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('resendSMSTimestamps')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.isLastTime)), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createVNode(_component_Button, {
                variant: "bordered",
                title: _ctx.$t('cancel'),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeModal')))
              }, null, 8, ["title"])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}