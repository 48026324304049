
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import ActiveBox from './ActiveBox/ActiveBox.vue';

import { PickupBox } from '@/store/modules/pharmacy/types';
import { Option } from '@/types';

@Options({
  name: 'InfoPickupBox',
  components: {
    Loading,
    ActiveBox
  },
  data() {
    return {
      loading: false,
      activePickupBox: null,
      selectedPickupBox: {
        value: '',
        label: ''
      },
      pickupBoxOptions: []
    };
  },
  computed: {
    ...mapState('pharmacy', ['pickupBoxes'])
  },
  created() {
    this.loadPickupBoxes();
  },
  methods: {
    ...mapActions('pharmacy', ['getPickupBoxesByPharmacy']),
    async loadPickupBoxes() {
      const { id } = this.$route.params;

      this.loading = true;
      await this.getPickupBoxesByPharmacy(id);
      this.loading = false;

      this.initPickupBoxOptions();
    },
    initPickupBoxOptions() {
      this.pickupBoxOptions = this.pickupBoxes.map((i: PickupBox) => ({
        value: i.info.id,
        label: i.info.name,
        img: require('@/assets/icons/PickupBox.svg')
      }));

      this.selectedPickupBox = this.pickupBoxOptions[0];
      this.activePickupBox = this.pickupBoxes[0];
      this.modules = this.activePickupBox?.info.modules;
    },
    changePickupBox(option: Option) {
      this.selectedPickupBox = option;
      this.activePickupBox = this.pickupBoxes.find(
        (i: PickupBox) => i.info.id === option.value
      );
    }
  }
})
export default class InfoPickupBox extends Vue {}
