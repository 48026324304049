import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(_component_FormKit, {
    type: _ctx.type,
    name: _ctx.name,
    label: _ctx.title,
    value: _ctx.value,
    disabled: _ctx.disabled
  }, null, 8, ["type", "name", "label", "value", "disabled"]))
}