
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

@Options({
  name: 'Input',
  props: [
    'id',
    'type',
    'value',
    'validation',
    'name',
    'forName',
    'label',
    'placeholder',
    'disabled',
    'preserve',
    'autocomplete',
    'forName',
    'validationMessages',
    'accept',
    'max',
    'min',
    'step',
    'onkeydown'
  ],
  computed: {
    ...mapState('account', ['user'])
  }
})
export default class InputComponent extends Vue {}
