export const tabs = [
  {
    label: 'lockers',
    value: 'Lockers'
  },
  {
    label: 'infoPickupBox',
    value: 'InfoPickupBox'
  },
  {
    label: 'reservations',
    value: 'Reservations'
  },
  {
    label: 'apiReservations',
    value: 'APIReservations'
  },
  {
    label: 'deliveryHistory',
    value: 'DeliveryHistory'
  },
  {
    label: 'temperatureLog',
    value: 'TemperatureLog'
  },
  {
    label: 'alarms',
    value: 'Alarms'
  },
  {
    label: 'deliveryStatistics',
    value: 'DeliveryStatistics'
  }
];
