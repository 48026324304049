
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import router from '@/router';
import Loading from '@/views/Loading.vue';

import PickupBoxForm from './PickupBoxForm/PickupBoxForm.vue';

import { getLangValue } from './configs';
import { DataPickupBoxUpdate } from '@/store/modules/pharmacy/types';

@Options({
  name: 'AddPickupBoxPage',
  components: {
    PickupBoxForm,
    Loading
  },
  data() {
    return {
      formData: {
        activeBox: null
      },
      loading: false
    };
  },
  created() {
    const { boxId } = this.$route.params;
    const { importFile } = this.$route.query;

    if (boxId && !importFile) {
      this.getPickupBox(boxId);
    }

    if (this.pharmacyForm?.pickupBox) {
      this.formData = {
        activeBox: this.pharmacyForm.pickupBox.templateId,
        pickupBox: this.pharmacyForm.pickupBox
      };
    }
  },
  computed: {
    ...mapState('pharmacy', ['pickupBox', 'pharmacyForm'])
  },
  methods: {
    ...mapActions('pharmacy', [
      'updatePickupBox',
      'getPickupBoxById',
      'savePharmacyForm',
      'toggleActivePickupBox'
    ]),
    async getPickupBox(boxId: string) {
      this.loading = true;
      await this.getPickupBoxById(boxId);
      this.loading = false;
    },
    backTo() {
      const { boxId } = this.$route.params;
      router.push({ name: 'InfoPickupBox', params: { boxId } });
    },
    async submitHandler(data: DataPickupBoxUpdate) {
      this.loading = true;
      const { id, boxId } = this.$route.params;

      const payload = {
        ...data.pickupBox,
        templateId: data.activeBox,
        pharmacyId: id,
        language: getLangValue[data.pickupBox.language],
        active: !data.pickupBox.active
      };

      this.loading = true;

      if (this.pickupBox.info) {
        if (this.pickupBox.info.active !== payload.active) {
          await this.toggleActivePickupBox({
            data: payload,
            boxId
          });
        }
      } else if (this.pickupBox.active) {
        if (this.pickupBox?.active === payload.active) {
          await this.toggleActivePickupBox({
            data: payload,
            boxId
          });
        }
      }

      await this.updatePickupBox({
        data: payload,
        boxId
      });
      router.push({ name: 'InfoPickupBox' });

      this.loading = false;
    },
    selectBox(box: number) {
      this.formData.activeBox = box;
    },
    submitForm() {
      this.$formkit.submit('pickupBox-form');
    },
    cleanForm() {
      this.$formkit.reset('pickupBox-form');
    }
  },
  watch: {
    pickupBox() {
      if (this.pickupBox) {
        if (this.pickupBox.info) {
          this.formData = {
            activeBox: this.pickupBox.info?.templateId,
            pickupBox: {
              id: this.pickupBox.info.id,
              ...this.pickupBox.info,
              active: !this.pickupBox.info.active
            }
          };
        } else {
          this.formData = {
            activeBox: this.pickupBox.templateId,
            pickupBox: {
              id: this.pickupBox.id,
              ...this.pickupBox,
              active: this.pickupBox.active
            }
          };
        }

        this.loading = false;
      }
    }
  }
})
export default class EditPickupBox extends Vue {}
