import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/Noutoapteekk.jpg'


const _hoisted_1 = { class: "changePharmacy" }
const _hoisted_2 = { class: "changePharmacy__content" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "changePharmacy__content-head" }
const _hoisted_5 = { class: "changePharmacy__content-form" }
const _hoisted_6 = { class: "form" }
const _hoisted_7 = { class: "form-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "changePharmacy__image" }, [
      _createElementVNode("div", { class: "container" }, [
        _createElementVNode("img", {
          class: "image",
          src: _imports_0,
          alt: "Noutoapteekk"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "changePharmacy__content-head-backTo",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backTo && _ctx.backTo(...args)))
          }, [
            _createVNode(_component_Link, {
              title: _ctx.$t('backToLogIn')
            }, null, 8, ["title"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('choosePharmacy')), 1)
            ]),
            _createVNode(_component_FormKit, {
              type: "form",
              id: "lang-form",
              actions: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  class: "changePharmacy-dropdown",
                  selectedOption: _ctx.selectedPharmacy,
                  options: _ctx.pharmacyOptions,
                  onSelectOption: _ctx.selectPharmacy,
                  placeholderText: _ctx.$t('placeholderText')
                }, null, 8, ["selectedOption", "options", "onSelectOption", "placeholderText"])
              ]),
              _: 1
            }),
            _createVNode(_component_Button, {
              id: "login",
              variant: "logIn",
              type: "button",
              onClick: _ctx.goToLockers,
              title: _ctx.$t('changePharmacy')
            }, null, 8, ["onClick", "title"])
          ])
        ])
      ])
    ])
  ]))
}