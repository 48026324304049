import { RootState } from '@/store/types';
import { Module } from 'vuex';
import { CallerAuthenticationState } from './types';
import { actions } from '@/store/modules/callerAuthentication/actions';
import { getters } from '@/store/modules/callerAuthentication/getters';
import { mutations } from '@/store/modules/callerAuthentication/mutations';

export const state: CallerAuthenticationState = {
  callerAuthentication: null,
  authenticationHistory: null,
  decodedSsn: null,
  callbackRequests: [],
  markAsDone: null,
  markAsMissed: null,
  callerAuthenticate: null,
  activeCallbackRequest: null
};

export const callerAuthentication: Module<
  CallerAuthenticationState,
  RootState
> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
