
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import TemperatureCheck from './TemperatureCheck/TemperatureCheck.vue';

import { columnsDeviations, optionsLineChart, columnsExport } from './configs';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import {
  dateTime,
  getAlarmComments,
  getAverageTemp,
  getDuration
} from '@/utils/alarmsDownloadPDF';

import { Params } from '@/store/modules/users/types';
import { InputFileEvent, Option } from '@/types';
import {
  AlarmsData,
  DataAlarmPDF,
  DataLineChartDataset,
  OneDateData,
  PickupBox,
  TemperatureLogItemData
} from '@/store/modules/pharmacy/types';

import { format } from 'date-fns';

const transformationDate = new Date().setDate(new Date().getDate() - 3);

@Options({
  name: 'TemperatureLog',
  components: {
    Loading,
    TemperatureCheck
  },
  data() {
    return {
      selectedPickupBox: {
        value: '',
        label: ''
      },
      dateRange: {
        startTime: format(new Date(transformationDate), 'dd/MM/yyyy'),
        endTime: format(new Date(), 'dd/MM/yyyy')
      },
      loading: false,
      showTimePanel: false,
      activePickupBox: null,
      alarmType: 'temp',
      pickupBoxOptions: [],
      columns: columnsDeviations(this.$t),
      columnsExport: columnsExport(this.$t),
      dataLineChart: null,
      optionsLineChart: optionsLineChart,
      height: 500,
      width: 1200,
      dbRef: null,
      chartLoaded: true,
      buttonLoaded: false,
      alarmsData: [],
      temperaturesForCSV: [],
      alarmsForCSV: [],
      totalEndTime: []
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState('pharmacy', ['pickupBoxes', 'alarms', 'temperatureLog']),
    styleLineChart() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
        position: 'relative'
      };
    },
    isDateCorrect() {
      if (this.isDatePresent) {
        const startTime = this.dateRange.startTime.split('/');
        const endTime = this.dateRange.endTime.split('/');

        let defaultParams = {
          ...this.dateRange,
          startTime: new Date(
            +startTime[2],
            startTime[1] - 1,
            +startTime[0]
          ).valueOf(),
          endTime: new Date(+endTime[2], endTime[1] - 1, +endTime[0]).valueOf()
        };

        return defaultParams.endTime >= defaultParams.startTime;
      }

      return true;
    },
    isDatePresent() {
      const startTime = this.dateRange.startTime.split('/');
      const endTime = this.dateRange.endTime.split('/');

      let defaultParams = {
        ...this.dateRange,
        startTime: new Date(
          +startTime[2],
          startTime[1] - 1,
          +startTime[0]
        ).valueOf(),
        endTime: new Date(+endTime[2], endTime[1] - 1, +endTime[0]).valueOf()
      };

      return defaultParams && defaultParams.startTime && defaultParams.endTime;
    }
  },
  unmounted() {
    this.clearAlarms();
  },
  methods: {
    ...mapActions('pharmacy', [
      'getPickupBoxesByPharmacy',
      'getAlarms',
      'getTemperatureLog',
      'clearAlarms'
    ]),
    async init() {
      await this.loadPickupBoxes();
      this.initPickupBoxOptions();
    },
    async loadPickupBoxes() {
      this.loading = true;

      const { id } = this.$route.params;
      await this.getPickupBoxesByPharmacy(id);
      this.initAlarms();

      this.loading = false;
    },
    getTotalEndTime() {
      const arrayEndTime = this.dateRange.endTime.split('/');
      const arrayToday = format(new Date(), 'dd/MM/yyyy/HH/mm/ss').split('/');
      const arrayHour = arrayToday.slice(-3);
      this.totalEndTime = arrayEndTime.concat(arrayHour);
    },
    async initTemperatureLog() {
      this.chartLoaded = false;
      this.loading = true;

      const boxId = this.activePickupBox?.id;

      const startTime = this.dateRange.startTime.split('/');
      this.getTotalEndTime();

      if (this.isDateCorrect) {
        await this.getTemperatureLog({
          boxId,
          startTime: new Date(
            +startTime[2],
            startTime[1] - 1,
            +startTime[0]
          ).valueOf(),
          endTime: new Date(
            +this.totalEndTime[2],
            +this.totalEndTime[1] - 1,
            +this.totalEndTime[0],
            +this.totalEndTime[3],
            +this.totalEndTime[4],
            +this.totalEndTime[5]
          ).valueOf()
        });

        this.dataLineChart = {
          ...this.temperatureLog,
          datasets: this.temperatureLog.datasets.map(
            (item: TemperatureLogItemData) => ({
              ...item,
              label: this.$t(item.label || ''),
              data: item.data ? [...item.data] : []
            })
          )
        };
      }

      this.chartLoaded = true;
      this.loading = false;
    },
    async initAlarms(params: Params) {
      this.loading = true;
      this.buttonLoaded = false;

      const boxId = this.activePickupBox?.id;

      const startTime = this.dateRange.startTime.split('/');
      this.getTotalEndTime();

      let defaultParams = {
        boxId,
        alarmType: this.alarmType,
        ...this.dateRange,
        startTime: new Date(
          +startTime[2],
          startTime[1] - 1,
          +startTime[0]
        ).valueOf(),
        endTime: new Date(
          +this.totalEndTime[2],
          +this.totalEndTime[1] - 1,
          +this.totalEndTime[0],
          +this.totalEndTime[3],
          +this.totalEndTime[4],
          +this.totalEndTime[5]
        ).valueOf()
      };

      if (params) {
        defaultParams = {
          ...defaultParams,
          ...(params.alarmType !== undefined && { alarmType: params.alarmType })
        };
      }

      if (
        boxId &&
        defaultParams.startTime &&
        defaultParams.endTime &&
        this.isDateCorrect
      ) {
        await this.getAlarms(defaultParams);
      }

      this.alarmsData = this.alarms;

      this.alarmsData.sort((a: AlarmsData, b: AlarmsData) => {
        return b.timestamp - a.timestamp;
      });

      this.loading = false;
    },
    changeDate(e: InputFileEvent) {
      const { name, value } = e.target;
      this.buttonLoaded = false;

      this.dateRange = {
        ...this.dateRange,
        [name]: value
      };

      this.buttonLoaded = true;
    },
    closeTimePanel() {
      this.showTimePanel = false;

      this.loadPickupBoxes(this.dateRange);
      this.initTemperatureLog();

      this.buttonLoaded = false;
    },
    closeButoon() {
      this.buttonLoaded = false;
    },
    initPickupBoxOptions() {
      this.pickupBoxOptions = (this.pickupBoxes || []).map((i: PickupBox) => ({
        value: i.info.id,
        label: i.info.name,
        img: require('@/assets/icons/PickupBox.svg')
      }));
      this.selectedPickupBox = this.pickupBoxOptions[0] || null;
      this.activePickupBox = this.pickupBoxes[0] || null;
    },
    changePickupBox(option: Option) {
      this.selectedPickupBox = option;
      this.activePickupBox = this.pickupBoxes.find(
        (i: PickupBox) => i.info.id === option.value
      );
    },
    getSensor(data: AlarmsData) {
      if (data.alarmType === 'temp_high' || data.alarmType === 'temp_low') {
        if (data.lockerId !== '99-99') {
          return `${this.$t('fridge')} ${data.lockerId}`;
        } else {
          return `${this.$t('roomTemp')}`;
        }
      } else if (data.lockerId) {
        return `${data.lockerId}`;
      }
    },
    getAlarmType(alarmType: string) {
      const type: Record<string, unknown> = {
        ok: this.$t('alarmOk'),
        door_open: this.$t('alarmDoorOpen'),
        door_closed: this.$t('alarmDoorClosed'),
        temp_high: this.$t('alarmTempHigh'),
        temp_low: this.$t('alarmTempLow'),
        other: this.$t('alarmOther'),
        missing: this.$t('alarmMissing')
      };

      return type[alarmType];
    },
    getAlarmPDF() {
      const data = this.alarmsData.map((el: DataAlarmPDF) => ({
        alarmType: this.getAlarmType(el.alarmType),
        timestamp: dateTime(el.timestamp),
        alarmEndTimestamp: dateTime(el.alarmEndTimestamp),
        duration: getDuration(el.timestamp, el.alarmEndTimestamp),
        alarmMax: el.alarmMax,
        alarmMin: el.alarmMin,
        sensor: this.getSensor(el),
        averageTemp: +getAverageTemp(el.alarmMax, el.alarmMin),
        alarmComment: getAlarmComments(el.check)
      }));

      const dataPDF = data.map((obj: DataAlarmPDF) => Object.values(obj));

      const columns = [...this.columns].map((i: Option) => i.label);

      const doc = new jsPDF('landscape');
      autoTable(doc, {
        head: [columns],
        body: dataPDF,
        theme: 'grid',
        headStyles: { fillColor: [71, 152, 127] },
        bodyStyles: { fillColor: [255, 255, 255] },
        margin: { top: 10 }
      });

      doc.save(`${this.$t('alarms')}.pdf`);
    },
    getTemperatureLogPDF() {
      const labelsDates = this.dataLineChart.labels.map((label: number) =>
        Object.values(label).join(' ')
      );

      const result = this.dataLineChart.datasets
        .map((dataset: DataLineChartDataset) => {
          if (dataset.label === this.$t('Box Temperature')) {
            return dataset.data.map((temp: number, idx: number) => {
              return {
                date: labelsDates[idx],
                temp,
                label: dataset.label
              };
            });
          }
        })
        .flat()
        .filter((item: OneDateData) => item !== undefined);

      const data = result.map((el: OneDateData) => ({
        data: el.label,
        date: el.date,
        temperature: el.temp
      }));

      const dataPDF = data.map((obj: OneDateData) => Object.values(obj));

      const columns = [...this.columnsExport].map((i: Option) => i.label);

      const doc = new jsPDF();
      autoTable(doc, {
        head: [columns],
        body: dataPDF,
        theme: 'grid',
        headStyles: { fillColor: [71, 152, 127] },
        bodyStyles: { fillColor: [255, 255, 255] },
        margin: { top: 10 }
      });

      doc.save(`${this.$t('temperatureLog')}.pdf`);
    },
    getPDF() {
      this.getTemperatureLogPDF();
      if (this.alarmsData.length) {
        this.getAlarmPDF();
      }
    }
  },
  watch: {
    selectedPickupBox() {
      if (this.selectedPickupBox?.value) {
        this.initAlarms();
        this.initTemperatureLog();
      }
    }
  }
})
export default class TemperatureLog extends Vue {}
