import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "customersFeedbackWeek" }
const _hoisted_2 = { class: "customersFeedbackWeek__chooseDate" }
const _hoisted_3 = { class: "customersFeedbackWeek__chooseDate__container" }
const _hoisted_4 = { class: "customersFeedbackWeek__charts" }
const _hoisted_5 = {
  key: 0,
  class: "lineCharts"
}
const _hoisted_6 = {
  key: 1,
  class: "barCharts"
}
const _hoisted_7 = {
  key: 1,
  class: "customersFeedbackWeek__noData"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.dataLineChartWeek?.labels?.length)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 0,
              class: "templatesCharts-dropdown",
              selectedOption: _ctx.getOptionTemplatesCharts.selectedOption,
              options: _ctx.getOptionTemplatesCharts.options,
              multiple: true,
              onSelectOption: _ctx.selectOptionTemplatesCharts
            }, null, 8, ["selectedOption", "options", "onSelectOption"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.dataLineChartWeek?.labels?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_LineChart, {
              id: 'lineChartWeek',
              dataLineChart: _ctx.dataLineChartWeek,
              optionsLineChart: _ctx.optionsLineChart,
              style: _normalizeStyle(_ctx.styleLineChart)
            }, null, 8, ["dataLineChart", "optionsLineChart", "style"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.dataBarChartWeek?.labels?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_BarChart, {
              id: 'barChartWeek',
              dataBarChart: _ctx.dataBarChartWeek,
              optionsBarChart: _ctx.optionsBarChart,
              style: _normalizeStyle(_ctx.styleBarChart)
            }, null, 8, ["dataBarChart", "optionsBarChart", "style"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.dataLineChartWeek?.labels?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('noData')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}