import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/DownloadBlack.svg'
import _imports_1 from '@/assets/icons/Order.svg'


const _hoisted_1 = { class: "order" }
const _hoisted_2 = { class: "order__header" }
const _hoisted_3 = {
  key: 0,
  class: "subtitle"
}
const _hoisted_4 = {
  key: 1,
  class: "subtitle"
}
const _hoisted_5 = {
  key: 1,
  class: "order__isOrder"
}
const _hoisted_6 = {
  key: 0,
  class: "info"
}
const _hoisted_7 = {
  key: 1,
  class: "info"
}
const _hoisted_8 = { class: "info" }
const _hoisted_9 = ["idActiveDelivery"]
const _hoisted_10 = {
  key: 3,
  class: "info"
}
const _hoisted_11 = {
  key: 4,
  class: "info"
}
const _hoisted_12 = {
  key: 5,
  class: "info"
}
const _hoisted_13 = {
  key: 6,
  class: "info"
}
const _hoisted_14 = {
  key: 7,
  class: "info"
}
const _hoisted_15 = {
  key: 8,
  class: "info"
}
const _hoisted_16 = {
  key: 9,
  class: "info"
}
const _hoisted_17 = {
  key: 10,
  class: "info"
}
const _hoisted_18 = {
  key: 11,
  class: "info"
}
const _hoisted_19 = {
  key: 12,
  class: "info"
}
const _hoisted_20 = {
  key: 13,
  class: "info"
}
const _hoisted_21 = {
  key: 14,
  class: "info"
}
const _hoisted_22 = {
  key: 2,
  class: "order__noOrder"
}
const _hoisted_23 = { class: "title" }
const _hoisted_24 = {
  key: 3,
  class: "order__noOrder"
}
const _hoisted_25 = { class: "title" }
const _hoisted_26 = { class: "footer-order" }
const _hoisted_27 = {
  key: 1,
  class: "tooltiptext"
}
const _hoisted_28 = {
  key: 1,
  class: "tooltiptext"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DeleteReservation = _resolveComponent("DeleteReservation")!
  const _component_ReserveLocker = _resolveComponent("ReserveLocker")!
  const _component_ChooseDelivery = _resolveComponent("ChooseDelivery")!
  const _component_ResendSMS = _resolveComponent("ResendSMS")!
  const _component_OpenQRCode = _resolveComponent("OpenQRCode")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('order')), 1),
          (_ctx.order.deliveryStatus === 'expired' && !_ctx.order.deliveryTime)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('reservation')), 1))
            : _createCommentVNode("", true),
          (_ctx.order.deliveryStatus === 'expired' && _ctx.order.deliveryTime)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('delivery')), 1))
            : _createCommentVNode("", true)
        ]),
        (_ctx.order.status !== 'inactive' && _ctx.order.deliveryStatus)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              alt: "",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getCSV && _ctx.getCSV(...args)))
            }))
          : _createCommentVNode("", true)
      ]),
      (_ctx.order.deliveryStatus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.order.phoneNumber)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('mobilePhone')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.order.phoneNumber), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.deliveryUserName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('deliveryMadeBy')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.order.deliveryUserName), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('ssn')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.getDataSSN()), 1),
              _createVNode(_component_Button, {
                class: "ssn_btn",
                variant: "notBordered",
                title: !_ctx.showDecodedSsn ? _ctx.$t('show') : '',
                onClick: _ctx.showSsn
              }, null, 8, ["title", "onClick"])
            ]),
            (_ctx.order.deliveryId)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "info delivery-id",
                  idActiveDelivery: _ctx.idActiveDelivery,
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openQRCode && _ctx.openQRCode(...args)))
                }, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('kolliID')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.getIds(_ctx.order.deliveryId, _ctx.order.additionalDeliveryIds)), 1)
                ], 8, _hoisted_9))
              : _createCommentVNode("", true),
            (_ctx.order.fulfillment_order_id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('fulfillment_order_id')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.order.fulfillment_order_id), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.order_id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('order_id')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.order.order_id), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('includesDrug')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.getIsDrud(_ctx.order.is_drug)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.deliveryTime || _ctx.order.deliveryUserName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('delivered')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.order.deliveryUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.order.deliveryTime)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.deleteTime || _ctx.order.deleteUserName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('deleted')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.order.deleteUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.order.deleteTime)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.reservedUserName || _ctx.order.reservedTime)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('reserved')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.order.reservedUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.order.reservedTime)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.assignedUserName || _ctx.order.assignedTime)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('assignedUserName')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.order.assignedUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.order.assignedTime)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.removedUserName || _ctx.order.removedTime)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('removed')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.order.removedUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.order.removedTime)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.pickupTime)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('timePickup')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.order.pickupTime)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.descriptionText)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('description')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.order.descriptionText), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.resendSMSCounter)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('resendSMSCounter')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.order.resendSMSCounter), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.resendSMSTimestamps)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('resendSMSTimestamps')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.isLastTime)), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.order.deliveryStatus && _ctx.order.status !== 'inactive')
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "img" }, [
              _createElementVNode("img", {
                src: _imports_1,
                alt: ""
              })
            ], -1)),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('noOrderReservedYet')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.order.status === 'inactive')
        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "img" }, [
              _createElementVNode("img", {
                src: _imports_1,
                alt: ""
              })
            ], -1)),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('enableLocker')), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", {
        class: _normalizeClass({
        'button-active': true,
        tootip:
          !_ctx.activePickupBox?.info.active && _ctx.order.deliveryStatus !== 'reserved'
      })
      }, [
        (_ctx.hideButton(_ctx.order))
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: _normalizeClass({
          'order-button': true,
          'resendSMS-button': _ctx.order.deliveryStatus === 'delivered'
        }),
              variant: _ctx.getVariant(),
              title: _ctx.$t(_ctx.getTitle()),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getActions()))
            }, null, 8, ["class", "variant", "title"]))
          : _createCommentVNode("", true),
        (
          !_ctx.activePickupBox?.info.active && _ctx.order.deliveryStatus !== 'reserved'
        )
          ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_ctx.$t('pickupboxIsInactive')), 1))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass({
        'button-active': true,
        tootip: !_ctx.activePickupBox?.info.active
      })
      }, [
        (_ctx.order.status !== 'inactive' && !_ctx.order.deliveryStatus)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "assignReservation-button",
              variant: _ctx.activePickupBox?.info.active ? 'filled' : 'disabled',
              title: _ctx.$t('assignReservation'),
              onClick: _ctx.assignReservation
            }, null, 8, ["variant", "title", "onClick"]))
          : _createCommentVNode("", true),
        (!_ctx.activePickupBox?.info.active)
          ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.$t('pickupboxIsInactive')), 1))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _createVNode(_component_DeleteReservation, {
      showModal: _ctx.showModal,
      onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModal = false)),
      onDeleteReservationRequest: _ctx.deleteReservationRequest,
      onDestroyReservationRequest: _ctx.destroyReservationRequest
    }, null, 8, ["showModal", "onDeleteReservationRequest", "onDestroyReservationRequest"]),
    (_ctx.showModalReserveLocker)
      ? (_openBlock(), _createBlock(_component_ReserveLocker, {
          key: 0,
          showModalReserveLocker: _ctx.showModalReserveLocker,
          onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModalReserveLocker = false)),
          activePickupBox: _ctx.activePickupBox,
          selectedLockers: _ctx.activeLocker
        }, null, 8, ["showModalReserveLocker", "activePickupBox", "selectedLockers"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ChooseDelivery, {
      showModalChooseDelivery: _ctx.showModalChooseDelivery,
      activeBoxId: _ctx.activeBoxId,
      deliveryId: _ctx.deliveryId,
      activeLocker: _ctx.activeLocker,
      onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showModalChooseDelivery = false))
    }, null, 8, ["showModalChooseDelivery", "activeBoxId", "deliveryId", "activeLocker"]),
    _createVNode(_component_ResendSMS, {
      showModalResendSMS: _ctx.showModalResendSMS,
      activeBoxId: _ctx.activeBoxId,
      deliveryId: _ctx.deliveryId,
      onCloseModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showModalResendSMS = false))
    }, null, 8, ["showModalResendSMS", "activeBoxId", "deliveryId"]),
    _createVNode(_component_OpenQRCode, {
      showQRCode: _ctx.showQRCode,
      idActiveDelivery: _ctx.idActiveDelivery,
      onCloseModal: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showQRCode = false))
    }, null, 8, ["showQRCode", "idActiveDelivery"])
  ], 64))
}