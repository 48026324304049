import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "infoAboutAlarm" }
const _hoisted_2 = {
  key: 0,
  class: "info"
}
const _hoisted_3 = {
  key: 1,
  class: "info"
}
const _hoisted_4 = {
  key: 2,
  class: "info"
}
const _hoisted_5 = {
  key: 3,
  class: "info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.activeAlarm.pickupboxId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('pickupBoxId')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeAlarm.pickupboxId), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeAlarm.lockerId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('lockerID')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeAlarm.lockerId), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeAlarm.timestamp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('startTime')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeAlarm.timestamp)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeAlarm.alarmEndTimestamp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('endTime')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeAlarm.alarmEndTimestamp)), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}