
import { Vue, Options } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import Modules from './Modules/Modules.vue';
import QuickReservation from './QuickReservation/QuickReservation.vue';

import { actions } from './configs';

import {
  LockerWithStatus,
  ModuleWithTemperature,
  PickupBox
} from '@/store/modules/pharmacy/types';

import { LangOptions, Option } from '@/types';
import { Locker, LockersType, Module } from '@/store/modules/pharmacy/types';
import { goToPickupBox } from '@/utils/goToPickupBox';

@Options({
  name: 'Lockers',
  components: {
    Loading,
    Modules,
    QuickReservation
  },
  data() {
    return {
      loading: false,
      actions,
      activePickupBox: null,
      modules: [],
      selectedPickupBox: {
        value: '',
        label: ''
      },
      pickupBoxOptions: [],
      lockersActiveBox: {},
      multipleLockers: false,
      showModalQuickReservation: false,
      disableButton: false,
      pickupBoxActiveId: null,
      positions: []
    };
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy', 'pickupBoxes']),
    ...mapState('account', ['user'])
  },
  created() {
    this.loadPickupBoxes();
  },
  methods: {
    ...mapActions('pharmacy', ['getPickupBoxesByPharmacy']),
    async loadPickupBoxes() {
      const { id, pickupBoxActiveId } = this.$route.params;
      this.pickupBoxActiveId = pickupBoxActiveId;

      this.loading = true;
      await this.getPickupBoxesByPharmacy(id);
      this.loading = false;

      this.initPickupBoxOptions();
    },
    initPickupBoxOptions() {
      const { boxId } = this.$route.params;

      this.pickupBoxOptions = this.pickupBoxes.map((i: PickupBox) => ({
        value: i.info.id,
        label: i.info.name,
        img: require('@/assets/icons/PickupBox.svg')
      }));

      this.selectedPickupBox = boxId
        ? this.pickupBoxOptions.find((boxOpt: LangOptions) => {
            return boxOpt.value === boxId;
          })
        : this.pickupBoxOptions[0];
      this.activePickupBox = boxId
        ? this.pickupBoxes.find((box: PickupBox) => {
            return box.id === boxId;
          })
        : this.pickupBoxes[0];

      this.modules = this.activePickupBox?.info.modules;

      this.positions = [];
      this.modules.forEach((module: Module, index: number) => {
        this.modules[index].id = index;
        this.positions[module.position] = this.modules[index].id;
      });

      this.initialTemperature();
      this.getFreeLockers();
    },
    // eslint-disable-next-line
    getTemperatureOfLockerFridge(module: any, lockerId: string) {
      if (module.fridgeTemperatures !== undefined) {
        return module.fridgeTemperatures[lockerId];
      }
    },
    initialTemperature() {
      this.modules = this.modules?.map(
        (module: ModuleWithTemperature, index: number) => {
          return {
            ...module,
            lockers: module.lockers.map((locker: LockerWithStatus) => {
              return {
                ...locker,
                temperature: locker.fridge
                  ? this.getTemperatureOfLockerFridge(
                      module,
                      `${index}-${locker.type}`
                    )
                  : module.temperature
              };
            })
          };
        }
      );
    },
    getVariant() {
      if (this.activePickupBox?.info.active) {
        if (!this.disableButton) {
          return 'filled';
        } else {
          return 'disabled';
        }
      } else {
        return 'disabled';
      }
    },
    changePickupBox(option: Option) {
      this.selectedPickupBox = option;
      this.activePickupBox = this.pickupBoxes.find(
        (i: PickupBox) => i.info.id === option.value
      );
      this.modules = this.activePickupBox.info.modules;
      this.positions = [];
      this.modules.forEach((module: Module, index: number) => {
        this.modules[index].id = index;
        this.positions[module.position] = this.modules[index].id;
      });

      goToPickupBox(this.user?.pharmacyId, this.activePickupBox.id);
      this.initialTemperature();
      this.getFreeLockers();
    },
    multipleLockersFalse() {
      this.multipleLockers = false;
    },
    getFreeLockers() {
      const allModulesLockers = this.activePickupBox?.info.modules
        .map((module: Module) => module.lockers)
        .flat();

      this.lockersActiveBox = allModulesLockers?.reduce(
        (lockers: LockersType, currentLocker: Locker) => {
          return {
            0:
              lockers[0] +
              +(
                currentLocker.type === 0 &&
                !currentLocker.order &&
                currentLocker.status !== 'inactive'
              ),
            1:
              lockers[1] +
              +(
                currentLocker.type === 1 &&
                !currentLocker.order &&
                !currentLocker.fridge &&
                currentLocker.status !== 'inactive'
              ),
            2:
              lockers[2] +
              +(
                currentLocker.type === 2 &&
                !currentLocker.order &&
                currentLocker.status !== 'inactive'
              ),
            fridge:
              lockers.fridge +
              +(
                currentLocker.fridge &&
                !currentLocker.order &&
                currentLocker.status !== 'inactive'
              )
          };
        },
        { 0: 0, 1: 0, 2: 0, fridge: 0 }
      );

      const valuesIsZero = (currentValue: unknown) => currentValue === 0;

      if (this.lockersActiveBox) {
        const valuesLockersActiveBox = Object.values(
          this.lockersActiveBox
        ).every(valuesIsZero);

        if (valuesLockersActiveBox) {
          return (this.disableButton = true);
        } else {
          return (this.disableButton = false);
        }
      }
    },
    quickReservation() {
      this.showModalQuickReservation = true;
    }
  },
  watch: {
    pickupBoxes() {
      this.multipleLockers = false;
      this.initPickupBoxOptions();
    }
  }
})
export default class Lockers extends Vue {}
