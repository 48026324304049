import { Option, TranslationFunction } from '@/types';

export const columnsLoginInfo = ($t: TranslationFunction): Option[] => [
  {
    value: 'timestamp',
    label: $t('lastLoginUpperCase')
  },
  {
    value: 'loginInfoUserName',
    label: $t('userUpperCase')
  },
  {
    value: 'email',
    label: $t('emailUpperCase')
  },
  {
    value: 'infoType',
    label: $t('typeUpperCase')
  }
];
