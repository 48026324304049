import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/CloseModal.svg'


const _hoisted_1 = { class: "openConfirmation" }
const _hoisted_2 = { class: "openConfirmation__header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "openConfirmation__body" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { class: "openConfirmation__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.showOpenConfirmation)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('confirmation')), 1),
              _createElementVNode("img", {
                class: "openConfirmation__header-img",
                src: _imports_0,
                alt: "Close Modal",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('confirmationText')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Button, {
                class: "openConfirmation__button",
                variant: "filled",
                title: _ctx.$t('confirm'),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirmRequest')))
              }, null, 8, ["title"]),
              _createVNode(_component_Button, {
                variant: "notBordered",
                title: _ctx.$t('cancel'),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeModal')))
              }, null, 8, ["title"])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}