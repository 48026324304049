import { Option, TranslationFunction } from '@/types';

export const tabs = [
  {
    label: 'statistics',
    value: 'Statistics'
  },
  {
    label: 'customers',
    value: 'Customers'
  }
];

export const defaultDataLineChart = {
  labels: [],
  datasets: [
    {
      label: 'Happy',
      data: [],
      backgroundColor: '#22AA7D',
      borderColor: '#22AA7D',
      borderWidth: 2,
      fill: false,
      hitRadius: 0,
      pointRadius: 0,
      radius: 0,
      tension: 0.1
    },
    {
      label: 'Ok',
      data: [],
      backgroundColor: '#FF9D2B',
      borderColor: '#FF9D2B',
      borderWidth: 2,
      fill: false,
      hitRadius: 0,
      pointRadius: 0,
      radius: 0,
      tension: 0.1
    },
    {
      label: 'Angry',
      data: [],
      backgroundColor: '#FF6363',
      borderColor: '#FF6363',
      borderWidth: 2,
      fill: false,
      hitRadius: 0,
      pointRadius: 0,
      radius: 0,
      tension: 0.1
    }
  ]
};

export const defaultDataBarChart = {
  labels: [],
  datasets: [
    {
      label: 'Number of users',
      data: [],
      backgroundColor: '#82828287',
      borderWidth: 1.5
    }
  ]
};

export const columnsTemplates = ($t: TranslationFunction): Option[] => [
  {
    label: '',
    value: 'nameTemplates'
  },
  {
    label: $t('active'),
    value: 'active'
  },
  {
    label: $t('translation'),
    value: 'translation'
  }
];

export const activeOptions = ($t: TranslationFunction): Option[] => [
  {
    label: `${10} ${$t('minutes')}`,
    value: 10
  },
  {
    label: `${15} ${$t('minutes')}`,
    value: 15
  },
  {
    label: `${30} ${$t('minutes')}`,
    value: 30
  },
  {
    label: `${60} ${$t('minutes')}`,
    value: 60
  }
];
