import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "feedbackChartsDays"
}
const _hoisted_2 = {
  key: 0,
  class: "feedbackChartsDays__line"
}
const _hoisted_3 = {
  key: 1,
  class: "feedbackChartsDays__bar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_ctx.dataBarChart?.labels?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.dataLineChart?.labels?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_LineChart, {
                id: 'lineChart',
                dataLineChart: _ctx.dataLineChart,
                optionsLineChart: _ctx.optionsLineChart,
                style: _normalizeStyle(_ctx.styleLineChart)
              }, null, 8, ["dataLineChart", "optionsLineChart", "style"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.dataBarChart?.labels?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_BarChart, {
                id: 'barChart',
                dataBarChart: _ctx.dataBarChart,
                optionsBarChart: _ctx.optionsBarChart,
                style: _normalizeStyle(_ctx.styleBarChart)
              }, null, 8, ["dataBarChart", "optionsBarChart", "style"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}