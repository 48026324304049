
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import { columns } from './configs';
import { DataReservations } from '@/store/modules/pharmacy/types';

@Options({
  name: 'ChooseDelivery',
  props: ['showModalChooseDelivery', 'activeBoxId', 'activeLocker'],
  components: {
    Loading
  },
  data() {
    return {
      data: [],
      reservations: [],
      activeReservations: null,
      columns: columns(this.$t),
      loading: false
    };
  },
  created() {
    this.show();
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy'])
  },
  methods: {
    ...mapActions('pharmacy', [
      'assignDelivery',
      'getPharmacy',
      'getPickupBoxesByPharmacy'
    ]),
    async show() {
      this.loading = true;

      if (this.pharmacy) {
        this.reservations = this.pharmacy?.reservations;

        this.data = [...this.reservations]?.filter(
          (reservation: DataReservations) => reservation.unassigned === true
        );
      }

      this.loading = false;
    },
    async assignDeliveryTable(deliveryId: string) {
      this.loading = true;

      this.activeReservations = this.data.find(
        (i: DataReservations) => i.deliveryId === deliveryId
      );

      const { id } = this.$route.params;

      const payload = {
        assignToBox: true,
        boxInfo: {
          lockerId: [this.activeLocker],
          boxId: this.activeBoxId
        }
      };

      await this.assignDelivery({
        data: payload,
        pharmacyId: id,
        deliveryId: deliveryId
      });

      this.$emit('closeModal');

      await this.getPharmacy(id);
      await this.getPickupBoxesByPharmacy(id);

      this.loading = false;

      this.show();
    }
  }
})
export default class ChooseDelivery extends Vue {}
