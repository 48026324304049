
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import { useRoute } from 'vue-router';

import QrcodeVue from 'qrcode.vue';

@Options({
  name: 'PickupPinQR',
  components: {
    QrcodeVue
  },
  data() {
    return {
      loading: true,
      pinCode: useRoute().query.data ? String(useRoute().query.data) : ''
    };
  },
  computed: {
    ...mapState('account', ['status'])
  }
})
export default class PickupPinQRComponent extends Vue {}
