
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import AssignDelivery from './AssignDelivery/AssignDelivery.vue';
import QuickReservation from './QuickReservation/QuickReservation.vue';
import PickedUpReservation from '../Reservations/PickedUpReservation/PickedUpReservation.vue';
import DeleteReservation from '../Reservations/DeleteReservation/DeleteReservation.vue';
import InfoReservation from './InfoReservation/InfoReservation.vue';

import Loading from '@/views/Loading.vue';

import { columns } from './configs';
import {
  APIDataReservations,
  DataReservations,
  PickupBox,
  Reservation,
  SSNData
} from '@/store/modules/pharmacy/types';

@Options({
  name: 'APIReservations',
  emits: [
    'assignDeliveryRequest',
    'quickReservationRequest',
    'deleteReservationRequest',
    'destroyReservationRequest',
    'pickedUpReservationRequest',
    'getDecodedSsn',
    'sortReservedTime'
  ],
  components: {
    AssignDelivery,
    QuickReservation,
    Loading,
    PickedUpReservation,
    DeleteReservation,
    InfoReservation
  },
  data() {
    return {
      data: [],
      columns: columns(this.$t),
      reservations: [],
      activeReservations: null,
      idDelivery: '',
      showModal: false,
      showModalAssign: false,
      showModalQuickReservation: false,
      loading: false,
      stop: false,
      idActiveDelivery: '',
      showModalPickedUpReservation: false,
      showModalDestroy: false,
      idPickupBox: '',
      showDecodedSsn: '',
      activePickupBox: null,
      changeSort: false
    };
  },
  created() {
    this.show();
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy', 'pickupBoxes', 'decodedSsn'])
  },
  methods: {
    ...mapActions('pharmacy', [
      'assignDelivery',
      'getPharmacy',
      'getPickupBoxesByPharmacy',
      'updateDescription',
      'updatePharmacyReservation',
      'destroyReservation',
      'getDeliveryShowDecodedSsn'
    ]),
    async show() {
      if (this.pharmacy && !this.stop) {
        const { id } = this.$route.params;

        this.stop = true;
        this.loading = true;

        await this.getPickupBoxesByPharmacy(id);
        await this.getPharmacy(id);

        this.loading = false;
      }
    },
    async loadAPIReservation() {
      this.loading = true;

      if (this.pharmacy) {
        this.reservations = this.pharmacy?.reservations || [];
        this.data = [...this.reservations]?.filter(
          (reservation: DataReservations) => reservation.unassigned === true
        );
      }

      this.data.sort((a: DataReservations, b: DataReservations) => {
        return b.reservedTime - a.reservedTime;
      });

      this.loading = false;
    },
    assignDeliveryTable(deliveryId: string) {
      this.idDelivery = deliveryId;

      this.activeReservations = this.data.find(
        (i: DataReservations) => i.deliveryId === deliveryId
      );
      this.sortReservedTime();
      this.showModalAssign = true;
    },
    async assignDeliveryRequest(deliveryId: string, description: string) {
      this.loading = true;

      this.activeReservations = this.data.find(
        (i: DataReservations) => i.deliveryId === deliveryId
      );

      const { id } = this.$route.params;

      if (description) {
        const payload = {
          descriptionText: description,
          pharmacyId: id,
          reservedTime: this.activeReservations.reservedTime
        };

        const deliveryId = this.activeReservations?.deliveryId;

        await this.updateDescription({
          data: payload,
          deliveryId
        });

        await this.assignDelivery({
          pharmacyId: id,
          deliveryId: deliveryId
        });
      } else {
        await this.assignDelivery({
          pharmacyId: id,
          deliveryId: deliveryId
        });
      }

      this.showModalAssign = false;

      await this.getPharmacy(id);

      this.loading = false;

      this.loadAPIReservation();
    },
    quickReservation(deliveryId: string) {
      this.idDelivery = deliveryId;

      this.activeReservations = this.data.find(
        (i: DataReservations) => i.deliveryId === deliveryId
      );

      this.showModalQuickReservation = true;
    },
    pickedUpReservation(deliveryId: string) {
      this.idDelivery = deliveryId;

      this.activeReservations = this.data.find(
        (i: APIDataReservations) => i.deliveryId === deliveryId
      );

      this.showModalPickedUpReservation = true;
    },
    async pickedUpReservationRequest(deliveryId: string) {
      this.loading = true;

      const { id } = this.$route.params;

      const payload = {
        deliveryStatus: 'pickup'
      };

      await this.updatePharmacyReservation({
        data: payload,
        pharmacyId: id,
        deliveryId: deliveryId
      });

      this.loading = false;
      this.showModalPickedUpReservation = false;

      this.getPharmacy(id);
      this.loadAPIReservation();
    },
    deleteReservationTable(deliveryId: string) {
      this.idDelivery = deliveryId;

      this.activeReservations = this.data.find(
        (i: APIDataReservations) => i.deliveryId === deliveryId
      );

      this.showModalDestroy = true;
    },
    async destroyReservationRequest(deliveryId: string) {
      const { id } = this.$route.params;

      const payload = {
        pharmacyId: id,
        deliveryId
      };

      await this.destroyReservation(payload);

      this.loading = false;
      this.showModalDestroy = false;

      this.getPharmacy(id);
      this.loadAPIReservation();
    },
    infoReservation(deliveryId: string) {
      this.activeReservations = this.data.find(
        (i: APIDataReservations) => i.deliveryId === deliveryId
      );

      this.showModal = true;
    },
    async getDecodedSsn(deliveryId: string) {
      this.idDelivery = deliveryId;

      this.activeReservations = this.data.find(
        (i: APIDataReservations) => i.deliveryId === deliveryId
      );

      this.activePickupBox = this.pickupBoxes.find((pickupBox: PickupBox) =>
        pickupBox.reservations
          .map((i: Reservation) => i.deliveryId)
          .includes(this.activeReservations.deliveryId)
      );

      this.idPickupBox = this.activePickupBox?.id;
      const { id } = this.$route.params;

      const params: SSNData = {
        deliveryId: this.activeReservations.deliveryId,
        reservedTime: this.activeReservations.reservedTime
      };

      if (this.idPickupBox) {
        params.pickupboxId = this.idPickupBox;
      } else {
        params.pharmacyId = id;
      }

      await this.getDeliveryShowDecodedSsn(params);

      this.showDecodedSsn = this.decodedSsn.ssn;
    },
    sortReservedTime() {
      this.changeSort = !this.changeSort;
      if (this.changeSort) {
        this.data.sort((a: APIDataReservations, b: APIDataReservations) => {
          return a.reservedTime - b.reservedTime;
        });
      } else {
        this.data.sort((a: APIDataReservations, b: APIDataReservations) => {
          return b.reservedTime - a.reservedTime;
        });
      }
    }
  },
  watch: {
    async pharmacy() {
      if (this.pharmacy) {
        this.loadAPIReservation();
      }
    },
    showModal() {
      this.showDecodedSsn = '';
    }
  }
})
export default class APIReservations extends Vue {}
