import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/CloseModal.svg'


const _hoisted_1 = { class: "resendSMS" }
const _hoisted_2 = { class: "resendSMS__header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "resendSMS__body" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { class: "resendSMS__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.showModalResendSMS)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('resendSMS')), 1),
              _createElementVNode("img", {
                src: _imports_0,
                alt: "CloseModal",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('wantResendSMS')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Button, {
                class: "resendSMS__button",
                variant: "filled",
                title: _ctx.$t('resendSMS'),
                onClick: _ctx.resendSMSRequest
              }, null, 8, ["title", "onClick"]),
              _createVNode(_component_Button, {
                variant: "redBordered",
                title: _ctx.$t('cancel'),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal')))
              }, null, 8, ["title"])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}