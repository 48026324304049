import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "callerAuthentication" }
const _hoisted_2 = { class: "callerAuthentication__authForm" }
const _hoisted_3 = { class: "callerAuthentication__title" }
const _hoisted_4 = { class: "authentication" }
const _hoisted_5 = { class: "phone-input" }
const _hoisted_6 = { class: "callerAuthentication-info" }
const _hoisted_7 = { class: "callerAuthentication-name" }
const _hoisted_8 = { class: "callerAuthentication-otherInfo" }
const _hoisted_9 = { class: "callerAuthentication__button" }
const _hoisted_10 = {
  key: 0,
  class: "callerAuthentication__consentGiven"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Title = _resolveComponent("Title")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_PaymentDetails = _resolveComponent("PaymentDetails")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Title, {
        title: _ctx.$t('callerAuthentication')
      }, null, 8, ["title"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('authentication')), 1)
        ]),
        _createVNode(_component_FormKit, {
          type: "form",
          id: "phoneNumber-form",
          modelValue: _ctx.phoneNumberData,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneNumberData) = $event)),
          actions: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Input, {
                class: "input-phoneNumber",
                name: "phoneNumber",
                type: "tel",
                label: _ctx.$t('phoneNumber'),
                onInput: _ctx.changeInput,
                onKeypress: _withKeys(_withModifiers(_ctx.handleEnter, ["prevent"]), ["enter"])
              }, null, 8, ["label", "onInput", "onKeypress"]),
              _createVNode(_component_Button, {
                class: "callerAuthentication-button",
                variant: "bordered",
                title: _ctx.$t('search'),
                onClick: _ctx.authenticateSearch
              }, null, 8, ["title", "onClick"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_FormKit, {
            type: "form",
            id: "profile-form",
            modelValue: _ctx.formData,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData) = $event)),
            actions: false
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authenticationNameInfo, (el, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createVNode(_component_Input, {
                      class: "input-name",
                      type: el.type,
                      name: el.name,
                      label: _ctx.$t(el.label),
                      disabled: ""
                    }, null, 8, ["type", "name", "label"])
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authenticationSsnAndAddressInfo, (el, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createVNode(_component_Input, {
                      class: "input-otherInfo",
                      type: el.type,
                      name: el.name,
                      label: _ctx.$t(el.label),
                      disabled: ""
                    }, null, 8, ["type", "name", "label"])
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_Button, {
          class: "firstButton",
          variant: _ctx.formData.phoneNumber ? 'bordered' : 'disabledBackground',
          title: _ctx.$t('send'),
          onClick: _ctx.sendSMS
        }, null, 8, ["variant", "title", "onClick"]),
        (_ctx.openButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "authCheck",
              variant: _ctx.getButtonAuthentication(),
              title: _ctx.$t(_ctx.getButtonAuthentication())
            }, null, 8, ["variant", "title"]))
          : _createCommentVNode("", true),
        (_ctx.openButtonUserFound)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              class: "authCheck",
              variant: _ctx.getButtonUserFound(),
              title: _ctx.$t(_ctx.getButtonUserFound())
            }, null, 8, ["variant", "title"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.authenticated === 'done' || _ctx.authenticated === 'completed')
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_Checkbox, {
              name: "consentGiven",
              value: _ctx.consentGiven
            }, null, 8, ["value"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('consentGivenText')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.authenticated === 'done' || _ctx.authenticated === 'completed')
        ? (_openBlock(), _createBlock(_component_PaymentDetails, {
            key: 1,
            userId: _ctx.formData.userId,
            reference: _ctx.callerAuthenticate?.reference,
            onClearAllForms: _ctx.clearAllForms
          }, null, 8, ["userId", "reference", "onClearAllForms"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}