export const messages = {
  en: {
    account: 'Account',
    login: 'Log in',
    logout: 'Log out',
    loginWithMicrosoft: 'Log in with provider',
    pleaseWait: 'Please wait',
    /*errors*/
    error: 'Error',
    not_authorized: 'User not authorized',
    Unauthorized: 'User not authorized',
    'Unknown error': 'Unknown error',
    'Verification link was not found': 'Verification link was not found',
    user_is_not_admin: 'User must be admin',
    user_is_not_found: 'User is not found',
    error_login_user_not_found: 'User not found',
    user_not_found: 'User not found',
    user_push_notification_disabled: 'User does not have notifications enabled',
    user_disabled_app_notifications: 'User disabled app notifications',
    pharmacy_not_found: 'Pharmacy not found',
    pickupbox_not_found: 'Pickupbox not found',
    template_not_found: 'Template was not found',
    alarm_not_found: 'Alarm not found',
    delivery_not_found: 'Delivery not found',
    link_not_found: 'Verification link was not found',
    not_enough_permissions: 'Not enough permissions to perform',
    error_session_expired: 'Session expired',
    no_content: 'No content',
    forbidden: 'Pharmacy access denied',
    strong_auth_failed: 'Strong authentication with BankID failed',

    user_with_email_does_not_exist: 'User with this email does not exist',
    error_login_too_many_attempts: 'Too many login attempts try again later',
    error_login_user_not_found_or_wrong_password:
      'User not found or wrong password',

    newPassword_missing: 'New password is missing',
    repeatNewPassword_missing: 'Repeat new password is missing',
    oldPassword_missing: 'Old password is missing',
    newPassword_not_matches_repeatedNewPassword:
      'The new password does not match the repeated new password',
    oldPassword_matches_newPassword:
      'The old password matches the new password',

    firstName_empty: 'First name is empty',
    lastName_empty: 'Last name is empty',
    email_empty: 'Email is empty',
    invalid_role: 'The wrong role',
    this_role_is_greater_than_yours: 'This role is greater than yours',
    email_missing: 'Email is missing',
    firstName_missing: 'First name is missing',
    lastName_missing: 'Last name is missing',
    appLanguage_missing: 'App language is missing',
    role_missing: 'Role is missing',
    pharmacyIds_must_match: 'Pharmacy IDs must match',

    pharmacyId_missing: 'Pharmacy ID is missing',
    webToolAccess_missing: 'No access to the web tool',
    boxAccess_missing: 'No access to the box',
    roleName_missing: 'Role name is missing',
    role_not_exist: 'The role does not exist',

    name_missing: 'Name is missing',
    businessId_missing: 'Business ID is missing',
    not_valid_strong_auth_value: 'Invalid value strong auth',
    businessId_already_exists: 'Business ID already exists',
    pharmacy_templateId_missing: 'Pharmacy template ID is missing',
    pharmacy_id_missing: 'Pharmacy ID is missing',
    pharmacy_country_missing: 'Pharmacy country is missing',
    pharmacy_language_missing: 'Pharmacy language is missing',
    pharmacy_city_missing: 'Pharmacy city is missing',
    pharmacy_outdoor_missing: 'Pharmacy outdoor is missing',
    pharmacy_uiLanguages_missing: 'Pharmacy UI languages are missing',
    pharmacy_zipCode_missing: 'Pharmacy zip code is missing',
    pharmacy_cardboardType_missing: 'Pharmacy cardboard type is missing',
    pharmacy_already_exists: 'The pharmacy already exists',
    pharmacy_does_not_exist: 'No pharmacy exists',
    not_valid_strong_auth_value_pharmacy_update:
      'Invalid pharmacy update with a strong authorization value',
    unsupported_file_extension: 'Failed to upload logo',
    pharmacy_payment_data_missing:
      'Failed to create payment, pharmacy is missing payment data',
    pharmacy_is_not_active: 'Pharmacy is disabled',

    pickupbox_templateId_missing: 'Pickupbox template ID is missing',
    pickupbox_name_missing: 'Pickupbox name is missing',
    pickupbox_id_missing: 'Pickupbox ID is missing',
    pickupbox_country_missing: 'Pickupbox country is missing',
    pickupbox_language_missing: 'Pickupbox language is missing',
    pickupbox_city_missing: 'Pickupbox city is missing',
    pickupbox_outdoor_missing: 'Pickupbox outdoor is missing',
    pickupbox_uiLanguages_missing: 'Pickupbox UI languages are missing',
    pickupbox_zipCode_missing: 'Pickupbox zip code is missing',
    pickupbox_cardboardType_missing: 'Pickupbox cardboard type is missing',
    pickupbox_already_exists: 'Pickupbox already exists',
    pickupbox__does_not_exist: 'No pickupbox exists',

    callback_not_found: 'Callback request not found',
    record_not_found: 'Record not found',
    user_fcm_token_missing: 'User FCM token is missing',
    payment_already_issued: 'Payment already issued',
    order_not_found: 'Order not found',
    deliveryMethod_validation_failed:
      'DeliveryMethod must be one of pharmacy, pickupbox, homeDelivery',

    missing_fields: 'Missing fields',
    reserved_locker_cannot_be_inactive: 'A reserved locker cannot be inactive',
    locker_do_not_exist: 'The locker does not exist',

    user_is_logged_in_to_box: 'The user is logged in to the Pick-up Box',
    reservation_delivered: 'Reservation delivered',
    pickupbox_disabled: 'The pickupbox is turned off',
    user_is_logged_in_to_box_not_create_reservation:
      'The user entered the field not to create a reservation',

    pickupbox_disabled_invalid_locker: 'The pickupbox disabled invalid locker',
    user_is_logged_in_to_box_invalid_locker:
      'The user is logged into an invalid PickupBox locker',
    invalid_locker: 'Invalid locker',
    locker_already_reserved: 'The locker is already reserved',
    wrong_delivery_status: 'Wrong delivery status',
    permissions_empty: 'Permissions are empty',
    bad_strong_auth_provider:
      'Bad server configuration, no provider for strong authentication given',
    authentication_link_has_expired: 'Authentication link has expired',
    cannot_cancel_payment_with_current_status:
      'Cannot cancel payment for order with current status',
    minimal_payment_amount: 'Minimal payment amount is 0.01',
    fieldIsIncorrectly: 'Sorry, not all fields are filled out correctly',

    validation: {
      required: 'This field is required.',
      email: 'Please enter a valid email address.',
      phoneNumber: 'Phone number format: +xxxxxxxxxxxx',
      length: 'This field should be 6 characters long.',
      length8: 'This field should be 8 characters long.',
      lengthLong: 'Password must be greater than or equal to 8 characters.',
      confirm: 'Passwords must match',
      forDeliveryId: 'Field cannot start from 0'
    },
    /*info*/
    address: 'Address',
    languages: 'Languages',
    versionSW: 'SW Version',
    temperatureInterval: 'Temperature Interval',
    temperatureAlarmInterval: 'Temperature Alarm Interval',
    email: 'Email',
    emailUpperCase: 'EMAIL',
    alarmEmail: 'Alarm Email',
    password: 'Password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    phoneNumber: 'Phone Number',
    show: 'show',
    showUpperCase: 'Show',
    hide: 'hide',
    mobilePhone: 'Mobile Phone',
    mobilePhoneUpperCase: 'MOBILE PHONE',
    phoneUpperCase: 'PHONE',
    alarmPhoneNumber: 'Alarm Phone Number',
    customerPhoneNumber: 'Customer Phone Number',
    firstName: 'First name',
    firstNameUpperCase: 'FIRST NAME',
    lastName: 'Last name',
    lastNameUpperCase: 'LAST NAME',
    fullName: 'Full Name',
    name: 'Name',
    nameUpperCase: 'NAME',
    pharmacyNameUpperCase: 'PHARMACY NAME',
    roleUpperCase: 'ROLE',
    role: 'Role',
    keyUpperCase: 'KEY',
    key: 'Key',
    id: 'ID',
    reservationKeepTime: 'Reservation Keep Time',
    pickupBoxName: 'Pick-up Box Name',
    pickupBoxId: 'Pick-up Box ID',
    city: 'City',
    country: 'Country',
    zipCode: 'Zip Code',
    UILanguages: 'UI Languages',
    defaultLanguages: 'Default Languages',
    cardboardType: 'Pick-up Box cardboard type',
    userIdUpperCase: 'USER ID',
    deliveryStatusUpperCase: 'DELIVERY STATUS',
    deliveryStatus: 'Delivery status',
    kolliIDUpperCase: 'DELIVERY-ID',
    lockerID: 'Locker-ID',
    lockerIDUpperCase: 'LOCKER-ID',
    orderExpired: 'Order Expired',
    orderExpiredUpperCase: 'ORDER EXPIRED',
    causeOfAlarmUpperCase: 'CAUSE OF ALARM',
    orderID: 'Order-ID',
    lang: 'Language',
    desktopLang: 'Desktop Language',
    pharmacyInfo: 'Pharmacy Info',
    userBoxPin: 'User Box Pin',
    farmacyEntryPin: 'Pharmacy Entry Pin',
    pharmacyReservations: 'Pharmacy Reservations',
    lastUpdate: 'Last Update',
    kolliID: 'Delivery-Id',
    timeReserved: 'Time Reserved',
    timeDelivery: 'Time Delivery',
    timePickup: 'Time Pickup',
    timeDelete: 'Time Delete',
    timeExpired: 'Time Expired',
    deliveryMadeBy: 'Delivery Made By',
    description: 'Description',
    resendSMSCounter: 'Amount of SMS sent:',
    resendSMSTimestamps: 'Time of the last SMS sent:',
    timestampUpperCase: 'TIMESTAMP',
    timestamp: 'Identification time',
    socialSecurityNumber: 'Social Security Number',
    homeAddress: 'Home Address',
    handlerUpperCase: 'HANDLER',
    deliveryUpperCase: 'DELIVERY',
    paymentUpperCase: 'PAYMENT',
    totalUpperCase: 'TOTAL (EUR)',
    contentUpperCase: 'CONTENT',
    issuedAtUpperCase: 'ISSUEA AT',
    eventTimeUpperCase: 'EVENT TIME',
    confirm: 'Confirm',
    descriptionUpperCase: 'DESCRIPTION',
    comment: 'Comment',
    commentUpperCase: 'COMMENT',
    internalNote: 'Internal note',
    customerReceiptMessage: 'Customer receipt message',
    reservationMadeByUpperCase: 'RESERVATION MADE BY',
    deliveredByUpperCase: 'DELIVERED BY',
    assignedTimeByUpperCase: 'ASSIGNED AT',
    assignedTime: 'Assigned at',
    assignedUserName: 'Assigned',
    fulfillment_order_id: 'Fulfillment order id',
    order_id: 'Order-ID',
    reservedUserName: 'Reservetion made by',
    pickupboxPageAssignDelivery: 'PICKUPBOX PAGE ASSIGN DELIVERY',
    lastLoginUpperCase: 'LAST LOGIN',
    userUpperCase: 'USER',
    customerUpperCase: 'CUSTOMER',
    typeUpperCase: 'TYPE',
    assignUpperCase: 'ASSIGN',
    clientUpperCase: 'CLIENT',
    preferredHoursUpperCase: 'PREFERRED HOURS',
    requestedAtUpperCase: 'REQUESTED AT',
    alarmOk: 'Everything is fine with the locker',
    alarmDoorOpen: 'The locker does not close',
    alarmDoorClosed: 'The locker does not open',
    alarmTempHigh: 'The temperature in the locker is high',
    alarmTempLow: 'The temperature in the locker is low',
    alarmOther: 'No data on the locker is available',
    alarmMissing: 'Locker data is missing',
    pending: 'PENDING',
    failed: 'FAILED',
    completed: 'COMPLETED',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    roomTemp: 'Room temp',
    includesDrug: 'Includes drug',
    includesDrugUpperCase: 'INCLUDES DRUG',
    log: 'Log',
    in: 'in',
    out: 'out',
    min: 'min',
    minute: ' minute',
    minutes: ' minutes',
    hour: 'hour',
    hours: 'hours',
    day: ' day',
    days: ' days',
    daysDelivery: 'Days',
    averagePickupTime: 'AVG PickupTime',
    Hours: 'Hours',
    basicLocker: 'Basic locker:',
    fridgeLocker: 'Fridge locker:',
    deliveryExpirationTime: 'Delivery expiration time',
    reminderMessageTime: 'Reminder message time',
    reservationExpirationTime: 'Reservation expiration time',
    fullHistory: 'Full history',
    /*actions*/
    forgotPassword: 'Forgot Password?',
    changePassword: 'Change Password',
    sendResetLink: 'Send reset link',
    newOrder: 'New Order',
    viewOrder: 'View Order',
    sentEmail: 'Password reset email sent.',
    edit: 'Edit',
    add: 'Add',
    addOption: 'Add Option',
    doNotSaveOption: 'Do not save this Option',
    chooseLanguage: 'Choose languages for SMS',
    serviceLanguagesAvailable: 'Service languages available',
    save: 'Save',
    send: 'Send',
    saveForm: 'Save Form',
    saveFormAndSendPayment: 'Save Form & Send Payment',
    finish: 'Finish',
    discard: 'Discard',
    delete: 'Delete',
    cancel: 'Cancel',
    exit: 'Exit',
    cleanFields: 'Clean fields',
    search: 'Search',
    authenticated: 'Authenticated',
    authenticationFailed: 'Authentication Failed',
    authenticationPending: 'Authenticating',
    paymentCompleted: 'Payment Completed',
    paymentFailed: 'Payment Failed',
    paymentSuccess: 'Payment Success',
    paymentPending: 'Payment Pending',
    userNotFound: 'User not found',
    userFound: 'User found',
    authenticationRegisterSuccess: 'Authentication success!',
    authenticationRegisterFailed: 'Authentication failed!',
    makeServiceRequest: 'Make a service request',
    seriviceRequested: 'Service requested',
    cancelPaymentRequest: 'Cancel payment request',
    deleteUser: 'Delete User',
    addUser: 'Add User',
    createUser: 'Create User',
    loginInformation: 'Login Information',
    selectMultipleLockers: 'Select Multiple Lockers',
    disableLocker: 'Disable Locker',
    locker: 'Locker',
    reservation: 'Reservation',
    delivery: 'Delivery',
    deleteReservation: 'Delete Reservation',
    reserve: 'Reserve',
    newBookingPharmacyPickup: 'New booking pharmacy pickup',
    exportJSON: 'Export.json',
    importJSON: 'Import.json',
    downloadCSV: 'Download.csv',
    downloadTempCSV: 'Download temperatures.csv',
    downloadPDF: 'Download.pdf',
    createReservation: 'Create Reservation',
    webToolAccess: 'Access to web tool',
    boxAccess: 'Access to box',
    ok: 'Ok',
    happy: 'Happy',
    angry: 'Angry',
    yes: 'Yes',
    no: 'No',
    and: 'and',
    assignReservation: 'Move aged reservation to box',
    redirectToPickupBox: 'Redirect to PickupBox',
    quickReservation: 'Quick reservation',
    quickReservationLockers: 'Manual quick reservation',
    isDrug: 'Drug contain',
    drug: 'Drug',
    ssn: 'SSN',
    consentGiven: 'CONSENT GIVEN',
    resendSMS: 'Resend SMS',
    showFullHistory: 'Show full history',
    hideFullHistory: 'Hide full history',
    authentication: 'Authentication',
    authenticateClient: 'Authenticate Client',
    paymentDetails: 'Payment Details',
    onBehalf: 'On Behalf?',
    reasonForTransaction: 'Reason for transaction',
    sendPaymentNotification: 'Send payment request',
    paymentNotificationWasSuccessfullyResend:
      'Payment notification was successfully re-sent.',
    /*text*/
    pageNotFound: 'Page Not Found',
    wrongFormat: 'Wrong format',
    checkURL:
      'The specified file was not found on this website. Please check the URL for mistakes and try again.',
    forgotPasswordTex:
      'Please enter your email address and we will send you an email. By following the link in the email you will be able to enter your new password.',
    passwordCharacters: 'Password should contain at least 8 characters.',
    youWantDelete: 'Are you sure you want to delete',
    thisChangeCannotBeUndone: 'This change cannot be undone.',
    descriptionDestroy:
      'Destroy - Move the reservation to history. That is to be used when you do destruction.',
    descriptionDelete:
      'Delete - Move the reservation back to the Aged reservations tab, i.e. cancel the reservation to reserve a new slot.',

    descriptionCancel: 'Cancel - Close window without action.',
    youWantDeleteReservation:
      'Are you sure you want to delete this reservation? This change cannot be undone',
    undone: '? This change cannot be undone.',
    changePasswordInfo:
      'INFO: Memorize the password and hand it over to the user. You cannot recover the password later. Password should contain at least 8 characters.',
    noPickupBox: 'No Pick-up Box',
    addDescription: 'Add description to delivery history',
    addComment: 'Add comment to order',
    infoReservation: 'Information about the reservation',
    addAlarmComment: 'Add Alarm comment',
    deviationsInTimeFrame: 'Deviations in time frame',
    addUserMainInfo:
      'INFO: Memorize the password and hand it over to the user. You cannot recover the password later.',
    addUserInfo: 'Password should contain at least 8 characters.',
    lockersSelected: 'lockers are selected',
    messageTemperatureHigh: 'Locker temperature is too high: ',
    messageTemperatureLow: 'Locker temperature is too low: ',
    noData: 'No data',
    errorDate: 'Error date',
    noReservations: 'No Reservations',
    noOrderReservedYet: 'No Order reserved yet.',
    enableLocker: 'No Order reserved yet. Enable Locker to reserve orders.',
    noAlarms: 'No alarms during selected time period',
    assignDeliveryFromDesk: 'Assign Delivery to be picked-up from desk',
    assignDeliveryFromLocker:
      'Assign Delivery to be picked-up from the PickUp Box',
    assignToLocker:
      'If you want this delivery to be picked up from a Pickup! box, press the Redirect to PickupBox button. Click on an empty slot and select Move aged reservation to box.',
    pickedUpRevervation: 'Was the reservation picked up?',
    chooseDelivery: 'Choose the delivery from the list',
    chooseThisDelivery: 'Choose this delivery',
    pickupboxIsInactive: 'Pickupbox is inactive. Feature unavailable',
    youReservationPinCode: 'You reservation Pin Code',
    pinCode: 'Pin Code',
    wantResendSMS: 'Are you sure you want to resend the SMS?',
    enoughPermissions: 'You do not have enough permissions',
    noContent: 'NO CONTENT.',
    locationNotAllowed: 'LOCATION NOT ALLOWED',
    noSpaceInPickupBoxes: 'No space in pickup boxes',
    descriptionServiceRequest: 'Description of the service request',
    choosePharmacy: 'Choose pharmacy you want to log in',
    changePharmacy: 'Change Pharmacy',
    placeholderText: 'Select Pharmacy',
    connectionLost: 'Internet connection lost',
    clientInformation: 'Client Information',
    deliveryMethodUpperCase: 'DELIVERY METHOD',
    deliveryMethod: 'Delivery Method',
    issuePaymentUpperCase: 'ISSUE PAYMENT',
    issuePayment: 'Issue Payment',
    deliveryContentMatchesOrder: 'Delivery Content Matches Order',
    handler: 'Handler',
    confirmedDy: 'Confirmed by: ',
    homeDelivery: 'Home Delivery',
    confirmation: 'Confirmation',
    confirmationText:
      'Please confirm that delivery content matches the order. This action is irreversible.',
    successfully: 'Successfully',
    workingHours: 'WORKING HOURS',
    services: 'SERVICES',
    showToCustomers: 'Show to customers',
    showToCustomersUpperCase: 'SHOW TO CUSTOMERS',
    sendNewCallbackRequestEmailNotification:
      'Send email notification about callback request',
    closed: 'Closed',
    closedUpperCase: 'CLOSED',
    startAt: 'START AT',
    endAt: 'END AT',
    editWorkingHours: 'Edit Working Hours',
    dayOfTheWeek: 'DAY OF THE WEEK',
    chooseWorkingHours: 'Choose working hours',
    orderPayment: 'Actions taken',
    prescription: 'Logging in to the prescription center',
    other: 'Other',
    medicationDelivery: 'Medication delivery',
    prescriptionDrugs: 'Prescription drugs',
    selfCare: 'Self care',
    medicalAdvice: 'Midical advice',
    medicalAdviceUpperCase: 'MEDICAL ADVICE',
    medicalAdviceGiven: 'Medical advice given',
    orderNumber: 'Purchase order number',
    orderNumberUpperCase: 'PURCHASE ORDER NUMBER',
    reference: 'Reference',
    totalSum: 'Total Sum (EUR)',
    discardAuthentication: 'Discard Authentication',
    discardAuthenticationText:
      'Are you sure you want to leave the "Caller Authentication" page? The data on the form will be lost',
    markAsDone: 'Mark as Done',
    markAsMissed: 'Mark as Missed',
    backToCallerAuthentication: 'Go to identification',
    totalCustomersVotes: 'Total customers votes: ',
    templates: 'Templates',
    allActive: 'All Active',
    active: 'Active',
    translation: 'Translation',
    numberOfUsers: 'Number of users',
    position: 'Position',
    title: 'Title',
    titleUpperCase: 'TITLE',
    subtitleUpperCase: 'SUBTITLE',
    advertPhoto: 'Advert Photo',
    photoUpperCase: 'PHOTO',
    screenTimeoutUpperCase: 'SCREEN TIMEOUT',
    colorUpperCase: 'COLOR',
    logoPhoto: 'Logo Photo',
    chooseScale: 'Choose the scale',
    hintTextUpperCase: 'HINT TEXT',
    optionsUpperCase: 'OPTIONS',
    reportUpperCase: 'REPORT',
    gradeUpperCase: 'GRADE',
    multipleOptions: 'Multiple Options',
    multipleOptionsUpperCase: 'MULTIPLE OPTIONS',
    timeout: 'Timeout',
    addNewOption:
      'You cannot create another Option because there are already three active ones',
    theSamePositions: 'These options have the same positions',
    positionIsTaken: 'This position is already taken, select another one',
    showOnlyWithContact: 'Show only with contact',
    showOnlyWithReport: 'Show only with report',
    showOnlyWithOption: 'Show only with option',
    showOnlyWithGrade: 'Show only with grade',
    type: 'Type',
    face: 'Face',
    higher: 'Higher',
    lower: 'Lower',
    same: 'Same',
    welcomeToApteekkiPuhelu: 'Welcome to ApteekkiPuhelu',
    openApp:
      'If you have the app installed, you can open it from here. If not,',
    clickHere: 'click here',
    downloadApp: ' to download the app.',
    platformUnsupported: 'Your platform is unsupported',
    chooseColor: 'Choose a color',
    infoChangePassword:
      'INFO: Memorize the password and hand it over to the user. You cannot recover the password later. Password should contain at least 8 characters.',
    green: 'New callback request',
    orange: 'Skipped callback request',
    rememberAboutMedicalAdvice:
      'Please remember to confirm if medicine advice was given',
    Alarm: 'Alarm',
    'Box Temperature': 'Box Temperature',
    'Fridge 0-1 Temperature': 'Fridge 0-1 Temperature',
    'Fridge 1-1 Temperature': 'Fridge 1-1 Temperature',
    'Fridge 2-1 Temperature': 'Fridge 2-1 Temperature',
    Max: 'Max',
    Min: 'Min',
    'Fridge Max': 'Fridge Max',
    'Fridge Min': 'Fridge Min',
    /*backTo*/
    backToUsers: 'Back To Users',
    backToInfoPickupBox: 'Back To Info Pick-up Box',
    backToLogIn: 'Back to Log in',
    backToAuthenticationHistory: 'Back to Authentication History',
    backToOrdersHistory: 'Back to Orders History',
    backToCustomersFeedback: 'Back to Customers Feedback',
    consentGivenText:
      'Customer gives consent to sharing Kela-direct reimbursement eligibility and prescription data from Prescription Centre.',
    /*lockers*/
    all: 'All',
    allUpperCase: 'ALL',
    free: 'Free',
    reserved: 'Reserved',
    reservedUpperCase: 'RESERVED',
    reservedTable: ' Reserved',
    reservedText: 'reserved',
    deleted: 'Deleted',
    destroy: 'Destroyed',
    deletedTable: ' Deleted',
    expired: 'Expired',
    expiredTable: ' Expired',
    notInUse: 'Not in Use',
    fridge: 'Fridge',
    delivered: 'Delivered to location',
    deliveredUpperCase: 'DELIVERED',
    deliveredTable: ' Delivered',
    removed: 'Removed',
    removedTable: ' Removed',
    disabled: 'Disabled',
    cancelled: 'Cancelled',
    cancelledTable: ' Cancelled',
    cancelledTableUpperCase: 'CANCELLED',
    doneUpperCase: 'DONE',
    pickedUp: 'Picked Up',
    pickup: 'Picked Up',
    pickedUpUpperCase: 'PICKED UP',
    destroyedUpUpperCase: 'DESTROYED',
    pickedUpTable: ' Picked Up',
    destroyedTable: ' Destroyed',
    destroyed: 'Destroyed',
    small: 'Small',
    medium: 'Medium',
    large: 'Large',
    noSsn: 'No SSN',
    givenOutUserName: 'Given out user',
    /*capacity Pick-up Box*/
    week: 'Week',
    weekUpperCase: 'WEEK',
    month: 'Month',
    boxTypeUpperCase: 'BOX TYPE',
    capacityUpperCase: 'CAPACITY %',
    averagePickupTimeUpperCase: 'AVERAGE PICK-UP TIME',
    /*edit Pick-up Box*/
    temperatureIntervals: 'Temperature Intervals',
    /*temperatureLog*/
    sensorUpperCase: 'LOCKER-ID/SENSOR',
    startTimeUpperCase: 'START TIME',
    startTime: 'Start time',
    endTimeUpperCase: 'END TIME',
    endTime: 'End time',
    durationUpperCase: 'DURATION',
    minUpperCase: 'MIN °C',
    maxUpperCase: 'MAX °C',
    averageUpperCase: 'AVERAGE °C',
    alarmCommentUpperCase: 'ALARM COMMENT',
    date: 'Date',
    temperature: 'Temperature',
    temperatureMax: 'Max Temperature',
    temperatureMin: 'Min Temperature',
    timestempTemperatures: 'Check temperatures',
    lastCheckedBy: 'Last checked by',
    checkTime: 'Checking time',
    /*deliveryStatistics*/
    monthUpperCase: 'MONTH',
    yearUpperCase: 'YEAR',
    deliveriesUpperCase: '# DELIVERIES',
    pickupsUpperCase: '# PICKEDUP',
    expiredUpperCase: '# EXPIRED',
    destroyedUpperCase: '# DESTROYED',
    avgPickupTimeUpperCase: '# AVG PICKUPTIME (H)',
    totalUsageUpperCase: '# TOTAL USAGE (%)',
    cancelledUpperCase: '# CANCELED',
    hoursUpperCase: 'Hours',
    avgPickupTime: 'AVG PICK-UP TIME',
    /*page*/
    pharmacy: 'Pharmacy',
    profile: 'My Information',
    callerAuthentication: 'Caller Authentication',
    authenticationHistory: 'Authentication History',
    ordersHistory: 'Orders History',
    pickupBox: 'Pick-up Box',
    pickupBoxes: 'Pick-up Boxes',
    pharmacyPickup: 'Pharmacy pick up',
    users: 'Users',
    user: 'User',
    mainUser: 'Main user',
    callbackRequests: 'Callback Requests',
    customersFeedback: 'Customers Feedback',
    userGuide: 'User Guide',
    pharmacyInformation: 'Pharmacy Information',
    lockers: 'Lockers',
    order: 'Order',
    temperatureChecking: 'Temperature Checking',
    infoPickupBox: 'Pick-up Box Info',
    boxInfo: 'Box Info',
    editPickupBox: 'Edit Pick-up Box',
    reservations: 'Reservations',
    apiReservations: 'Aged Reservations',
    deliveryHistory: 'Delivery History',
    temperatureLog: 'Temperature Log',
    alarms: 'Alarms',
    capacity: 'Capacity',
    deliveryStatistics: 'Delivery Statistics',
    reserveLocker: 'Reserve Locker',
    settings: 'Settings Customers Feedback',
    general: 'General',
    options: 'Options',
    report: 'Report',
    grade: 'Grade',
    contact: 'Contact',
    contactUpperCase: 'CONTACT',
    thanks: 'Thanks',
    statistics: 'Statistics',
    customers: 'Customers'
  },
  fi: {
    account: 'Tili',
    login: 'Kirjaudu sisään',
    logout: 'Kirjaudu ulos',
    loginWithMicrosoft: 'Kirjaudu sisään palveluntarjoajan avulla',
    pleaseWait: 'Ole hyvä ja odota',
    /*errors*/
    error: 'Virhe',
    not_authorized: 'Käyttäjällä ei ole lupaa',
    Unauthorized: 'Käyttäjällä ei ole lupaa',
    'Unknown error': 'Tuntematon virhe',
    'Verification link was not found': 'Varmennuslinkkiä ei löytynyt',
    user_is_not_admin: 'Käyttäjän on oltava järjestelmänvalvoja',
    user_is_not_found: 'Käyttäjää ei löydy',
    error_login_user_not_found: 'Käyttäjää ei löydy',
    user_not_found: 'Käyttäjää ei löydy',
    user_push_notification_disabled: 'Käyttäjällä ei ole ilmoituksia käytössä',
    user_disabled_app_notifications:
      'Käyttäjän käytöstä poistetut sovellusilmoitukset',
    pharmacy_not_found: 'Apteekkia ei löydy',
    pickupbox_not_found: 'Pickupboxia ei löydy',
    template_not_found: 'Mallia ei löytynyt',
    alarm_not_found: 'Hälytystä ei löydy',
    delivery_not_found: 'Toimitusta ei löydy',
    link_not_found: 'Varmennuslinkkiä ei löytynyt',
    not_enough_permissions: 'Ei tarpeeksi oikeuksia suorittamiseen',
    error_session_expired: 'Istunto päättynyt',
    no_content: 'Ei sisältöä',
    forbidden: 'Apteekkiin pääsy kielletty',
    strong_auth_failed: 'Vahva todennus BankID:llä epäonnistui',

    user_with_email_does_not_exist:
      'Käyttäjää, jolla on tämä sähköpostiosoite, ei ole olemassa',
    error_login_too_many_attempts:
      'Liian monta kirjautumisyritystä yritä myöhemmin uudelleen',
    error_login_user_not_found_or_wrong_password:
      'Käyttäjää ei löydy tai salasana on väärä',

    newPassword_missing: 'Salasanan vaihto epäonnistui',
    repeatNewPassword_missing: 'Salasanan vaihto epäonnistui',
    oldPassword_missing: 'Vanha salasana puuttuu',
    newPassword_not_matches_repeatedNewPassword:
      'Uusi salasana ei vastaa toistettua uutta salasanaa',
    oldPassword_matches_newPassword: 'Vanha salasana vastaa uutta salasanaa',

    firstName_empty: 'Etunimi on tyhjä',
    lastName_empty: 'Sukunimi on tyhjä',
    email_empty: 'Sähköpostiosoite on tyhjä',
    invalid_role: 'Väärä rooli',
    this_role_is_greater_than_yours: 'Tämä rooli on suurempi kuin sinun',
    email_missing: 'Sähköposti puuttuu',
    firstName_missing: 'Etunimi puuttuu',
    lastName_missing: 'Sukunimi puuttuu',
    appLanguage_missing: 'Sovelluksen kieli puuttuu',
    role_missing: 'Rooli puuttuu',
    pharmacyIds_must_match: 'Apteekkitunnusten on täsmättävä',
    pharmacyId_missing: 'Apteekin tunnus puuttuu',
    webToolAccess_missing: 'Ei pääsyä verkkotyökaluun',
    boxAccess_missing: 'Ei pääsyä laatikkoon',
    roleName_missing: 'Roolin nimi puuttuu',
    role_not_exist: 'Roolia ei ole olemassa',

    name_missing: 'Nimi puuttuu',
    businessId_missing: 'Yritystunnus puuttuu',
    not_valid_strong_auth_value: 'Invalid value strong auth',
    businessId_already_exists: 'Business ID on jo olemassa',
    pharmacy_templateId_missing: 'Apteekkimallin tunnus puuttuu',
    pharmacy_id_missing: 'Apteekin tunnus puuttuu',
    pharmacy_country_missing: 'Apteekin maa puuttuu',
    pharmacy_language_missing: 'Apteekin kieli puuttuu',
    pharmacy_city_missing: 'Apteekin kaupunki puuttuu',
    pharmacy_outdoor_missing: 'Apteekin ulkoalue puuttuu',
    pharmacy_uiLanguages_missing: 'Apteekki UI kielet puuttuvat',
    pharmacy_zipCode_missing: 'Apteekin postinumero puuttuu',
    pharmacy_cardboardType_missing: 'Apteekin pahvityyppi puuttuu',
    pharmacy_already_exists: 'Apteekki on jo olemassa',
    pharmacy_does_not_exist: 'Apteekkia ei ole olemassa',
    not_valid_strong_auth_value_pharmacy_update:
      'Virheellinen apteekkipäivitys vahvalla valtuutusarvolla',
    unsupported_file_extension: 'Logon lataaminen epäonnistui',
    pharmacy_payment_data_missing:
      'Maksun luominen epäonnistui, apteekista puuttuu maksutiedot',
    pharmacy_is_not_active: 'Apteekki on poistettu käytöstä',

    pickupbox_templateId_missing: 'Pickupbox-mallin tunnus puuttuu',
    pickupbox_name_missing: 'Pickupboxin nimi puuttuu',
    pickupbox_id_missing: 'Pickupbox-tunnus puuttuu',
    pickupbox_country_missing: 'Pickupboxin maa puuttuu',
    pickupbox_language_missing: 'Pickupbox-kieli puuttuu',
    pickupbox_city_missing: 'Poimintalaatikon kaupunki puuttuu',
    pickupbox_outdoor_missing: 'Poimintalaatikon kaupunki puuttuu',
    pickupbox_uiLanguages_missing:
      'Poimintalaatikon käyttöliittymän kielet puuttuvat',
    pickupbox_zipCode_missing: 'Pickupboxin postinumero puuttuu',
    pickupbox_cardboardType_missing: 'Pickupbox cardboard type puuttuu',
    pickupbox_already_exists: 'Pickupbox on jo olemassa',
    pickupbox__does_not_exist: 'Pickupboxia ei ole olemassa',

    callback_not_found: 'Soittopyyntöä ei löydy',
    record_not_found: 'Tietuetta ei löydy',
    user_fcm_token_missing: 'Käyttäjän FCM-tunniste puuttuu',
    payment_already_issued: 'Maksu on jo suoritettu',
    order_not_found: 'Tilausta ei löydy',
    deliveryMethod_validation_failed:
      'Toimitustapa on oltava jokin seuraavista: apteekki, noutolaatikko, kotiinkuljetus',

    missing_fields: 'Puuttuvat kentät',
    reserved_locker_cannot_be_inactive:
      'Varattu lokero ei voi olla inaktiivinen',
    locker_do_not_exist: 'Lokeroa ei ole olemassa',

    user_is_logged_in_to_box: 'Käyttäjä on kirjautunut noutolaatikkoon',
    reservation_delivered: 'Varaus toimitettu',
    pickupbox_disabled: 'Noutolaatikko on kytketty pois päältä',
    user_is_logged_in_to_box_not_create_reservation:
      'Käyttäjä kirjoitti kenttään ei luoda varausta',

    pickupbox_disabled_invalid_locker:
      'Poimintalaatikko poistettu käytöstä virheellinen kaappi',
    user_is_logged_in_to_box_invalid_locker:
      'Käyttäjä on kirjautunut virheelliseen noutolaatikkoon',
    invalid_locker: 'Virheellinen lokero',
    locker_already_reserved: 'Lokerikko on jo varattu',
    wrong_delivery_status: 'Väärä toimituksen tila',
    permissions_empty: 'Oikeudet ovat tyhjiä',
    bad_strong_auth_provider:
      'Huono palvelinkonfiguraatio, vahvan tunnistautumisen tarjoajaa ei ole annettu',
    authentication_link_has_expired: 'Tunnistuslinkki on vanhentunut',
    cannot_cancel_payment_with_current_status:
      'Nykyisessä tilassa olevan tilauksen maksua ei voi peruuttaa',
    minimal_payment_amount: 'Maksun vähimmäismäärä on 0,01',
    fieldIsIncorrectly: 'Kaikkia kenttiä ei ole täytetty oikein',

    validation: {
      required: 'Tämä kenttä on pakollinen.',
      email: 'Kirjoita voimassa oleva sähköpostiosoite.',
      phoneNumber: 'Puhelinnumeron muoto: +xxxxxxxxxxxx',
      length: 'Tämän kentän tulee olla 6 merkkiä pitkä.',
      length8: 'Tämän kentän tulee olla 8 merkkiä pitkä.',
      lengthLong: 'Salasanan on oltava vähintään 8 merkkiä.',
      confirm: 'Salasanojen on vastattava',
      forDeliveryId: 'Kenttä ei voi alkaa arvosta 0'
    },
    /*info*/
    address: 'Osoite',
    languages: 'Kielet',
    versionSW: 'SW Versio',
    temperatureInterval: 'Lämpötilan intervalli',
    temperatureAlarmInterval: 'Lämpötilahälytysväli',
    email: 'Sähköposti',
    emailUpperCase: 'SÄHKÖPOSTI',
    alarmEmail: 'Hälytysten sähköpostiosoite',
    password: 'Salasana',
    currentPassword: 'Nykyinen salasana',
    newPassword: 'Uusi salasana',
    confirmPassword: 'Vahvista salasana',
    phoneNumber: 'Puhelinnumero',
    show: 'näytä',
    showUpperCase: 'Näytä',
    hide: 'piilota',
    mobilePhone: 'Matkapuhelin',
    mobilePhoneUpperCase: 'MATKAPUHELIN',
    phoneUpperCase: 'PUHELIN',
    alarmPhoneNumber: 'Hälytyksen puhelinnumero',
    customerPhoneNumber: 'Apteekin puhelinnumero',
    firstName: 'Etunimi',
    firstNameUpperCase: 'ETUNIMI',
    lastName: 'Sukunimi',
    lastNameUpperCase: 'SUKUNIMI',
    fullName: 'Koko nimi',
    name: 'Nimi',
    nameUpperCase: 'NIMI',
    pharmacyNameUpperCase: 'APTEEKIN NIMI',
    role: 'Rooli',
    roleUpperCase: 'ROOLI',
    keyUpperCase: 'AVAIN',
    key: 'Avain',
    id: 'ID',
    reservationKeepTime: 'Varauksen säilytysaika',
    pickupBoxName: 'Noutolaatikon Nimi',
    pickupBoxId: 'Noutolaatikon Tunnus',
    city: 'Kaupunki',
    country: 'Maa',
    zipCode: 'Postinumero',
    UILanguages: 'UI Kielet',
    defaultLanguages: 'Oletuskielet',
    cardboardType: 'Noutolaatikko Pahvityyppinen',
    userIdUpperCase: 'KÄYTTÄJÄTUNNUS',
    deliveryStatusUpperCase: 'TOIMITUKSEN TILA',
    deliveryStatus: 'Toimituksen tila',
    kolliIDUpperCase: 'TOIMITUKSEN ID',
    lockerID: 'Locker-ID',
    lockerIDUpperCase: 'LOCKER-ID',
    orderExpired: 'Tilaus vanhentunut',
    orderExpiredUpperCase: 'TILAUS VANHENTUNUT',
    causeOfAlarmUpperCase: 'HÄLYTYKSEN SYY',
    orderID: 'Tilausnumero',
    lang: 'Kieli',
    desktopLang: 'Työpöydän Kieli',
    pharmacyInfo: 'Apteekin tiedot',
    userBoxPin: 'Käyttäjän PIN-koodi',
    farmacyEntryPin: 'Käyttäjätunnus',
    pharmacyReservations: 'Apteekkivaraukset',
    lastUpdate: 'Viimeisin Päivitys',
    kolliID: 'Toimituksen ID',
    timeReserved: 'Aika Varattu',
    timeDelivery: 'Ajan Toimitus',
    timePickup: 'Aika Nouto',
    timeDelete: 'Aika Poista',
    timeExpired: 'Aika Päättynyt',
    deliveryMadeBy: 'Toimitus Tekijä',
    description: 'Kuvaus',
    comment: 'Kommentti',
    commentUpperCase: 'KOMMENTTI',
    internalNote: 'Sisäinen muistiinpano',
    customerReceiptMessage: 'Asiakaskuitin viesti',
    resendSMSCounter: 'Lähetettyjen SMS-viestien määrä:',
    resendSMSTimestamps: 'Viimeisen lähetetyn SMS:n aika:',
    timestampUpperCase: 'AIKA',
    timestamp: 'Tunnistusaika',
    socialSecurityNumber: 'Sosiaaliturvatunnus',
    homeAddress: 'Kotiosoite',
    handlerUpperCase: 'KÄSITTELIJÄ',
    deliveryUpperCase: 'TOIMITUS',
    paymentUpperCase: 'MAKSU',
    totalUpperCase: 'YHTEENSÄ (EUR)',
    contentUpperCase: 'SISÄLTÖ',
    issuedAtUpperCase: 'MAKSUN LÄHETYSAIKA',
    eventTimeUpperCase: 'TAPAHTUMA-AIKA',
    confirm: 'Vahvista',
    descriptionUpperCase: 'KUVAUS',
    reservationMadeByUpperCase: 'VARAUKSEN TEKEE',
    deliveredByUpperCase: 'TOIMITETTU',
    assignedTimeByUpperCase: 'MÄÄRITETTY AIKAAN',
    assignedTime: 'Määritelty aikaan',
    assignedUserName: 'Määritelty',
    fulfillment_order_id: 'Toimitustilauksen-ID',
    order_id: 'Tilauksen-ID',
    reservedUserName: 'Varauksen tekee',
    pickupboxPageAssignDelivery: 'PICKUPBOX-SIVU SIIRRÄ TOIMITUS',
    lastLoginUpperCase: 'VIIMEINEN KIRJAUTUMINEN',
    userUpperCase: 'KÄYTTÄJÄ',
    customerUpperCase: 'ASIAKAS',
    typeUpperCase: 'TYYPPI',
    assignUpperCase: 'SIIRRÄ',
    clientUpperCase: 'ASIAKAS',
    preferredHoursUpperCase: 'SUOSITELLUT SOITTOAJAT',
    requestedAtUpperCase: 'PYYDETÄÄN',
    alarmOk: 'Kaikki on kunnossa kaapin kanssa',
    alarmDoorOpen: 'Kaappi ei sulkeudu',
    alarmDoorClosed: 'Kaappi ei avaudu',
    alarmTempHigh: 'Lokeron lämpötila on korkea',
    alarmTempLow: 'Lokeron lämpötila on alhainen',
    alarmOther: 'Lokerosta ei ole tietoja saatavilla',
    alarmMissing: 'Lokeron tiedot puuttuvat',
    pending: 'VIREILLÄ',
    failed: 'EPÄONNISTUNUT',
    completed: 'TÄYDENNETTY',
    january: 'Tammikuu',
    february: 'Helmikuu',
    march: 'Maaliskuu',
    april: 'Huhtikuu',
    may: 'Toukokuu',
    june: 'Kesäkuu',
    july: 'Heinäkuu',
    august: 'Elokuu',
    september: 'Syyskuu',
    october: 'Lokakuu',
    november: 'Marraskuu',
    december: 'Joulukuu',
    monday: 'Maanantai',
    tuesday: 'Tiistai',
    wednesday: 'Keskiviikko',
    thursday: 'Torstai',
    friday: 'Perjantai',
    saturday: 'Lauantai',
    sunday: 'Sunnuntai',
    roomTemp: 'Huoneen lämpötila',
    includesDrug: 'Sisältää lääkkeen',
    includesDrugUpperCase: 'SISÄLTÄÄ LÄÄKKEEN',
    log: 'Kirjautui',
    in: 'sisään',
    out: 'ulos',
    min: 'minuutti',
    minute: ' minuutti',
    minutes: ' minuuttia',
    hour: 'tunti',
    hours: 'tuntia',
    day: ' päivä',
    days: ' päivät',
    daysDelivery: 'Päivät',
    basicLocker: 'Peruslokero:',
    fridgeLocker: 'Jääkaapin lukko:',
    deliveryExpirationTime: 'Toimituksen vanhenemisaika',
    reminderMessageTime: 'Muistutusviestin aika',
    reservationExpirationTime: 'Varauksen vanhenemisaika',
    fullHistory: 'Koko historia',
    /*actions*/
    forgotPassword: 'Unohtuiko salasana?',
    changePassword: 'Vaihda Salasana',
    sendResetLink: 'Lähetä nollauslinkki',
    newOrder: 'Uusi tilaus',
    viewOrder: 'Näytä tilaus',
    sentEmail: 'Salasanan palautusviesti lähetetty.',
    edit: 'Muokkaa',
    add: 'Lisää',
    addOption: 'Lisää vaihtoehto',
    doNotSaveOption: 'Älä tallenna tätä vaihtoehtoa',
    chooseLanguage: 'Valitse tekstiviestien kielet',
    serviceLanguagesAvailable: 'Palvelua seuraavilla kielillä:',
    save: 'Tallenna',
    send: 'Lähetä',
    saveForm: 'Tallenna lomake',
    saveFormAndSendPayment: 'Tallenna lomake & lähetä maksu',
    finish: 'Valmis',
    discard: 'Tyhjennä',
    delete: 'Poista',
    cancel: 'Peruuta',
    exit: 'Poistu',
    cleanFields: 'Tyhjennä kentät',
    search: 'Hae',
    authenticated: 'Vahvistettu',
    authenticationFailed: 'Autentikointi epäonnistui',
    authenticationPending: 'Vahva tunnistaminen',
    paymentCompleted: 'Maksu suoritettu',
    paymentFailed: 'Maksu epäonnistui',
    paymentSuccess: 'Maksujen onnistuminen',
    paymentPending: 'Maksua odotetaan',
    userNotFound: 'Käyttäjää ei löydy',
    userFound: 'Käyttäjä löytynyt',
    authenticationRegisterSuccess: 'Tunnistautuminen onnistui!',
    authenticationRegisterFailed: 'Tunnistautuminen epäonnistui!',
    makeServiceRequest: 'Tee palvelupyyntö',
    seriviceRequested: 'Palvelua pyydettiin',
    cancelPaymentRequest: 'Peru maksupyyntö',
    deleteUser: 'Poista käyttäjä',
    addUser: 'Lisää käyttäjä',
    createUser: 'Luo käyttäjä',
    loginInformation: 'Sisäänkirjautumistiedot',
    selectMultipleLockers: 'Valitse useita lokeroita',
    disableLocker: 'Poista laatikosto käytöstä',
    locker: 'Lokero',
    reservation: 'Varaukset',
    delivery: 'Toimitus',
    deleteReservation: 'Poista varaus',
    reserve: 'Varaa',
    newBookingPharmacyPickup: 'Uusi varaus apteekkinoutoa varten',
    exportJSON: 'Vie.json',
    importJSON: 'Tuo.json',
    downloadCSV: 'Lataa.csv',
    downloadTempCSV: 'Lataa lämpötilat.csv',
    downloadPDF: 'Lataa.pdf',
    createReservation: 'Luo varaus',
    webToolAccess: 'Pääsy webtooliin',
    boxAccess: 'Pääsy laatikkoon',
    ok: 'Ok',
    happy: 'Tyytyväinen',
    angry: 'Tyytymätön',
    yes: 'Kyllä',
    no: 'Ei',
    and: 'ja',
    assignReservation: 'Siirrä vanhentunut varaus lokeroon',
    redirectToPickupBox: 'Ohjaa noutoboksiin',
    quickReservation: 'Pikavaraus',
    quickReservationLockers: 'Manuaalinen pikavaraus',
    isDrug: 'Sisältää reseptilääkkeitä',
    drug: 'Reseptilääke',
    ssn: 'HENKILÖTUNNUS',
    consentGiven: 'ANNETTU SUOSTUMUS',
    resendSMS: 'Lähetä tekstiviesti uudelleen',
    showFullHistory: 'Näytä koko historia',
    hideFullHistory: 'Piilota koko historia',
    authentication: 'Todentaminen',
    authenticateClient: 'Tunnista asiakas',
    paymentDetails: 'Maksutiedot',
    onBehalf: 'Toisen puolesta asiointi',
    reasonForTransaction: 'Maksun syy',
    sendPaymentNotification: 'Lähetä maksuilmoitus',
    paymentNotificationWasSuccessfullyResend:
      'Maksuilmoitus lähetettiin onnistuneesti uudelleen.',
    /*text*/
    pageNotFound: 'Sivua ei Löydetty',
    wrongFormat: 'Väärä muoto',
    checkURL:
      'Määritettyä tiedostoa ei löytynyt tältä sivustolta. Tarkista URL-osoite virheiden varalta ja yritä uudelleen.',
    forgotPasswordTex:
      'Siirrä sähköpostiosoitteesi, niin lähetämme sinulle sähköpostin. Sähköpostissa olevaa linkkiä seuraamalla pääset syöttämään uuden salasanasi.',
    passwordCharacters: 'Salasanan tulee sisältää vähintään 8 merkkiä.',
    youWantDelete: 'Haluatko varmasti poistaa',
    thisChangeCannotBeUndone: 'Tätä muutosta ei voi kumota.',
    descriptionDestroy:
      'Tuhottu - Siirrä varaus historiaan. Tämä tehdään varauksen tuhoamisen yhteydessä.',
    descriptionDelete:
      'Poistaa - Siirrä varaus takaisin Vanhentuneet varaukset -välilehdelle, eli peru varaus varataksesi sen uudelleen.',

    descriptionCancel: 'Peruuta - sulje ikkuna ilman toimenpidettä.',
    youWantDeleteReservation:
      'Haluatko varmasti poistaa tämän varauksen? Tätä muutosta ei voi kumota',
    undone: '? Tätä muutosta ei voi kumota.',
    changePasswordInfo:
      'INFO: Paina salasana mieleesi ja siirrä se käyttäjälle Et voi palauttaa salasanaa myöhemmin. Salasanan tulee sisältää vähintään 8 merkkiä.',
    noPickupBox: 'Ei noutolaatikostoa',
    addDescription: 'Lisää kuvaus toimitushistoriaan',
    addComment: 'Lisää kommentti tilaukseen',
    infoReservation: 'Tietoa varauksesta',
    addAlarmComment: 'Lisää hälytyskommentti',
    deviationsInTimeFrame: 'Poikkeamat aikarajoissa',
    addUserMainInfo:
      'INFO: Paina salasana mieleesi ja siirrä se käyttäjälle. Et voi palauttaa salasanaa myöhemmin.',
    addUserInfo: 'Salasanan tulee sisältää vähintään 8 merkkiä.',
    lockersSelected: 'Lokerot on valittu',
    messageTemperatureHigh: 'Lokeron lämpötila on liian korkea: ',
    messageTemperatureLow: 'Lokeron lämpötila on liian alhainen: ',
    noData: 'Ei dataa',
    errorDate: 'Virhepäivämäärä',
    noReservations: 'Ei varauksia',
    noOrderReservedYet: 'Tilausta ei ole vielä varattu',
    enableLocker:
      'Tilausta ei ole vielä varattu. Ota laatikosto käyttöön tilausten varaamiseksi.',
    noAlarms: 'Ei hälytyksiä valitun ajanjakson aikana',
    assignDeliveryFromDesk: 'Määritä toimitus noudettavaksi apteekin tiskiltä',
    assignDeliveryFromLocker: 'Määritä toimitus noudettavaksi noutolaatikosta',
    assignToLocker:
      'Jos haluat, että tämä toimitus noudetaan noutolaatikosta, paina "Ohjaa noutoboksiin"-painiketta. Valitse vapaa lokero, ja valitse noutolaatikko-sivulta "Siirrä vanhentunut varaus lokeroon".',
    pickedUpRevervation: 'Onko varaus noudettu?',
    chooseDelivery: 'Valitse toimitus luettelosta',
    chooseThisDelivery: 'Valitse tämä toimitus',
    pickupboxIsInactive:
      'Pickupbox ei ole aktiivinen. Ominaisuus ei ole käytettävissä',
    youReservationPinCode: 'Varauksenne PIN-koodi',
    pinCode: 'PIN-koodi',
    wantResendSMS: 'Haluatko varmasti lähettää tekstiviestin uudelleen?',
    enoughPermissions: 'Sinulla ei ole tarpeeksi oikeuksia',
    noContent: 'EI SISÄLTÖÄ.',
    locationNotAllowed: 'SIJAINTI EI SALLITTU',
    noSpaceInPickupBoxes: 'Noutolaatikossa ei ole tilaa',
    descriptionServiceRequest: 'Palvelupyynnön kuvaus',
    choosePharmacy: 'Valitse apteekki, johon haluat kirjautua',
    changePharmacy: 'Kirjaudu sisään',
    placeholderText: 'Valitse apteekki',
    connectionLost: 'Internet-yhteys katkesi',
    clientInformation: 'Asiakkaan tiedot',
    deliveryMethodUpperCase: 'TOIMITUSTAPA',
    deliveryMethod: 'Toimitustapa',
    issuePaymentUpperCase: 'MAKSAMINEN',
    issuePayment: 'Maksa sovelluksessa',
    deliveryContentMatchesOrder: 'Toimituksen sisältö vastaa tilausta',
    handler: 'Käsittelijä',
    confirmedDy: 'Vahvistanut: ',
    homeDelivery: 'Kotiinkuljetus',
    confirmation: 'Vahvistus',
    confirmationText:
      'Vahvista, että toimituksen sisältö vastaa tilausta. Tätä toimenpidettä ei voi peruuttaa.',
    successfully: 'Onnistuneesti',
    workingHours: 'AUKIOLOAIKA',
    services: 'PALVELUT',
    showToCustomers: 'Näkyvyys asiakkaille',
    showToCustomersUpperCase: 'NÄKYVYYS ASIAKKAILLE',
    sendNewCallbackRequestEmailNotification:
      'Lähetä sähköposti-ilmoitus takaisinsoittopyynnöstä',
    closed: 'Suljettu',
    closedUpperCase: 'SULJETTU',
    startAt: 'ALOITUS KLO',
    endAt: 'LOPPUU',
    editWorkingHours: 'Muokkaa aukioloaikoja',
    dayOfTheWeek: 'VIIKONPÄIVÄ',
    chooseWorkingHours: 'Määrittele aukioloajat',
    orderPayment: 'Tehdyt toimenpiteet',
    prescription: 'Kirjautuminen reseptikeskukseen',
    other: 'Muu asiointi',
    medicationDelivery: 'Lääketoimitus',
    prescriptionDrugs: 'Reseptilääkkeet',
    selfCare: 'Itsehoito',
    medicalAdvice: 'Lääkeneuvonta',
    medicalAdviceUpperCase: 'LÄÄKENEUVONTA',
    medicalAdviceGiven: 'Lääkeneuvonta annettu',
    orderNumber: 'Ostokerran numero',
    orderNumberUpperCase: 'OSTOKERRAN NUMER0',
    reference: 'Viite',
    totalSum: 'Kokonaissumma (EUR)',
    discardAuthentication: 'Peruutta tunnistautumisprosessi',
    discardAuthenticationText:
      'Oletko varma että haluat poistua “Soittajan vahva tunnistaminen”-sivulta? Lomakkeen tiedot menetetään.',
    markAsDone: 'Merkitse valmiiksi',
    markAsMissed: 'Merkitse ohitetuksi',
    backToCallerAuthentication: 'Siirry asiakkaan tunnistamiseen',
    totalCustomersVotes: 'Palautteet yhteensä: ',
    templates: 'Mallit',
    allActive: 'Kaikki aktiiviset',
    active: 'Aktiivinen',
    translation: 'Käännös',
    numberOfUsers: 'Käyttäjämäärä',
    position: 'Asema',
    title: 'Titteli',
    titleUpperCase: 'NIMIKE',
    subtitleUpperCase: 'ALATYYPPI',
    advertPhoto: 'Mainoskuva',
    photoUpperCase: 'KUVA',
    screenTimeoutUpperCase: 'NÄYTÖN AIKAKATKAISU',
    colorUpperCase: 'VÄRI',
    logoPhoto: 'Logo Kuva',
    chooseScale: 'Valitse mittakaava',
    hintTextUpperCase: 'VINKKITEKSTI',
    optionsUpperCase: 'VAIHTOEHDOT',
    reportUpperCase: 'RAPORTTI',
    gradeUpperCase: 'LUOKITUS',
    multipleOptions: 'Useita vaihtoehtoja',
    multipleOptionsUpperCase: 'USEITA VAIHTOEHTOJA',
    timeout: 'Aikakatkaisu',
    addNewOption:
      'Et voi luoda uutta vaihtoehtoa, koska käytössä on jo kolme aktiivista vaihtoehtoa',
    theSamePositions: 'Näillä vaihtoehdoilla on samat kannat',
    positionIsTaken: 'Tämä asema on jo varattu, valitse toinen',
    showOnlyWithContact: 'Näytä vain yhteyshenkilön kanssa',
    showOnlyWithReport: 'Näytä vain raportin kanssa',
    showOnlyWithOption: 'Näytä vain vaihtoehdon kanssa',
    showOnlyWithGrade: 'Näytä vain arvosanan kanssa',
    type: 'Tyyppi',
    face: 'Kasvot',
    higher: 'Korkeampi',
    lower: 'Alempi',
    same: 'Sama',
    welcomeToApteekkiPuhelu: 'Tervetuloa ApteekkiPuhelu',
    openApp:
      'Jos sinulla on sovellus asennettuna, voit avata sen tästä. Jos ei,',
    clickHere: 'klikkaa tästä',
    downloadApp: ' ladataksesi sovelluksen.',
    platformUnsupported: 'Alustasi ei ole tuettu',
    chooseColor: 'Valitse väri',
    infoChangePassword:
      'INFO: Muista salasana ja siirrä se käyttäjälle. Et voi palauttaa salasanaa myöhemmin. Salasanan tulee sisältää vähintään 8 merkkiä.',
    green: 'Uusi soittopyyntö',
    orange: 'Ohitettu soittopyyntö',
    rememberAboutMedicalAdvice:
      'Muistathan merkitä, annettiinko lääkeneuvontaa',
    Alarm: 'Hälytys',
    'Box Temperature': 'Laatikoston lämpötila',
    'Fridge 0-1 Temperature': 'Jääkaappi 0-1 Lämpötila',
    'Fridge 1-1 Temperature': 'Jääkaappi 1-1 Lämpötila',
    'Fridge 2-1 Temperature': 'Jääkaappi 2-1 Lämpötila',
    Max: 'Max',
    Min: 'Min',
    'Fridge Max': 'Jääkaappi Max',
    'Fridge Min': 'Jääkaappi Min',
    /*backTo*/
    backToUsers: 'Takaisin käyttäjiin',
    backToInfoPickupBox: 'Takaisin noutolaatikkon tietoihin',
    backToLogIn: 'Takaisin sisäänkirjautumiseen',
    backToAuthenticationHistory: 'Takaisin tunnistautumishistoriaan',
    backToOrdersHistory: 'Takaisin tilaushistoriaan',
    backToCustomersFeedback: 'Takaisin asiakaspalautteeseen',
    consentGivenText:
      'Asiakas antoi suostumuksen Kela-korvaus tietojen hakuun sekä siihen, että apteekki hakee reseptitietoni Reseptikeskuksesta.',
    /*lockers*/
    all: 'Kaikki',
    allUpperCase: 'KAIKKI',
    free: 'Vapaa',
    reserved: 'Varattu',
    reservedUpperCase: 'VARATTU',
    reservedTable: ' Varattu',
    reservedText: 'varattu',
    deleted: 'Poistettu',
    deletedTable: ' Poistettu',
    expired: 'Vanhentunut',
    expiredTable: ' Vanhentunut',
    notInUse: 'Ei käytössä',
    fridge: 'Jääkaappi',
    delivered: 'Toimitettu paikan päällä',
    deliveredUpperCase: 'TOIMITETTU',
    deliveredTable: ' Toimitettu',
    removed: 'Poistettu',
    removedTable: ' Poistettu',
    disabled: 'Poistettu käytöstä',
    cancelled: 'Peruttu',
    cancelledTable: ' Peruttu',
    cancelledTableUpperCase: 'PERUTTU',
    doneUpperCase: 'TEHTY',
    pickedUp: 'Noudettu',
    pickup: 'Noudettu',
    pickedUpUpperCase: 'NOUDETTU',
    destroyedUpUpperCase: 'TUHOTTU',
    pickedUpTable: ' Noudettu',
    destroyedTable: ' Tuhottu',
    destroyed: 'Tuhottu',
    small: 'Pieni',
    medium: 'Keskikokoinen',
    large: 'Suuri',
    noSsn: 'Ei henkilötunnusta',
    givenOutUserName: 'Annettu käyttäjälle',
    /*capacity Pick-up Box*/
    week: 'Viikko',
    weekUpperCase: 'VIIKKO',
    month: 'Kuukausi',
    boxTypeUpperCase: 'LAATIKON TYYPPI',
    capacityUpperCase: 'KAPASITEETTI %',
    averagePickupTimeUpperCase: 'KESKIMÄÄRÄINEN NUOTOAIKA',
    /*edit Pick-up Box*/
    temperatureIntervals: 'Lämpötilan vaihteluvälit',
    /*temperatureLog*/
    sensorUpperCase: 'LOCKER-ID/SENSORI',
    startTimeUpperCase: 'ALOITUSAIKA',
    startTime: 'Aloitusaika',
    endTimeUpperCase: 'LOPPUAIKA',
    endTime: 'Loppu aika',
    durationUpperCase: 'KESTO',
    minUpperCase: '°C MIN',
    maxUpperCase: '°C MAX',
    averageUpperCase: 'KESKIARVO °C',
    alarmCommentUpperCase: 'HÄLYTYSKOMMENTTI',
    date: 'Päivämäärä',
    temperature: 'Lämpötila',
    temperatureMax: 'Max Lämpötila',
    temperatureMin: 'Min Lämpötila',
    timestempTemperatures: 'Tarkista lämpötilat',
    lastCheckedBy: 'Viimeksi tarkistanut',
    checkTime: 'Tarkistusaika',
    /*deliveryStatistics*/
    monthUpperCase: 'KUUKAUSI',
    yearUpperCase: 'VUOSI',
    deliveriesUpperCase: '# TOIMITUKSET',
    pickupsUpperCase: '# NOUDETUT',
    expiredUpperCase: '# ERÄÄNTYNEET',
    destroyedUpperCase: '# TUHOTUT',
    avgPickupTimeUpperCase: '# KESKIMÄÄRÄINEN NOUTOAIKA (T)',
    totalUsageUpperCase: '# KÄYTTÖ YHTEENSÄ (%)',
    cancelledUpperCase: '# PERUUTETTU',
    averagePickupTime: 'KESKIMÄÄRÄINEN Noutoaika',
    hoursUpperCase: 'Tunnit',
    avgPickupTime: 'KESKIMÄÄRÄINEN NUOTOAIKA',
    /*page*/
    pharmacy: 'Apteekki',
    profile: 'Minun tietoni',
    callerAuthentication: 'Soittajan vahva tunnistaminen',
    authenticationHistory: 'Tunnistautumishistoria',
    ordersHistory: 'Tilaushistoria',
    pickupBox: 'Noutolaatikko',
    pickupBoxes: 'Noutolaatikot',
    pharmacyPickup: 'Nouto apteekista',
    users: 'Käyttäjät',
    user: 'Käyttäjä',
    mainUser: 'Pääkäyttäjä',
    callbackRequests: 'Soittopyynnöt',
    customersFeedback: 'Asiakaspalaute',
    userGuide: 'Käyttöohjeet',
    pharmacyInformation: 'Apteekin Tiedot',
    lockers: 'Noutolokerikot',
    order: 'Tilaus',
    temperatureChecking: 'Lämpötilan tarkistus',
    infoPickupBox: 'Noutolaatikoston Tiedot',
    boxInfo: 'Laatikon Tiedot',
    editPickupBox: 'Muokkaa Noutolaatikostoa',
    reservations: 'Varaukset',
    apiReservations: 'Vanhentuneet varaukset',
    deliveryHistory: 'Toimitushistoria',
    temperatureLog: 'Lämpötilaloki',
    alarms: 'Hälytykset',
    capacity: 'Kapasiteetti',
    deliveryStatistics: 'Toimitustilastot',
    reserveLocker: 'Varaa lokero',
    settings: 'Asiakaspalautteiden asetukset',
    general: 'Yleistä',
    options: 'Vaihtoehdot',
    report: 'Raportti',
    grade: 'Arvosana',
    contact: 'Ota yhteyttä',
    contactUpperCase: 'YHTEYSTIEDOT',
    thank: 'Kiitos',
    statistics: 'Tilastot',
    customers: 'Asiakkaat'
  },
  sv: {
    account: 'Konto',
    login: 'Logga in',
    logout: 'Logga ut',
    loginWithMicrosoft: 'Logga in med leverantör',
    pleaseWait: 'Vänligen vänta',
    /*errors*/
    error: 'Fel',
    not_authorized: 'Användaren är inte auktoriserad',
    Unauthorized: 'Användaren är inte auktoriserad',
    'Unknown error': 'Okänt fel',
    'Verification link was not found': 'Verifieringslänken hittades inte',
    user_is_not_admin: 'Användaren måste vara administratör',
    user_is_not_found: 'Användaren har inte hittats',
    error_login_user_not_found: 'Användaren hittades inte',
    user_not_found: 'Användaren hittas ej',
    user_push_notification_disabled:
      'Användaren har inte aktiverat aviseringar',
    user_disabled_app_notifications:
      'Avaktiverade appaviseringar för användare',
    pharmacy_not_found: 'Apotek hittades inte',
    pickupbox_not_found: 'Pickupbox hittades inte',
    template_not_found: 'Mall hittades inte',
    alarm_not_found: 'Larm hittades inte',
    delivery_not_found: 'Leverans hittades inte',
    link_not_found: 'Verifieringslänk hittades inte',
    not_enough_permissions: 'Inte tillräckligt med behörigheter för att utföra',
    error_session_expired: 'Sessionen har löpt ut',
    no_content: 'Inget innehåll',
    forbidden: 'Åtkomst till apotek nekad',
    strong_auth_failed: 'Stark autentisering med BankID misslyckades',

    user_with_email_does_not_exist:
      'Användare med denna e-postadress finns inte',
    error_login_too_many_attempts:
      'För många inloggningsförsök, försök igen senare',
    error_login_user_not_found_or_wrong_password:
      'Användaren hittades inte eller fel lösenord',
    firebase_wrong_password:
      'Firebase: The password is invalid or the user does not have a password. (auth/wrong-password).',

    newPassword_missing: 'Lösenordsändring misslyckades',
    repeatNewPassword_missing: 'Lösenordsändring misslyckades',
    oldPassword_missing: 'Gammalt lösenord saknas',
    newPassword_not_matches_repeatedNewPassword:
      'Det nya lösenordet matchar inte det upprepade nya lösenordet',
    oldPassword_matches_newPassword:
      'Det gamla lösenordet matchar det nya lösenordet',

    firstName_empty: 'Förnamnet är tomt',
    lastName_empty: 'Efternamnet är tomt',
    email_empty: 'E-postadressen är tom',
    invalid_role: 'Fel roll',
    this_role_is_greater_than_yours: 'Den här rollen är större än din',
    email_missing: 'E-post saknas',
    firstName_missing: 'Förnamn saknas',
    lastName_missing: 'Efternamn saknas',
    appLanguage_missing: 'Appens språk saknas.',
    role_missing: 'Roll saknas',
    pharmacyIds_must_match: 'Apotekets ID måste matcha',

    pharmacyId_missing: 'Apoteks-ID saknas',
    webToolAccess_missing: 'Ingen tillgång till webbverktyget',
    boxAccess_missing: 'Ingen åtkomst till boxen',
    roleName_missing: 'Rollnamn saknas',
    role_not_exist: 'Rollen existerar inte',

    name_missing: 'Namn saknas',
    businessId_missing: 'Företags-ID saknas',
    not_valid_strong_auth_value: 'Ogiltigt värde strong auth',
    businessId_already_exists: 'Företagets ID finns redan',
    pharmacy_templateId_missing: 'Apoteksmallens ID saknas',
    pharmacy_id_missing: 'Apotekets ID saknas',
    pharmacy_country_missing: 'Apotekets land saknas',
    pharmacy_language_missing: 'Apotekets språk saknas',
    pharmacy_city_missing: 'Apotekets stad saknas',
    pharmacy_outdoor_missing: 'Apotekets utomhusområde saknas',
    pharmacy_uiLanguages_missing: 'Apotekets UI-språk saknas',
    pharmacy_zipCode_missing: 'Postnummer för apotek saknas',
    pharmacy_cardboardType_missing: 'Kartongtyp för apotek saknas',
    pharmacy_already_exists: 'Apoteket finns redan',
    pharmacy_does_not_exist: 'Inget apotek finns',
    not_valid_strong_auth_value_pharmacy_update:
      'Ogiltig apoteksuppdatering med ett starkt auktoriseringsvärde',
    unsupported_file_extension: 'Misslyckades med att ladda upp logotypen',
    pharmacy_payment_data_missing:
      'Misslyckades med att skapa betalning, apoteket saknar betalningsdata',
    pharmacy_is_not_active: 'Apoteket är avstängt',

    pickupbox_templateId_missing: 'ID för mall för pickupbox saknas',
    pickupbox_name_missing: 'Pickupboxens namn saknas',
    pickupbox_id_missing: 'Pickupbox ID saknas',
    pickupbox_country_missing: 'Pickupboxens land saknas',
    pickupbox_language_missing: 'Pickupboxens språk saknas',
    pickupbox_city_missing: 'Pickupbox stad saknas',
    pickupbox_outdoor_missing: 'Pickupbox utomhus saknas',
    pickupbox_uiLanguages_missing: 'Pickupbox UI språk saknas',
    pickupbox_zipCode_missing: 'Postnummer för pickupbox saknas',
    pickupbox_cardboardType_missing: 'Kartongtyp för pickupbox saknas',
    pickupbox_already_exists: 'Pickupbox finns redan',
    pickupbox__does_not_exist: 'Ingen pickupbox finns',

    callback_not_found: 'Begäran om återuppringning hittades inte',
    record_not_found: 'Skivan hittades inte',
    user_fcm_token_missing: 'FCM-token för användare saknas',
    payment_already_issued: 'Betalning redan utförd',
    order_not_found: 'Ordern hittades inte',
    deliveryMethod_validation_failed:
      'Leveransmetod måste vara en av följande: pharmacy, pickupbox, homeDelivery',

    missing_fields: 'Saknade fält',
    reserved_locker_cannot_be_inactive:
      'Ett reserverat skåp kan inte vara inaktivt',
    locker_do_not_exist: 'Skåpet existerar inte',

    user_is_logged_in_to_box: 'Användaren är inloggad på brevlådan',
    reservation_delivered: 'Bokning levererad',
    pickupbox_disabled: 'Pickupboxen är avstängd',
    user_is_logged_in_to_box_not_create_reservation:
      'Användaren gick in i fältet för att inte skapa en reservation',

    pickupbox_disabled_invalid_locker: 'Pickupboxen inaktiverad ogiltigt skåp',
    user_is_logged_in_to_box_invalid_locker:
      'Användaren är inloggad på en ogiltig brevlåda',
    invalid_locker: 'Ogiltigt skåp',
    locker_already_reserved: 'Skåpet är redan reserverat',
    wrong_delivery_status: 'Felaktig leveransstatus',
    permissions_empty: 'Behörigheterna är tomma',
    bad_strong_auth_provider:
      'Dålig serverkonfiguration, ingen leverantör för stark autentisering angiven',
    authentication_link_has_expired: 'Autentiseringslänken har gått ut',
    cannot_cancel_payment_with_current_status:
      'Kan inte avbryta betalning för order med aktuell status',
    minimal_payment_amount: 'Minsta betalningsbelopp är 0,01',
    fieldIsIncorrectly: 'Tyvärr är inte alla fält korrekt ifyllda',

    validation: {
      required: 'Detta fält är obligatoriskt.',
      email: 'Vänligen ange en giltig e-postadress.',
      phoneNumber: 'Format för telefonnummer: +xxxxxxxxxxxx',
      length: 'Detta fält ska vara 6 tecken långt.',
      length8: 'Detta fält ska vara 8 tecken långt.',
      lengthLong: 'Lösenordet måste vara större än eller lika med 8 tecken.',
      confirm: 'Lösenord måste stämma överens',
      forDeliveryId: 'Fältet kan inte starta från 0'
    },
    /*info*/
    address: 'Adress',
    languages: 'Språk',
    versionSW: 'Mjukvaruversion',
    temperatureInterval: 'Temperaturintervall',
    temperatureAlarmInterval: 'Temperaturlarmintervall',
    email: 'E-post',
    emailUpperCase: 'E-POST',
    alarmEmail: 'E-post för larm',
    password: 'Lösenord',
    currentPassword: 'Nuvarande lösenord',
    newPassword: 'Nytt lösenord',
    confirmPassword: 'Bekräfta lösenord',
    phoneNumber: 'Telefonnummer',
    show: 'visa',
    showUpperCase: 'Visa',
    hide: 'gömma',
    mobilePhone: 'Mobiltelefon',
    mobilePhoneUpperCase: 'MOBILTELEFON',
    phoneUpperCase: 'TELEFON',
    alarmPhoneNumber: 'Telefonnummer för larm',
    customerPhoneNumber: 'Apotekets telefonnummer',
    firstName: 'Förnamn',
    firstNameUpperCase: 'FÖRNAMN',
    lastName: 'Efternamn',
    lastNameUpperCase: 'EFTERNAMN',
    fullName: 'Fullständigt namn',
    name: 'Fullständigt namn',
    nameUpperCase: 'NAMN',
    pharmacyNameUpperCase: 'APOTEKETS NAMN',
    role: 'Användarroll',
    roleUpperCase: 'ANVÄNDARROLL',
    keyUpperCase: 'NYCKEL',
    key: 'Nyckel',
    id: 'ID',
    reservationKeepTime: 'Bokningens varaktighet',
    pickupBoxName: 'Pickupboxens namn',
    pickupBoxId: 'Pickupboxens ID',
    city: 'Stad',
    country: 'Land',
    zipCode: 'Postnummer',
    UILanguages: 'Användargränssnitsspråk',
    defaultLanguages: 'Standardspråk',
    cardboardType: 'Boxs korttyp',
    userIdUpperCase: 'ANVÄNDAR ID',
    deliveryStatusUpperCase: 'LEVERANSSTATUS',
    deliveryStatus: 'Leveransstatus',
    kolliIDUpperCase: 'DELIVERY-ID',
    lockerID: 'Box-ID',
    lockerIDUpperCase: 'BOX-ID',
    orderExpired: 'Beställning har gått ut',
    orderExpiredUpperCase: 'BESTÄLLNING GICK UT',
    causeOfAlarmUpperCase: 'ORSAK TILL LARM',
    orderID: 'Beställnings-ID',
    lang: 'Språk',
    desktopLang: 'Skrivbordsspråk',
    pharmacyInfo: 'Apotekinfo',
    userBoxPin: 'PIN-kod',
    farmacyEntryPin: 'Användarnamn',
    pharmacyReservations: 'Apoteksbokningar',
    lastUpdate: 'Senaste uppdateringen',
    kolliID: 'Leverans-ID',
    timeReserved: 'Reservationstid',
    timeDelivery: 'Tidsbestämd Leverans',
    timePickup: 'Tid för Upphämtning',
    timeDelete: 'Tid för Borttagning',
    timeExpired: 'Tid Passerad',
    deliveryMadeBy: 'Levererad av',
    description: 'Beskrivning',
    descriptionUpperCase: 'BESKRIVNING',
    comment: 'Kommentar',
    commentUpperCase: 'KOMMENTAR',
    internalNote: 'Intern kommentar',
    customerReceiptMessage: 'Meddelande på kundens kvitto',
    resendSMSCounter: 'Antal skickade SMS:',
    resendSMSTimestamps: 'Tidpunkt för senast skickat SMS:',
    reservationMadeByUpperCase: 'BOKAD AV',
    timestampUpperCase: 'TIDSSTÄMPEL',
    timestamp: 'Identifieringstid',
    socialSecurityNumber: 'Personnummer',
    homeAddress: 'Hemadress',
    handlerUpperCase: 'HANDLÄGGARE',
    deliveryUpperCase: 'LEVERANS',
    paymentUpperCase: 'BETALNINGSSTATUS',
    totalUpperCase: 'SUMMA (EUR)',
    contentUpperCase: 'LEVERANSBEKRÄFTELSE',
    issuedAtUpperCase: 'IDENTIFIKATIONSTID',
    eventTimeUpperCase: 'HÄNDELSETID',
    confirm: 'Bekräfta',
    deliveredByUpperCase: 'LEVERERAD AV',
    assignedTimeByUpperCase: 'TILLDELAD VID',
    assignedTime: 'Tilldelad vid',
    assignedUserName: 'Tilldelad',
    fulfillment_order_id: 'Uppfyllningsorder-ID',
    order_id: 'Order-ID',
    reservedUserName: 'Bokad av',
    pickupboxPageAssignDelivery: 'PICKUPBOXSIDA TILLDELA LEVERANS',
    lastLoginUpperCase: 'SENASTE INLOGGNING',
    userUpperCase: 'ANVÄNDARE',
    customerUpperCase: 'KUND',
    typeUpperCase: 'TYP',
    assignUpperCase: 'TILLDELA',
    clientUpperCase: 'KUND',
    preferredHoursUpperCase: 'ÖNSKADE TIMMAR',
    requestedAtUpperCase: 'BEGÄRD AT',
    alarmOk: 'Boxstatus ok',
    alarmDoorOpen: 'Luckan stängs inte',
    alarmDoorClosed: 'Luckan öppnas inte',
    alarmTempHigh: 'Temperaturen i skåpet är hög',
    alarmTempLow: 'Temperaturen i skåpet är låg',
    alarmOther: 'Inga uppgifter om skåpet finns tillgängliga',
    alarmMissing: 'Uppgifter om skåpet saknas',
    pending: 'VÄNTAR',
    failed: 'MISSLYCKAD',
    completed: 'UTFÖRD',
    january: 'Januari',
    february: 'Februari',
    march: 'Mars',
    april: 'April',
    may: 'Maj',
    june: 'Juni',
    july: 'Juli',
    august: 'Augusti',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'December',
    monday: 'Måndag',
    tuesday: 'Tisdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag',
    saturday: 'Lördag',
    sunday: 'Söndag',
    roomTemp: 'Rumstemperatur',
    includesDrug: 'Förskrivna läkemedel',
    includesDrugUpperCase: 'FÖRSKRIVNA LÄKEMEDEL',
    log: 'Logga',
    in: 'in',
    out: 'ut',
    min: 'minut',
    minute: ' minut',
    minutes: ' minuter',
    hour: 'timme',
    hours: 'timmar',
    day: ' dag',
    days: ' dagar',
    daysDelivery: 'Dagar',
    basicLocker: 'Vanligt fack:',
    fridgeLocker: 'Kylfack:',
    deliveryExpirationTime: 'Utgångstid för leverans',
    reminderMessageTime: 'Tid för påminnelsemeddelande',
    reservationExpirationTime: 'Reservationens utgångstid',
    fullHistory: 'Fullständig historik',
    /*actions*/
    forgotPassword: 'Glömt lösenordet?',
    changePassword: 'Ändra lösenord',
    sendResetLink: 'Skicka återställningslänk för lösenord',
    newOrder: 'Ny beställning',
    viewOrder: 'Visa order',
    sentEmail: 'E-postmeddelande om lösenordsåterställning har skickats.',
    edit: 'Redigera',
    add: 'Lägg till',
    addOption: 'Lägg till alternativ',
    doNotSaveOption: 'Spara inte detta alternativ',
    chooseLanguage: 'Välj språk för SMS',
    serviceLanguagesAvailable: 'Tillgängliga språk för service',
    save: 'Bekräfta',
    send: 'Skicka',
    saveForm: 'Spara formulär',
    saveFormAndSendPayment: 'Spara formulär & skicka betalning',
    finish: 'Avsluta',
    discard: 'Kassera',
    delete: 'Radera',
    cancel: 'Avbryt',
    exit: 'Avsluta',
    cleanFields: 'Töm fält',
    search: 'Sök',
    authenticated: 'Autentiserad',
    authenticationFailed: 'Autentisering misslyckades',
    authenticationPending: 'Autentisering',
    paymentCompleted: 'Betalning slutförd',
    paymentFailed: 'Betalning misslyckad',
    paymentSuccess: 'Betalningsframgång',
    paymentPending: 'Betalning väntar',
    userNotFound: 'Användaren hittades inte',
    userFound: 'Användaren hittades',
    authenticationRegisterSuccess: 'Autentiseringen lyckades!',
    authenticationRegisterFailed: 'Autentiseringen misslyckades!',
    makeServiceRequest: 'Gör en servicebegäran',
    seriviceRequested: 'Service begärd',
    cancelPaymentRequest: 'Annulera betalning',
    deleteUser: 'Ta bort användare',
    addUser: 'Lägg till användare',
    createUser: 'Skapa användare',
    loginInformation: 'Inloggningsinformation',
    selectMultipleLockers: 'Välj flera luckor',
    disableLocker: 'Inaktivera lucka',
    locker: 'Lucka',
    reservation: 'Bokning',
    delivery: 'Leverans',
    deleteReservation: 'Radera bokning',
    reserve: 'Boka',
    newBookingPharmacyPickup: 'Ny bokning för avhämtning av apotek',
    exportJSON: 'Exportera.json',
    importJSON: 'Importera.json',
    downloadCSV: 'Ladda ner.csv',
    downloadTempCSV: 'Ladda ner temperaturer.csv',
    downloadPDF: 'Ladda.pdf',
    createReservation: 'Gör en bokning',
    webToolAccess: 'Tillgång till Webtool',
    boxAccess: 'Tillgång till box',
    ok: 'Ok',
    happy: 'Lycklig',
    angry: 'Arg',
    yes: 'Ja',
    no: 'Nej',
    and: 'och',
    assignReservation: 'Flytta utgådd reservation till box',
    redirectToPickupBox: 'Till Pickupbox',
    quickReservation: 'Snabbokning',
    quickReservationLockers: 'Manuell snabbokning',
    isDrug: 'Innehåller förskrivet',
    drug: 'Läkemedel',
    ssn: 'PERS.NR',
    consentGiven: 'SAMTYCKE GIVET',
    resendSMS: 'Skicka SMS på nytt',
    showFullHistory: 'Visa hela historiken',
    hideFullHistory: 'Dölj hela historiken',
    authentication: 'Autentisering',
    authenticateClient: 'Autentisera klient',
    paymentDetails: 'Betalningsinformation',
    onBehalf: 'På uppdrag av?',
    reasonForTransaction: 'Orsak till transaktionen',
    sendPaymentNotification: 'Skicka betalningsbegäran',
    paymentNotificationWasSuccessfullyResend:
      'Betalningsaviseringen skickades framgångsrikt igen.',
    /*text*/
    pageNotFound: 'Sidan hittas ej',
    wrongFormat: 'Felaktigt format',
    checkURL:
      'Den angivna filen hittades inte på denna webbplats. Kontrollera webbadressen och försök igen.',
    forgotPasswordTex:
      'Vänligen ange din e-postadress så skickar vi ett e-postmeddelande. Genom att följa länken i mejlet kommer du att kunna ange ditt nya lösenord.',
    passwordCharacters: 'Lösenordet bör innehålla minst 8 tecken.',
    youWantDelete: 'Är du säker att du vill radera?',
    thisChangeCannotBeUndone: 'Denna ändring kan inte ångras.',
    descriptionDestroy:
      'Förstör - Flytta reservationen till historik, detta är att likställa med ”Destruera” i API. Dvs skall användas när ni gör destruering.',
    descriptionDelete:
      'Radera - Flytta tillbaka reservationen till fliken Utgådda reservationer” dvs avbryt reservationen till box för att reservera ny lucka.',
    descriptionCancel: 'Avbryt - Stäng fönster utan åtgärd',

    youWantDeleteReservation:
      'Är du säker på att du vill ta bort denna reservation? Denna ändring kan inte ångras.',
    undone: '? Denna ändring kan inte ångras.',
    changePasswordInfo:
      'INFO: Memorera lösenordet och lämna över det till användaren. Du kan inte återställa lösenordet senare. Lösenordet bör innehålla minst 8 tecken.',
    noPickupBox: 'Ingen pickupbox',
    addDescription: 'Lägg till beskrivning till leveranshistorik',
    addComment: 'Lägg till kommentar till order',
    infoReservation: 'Information om reservationen',
    addAlarmComment: 'Lägg till larmkommentar',
    deviationsInTimeFrame: 'Avvikelser i tidsram',
    addUserMainInfo:
      'INFO: Memorera lösenordet och lämna över det till användaren. Du kan inte återställa lösenordet senare.',
    addUserInfo: 'Lösenordet bör innehålla minst 8 tecken.',
    lockersSelected: 'luckor är valda',
    messageTemperatureHigh: 'Skåpets temperatur är för hög: ',
    messageTemperatureLow: 'Skåpets temperatur är för låg: ',
    noData: 'Ingen data',
    errorDate: 'Feldatum',
    noReservations: 'Inga bokningar',
    noOrderReservedYet: 'Ingen beställning bokad än.',
    enableLocker: 'Ingen beställning bokad än.',
    noAlarms: 'Inga larm under vald tidsperiod',
    assignDeliveryFromDesk: 'Leverans hämtas från disken',
    assignDeliveryFromLocker: 'Leverans hämtas från pickupbox',
    assignToLocker:
      'Om du vill att denna leverans ska hämtas från en pickupbox, tryck på knappen "Till Pickupbox". Klicka på en ledig lucka, och välj “Flytta utgådd reservation till box”.',
    pickedUpRevervation: 'Hämtas bokningen?',
    chooseDelivery: 'Välj leverans från listan',
    chooseThisDelivery: 'Välj denna leverans',
    pickupboxIsInactive: 'Pickupbox är inaktiv. Funktionen är inte tillgänglig',
    youReservationPinCode: 'Din boknings PIN-kod',
    pinCode: 'PIN-kod',
    wantResendSMS: 'Är du säker att du vill skicka SMS på nytt?',
    enoughPermissions: 'Du har inte tillräckliga behörigheter',
    noContent: 'INGET INNEHÅLL.',
    locationNotAllowed: 'PLATS INTE TILLÅTEN',
    noSpaceInPickupBoxes: 'Inget utrymme i hämtningslådor',
    descriptionServiceRequest: 'Beskrivning av tjänstebegäran',
    choosePharmacy: 'Välj apotek som du vill logga in på',
    changePharmacy: 'Välj apotek',
    placeholderText: 'Välj Apotek',
    connectionLost: 'Internetanslutningen bruten',
    clientInformation: 'Klientinformation',
    deliveryMethodUpperCase: 'LEVERANSMETOD',
    deliveryMethod: 'Leveransmetod',
    issuePaymentUpperCase: 'UTFÄRDA BETALNING',
    issuePayment: 'Utfärda betalning',
    deliveryContentMatchesOrder: 'Leveransinnehåll matchar order',
    handler: 'Handläggare',
    confirmedDy: 'Bekräftad av: ',
    homeDelivery: 'Hemleverans',
    confirmation: 'Bekräftelse',
    confirmationText:
      'Vänligen bekräfta att leveransinnehållet matchar beställningen. Denna åtgärd är oåterkallelig.',
    successfully: 'Framgångsrikt',
    workingHours: 'ÖPPETTIDER',
    services: 'TJÄNSTER',
    showToCustomers: 'Visa för kunder',
    showToCustomersUpperCase: 'VISA FÖR KUNDER',
    sendNewCallbackRequestEmailNotification:
      'Skicka e-postmeddelande om begäran om återuppringning',
    closed: 'Stängt',
    closedUpperCase: 'STÄNGT',
    startAt: 'START AT',
    endAt: 'SLUT AT',
    editWorkingHours: 'Redigera öppettider',
    dayOfTheWeek: 'VECKODAG',
    chooseWorkingHours: 'Välj arbetstid',
    orderPayment: 'Vidtagna åtgärder',
    prescription: 'Loggat in på receptcentret',
    other: 'Övriga saker',
    medicationDelivery: 'Beställning av medicin',
    prescriptionDrugs: 'Receptbelagda läkemedel',
    selfCare: 'Självvård',
    medicalAdvice: 'Medicinsk rådgivning',
    medicalAdviceUpperCase: 'MEDICINSK RÅDGIVNING',
    medicalAdviceGiven: 'Medicinsk rådgivning given',
    orderNumber: 'Inköpsordernummer',
    orderNumberUpperCase: 'INKÖPSORDERNUMMER',
    reference: 'Referens',
    totalSum: 'Total summa (EUR)',
    discardAuthentication: 'Avvisa autentisering',
    discardAuthenticationText:
      'Är du säker att du vill lämna sidan ”Autentisering av uppringare”? Uppgifterna i formuläret kommer att gå förlorade.',
    markAsDone: 'Markera som gjord',
    markAsMissed: 'Markera som missad',
    backToCallerAuthentication: 'Till identifiering',
    totalCustomersVotes: 'Totalt antal kundröster: ',
    templates: 'Mallar',
    allActive: 'Alla aktiva',
    active: 'Aktiva',
    translation: 'Översättning',
    numberOfUsers: 'Antal användare',
    position: 'Position',
    title: 'Titel',
    titleUpperCase: 'TITEL',
    subtitleUpperCase: 'UNDERTITEL',
    advertPhoto: 'Annons Foto',
    photoUpperCase: 'FOTO',
    screenTimeoutUpperCase: 'TIMEOUT FÖR SKÄRMEN',
    colorUpperCase: 'FÄRG',
    logoPhoto: 'Logotypfoto',
    chooseScale: 'Välj skala',
    hintTextUpperCase: 'HINT TEXT',
    optionsUpperCase: 'ALTERNATIV',
    reportUpperCase: 'RAPPORT',
    gradeUpperCase: 'BETYG',
    multipleOptions: 'Flera alternativ',
    multipleOptionsUpperCase: 'FLERA ALTERNATIV',
    timeout: 'Tidsgräns',
    addNewOption:
      'Du kan inte skapa ett nytt alternativ eftersom det redan finns tre aktiva alternativ',
    theSamePositions: 'Dessa alternativ har samma positioner',
    positionIsTaken: 'Den här positionen är redan upptagen, välj en annan',
    showOnlyWithContact: 'Visa endast med kontakt',
    showOnlyWithReport: 'Visa endast med rapport',
    showOnlyWithOption: 'Visa endast med alternativ',
    showOnlyWithGrade: 'Visa endast med betyg',
    type: 'Typ av',
    face: 'Ansikte',
    higher: 'Högre',
    lower: 'Lägre',
    same: 'Samma',
    welcomeToApteekkiPuhelu: 'Välkommen till ApteekkiPuhelu',
    openApp: 'Om du har appen installerad kan du öppna den härifrån. Om inte,',
    clickHere: 'klicka här',
    downloadApp: ' för att ladda ner appen.',
    platformUnsupported: 'Din plattform stöds inte',
    chooseColor: 'Välj en färg',
    infoChangePassword:
      'INFO: Memorera lösenordet och lämna över det till användaren. Du kan inte återställa lösenordet senare. Lösenordet bör innehålla minst 8 tecken.',
    green: 'Ny motrigning',
    orange: 'Ignorerad motrigning',
    rememberAboutMedicalAdvice:
      'Kom ihåg att indikera om medicinrådgivning givits',
    Alarm: 'Larm',
    'Box Temperature': 'Box Temperatur',
    'Fridge 0-1 Temperature': 'Kylskåp 0-1 Temperatur',
    'Fridge 1-1 Temperature': 'Kylskåp 1-1 Temperatur',
    'Fridge 2-1 Temperature': 'Kylskåp 2-1 Temperatur',
    Max: 'Max',
    Min: 'Min',
    'Fridge Max': 'Kylskåp Max',
    'Fridge Min': 'Kylskåp Min',
    /*backTo*/
    backToUsers: 'Tillbaka till användare',
    backToInfoPickupBox:
      'Ingen beställning reserverad än. Aktivera pickupbox för att reservera beställningar.',
    backToLogIn: 'Tillbaka till inloggning',
    backToAuthenticationHistory: 'Tillbaka till identifikationshistorik',
    backToOrdersHistory: 'Tillbaka till Orderhistorik',
    backToCustomersFeedback: 'Tillbaka till Feedback från kunder',
    consentGivenText:
      'Kunden ger sitt samtycke till att apoteket hämtar ut och lagrar receptuppgifter från receptcentret och information om Kela-ersättningen som gäller recept.',
    /*lockers*/
    all: 'Allt',
    allUpperCase: 'ALLT',
    free: 'Ledig',
    reserved: 'Reserverad',
    reservedUpperCase: 'RESERVERAD',
    reservedTable: ' Reserverad',
    reservedText: 'reserverad',
    deleted: 'Borttagen',
    deletedTable: ' Borttagen',
    expired: 'Utgått',
    expiredTable: ' Utgått',
    notInUse: 'Ej i bruk',
    fridge: 'Kylskåp',
    delivered: 'Levererad på plats',
    deliveredUpperCase: 'LEVERERAD',
    deliveredTable: ' Levererad',
    removed: 'Borttagen',
    removedTable: ' Borttagen',
    disabled: 'Inaktiverad',
    cancelled: 'Annulerad',
    cancelledTable: ' Annulerad',
    cancelledTableUpperCase: 'ANNULERAD',
    doneUpperCase: 'BETALD',
    pickedUp: 'Hämtad',
    pickup: 'Hämtad',
    pickedUpUpperCase: 'HÄMTAD',
    destroyedUpUpperCase: 'FÖRSTÖRD',
    pickedUpTable: ' Hämtad',
    destroyedTable: ' Förstörd',
    destroyed: 'Förstörd',
    small: 'Liten',
    medium: 'Mellan',
    large: 'Stor',
    noSsn: 'Inget SSN',
    givenOutUserName: 'Utdelad användare',
    /*capacity Pick-up Box*/
    week: 'Vecka',
    weekUpperCase: 'VECKA',
    month: 'Månad',
    boxTypeUpperCase: 'FACKTYP',
    capacityUpperCase: 'KAPACITET %',
    averagePickupTimeUpperCase: 'SNITTHÄMTNINGSTID',
    /*edit Pick-up Box*/
    temperatureIntervals: 'Temperaturintervall',
    /*temperatureLog*/
    sensorUpperCase: 'LUCKANS ID/SENSOR',
    startTimeUpperCase: 'STARTTID',
    startTime: 'Starttid',
    endTimeUpperCase: 'SLUTTID',
    endTime: 'Sluttid',
    durationUpperCase: 'VARAKTIGHET',
    minUpperCase: 'MIN °C',
    maxUpperCase: 'MAX °C',
    averageUpperCase: 'MEDEL °C',
    alarmCommentUpperCase: 'LARMKOMMENTAR',
    date: 'Datum',
    temperature: 'Temperatur',
    temperatureMax: 'Temperatur Max',
    temperatureMin: 'Temperatur Min',
    timestempTemperatures: 'Bekräfta temperatur',
    lastCheckedBy: 'Senast kontrollerad av',
    checkTime: 'Kontrolltid',
    /*deliveryStatistics*/
    monthUpperCase: 'MÅNAD',
    yearUpperCase: 'ÅR',
    deliveriesUpperCase: '# LEVERANSER',
    pickupsUpperCase: '# UPPHÄMTNINGAR',
    expiredUpperCase: '# UTGÅNGNA',
    destroyedUpperCase: '# FÖRSTÖRDA',
    avgPickupTimeUpperCase: '# SNITT UPPHÄMTNINGSTID (T)',
    totalUsageUpperCase: '# TOTAL ANVÄNDNING (%)',
    cancelledUpperCase: '# INSTÄLLDA',
    averagePickupTime: 'SNITT Hämtningstid',
    hoursUpperCase: 'Timmar',
    avgPickupTime: 'SNITTHÄMTNINGSTID',
    /*page*/
    pharmacy: 'Apotek',
    profile: 'Min information',
    callerAuthentication: 'Identifiering av uppringare',
    authenticationHistory: 'Identifikationshistorik',
    ordersHistory: 'Beställningshistorik',
    pickupBox: 'Pickupboxar',
    pickupBoxes: 'PickupApotek',
    pharmacyPickup: 'Upphämtning från apotek',
    users: 'Användare',
    user: 'Användare',
    mainUser: 'Huvudanvändare',
    callbackRequests: 'Begäran om motrigning',
    customersFeedback: 'Feedback från kunder',
    userGuide: 'Användarguide',
    pharmacyInformation: 'Apoteksinformation',
    lockers: 'Luckor',
    order: 'Ordning',
    temperatureChecking: 'Kontroll av temperatur',
    infoPickupBox: 'Pickubox info',
    boxInfo: 'Box info',
    editPickupBox: 'Redigera pickupbox',
    reservations: 'Bokningar',
    apiReservations: 'Utgådda reservationer',
    deliveryHistory: 'Leveranshistorik',
    temperatureLog: 'Temperaturlogg',
    alarms: 'Larm',
    capacity: 'Kapacitet',
    deliveryStatistics: 'Leveransstatistik',
    reserveLocker: 'Boka',
    settings: 'Kundfeedbacks inställningar',
    general: 'Allmänt',
    options: 'Alternativ',
    report: 'Rapportera',
    grade: 'Betyg',
    contact: 'Kontakt',
    contactUpperCase: 'KONTAKT',
    thank: 'Tack',
    statistics: 'Statistik',
    customers: 'Kunder'
  },
  no: {
    account: 'Konto',
    login: 'Logg inn',
    logout: 'Logg ut',
    loginWithMicrosoft: 'Logg inn med leverandør',
    pleaseWait: 'Vennligst vent',
    /*errors*/
    error: 'Feil',
    not_authorized: 'Brukeren er ikke autorisert',
    Unauthorized: 'Brukeren er ikke autorisert',
    'Unknown error': 'Ukjent feil',
    'Verification link was not found': 'Bekreftelseslenken ble ikke funnet',
    user_is_not_admin: 'Brukeren må være admin',
    user_is_not_found: 'Brukeren er ikke funnet',
    error_login_user_not_found: 'Brukeren er ikke funnet',
    user_not_found: 'Brukeren ble ikke funnet',
    user_push_notification_disabled: 'Brukeren har ikke aktivert varslinger',
    user_disabled_app_notifications: 'Brukerdeaktiverte appvarsler',
    pharmacy_not_found: 'Apotek ikke funnet',
    pickupbox_not_found: 'Pickupbox ikke funnet',
    template_not_found: 'Mal ble ikke funnet',
    alarm_not_found: 'Alarm ikke funnet',
    delivery_not_found: 'Levering ikke funnet',
    link_not_found: 'Verifiseringslenke ble ikke funnet',
    not_enough_permissions: 'Ikke nok tillatelser til å utføre',
    error_session_expired: 'Økten er utløpt',
    no_content: 'Ikke noe innhold',
    forbidden: 'Nektet tilgang til apotek',
    strong_auth_failed: 'Sterk autentisering med BankID mislyktes',

    user_with_email_does_not_exist:
      'Bruker med denne e-postadressen eksisterer ikke',
    error_login_too_many_attempts:
      'For mange påloggingsforsøk prøver igjen senere',
    error_login_user_not_found_or_wrong_password:
      'Bruker ikke funnet eller feil passord',

    newPassword_missing: 'Endring av passord mislyktes',
    repeatNewPassword_missing: 'Endring av passord mislyktes',
    oldPassword_missing: 'Det gamle passordet mangler',
    newPassword_not_matches_repeatedNewPassword:
      'Det nye passordet samsvarer ikke med det gjentatte nye passordet',
    oldPassword_matches_newPassword:
      'Det gamle passordet samsvarer med det nye passordet',

    firstName_empty: 'Fornavnet er tomt',
    lastName_empty: 'Etternavnet er tomt',
    email_empty: 'E-postadressen er tom',
    invalid_role: 'Feil rolle',
    this_role_is_greater_than_yours: 'Denne rollen er større enn din',
    email_missing: 'E-postadressen mangler',
    firstName_missing: 'Fornavn mangler',
    lastName_missing: 'Etternavn mangler',
    appLanguage_missing: 'Appens språk mangler',
    role_missing: 'Rolle mangler',
    pharmacyIds_must_match: 'Apotek-ID må stemme overens',

    pharmacyId_missing: 'Apotek-ID mangler',
    webToolAccess_missing: 'Ingen tilgang til nettverktøyet',
    boxAccess_missing: 'Ingen tilgang til boksen',
    roleName_missing: 'Rollenavn mangler',
    role_not_exist: 'Rollen finnes ikke',

    name_missing: 'Navn mangler',
    businessId_missing: 'Virksomhets-ID mangler',
    not_valid_strong_auth_value: 'Ugyldig verdi strong auth',
    businessId_already_exists: 'Virksomhets-ID finnes allerede',
    pharmacy_templateId_missing: 'Apotekmal-ID mangler',
    pharmacy_id_missing: 'Apotek-ID mangler',
    pharmacy_country_missing: 'Apotekets land mangler',
    pharmacy_language_missing: 'Apotekets språk mangler',
    pharmacy_city_missing: 'Apotekets by mangler',
    pharmacy_outdoor_missing: 'Apotek utendørs mangler',
    pharmacy_uiLanguages_missing: 'Apotekets UI-språk mangler',
    pharmacy_zipCode_missing: 'Apotek postnummer mangler',
    pharmacy_cardboardType_missing: 'Apotekets papptype mangler',
    pharmacy_already_exists: 'Apoteket finnes allerede',
    pharmacy_does_not_exist: 'Det finnes ikke noe apotek',
    not_valid_strong_auth_value_pharmacy_update:
      'Ugyldig apotekoppdatering med en sterk autorisasjonsverdi',
    unsupported_file_extension: 'Kunne ikke laste opp logo',
    pharmacy_payment_data_missing:
      'Kunne ikke opprette betaling, apoteket mangler betalingsdata',
    pharmacy_is_not_active: 'Apotek er deaktivert',

    pickupbox_templateId_missing: 'ID for henteboksmal mangler',
    pickupbox_name_missing: 'Pickupbox-navn mangler',
    pickupbox_id_missing: 'ID for henteboks mangler',
    pickupbox_country_missing: 'Pickupbox-land mangler',
    pickupbox_language_missing: 'Henteboksens språk mangler',
    pickupbox_city_missing: 'Pickupbox by mangler',
    pickupbox_outdoor_missing: 'Pickupbox utendørs mangler',
    pickupbox_uiLanguages_missing: 'Pickupbox UI-språk mangler',
    pickupbox_zipCode_missing: 'Pickupbox postnummer mangler',
    pickupbox_cardboardType_missing: 'Pickupbox papptype mangler',
    pickupbox_already_exists: 'Pickupbox finnes allerede',
    pickupbox__does_not_exist: 'Det finnes ingen pickupbox',

    callback_not_found: 'Tilbakekallingsforespørsel ikke funnet',
    record_not_found: 'Oppføring ikke funnet',
    user_fcm_token_missing: 'Brukerens FCM-token mangler',
    payment_already_issued: 'Betaling allerede utstedt',
    order_not_found: 'Bestillingen ble ikke funnet',
    deliveryMethod_validation_failed:
      'Leveringsmetode må være en av følgende: apotek, pickupbox, homeDelivery',

    missing_fields: 'Felt mangler',
    reserved_locker_cannot_be_inactive:
      'Et reservert skap kan ikke være inaktivt',
    locker_do_not_exist: 'Skapet finnes ikke',

    user_is_logged_in_to_box: 'Brukeren er logget inn på kassen',
    reservation_delivered: 'Reservasjonen er levert',
    pickupbox_disabled: 'Henteboksen er slått av',
    user_is_logged_in_to_box_not_create_reservation:
      'Brukeren gikk inn i feltet for ikke å opprette en reservasjon',

    pickupbox_disabled_invalid_locker: 'Pickupboxen deaktiverte ugyldig skap',
    user_is_logged_in_to_box_invalid_locker:
      'Brukeren er logget på en ugyldig kasse',
    invalid_locker: 'Ugyldig skap',
    locker_already_reserved: 'Skapet er allerede reservert',
    wrong_delivery_status: 'Feil leveringsstatus',
    permissions_empty: 'Tillatelsene er tomme',
    bad_strong_auth_provider:
      'Dårlig serverkonfigurasjon, ingen leverandør for sterk autentisering oppgitt',
    authentication_link_has_expired: 'Autentiseringslenke har utløpt',
    cannot_cancel_payment_with_current_status:
      'Kan ikke kansellere betaling for ordre med gjeldende status',
    minimal_payment_amount: 'Minste betalingsbeløp er 0,01',
    fieldIsIncorrectly: 'Beklager, ikke alle feltene er riktig utfylt',

    validation: {
      required: 'Dette feltet er obligatorisk.',
      email: 'Vennligst skriv inn en gyldig e-postadresse.',
      phoneNumber: 'Format for telefonnummer: +xxxxxxxxxxxx',
      length: 'Dette feltet skal være 6 tegn langt.',
      length8: 'Dette feltet skal være 8 tegn langt.',
      lengthLong: 'Passordet må være større enn eller lik 8 tegn.',
      confirm: 'Passordene må stemme overens',
      forDeliveryId: 'Feltet kan ikke starte fra 0'
    },
    /*info*/
    address: 'Adresse',
    languages: 'Språk',
    versionSW: 'SW-versjon',
    temperatureInterval: 'Temperaturintervall',
    temperatureAlarmInterval: 'Alarmintervall for temperatur',
    email: 'E-post',
    emailUpperCase: 'E-POST',
    alarmEmail: 'E-post for alarm',
    password: 'Passord',
    currentPassword: 'Nåværende passord',
    newPassword: 'Nytt passord',
    confirmPassword: 'Bekreft passord',
    phoneNumber: 'Telefonnummer',
    show: 'vis',
    showUpperCase: 'Vis',
    hide: 'skjule',
    mobilePhone: 'Mobiltelefon',
    mobilePhoneUpperCase: 'MOBILTELEFON',
    phoneUpperCase: 'TELEFON',
    alarmPhoneNumber: 'Telefonnummer for alarm',
    customerPhoneNumber: 'Kundens telefonnummer',
    firstName: 'Fornavn',
    firstNameUpperCase: 'FORNAVN',
    lastName: 'Etternavn',
    lastNameUpperCase: 'ETTERNAVN',
    fullName: 'Fullt navn',
    name: 'Navn',
    nameUpperCase: 'NAVN',
    pharmacyNameUpperCase: 'APOTEKETS NAVN',
    roleUpperCase: 'ROLLE',
    role: 'Rolle',
    keyUpperCase: 'NØKKEL',
    key: 'Nøkkel',
    id: 'ID',
    reservationKeepTime: 'Opprettholdelse av reservasjonstid',
    pickupBoxName: 'Henteboksnavn',
    pickupBoxId: 'Henteboks-ID',
    city: 'By',
    country: 'Land',
    zipCode: 'Postkode',
    UILanguages: 'UI-språk',
    defaultLanguages: 'Standardspråk',
    cardboardType: 'Papptype for henteboks',
    userIdUpperCase: 'BRUKER-ID',
    deliveryStatusUpperCase: 'LEVERINGSSTATUS',
    deliveryStatus: 'Leveringsstatus',
    kolliIDUpperCase: 'LEVERING-ID',
    lockerID: 'Skap-ID',
    lockerIDUpperCase: 'SKÅP-ID',
    orderExpired: 'Bestilling utløpt',
    orderExpiredUpperCase: 'BESTILLING UTLØPT',
    causeOfAlarmUpperCase: 'ÅRSAK TIL ALARM',
    orderID: 'Bestillings-ID',
    lang: 'Språk',
    desktopLang: 'Skrivebordsspråk',
    pharmacyInfo: 'Apotekinfo',
    userBoxPin: 'Brukerboks-PIN',
    farmacyEntryPin: 'Inngangs-PIN for apotek',
    pharmacyReservations: 'Apotekreservasjoner',
    lastUpdate: 'Siste oppdatering',
    kolliID: 'Levering-ID',
    timeReserved: 'Reservert tid',
    timeDelivery: 'Tidslevering',
    timePickup: 'Tid for henting',
    timeDelete: 'Tid for sletting',
    timeExpired: 'Utløpstid',
    deliveryMadeBy: 'Levering utført av',
    description: 'Beskrivelse',
    descriptionUpperCase: 'BESKRIVELSE',
    comment: 'Kommentar',
    commentUpperCase: 'KOMMENTAR',
    internalNote: 'Internt notat',
    customerReceiptMessage: 'Kvitteringsmelding til kunden',
    resendSMSCounter: 'Antall sendte SMS:',
    resendSMSTimestamps: 'Tidspunkt for siste SMS sendt:',
    reservationMadeByUpperCase: 'RESERVASJON GJORT AV',
    timestampUpperCase: 'TIMESTAMP',
    timestamp: 'Identifikasjonstid',
    socialSecurityNumber: 'Personnummer',
    homeAddress: 'Hjemmeadresse',
    handlerUpperCase: 'HÅNDTERER',
    deliveryUpperCase: 'LEVERING',
    paymentUpperCase: 'BETALING',
    totalUpperCase: 'TOTAL (EUR)',
    contentUpperCase: 'INNHOLD',
    issuedAtUpperCase: 'ISSUEA PÅ',
    eventTimeUpperCase: 'TIDSPUNKT FOR HENDELSEN',
    confirm: 'Bekreft',
    deliveredByUpperCase: 'LEVERT AV',
    assignedTimeByUpperCase: 'TILDELT PÅ',
    assignedTime: 'Tildelt på',
    assignedUserName: 'Tildelt',
    fulfillment_order_id: 'Ordre-ID for innfrielse',
    order_id: 'Ordre-ID',
    reservedUserName: 'Reservasjon gjort av',
    pickupboxPageAssignDelivery: 'SIDE FOR LEVERING AV PICKUPBOX',
    lastLoginUpperCase: 'SISTE INNLOGGING',
    userUpperCase: 'BRUKER',
    customerUpperCase: 'KUNDE',
    typeUpperCase: 'TYPE',
    assignUpperCase: 'TILDEL',
    clientUpperCase: 'KUNDEN',
    preferredHoursUpperCase: 'FORETRUKKET ARBEIDSTID',
    requestedAtUpperCase: 'ANMODET PÅ',
    alarmOk: 'Alt er i orden med skapet',
    alarmDoorOpen: 'Skapet lukkes ikke',
    alarmDoorClosed: 'Skapet åpnes ikke',
    alarmTempHigh: 'Temperaturen i skapet er høy',
    alarmTempLow: 'Temperaturen i skapet er lav',
    alarmOther: 'Ingen data om skapet er tilgjengelig',
    alarmMissing: 'Data om skapet mangler',
    pending: 'PENDING',
    failed: 'MISLYKKET',
    completed: 'FULLFØRT',
    january: 'Januar',
    february: 'Februar',
    march: 'Mars',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Desember',
    monday: 'Mandag',
    tuesday: 'Tirsdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag',
    saturday: 'lørdag',
    sunday: 'Søndag',
    roomTemp: 'Romtemperatur',
    includesDrug: 'Inkluderer legemiddel',
    includesDrugUpperCase: 'INKLUDERER LEGEMIDDEL',
    log: 'Logg',
    in: 'inn',
    out: 'ut',
    min: 'min',
    minute: ' minutt',
    minutes: ' minutter',
    hour: 'time',
    hours: 'timer',
    day: ' dag',
    days: ' dager',
    daysDelivery: 'Dager',
    basicLocker: 'Grunnleggende skap:',
    fridgeLocker: 'Kjøleskapsskap:',
    deliveryExpirationTime: 'Utløpstid for levering',
    reminderMessageTime: 'Tid for påminnelsesmelding',
    reservationExpirationTime: 'Utløpstid for reservasjon',
    fullHistory: 'Full historikk',
    /*actions*/
    forgotPassword: 'Glemt Passord?',
    changePassword: 'Bytt Passord',
    sendResetLink: 'Send tilbakestillingslenke',
    newOrder: 'Ny bestilling',
    viewOrder: 'Vis bestilling',
    sentEmail: 'E-post for tilbakestilling av passord er sendt.',
    edit: 'Rediger',
    add: 'Legg til',
    addOption: 'Legg til alternativ',
    doNotSaveOption: 'Ikke lagre dette alternativet',
    chooseLanguage: 'Velg språk for SMS',
    serviceLanguagesAvailable: 'Tjenestespråk tilgjengelig',
    save: 'Lagre',
    send: 'Send',
    saveForm: 'Lagre Skjema',
    saveFormAndSendPayment: 'Lagre skjema & send betaling',
    finish: 'Avslutt',
    discard: 'Forkast',
    delete: 'Slett',
    cancel: 'Avbryt',
    exit: 'Gå ut',
    cleanFields: 'Tøm felter',
    search: 'Søk',
    authenticated: 'Godkjent',
    authenticationFailed: 'Autentisering mislyktes',
    authenticationPending: 'Autentisering',
    paymentCompleted: 'Betaling fullført',
    paymentFailed: 'Betaling mislyktes',
    paymentSuccess: 'Betalingssuksess',
    paymentPending: 'Venter på betaling',
    userNotFound: 'Bruker ikke funnet',
    userFound: 'Bruker funnet',
    authenticationRegisterSuccess: 'Godkjenning vellykket!',
    authenticationRegisterFailed: 'Autentisering mislyktes!',
    makeServiceRequest: 'Lag en serviceforespørsel',
    seriviceRequested: 'Service forespurt',
    cancelPaymentRequest: 'Avbryt betalingsforespørsel',
    deleteUser: 'Slett bruker',
    addUser: 'Legg til bruker',
    createUser: 'Opprett bruker',
    loginInformation: 'Innloggingsinformasjon',
    selectMultipleLockers: 'Velg flere skap',
    disableLocker: 'Deaktiver skap',
    locker: 'Skap',
    reservation: 'Reservasjon',
    delivery: 'Levering',
    deleteReservation: 'Slett reservasjon',
    reserve: 'Reserver',
    newBookingPharmacyPickup: 'Ny bestilling for henting av apotekartikler',
    exportJSON: 'Export.json',
    importJSON: 'Import.json',
    downloadCSV: 'Download.csv',
    downloadTempCSV: 'Download temperatures.csv',
    downloadPDF: 'Download.pdf',
    createReservation: 'Opprett reservasjon',
    webToolAccess: 'Tilgang til webverktøy',
    boxAccess: 'Tilgang til boks',
    ok: 'Ok',
    happy: 'Glad',
    angry: 'Sint',
    yes: 'Ja',
    no: 'Nei',
    and: 'og',
    assignReservation: 'Henteboksside for tildeling av reservasjon',
    redirectToPickupBox: 'Omdiriger til henteboks',
    quickReservation: 'Hurtigreservasjon',
    quickReservationLockers: 'Manuell hurtigbestilling',
    isDrug: 'Inneholder legemidler',
    drug: 'Legemidler',
    ssn: 'SSN',
    consentGiven: 'SAMTYKKE GITT',
    resendSMS: 'Send SMS på nytt',
    showFullHistory: 'Vis hele historikken',
    hideFullHistory: 'Skjul hele historikken',
    authentication: 'Autentisering',
    authenticateClient: 'Autentiser klient',
    paymentDetails: 'Betalingsdetaljer',
    onBehalf: 'På vegne av?',
    reasonForTransaction: 'Årsak til transaksjonen',
    sendPaymentNotification: 'Send betalingsforespørsel',
    paymentNotificationWasSuccessfullyResend:
      'Betalingsvarselet ble sendt på nytt.',
    /*text*/
    pageNotFound: 'Side ikke funnet',
    wrongFormat: 'Feil format',
    checkURL:
      'Den angitte filen ble ikke funnet på denne nettsiden. Sjekk nettadressen for feil og prøv igjen.',
    forgotPasswordTex:
      'Vennligst skriv inn e-postadressen din, så sender vi deg en e-post. Ved å følge lenken i e-posten vil du kunne skrive inn ditt nye passord.',
    passwordCharacters: 'Passordet skal inneholde minst 8 tegn.',
    youWantDelete: 'Er du sikker på at du vil slette',
    thisChangeCannotBeUndone: 'Denne endringen kan ikke angres.',
    descriptionDestroy:
      'Ødelagt - Flytt reservasjonen til historikken. Dette skal brukes når du ødelegger.',
    descriptionDelete:
      'Slett - Flytt reservasjonen tilbake til API-reservasjoner-fanen, dvs. avbryt reservasjonen for å reservere en ny plass.',

    descriptionCancel: 'Avbryt - Lukk vinduet uten handling.',
    youWantDeleteReservation:
      'Er du sikker på at du vil slette denne reservasjonen? Denne endringen kan ikke angres',
    undone: '? Denne endringen kan ikke angres.',
    changePasswordInfo:
      'INFO: Husk passordet og gi det til brukeren. Du kan ikke gjenopprette passordet senere. Passordet må inneholde minst 8 tegn.',
    noPickupBox: 'Ingen henteboks',
    addDescription: 'Legg til beskrivelse i leveringshistorikken',
    addComment: 'Legg til kommentar til bestillingen',
    infoReservation: 'Informasjon om reservasjonen',
    addAlarmComment: 'Legg til alarmkommentar',
    deviationsInTimeFrame: 'Avvik i tidsramme',
    addUserMainInfo:
      'INFO: Husk passordet og gi det til brukeren. Du kan ikke gjenopprette passordet senere.',
    addUserInfo: 'Passordet må inneholde minst 8 tegn.',
    lockersSelected: 'skap er valgt',
    messageTemperatureHigh: 'Skaptemperaturen er for høy: ',
    messageTemperatureLow: 'Skaptemperaturen er for lav: ',
    noData: 'Ingen data',
    errorDate: 'Feil ved dato',
    noReservations: 'Ingen reservasjoner',
    noOrderReservedYet: 'Ingen bestilling er reservert ennå.',
    enableLocker:
      'Ingen bestilling er reservert ennå. Aktiver Locker for å reservere bestillinger.',
    noAlarms: 'Ingen alarmer i den valgte tidsperioden',
    assignDeliveryFromDesk:
      'Tilordne levering som skal hentes fra skrivebordet',
    assignDeliveryFromLocker:
      'Tilordne levering som skal hentes fra henteboksen',
    assignToLocker:
      'Hvis du vil at leveransen skal hentes fra en Pickup!-boks, trykker du på knappen Omdiriger til henteboks. Trykk på en ledig plass og velg Tilordne reservasjon for henteboks.',
    pickedUpRevervation: 'Ble reservasjonen hentet?',
    chooseDelivery: 'Velg leveransen fra listen',
    chooseThisDelivery: 'Velg denne leveransen',
    pickupboxIsInactive:
      'Henteboksen er inaktiv. Funksjonen er ikke tilgjengelig',
    youReservationPinCode: 'PIN-kode for din reservasjon',
    pinCode: 'PIN-kode',
    wantResendSMS: 'Er du sikker på at du vil sende SMS-en på nytt?',
    enoughPermissions: 'Du har ikke nok tillatelser',
    noContent: 'INGEN INNHOLD.',
    locationNotAllowed: 'PLASSERING IKKE TILLATT',
    noSpaceInPickupBoxes: 'Ingen plass i hentebokser',
    descriptionServiceRequest: 'Beskrivelse av tjenesteforespørselen',
    choosePharmacy: 'Velg apoteket du vil logge inn på',
    changePharmacy: 'Endre apotek',
    placeholderText: 'Velg apotek',
    connectionLost: 'Internettforbindelsen er brutt',
    clientInformation: 'Kundeinformasjon',
    deliveryMethodUpperCase: 'LEVERINGSMETODE',
    deliveryMethod: 'Leveringsmetode',
    issuePaymentUpperCase: 'UTFØR BETALING',
    issuePayment: 'Utsted betaling',
    deliveryContentMatchesOrder: 'Leveringsinnhold samsvarer med bestillingen',
    handler: 'Behandler',
    confirmedDy: 'Bekreftet av: ',
    homeDelivery: 'Hjemlevering',
    confirmation: 'Bekreftelse',
    confirmationText:
      'Bekreft at leveringsinnholdet samsvarer med bestillingen. Denne handlingen er irreversibel.',
    successfully: 'Vellykket',
    workingHours: 'ARBEIDSTIDER',
    services: 'TJENESTER',
    showToCustomers: 'Vis til kundene',
    showToCustomersUpperCase: 'VIS TIL KUNDENE',
    sendNewCallbackRequestEmailNotification:
      'Send e-postvarsel om tilbakekallingsforespørsel',
    closed: 'Stengt',
    closedUpperCase: 'STENGT',
    startAt: 'START KL',
    endAt: 'SLUTT PÅ',
    editWorkingHours: 'Rediger arbeidstid',
    dayOfTheWeek: 'UKEDAG',
    chooseWorkingHours: 'Velg arbeidstid',
    orderPayment: 'Tiltak som er iverksatt',
    prescription: 'Innlogging til reseptsenteret',
    other: 'Annet',
    medicationDelivery: 'Levering av medisiner',
    prescriptionDrugs: 'Reseptbelagte legemidler',
    selfCare: 'Egenomsorg',
    medicalAdvice: 'Medisinske råd',
    medicalAdviceUpperCase: 'MEDISINSKE RÅD',
    medicalAdviceGiven: 'Medisinske råd gitt',
    orderNumber: 'Innkjøpsordrenummer',
    orderNumberUpperCase: 'INNKJØPSORDRENUMMER',
    reference: 'Referanse',
    totalSum: 'Total sum (EUR)',
    discardAuthentication: 'Forkast autentisering',
    discardAuthenticationText:
      'Er du sikker på at du vil forlate siden «Anropsgodkjenning»? Dataene i skjemaet vil gå tapt.',
    markAsDone: 'Merk som utført',
    markAsMissed: 'Merk som savnet',
    backToCallerAuthentication: 'Gå til identifikasjon',
    totalCustomersVotes: 'Totalt antall kundestemmer: ',
    templates: 'Maler',
    allActive: 'Alle aktive',
    active: 'Aktiv',
    translation: 'Oversettelse',
    numberOfUsers: 'Antall brukere',
    position: 'Stilling',
    title: 'Tittel',
    titleUpperCase: 'TITTEL',
    subtitleUpperCase: 'UNDERTITEL',
    advertPhoto: 'Annonsefoto',
    photoUpperCase: 'FOTO',
    screenTimeoutUpperCase: 'TIDSAVBRUDD PÅ SKJERMEN',
    colorUpperCase: 'FARGE',
    logoPhoto: 'Logo Foto',
    chooseScale: 'Velg skala',
    hintTextUpperCase: 'HINT TEKST',
    optionsUpperCase: 'ALTERNATIVER',
    reportUpperCase: 'RAPPORT',
    gradeUpperCase: 'KARAKTER',
    multipleOptions: 'Flere alternativer',
    multipleOptionsUpperCase: 'FLERE ALTERNATIVER',
    timeout: 'Tidsavbrudd',
    addNewOption:
      'Du kan ikke opprette et nytt alternativ fordi det allerede finnes tre aktive alternativer',
    theSamePositions: 'Disse alternativene har de samme posisjonene',
    positionIsTaken: 'Denne posisjonen er allerede opptatt, velg en annen',
    showOnlyWithContact: 'Vis bare med kontakt',
    showOnlyWithReport: 'Vis bare med rapport',
    showOnlyWithOption: 'Vis bare med alternativ',
    showOnlyWithGrade: 'Vis bare med vurdering',
    type: 'Vis med type',
    face: 'Ansikt',
    higher: 'Høyere',
    lower: 'Lavere',
    same: 'Samme',
    welcomeToApteekkiPuhelu: 'Velkommen til ApteekkiPuhelu',
    openApp: 'Hvis du har appen installert, kan du åpne den herfra. Hvis ikke,',
    clickHere: 'klikk her',
    downloadApp: ' for å laste ned appen.',
    platformUnsupported: 'Plattformen din støttes ikke',
    chooseColor: 'Velg en farge',
    infoChangePassword:
      'INFO: Husk passordet og overgi det til brukeren. Du kan ikke gjenopprette passordet senere. Passordet skal inneholde minst 8 tegn.',
    green: 'Ny tilbakekallingsforespørsel',
    orange: 'Hoppet over tilbakekallingsforespørsel',
    rememberAboutMedicalAdvice:
      'Husk å bekrefte om det ble gitt råd om medisinering',
    Alarm: 'Alarm',
    'Box Temperature': 'Temperatur i boksen',
    'Fridge 0-1 Temperature': 'Kjøleskap 0-1 Temperatur',
    'Fridge 1-1 Temperature': 'Kjøleskap 1-1 Temperatur',
    'Fridge 2-1 Temperature': 'Kjøleskap 2-1 Temperatur',
    Max: 'Max',
    Min: 'Min',
    'Fridge Max': 'Kjøleskap Maxs',
    'Fridge Min': 'Kjøleskap Min',
    /*backTo*/
    backToUsers: 'Tilbake til Brukere',
    backToInfoPickupBox: 'Tilbake til Informasjon om henteboks',
    backToLogIn: 'Tilbake til innlogging',
    backToAuthenticationHistory: 'Tilbake til autentiseringshistorikk',
    backToOrdersHistory: 'Tilbake til Bestillingshistorikk',
    backToCustomersFeedback: 'Tilbake til tilbakemeldinger fra kunder',
    consentGivenText:
      'Kunden gir samtykke til at Reseptformidleren kan dele opplysninger om refusjonsberettigelse og reseptopplysninger fra FPA-direkt.',
    /*lockers*/
    all: 'Alle',
    allUpperCase: 'ALLE',
    free: 'Tom',
    reserved: 'Reservert',
    reservedUpperCase: 'RESERVERT',
    reservedTable: ' Reservert',
    reservedText: 'reservert',
    deleted: 'Slettet',
    deletedTable: ' Slettet',
    expired: 'Utløpt',
    expiredTable: ' Utløpt',
    notInUse: 'Ikke i bruk',
    fridge: 'Kjøleskap',
    delivered: 'Leveres på stedet',
    deliveredUpperCase: 'LEVERES',
    deliveredTable: ' Levert',
    removed: 'Fjernet',
    removedTable: ' Fjernet',
    disabled: 'Deaktivert',
    cancelled: 'Avbrutt',
    cancelledTable: ' Avbrutt',
    cancelledTableUpperCase: 'AVLYST',
    doneUpperCase: 'FERDIG',
    pickedUp: 'Plukket Opp',
    pickup: 'Plukket Opp',
    pickedUpUpperCase: 'PLUKKET OPP',
    destroyedUpUpperCase: 'ØDELAGT',
    pickedUpTable: ' Hentet',
    destroyedTable: ' Ødelagt',
    destroyed: 'Ødelagt',
    small: 'Liten',
    medium: 'Medium',
    large: 'Stort',
    noSsn: 'Ingen SSN',
    givenOutUserName: 'Gitt ut bruker',
    /*capacity Pick-up Box*/
    week: 'Uke',
    weekUpperCase: 'UKE',
    month: 'Måned',
    boxTypeUpperCase: 'BOKSTYPE',
    capacityUpperCase: 'KAPASITET %',
    averagePickupTimeUpperCase: 'GJENNOMSNITTLIG HENTETID',
    /*edit Pick-up Box*/
    temperatureIntervals: 'Temperaturintervaller',
    /*temperatureLog*/
    sensorUpperCase: 'SKAP-ID/SENSOR',
    startTimeUpperCase: 'STARTTID',
    startTime: 'Starttid',
    endTimeUpperCase: 'SLUTTID',
    endTime: 'Sluttid',
    durationUpperCase: 'VARIGHET',
    minUpperCase: 'MIN °C',
    maxUpperCase: 'MAKS °C',
    averageUpperCase: 'GJENNOMSNITT °C',
    alarmCommentUpperCase: 'ALARMKOMMENTAR',
    date: 'Dato',
    temperature: 'Temperatur',
    temperatureMax: 'Maks temperatur',
    temperatureMin: 'Min temperatur',
    timestempTemperatures: 'Kontroller temperaturene',
    lastCheckedBy: 'Sist sjekket av',
    checkTime: 'Kontrolltid',
    /*deliveryStatistics*/
    monthUpperCase: 'MÅNED',
    yearUpperCase: 'ÅR',
    deliveriesUpperCase: '# LEVERINGER',
    pickupsUpperCase: '# HENTINGER',
    expiredUpperCase: '# UTLØPT',
    destroyedUpperCase: '# ØDELAGTE',
    avgPickupTimeUpperCase: '# AVG HENTETID (T)',
    totalUsageUpperCase: '# TOTAL BRUK (%)',
    cancelledUpperCase: '# KANSELLERT',
    averagePickupTime: 'AVG Hentetid',
    hoursUpperCase: 'Timer',
    avgPickupTime: 'GJENNOMSNITTLIG HENTETID',
    /*page*/
    pharmacy: 'Apotek',
    profile: 'Mine opplysninger',
    callerAuthentication: 'Autentisering av innringer',
    authenticationHistory: 'Autentiseringshistorikk',
    ordersHistory: 'Bestillingshistorikk',
    pickupBox: 'Henteboks',
    pickupBoxes: 'Hentebokser',
    pharmacyPickup: 'Henting på apotek',
    users: 'Brukere',
    user: 'Bruker',
    mainUser: 'Hovedbruker',
    callbackRequests: 'Forespørsler om tilbakeringing',
    customersFeedback: 'Tilbakemeldinger Fra Kunder',
    userGuide: 'Brukerveiledning',
    pharmacyInformation: 'Apotekinformasjon',
    lockers: 'Skap',
    order: 'Bestilling',
    temperatureChecking: 'Kontroll av temperatur',
    infoPickupBox: 'Henteboksinfo',
    boxInfo: 'Boksinfo',
    editPickupBox: 'Rediger henteboks',
    reservations: 'Reservasjoner',
    apiReservations: 'API-reservasjoner',
    deliveryHistory: 'Leveringshistorikk',
    temperatureLog: 'Temperaturlogg',
    alarms: 'Alarmer',
    capacity: 'Kapasitet',
    deliveryStatistics: 'Leveringsstatistikk',
    reserveLocker: 'Reserveskap',
    settings: 'Innstillinger Kunder Tilbakemeldinger',
    general: 'Generelt',
    options: 'Alternativer',
    report: 'Rapporter',
    grade: 'Karakter',
    contact: 'Kontakt',
    contactUpperCase: 'KONTAKT',
    thank: 'Takk',
    statistics: 'Statistikk',
    customers: 'Kunder'
  }
};
