import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/Hyphen.svg'


const _hoisted_1 = { class: "deliveryStatistics" }
const _hoisted_2 = { class: "deliveryStatistics__header" }
const _hoisted_3 = { class: "deliveryStatistics__header-chooseDate" }
const _hoisted_4 = { class: "chooseDate-inputs" }
const _hoisted_5 = { class: "inputs" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = { class: "input" }
const _hoisted_8 = {
  key: 0,
  class: "date-error"
}
const _hoisted_9 = { class: "block-input" }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputAverageTotal = _resolveComponent("InputAverageTotal")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Dropdown, {
        class: "deliveryStatistics__header-dropdown",
        selectedOption: _ctx.selectedStatistics.selectedOption,
        options: _ctx.selectedStatistics.options,
        multiple: true,
        onSelectOption: _ctx.selectStatistics,
        "data-testid": "deliveryStatistics"
      }, null, 8, ["selectedOption", "options", "onSelectOption"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Button, {
          class: "button-downloadCSV",
          variant: "bordered",
          title: _ctx.$t('downloadCSV'),
          onClick: _ctx.getCSV
        }, null, 8, ["title", "onClick"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Calendar, {
                type: "month",
                format: "DD/MM/YYYY",
                value: _ctx.firstDay,
                name: "startTime",
                message: _ctx.$t('errorDate'),
                onChange: _ctx.changeDateStartTime,
                "data-testid": "calendarStartTime"
              }, null, 8, ["value", "message", "onChange"])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              class: "chooseDate-img",
              src: _imports_0,
              alt: "Hyphen"
            }, null, -1)),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Calendar, {
                type: "month",
                format: "DD/MM/YYYY",
                value: _ctx.today,
                name: "endTime",
                onChange: _ctx.changeDateEndTime,
                "data-testid": "calendarEndTime"
              }, null, 8, ["value", "onChange"])
            ])
          ]),
          (!_ctx.isDateCorrect)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('errorDate')), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_InputAverageTotal, {
        modelValue: _ctx.averageTotal,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.averageTotal) = $event)),
        name: "averageTotal",
        type: "number",
        label: _ctx.$t('averagePickupTime'),
        disabled: ""
      }, null, 8, ["modelValue", "label"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('hoursUpperCase')), 1)
    ]),
    (_ctx.isDateCorrect)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_Table, {
            data: _ctx.dataDeliveriesStatisticsMonthlyValue,
            columns: _ctx.columns
          }, null, 8, ["data", "columns"])
        ]))
      : _createCommentVNode("", true)
  ]))
}