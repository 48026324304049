
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';

import Order from './Order/Order.vue';
import Loading from '@/views/Loading.vue';

import { orderStatus } from './configs';
import { allStatus } from '@/constants';
import { AlarmsData } from '@/store/modules/pharmacy/types';

@Options({
  name: 'InfoLocker',
  emits: ['updateLockerDataCallback', 'getDecodedSsn'],
  props: ['order', 'activeLocker', 'lockerData', 'activePickupBox'],
  components: {
    Order,
    Loading
  },
  computed: {
    ...mapState('pharmacy', ['alarms', 'decodedSsn']),
    ...mapState('account', ['user']),
    status() {
      if (this.order.deliveryStatus) {
        return this.order.deliveryStatus;
      } else {
        return this.order.status;
      }
    },
    getFridgeMax() {
      return (
        this.order.temperature >
        this.activePickupBox.info.temperatureRangeFridge.max
      );
    },
    getFridgeMin() {
      return (
        this.order.temperature <
        this.activePickupBox.info.temperatureRangeFridge.min
      );
    },
    getBasicMax() {
      return (
        this.order.temperature >
        this.activePickupBox.info.temperatureRangeBasic.max
      );
    },
    getBasicMin() {
      return (
        this.order.temperature <
        this.activePickupBox.info.temperatureRangeBasic.min
      );
    },
    getMaxTemp() {
      return this.lockerData.fridge ? this.getFridgeMax : this.getBasicMax;
    },
    getIncreasedOrDecreasedTemp() {
      return this.getFridgeMinMax || this.getBasicMinMax;
    },
    getFridgeMinMax() {
      return this.lockerData.fridge && (this.getFridgeMax || this.getFridgeMin);
    },
    getBasicMinMax() {
      return !this.lockerData.fridge && (this.getBasicMax || this.getBasicMin);
    }
  },
  data() {
    return {
      formData: {
        inactive: false,
        lockerId: ''
      },
      orderStatus,
      allStatus,
      alarmsTempList: [],
      loading: false,
      showDecodedSsn: ''
    };
  },
  methods: {
    ...mapActions('pharmacy', [
      'getAlarms',
      'toggleLockerPickupBox',
      'getPickupBoxesByPharmacy',
      'getDeliveryShowDecodedSsn'
    ]),
    async loadingAlarms() {
      const boxId = this.activePickupBox.id;

      const endDay = new Date();

      const defaultParams = {
        boxId,
        endTime: endDay.valueOf(),
        startTime: new Date(
          endDay.getFullYear(),
          endDay.getMonth(),
          1
        ).valueOf()
      };

      await this.getAlarms(defaultParams);
    },
    loadingTempAlarms() {
      const alarm = [...this.alarms].find(
        (item: AlarmsData) => item['lockerId'] === this.activeLocker
      );

      return moment(alarm?.timestamp).format('DD.MM.YYYY HH:mm');
    },
    valueToggle() {
      this.formData.inactive = this.lockerData.inactive;

      if (this.formData.inactive) {
        this.lockerData.status = 'inactive';
        this.order.status = this.lockerData.status;
      } else {
        this.lockerData.status = 'free';
        this.order.status = this.lockerData.status;
      }

      return this.formData.inactive;
    },
    async submitHandler() {
      const boxId = this.activePickupBox.id;

      this.loading = true;

      this.formData = {
        ...this.formData,
        inactive: this.formData.inactive,
        lockerId: this.activeLocker
      };

      await this.toggleLockerPickupBox({
        data: this.formData,
        boxId
      });

      const { id } = this.$route.params;
      await this.getPickupBoxesByPharmacy(id);
      this.loading = false;

      this.$emit('updateLockerDataCallback');
    },
    changeDisableLocker() {
      this.formData.inactive = !this.formData.inactive;
      this.submitHandler();
    },
    isDisabled() {
      if (
        this.lockerData.order ||
        !this.activePickupBox?.info.active ||
        !this.user.role.permissions.pickupbox.update
      ) {
        return true;
      }
      return false;
    },
    async getDecodedSsn() {
      const params = {
        deliveryId: this.order.deliveryId,
        reservedTime: this.order.reservedTime,
        pickupboxId: this.activePickupBox.id
      };

      await this.getDeliveryShowDecodedSsn(params);
      this.showDecodedSsn = this.decodedSsn;
    }
  },
  watch: {
    activePickupBox() {
      if (this.activePickupBox) {
        this.loadingAlarms();
      }
    },
    order() {
      this.showDecodedSsn = '';
    }
  }
})
export default class InfoLocker extends Vue {}
