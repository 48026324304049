import { Option, TranslationFunction } from '@/types';

export const columns = ($t: TranslationFunction): Option[] => [
  {
    value: 'alarmType',
    label: $t('causeOfAlarmUpperCase')
  },
  {
    value: 'timestamp',
    label: $t('startTimeUpperCase')
  },
  {
    value: 'alarmEndTimestamp',
    label: $t('endTimeUpperCase')
  },
  {
    value: 'duration',
    label: $t('durationUpperCase')
  },
  {
    value: 'alarmMax',
    label: $t('maxUpperCase')
  },
  {
    value: 'alarmMin',
    label: $t('minUpperCase')
  },
  {
    value: 'sensor',
    label: $t('sensorUpperCase')
  },
  {
    value: 'averageTemp',
    label: $t('averageUpperCase')
  },
  {
    value: 'alarmComment',
    label: $t('alarmCommentUpperCase')
  }
];
