import firebase from 'firebase/compat/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import 'firebase/auth';
import config from '../config';
import { AccountUser, EmailUser } from '@/store/modules/account/types';

const firebaseConfig = config.firebaseConfig;

const firebaseApp = firebase.initializeApp(firebaseConfig);

const rldb = getDatabase(firebaseApp);

const getCurrentUser = async (): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

const userAuthenticate = async (
  user: AccountUser
): Promise<firebase.auth.UserCredential> => {
  return firebaseApp
    .auth()
    .signInWithEmailAndPassword(user.email, user.password);
};

// eslint-disable-next-line
const userAuthenticateWithMicrosoft = async () => {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');
  provider.setCustomParameters({
    tenant: 'apoteketab.onmicrosoft.com'
  });
  return provider;
};

const resetPassword = async (user: EmailUser): Promise<unknown> => {
  return firebaseApp
    .auth()
    .sendPasswordResetEmail(user.email)
    .then(() => {
      return 'Password reset email sent.';
    });
};

const userLogout = async (): Promise<void> => {
  return firebaseApp.auth().signOut();
};

const getCurrentUserId = async (): Promise<string | undefined> => {
  return firebaseApp.auth().currentUser?.getIdToken(true);
};

export {
  rldb,
  ref,
  onValue,
  firebaseApp,
  getCurrentUser,
  userAuthenticate,
  userAuthenticateWithMicrosoft,
  getCurrentUserId,
  userLogout,
  resetPassword
};
