
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { mapScreensToSelectedPositions } from './configs';
import { optionsPositions } from './configs';

import { Option } from '@/types';

@Options({
  name: 'ShowSettings',
  props: ['value', 'selectedOption'],
  data() {
    return {
      screens: {},
      name: '',
      positionNumber: 0,
      selectedPositions: {
        selectedOption: {},
        options: optionsPositions
      }
    };
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy'])
  },
  created() {
    if (this.pharmacy) {
      this.show();
    }
  },
  methods: {
    show() {
      this.loading = true;

      if (this.pharmacy) {
        this.screens = this.pharmacy.feedbackTemplate;
        this.name = this.$route.name.toLowerCase();

        this.selectedPositions.selectedOption = mapScreensToSelectedPositions(
          this.screens,
          this.name
        );
      }

      this.loading = false;
    },
    selectOption(value: boolean) {
      // eslint-disable-next-line
      value = !value;
    },
    selectPositions(option: Option) {
      this.selectedPositions.selectedOption = option;
      this.$emit('update:selectedPosition', option.value);
    }
  },
  watch: {
    pharmacy() {
      if (this.pharmacy) {
        this.show();
      }
    }
  }
})
export default class ShowSettingsComponent extends Vue {}
