import { ActionTree } from 'vuex';
import {
  AccountState,
  AccountUser,
  AuthenticationSignicat,
  EmailUser,
  UserProfile
} from './types';
import { RootState } from '@/store/types';
import {
  getCurrentUserId,
  resetPassword,
  userAuthenticate,
  userAuthenticateWithMicrosoft,
  userLogout
} from '@/firebase/firebaseInit';
import { getAuth, signInWithPopup } from 'firebase/auth';
import {
  authorizeRegisterUser,
  authorizeUser,
  authorizeUserVieStrongAuthRedirect,
  changeLang,
  changePasswordAdminProfile,
  deleteHeaderToken,
  getAuthenticationSignicat,
  getPharmacyAccesses,
  getProfile,
  setHeaderToken,
  setUserLang,
  updateProfile,
  writeLoggedIn,
  writeLoggedOut
} from '@/services/axiosService';
import router from '@/router';
import { state } from '@/store/modules/account/index';
import firebase from 'firebase/compat';
import FirebaseError = firebase.FirebaseError;
import { AccountMutations } from './mutations';
import { AxiosError } from 'axios';

const cleanLocalStorage = () => {
  localStorage.removeItem('locale');
  localStorage.removeItem('token');
};

export const actions: ActionTree<AccountState, RootState> = {
  async login({ commit, dispatch }, user: AccountUser) {
    try {
      await userAuthenticate(user)
        .then(async () => {
          commit('login_request');
          const token = await getCurrentUserId();
          if (token === undefined) {
            throw new Error('user_token_can_not_be_retrieved');
          }
          localStorage.setItem('token', token);
          setHeaderToken(token);
          const result = await getPharmacyAccesses();
          if (result.status === 200) {
            await dispatch('loginToPharmacy');
            await router.push({
              name: 'Home'
            });
          } else if (result.status === 300) {
            await router.push({
              name: 'ChangePharmacy',
              query: {
                data: result.data
              }
            });
          } else {
            throw new Error('error_login_user_not_found_or_wrong_password');
          }
        })
        .catch(async (err) => {
          const errorCode = err?.code;

          switch (errorCode) {
            case 'auth/invalid-email':
            case 'auth/missing-email':
              throw new Error('user_with_email_does_not_exist');
            case 'auth/too-many-requests':
              throw new Error('error_login_too_many_attempts');
            case 'auth/account-not-found':
            case 'auth/user-not-found':
            case 'auth/wrong-password':
            case 'auth/internal-error':
            case 'auth/invalid-login-credentials':
              throw new Error('error_login_user_not_found_or_wrong_password');
            case 'auth/account-disabled':
              throw new Error('user_is_disabled');
            default:
              throw new Error(err?.message);
          }
        });
    } catch (error: unknown) {
      const axiosError = error as AxiosError;

      const responseData: unknown = axiosError.response?.data;

      cleanLocalStorage();
      deleteHeaderToken();
      const errorMsg = axiosError.response ? responseData : axiosError.message;
      commit('login_error', errorMsg);
    }
  },
  async loginToPharmacy({ commit }) {
    const userProfile: UserProfile | undefined = await getProfile();
    if (
      userProfile === undefined ||
      userProfile.role.app_access !== 'web_app'
    ) {
      throw new Error('user_is_not_found');
    }
    await writeLoggedIn();
    commit(AccountMutations.login_set_user, userProfile);
    localStorage.setItem(
      'locale',
      userProfile.appLanguage === 'undefined' ? 'sv' : userProfile.appLanguage
    );
    commit('login_success');
  },
  async resetPassword({ commit }, user: EmailUser) {
    try {
      await resetPassword({ email: user.email })
        .then((email) => {
          console.info(email);
        })
        .catch(async (err: unknown) => {
          const axiosError = err as AxiosError;
          const errorCode = axiosError?.code;

          switch (errorCode) {
            case 'auth/invalid-email':
            case 'auth/missing-email':
              throw new Error('user_with_email_does_not_exist');
            case 'auth/too-many-requests':
              throw new Error('error_login_too_many_attempts');
            case 'auth/account-not-found':
            case 'auth/user-not-found':
            case 'auth/internal-error':
              throw new Error('error_login_user_not_found');
            case 'auth/account-disabled':
              throw new Error('user_is_disabled');
            default:
              throw new Error(axiosError?.message);
          }
        });
    } catch (error: unknown) {
      const axiosError = error as AxiosError;
      const responseData: unknown = axiosError.response?.data;

      cleanLocalStorage();
      deleteHeaderToken();
      const errorMsg = axiosError.response ? responseData : axiosError.message;
      commit('reset_password_error', errorMsg);
    }
  },
  async loginWithMicrosoft({ commit, dispatch }) {
    try {
      const provider = await userAuthenticateWithMicrosoft();
      const auth = getAuth();

      signInWithPopup(auth, provider)
        .then(async () => {
          commit('login_request');
          const token = await getCurrentUserId();
          if (token === undefined) {
            throw new Error('user_token_can_not_be_retrieved');
          }
          localStorage.setItem('token', token);
          setHeaderToken(token);
          const result = await getPharmacyAccesses();
          if (result.status === 200) {
            await dispatch('loginToPharmacy');
            await router.push({
              name: 'Home'
            });
          } else if (result.status === 300) {
            await router.push({
              name: 'ChangePharmacy',
              query: {
                data: result.data
              }
            });
          } else {
            throw new Error('error_login_user_not_found_or_wrong_password');
          }
        })
        .catch(async (err: unknown) => {
          const axiosError = err as AxiosError;
          const errorCode = axiosError?.code;

          switch (errorCode) {
            case 'auth/invalid-email':
            case 'auth/missing-email':
              throw new Error('user_with_email_does_not_exist');
            case 'auth/too-many-requests':
              throw new Error('error_login_too_many_attempts');
            case 'auth/account-not-found':
            case 'auth/user-not-found':
            case 'auth/internal-error':
              throw new Error('error_login_user_not_found_or_wrong_password');
            case 'auth/account-disabled':
              throw new Error('user_is_disabled');
            default:
              throw new Error(axiosError?.message);
          }
        });
    } catch (error: unknown) {
      const axiosError = error as AxiosError;
      const responseData: unknown = axiosError.response?.data;

      cleanLocalStorage();
      deleteHeaderToken();
      const errorMsg = axiosError.response ? responseData : axiosError.message;
      commit('login_error', errorMsg);
    }
  },
  async logout({ commit }, { msg } = { msg: '' }) {
    if (state.status.loggedIn && !state.status.loggingOut) {
      commit(AccountMutations.logging_out);
      deleteHeaderToken();
      await writeLoggedOut();
      userLogout()
        .then(() => {
          commit('logout', msg);
          cleanLocalStorage();
          commit('loaded_true');
          router.push({ name: 'Login' });
        })
        .catch((err?: FirebaseError) => {
          console.log(err?.message);
        });
    }
  },
  async logoutToken({ commit }, { msg } = { msg: '' }) {
    if (state.status.loggedIn && !state.status.loggingOut) {
      commit(AccountMutations.logging_out);
      deleteHeaderToken();

      commit('logoutToken', msg);
      cleanLocalStorage();
      commit('loaded_true');
      router.push({ name: 'Login' });
    }
  },
  async langChange(_, { lang }) {
    localStorage.setItem('locale', lang);
    await setUserLang(lang);
  },
  async getUserProfile({ commit }) {
    const userProfile = await getProfile();
    if (userProfile === undefined) {
      throw new Error('user_is_not_found');
    }
    commit(AccountMutations.login_set_user, userProfile);
  },
  async updateProfile({ commit }, data) {
    try {
      const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        pharmacistPin: data.pharmacistPin
      };

      await updateProfile(payload);
      commit(AccountMutations.update_admin_profile, { payload });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async changePasswordAdminProfile(_, data) {
    try {
      await changePasswordAdminProfile(data);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getAuthenticationSignicat({ commit }, { businessId, deliveryId }) {
    try {
      const authenticationSignicat: AuthenticationSignicat | undefined =
        await getAuthenticationSignicat(businessId, deliveryId);

      commit(AccountMutations.authentication_signicat, {
        authenticationSignicat
      });
    } catch (error: unknown) {
      const axiosError = error as AxiosError;
      commit(AccountMutations.login_error, axiosError.message);
      cleanLocalStorage();
    }
  },
  async authorizeUser({ commit }, data) {
    try {
      const payload = {
        verificationData: data.verificationData
      };

      const resultPickupPin = await authorizeUser(payload);

      commit(AccountMutations.result_pickup_pin, {
        resultPickupPin
      });
    } catch (error: unknown) {
      const axiosError = error as AxiosError;
      commit(AccountMutations.login_error, axiosError.message);
      cleanLocalStorage();
    }
  },
  async authorizeRegisterUser({ commit }, data) {
    try {
      const payload = {
        verificationData: data.verificationData
      };

      const resultPickupPin = await authorizeRegisterUser(payload);

      commit(AccountMutations.result_pickup_pin, {
        resultPickupPin
      });
    } catch (error: unknown) {
      const axiosError = error as AxiosError;
      commit(AccountMutations.login_error, axiosError.message);
      cleanLocalStorage();
    }
  },
  async authorizeUserVieStrongAuthRedirect({ commit }, data) {
    try {
      const payload = {
        verificationData: data.verificationData
      };

      const resultPickupPinStrongRedirect =
        await authorizeUserVieStrongAuthRedirect(payload);

      commit(AccountMutations.result_pickup_pin_strong_redirect, {
        resultPickupPinStrongRedirect
      });
    } catch (error: unknown) {
      const axiosError = error as AxiosError;
      commit(AccountMutations.login_error, axiosError.message);
      cleanLocalStorage();
    }
  },
  async changeLang(_, data) {
    try {
      const payload = {
        appLanguage: data.lang
      };

      await changeLang(payload);
      localStorage.setItem('locale', data.lang);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  }
};
