
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import router from '@/router';

import { Option } from '@/types';
import { PharmacyOption } from '@/store/modules/users/types';

import { goToPickupBox } from '@/utils/goToPickupBox';
import { goToEditPharmacy } from '@/utils/goToEditPharmacy';

@Options({
  name: 'ChangePharmacy',
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      selectedPharmacy: {
        value: '',
        label: ''
      },
      pharmacyOptions: [],
      pharmacyLoading: true,
      allPharmacies: [],
      activePharmacy: null,
      pharmacies: {}
    };
  },
  created() {
    this.show();
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('pharmacy', ['pharmacy']),
    ...mapState('users', ['users', 'pharmacyAccesses']),
    ...mapState('entityNames', ['entityNames'])
  },
  methods: {
    ...mapActions('users', ['getPharmacyAccesses', 'updatePharmacyAccess']),
    ...mapActions('entityNames', ['getEntityNames']),
    ...mapActions('account', ['loginToPharmacy', 'logoutToken']),
    ...mapActions('pharmacy', ['getPharmacy']),
    async show() {
      this.loading = true;

      await this.getEntityNames();
      this.pharmacies = this.entityNames.pharmacies;

      this.initPharmacyOptions();
      this.loading = false;
    },
    getEntityName(name: string, entities: Record<string, unknown>) {
      const entity = Object.entries(entities).find((item) =>
        item[0].includes(name)
      );

      return entity ? entity[1] : null;
    },
    initPharmacyOptions() {
      const pharmaciesData = this.$route.query.data;

      this.pharmacyOptions = pharmaciesData.map((pharmacy: PharmacyOption) => ({
        value: pharmacy,
        label: this.getEntityName(pharmacy, this.pharmacies),
        img: require('@/assets/icons/Apoteket.svg')
      }));
    },
    selectPharmacy(option: Option) {
      this.selectedPharmacy = option;

      this.activePharmacy = this.allPharmacies.find(
        (pharmacy: PharmacyOption) => pharmacy.id === option.value
      );
    },
    async goToLockers() {
      const data = {
        pharmacyId: this.selectedPharmacy.value
      };

      this.loading = true;

      try {
        await this.updatePharmacyAccess(data);
        await this.loginToPharmacy();
        this.getTabs();

        localStorage.setItem('id', this.selectedPharmacy.value);
        this.$i18n.locale = localStorage.getItem('locale');
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    async getTabs() {
      this.loading = true;
      this.pharmacyLoading = true;

      await this.getPharmacy(this.user?.pharmacyId);

      this.boxId = this.pharmacy?.pickupBoxes[0];
      this.pharmacyLoading = false;

      if (!this.pharmacyLoading && this.user) {
        if (this.pharmacy?.remomediServices.pickupbox && this.boxId) {
          return goToPickupBox(
            this.user?.pharmacyId,
            this.pharmacy?.pickupBoxes[0]
          );
        } else {
          return this.pharmacy?.remomediServices.mobileApp
            ? router.push({ name: 'CallerAuthentication' })
            : goToEditPharmacy(this.user.pharmacyId);
        }
      }

      this.loading = false;
    },
    async backTo() {
      await this.logoutToken();
    }
  }
})
export default class ChangePharmacy extends Vue {}
