import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/icons/CloseInput.svg'


const _hoisted_1 = {
  key: 1,
  class: "tooltiptext"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      input: true,
      additionalInput: _ctx.$route.name === 'EditPharmacy',
      tootip:
        !_ctx.user?.role.permissions.pharmacy.update &&
        _ctx.$route.name === 'EditPharmacy'
    })
  }, [
    _createVNode(_component_FormKit, {
      type: _ctx.type,
      name: _ctx.name,
      id: _ctx.id,
      label: _ctx.label,
      value: _ctx.value,
      validation: _ctx.validation,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      preserve: _ctx.preserve,
      autocomplete: _ctx.autocomplete,
      forName: _ctx.forName,
      validationMessages: _ctx.validationMessages,
      accept: _ctx.accept,
      max: _ctx.max,
      min: _ctx.min,
      step: _ctx.step,
      onkeydown: _ctx.onkeydown,
      onChange: _cache[0] || (_cache[0] = (e) => _ctx.$emit('change', e))
    }, null, 8, ["type", "name", "id", "label", "value", "validation", "placeholder", "disabled", "preserve", "autocomplete", "forName", "validationMessages", "accept", "max", "min", "step", "onkeydown"]),
    (
        _ctx.$route.name === 'EditPharmacy' &&
        _ctx.name !== 'name' &&
        _ctx.name !== 'email' &&
        _ctx.name !== 'phoneNumber' &&
        _ctx.name !== 'pinCode' &&
        _ctx.name !== 'deliveryKeepTime' &&
        _ctx.name !== 'deliveryReminderMessagePeriod' &&
        _ctx.name !== 'pickupbox' &&
        _ctx.name !== 'homeDelivery' &&
        _ctx.name !== 'pharmacyPickup' &&
        _ctx.name === 'closed'
      )
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: _normalizeClass({
        closeInput: !_ctx.user?.role.permissions.pharmacy.update
      }),
          src: _imports_0,
          alt: "Close Input",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeInput', _ctx.id)))
        }, null, 2))
      : _createCommentVNode("", true),
    (
        !_ctx.user?.role.permissions.pharmacy.update &&
        _ctx.$route.name === 'EditPharmacy'
      )
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('enoughPermissions')), 1))
      : _createCommentVNode("", true)
  ], 2))
}