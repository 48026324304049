
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import AddDescription from './AddDescription/AddDescription.vue';
import OpenQRCode from '@/duplicatePages/OpenQRCode/OpenQRCode.vue';
import PickedUpDeleteReservation from './PickedUpDeleteReservation/PickedUpDeleteReservation.vue';
import InfoReservation from './InfoReservation/InfoReservation.vue';

import { searchByOptions, searchActions, columns } from './configs';
import { InputFileEvent, Option } from '@/types';
import {
  DataReservations,
  Delivery,
  DeliveryDefaultParams,
  PickupBox,
  Reservation
} from '@/store/modules/pharmacy/types';

import { format } from 'date-fns';

@Options({
  name: 'DeliveryHistory',
  emits: ['pickedUpdeletedOrRemovedRequest', 'getDecodedSsn'],
  components: {
    AddDescription,
    OpenQRCode,
    PickedUpDeleteReservation,
    Loading,
    InfoReservation
  },
  data() {
    return {
      selectedReservations: {
        selectedOption: [],
        options: {}
      },
      getOrderStatus: {
        selectedOption: {
          value: 'all',
          label: this.$t('allUpperCase')
        },
        options: searchByOptions(this.$t)
      },
      getSearchBy: {
        selectedOption: {
          value: 'phoneNumber',
          label: this.$t('mobilePhone')
        },
        options: searchActions(this.$t)
      },
      search: '',
      activeDelivery: null,
      idActiveDelivery: '',
      activePickupBox: null,
      activePickupBoxes: null,
      columns: columns(this.$t),
      showModal: false,
      showModalReservation: false,
      showQRCode: false,
      showModalDelete: false,
      idDelivery: '',
      dataPickedUpRequest: {},
      loading: false,
      params: null,
      deliveryHistoryList: {},
      deliveryHistoryData: {},
      defaultParams: null,
      boxId: '',
      updateListRequest: false,
      showDecodedSsn: '',
      changeDateParams: {
        target: {
          name: '',
          value: ''
        }
      }
    };
  },
  created() {
    this.loadPickupBoxes();
  },
  computed: {
    ...mapState('pharmacy', [
      'pharmacy',
      'pickupBoxes',
      'deliveryHistory',
      'decodedSsn'
    ]),
    today() {
      const dateString = format(new Date(), 'dd/MM/yyyy');

      return dateString;
    },
    firstDay() {
      const firstDate = format(new Date(), 'dd/MM/yyyy').split('/');
      firstDate.splice(0, 1, '01');

      return firstDate.join('/');
    },
    isDateCorrect() {
      if (this.isDatePresent) {
        return this.params.endTime >= this.params.startTime;
      }

      return true;
    },
    isDatePresent() {
      return this.params && this.params.startTime && this.params.endTime;
    }
  },
  updated() {
    if (this.pickupBoxes) {
      this.selectedReservations.selectedOption =
        this.pickupBoxAndPharmacyOptions;
    }
  },
  methods: {
    ...mapActions('pharmacy', [
      'getPickupBoxesByPharmacy',
      'getDeliveryHistory',
      'deliveryDeletedOrRemovedPickup',
      'getDeliveryShowDecodedSsn'
    ]),
    async loadPickupBoxes() {
      this.loading = true;

      const { id } = this.$route.params;
      await this.getPickupBoxesByPharmacy(id);

      this.loading = false;

      this.initPickupBoxOptions();
      this.initRequest();
    },
    initPickupBoxOptions() {
      this.pickupBoxOptions = this.pickupBoxes.map((pickupBox: PickupBox) => ({
        value: pickupBox.info.id,
        label: pickupBox.info.name,
        img: require('@/assets/icons/PickupBox.svg')
      }));

      const { id } = this.$route.params;

      const pharmacyReservations = {
        value: id,
        label: this.$t('pharmacyReservations')
      };

      this.selectedReservations.options = {
        pharmacyReservations,
        ...this.pickupBoxOptions
      };

      this.pickupBoxAndPharmacyOptions = [
        ...this.pickupBoxOptions,
        pharmacyReservations
      ];

      this.selectedReservations.selectedOption =
        this.pickupBoxAndPharmacyOptions;
    },
    initRequest() {
      const startTime = this.firstDay.split('/');

      this.params = {
        startTime: new Date(
          +startTime[2],
          startTime[1] - 1,
          +startTime[0]
        ).valueOf(),
        endTime: new Date(Date.now()).valueOf()
      };
    },
    show() {
      const mapBox = this.pickupBoxAndPharmacyOptions.map(
        (i: PickupBox) => i.id || i.value
      );

      mapBox.forEach((value: string) => {
        this.defaultParams = {
          boxId_or_pharmacyId: value,
          ...this.params
        };

        this.getDeliveryHistoryList(this.defaultParams);
      });
    },
    async getDeliveryHistoryList(defaultParams: DeliveryDefaultParams) {
      if (
        defaultParams.boxId_or_pharmacyId &&
        defaultParams.startTime &&
        defaultParams.endTime &&
        this.isDateCorrect
      ) {
        this.loading = true;

        await this.getDeliveryHistory(this.defaultParams);

        this.deliveryHistoryData = this.deliveryHistory;

        this.deliveryHistoryList = Object.values(
          this.deliveryHistoryData
        ).flat();

        this.deliveryHistoryList.sort((a: Delivery, b: Delivery) => {
          return b.reservedTime - a.reservedTime;
        });

        this.loading = false;
      }
    },
    changeDateStartTime(e: InputFileEvent) {
      const { name, value } = e.target;

      const dateNumbers = value.split('/');
      const date = `${dateNumbers[1]}/${dateNumbers[0]}/${dateNumbers[2]}`;

      this.params = {
        ...this.params,
        [name]:
          new Date(date).valueOf() + new Date(date).getTimezoneOffset() * -60000
      };
    },
    changeDateEndTime(e: InputFileEvent) {
      let { name, value } = e.target;

      const dateNumbers = value.split('/');

      const date = new Date(
        +dateNumbers[2],
        +dateNumbers[1] - 1,
        +dateNumbers[0]
      );

      const timestamp = date.setHours(23, 59, 60, 999);

      this.params = {
        ...this.params,
        [name]: timestamp - 1000
      };
    },
    selectReservations(option: readonly Option[]) {
      this.selectedReservations.selectedOption = option;

      const selectedReservations = this.selectedReservations.selectedOption.map(
        (option: Option) => option.value
      );

      const filteredHistory = Object.fromEntries(
        Object.entries(this.deliveryHistoryData).filter(([key]) =>
          selectedReservations.includes(key)
        )
      );

      this.deliveryHistoryList = Object.values(filteredHistory).flat();

      this.deliveryHistoryList.sort((a: Delivery, b: Delivery) => {
        return b.reservedTime - a.reservedTime;
      });
    },
    changeOrderStatus(option: readonly Option[]) {
      this.getOrderStatus.selectedOption = option;

      this.deliveryHistoryList = [
        ...(Object.values(this.deliveryHistory) as DataReservations[]).flat()
      ].filter(
        (item: DataReservations) =>
          item.deliveryStatus === this.getOrderStatus.selectedOption.value
      );

      if (this.getOrderStatus.selectedOption.value === 'all') {
        this.show();
      }
    },
    changeInput(e: InputFileEvent) {
      const { value } = e.target;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (value === '') {
          this.deliveryHistoryList = Object.values(
            this.deliveryHistoryData
          ).flat();

          this.deliveryHistoryList.sort((a: Delivery, b: Delivery) => {
            return b.reservedTime - a.reservedTime;
          });
        } else if (this.getSearchBy.selectedOption.value === 'phoneNumber') {
          this.deliveryHistoryList = [
            ...(
              Object.values(this.deliveryHistory) as DataReservations[]
            ).flat()
          ].filter((item: DataReservations) =>
            item.phoneNumber.toLowerCase().includes(value?.toLowerCase())
          );
        } else if (this.getSearchBy.selectedOption.value === 'deliveryId') {
          this.deliveryHistoryList = [
            ...(
              Object.values(this.deliveryHistory) as DataReservations[]
            ).flat()
          ].filter((item: DataReservations) =>
            item.deliveryId.toLowerCase().includes(value?.toLowerCase())
          );
        }
      }, 600);
    },
    changeSearchBy(option: readonly Option[]) {
      this.getSearchBy.selectedOption = option;

      this.deliveryHistoryList = [
        ...(Object.values(this.deliveryHistory) as DataReservations[]).flat()
      ].filter((item: DataReservations) =>
        (
          item[
            this.getSearchBy.selectedOption.value as keyof DataReservations
          ] as string
        )
          ?.toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
    async pickedUpdeletedOrRemovedRequest(deliveryId: string) {
      const { id } = this.$route.params;

      const payload = {
        pharmacyId: id,
        deliveryId: deliveryId,
        pickupboxId: this.dataPickedUpRequest.pickupboxId,
        reservedTime: this.dataPickedUpRequest.reservedTime
      };

      await this.deliveryDeletedOrRemovedPickup(payload);

      this.showModalDelete = false;

      this.show();
      this.loadPickupBoxes();
    },
    addDescription(id: string) {
      const deliveryHistoryWhitIdBox = Object.entries(this.deliveryHistory)
        .map(([boxId, reservations]: [string, unknown]) =>
          (reservations as Delivery[]).map((reservation: Delivery) => ({
            ...reservation,
            boxId: boxId
          }))
        )
        .flat();

      this.activeDelivery = deliveryHistoryWhitIdBox.find(
        (i: Delivery) => i.deliveryId === id
      );

      this.activePickupBox = this.pickupBoxes.find((pickupBox: PickupBox) =>
        pickupBox.reservations
          .map((i: Reservation) => i.deliveryId)
          .includes(this.activeDelivery.deliveryId)
      );

      this.boxId = this.activeDelivery.boxId;

      this.showModal = true;
    },
    async getDecodedSsn(deliveryId: string) {
      const deliveryHistoryWhitIdBox = Object.entries(this.deliveryHistory)
        .map(([boxId, reservations]: [string, unknown]) =>
          (reservations as Delivery[]).map((reservation: Delivery) => ({
            ...reservation,
            boxId: boxId
          }))
        )
        .flat();

      this.activeDelivery = deliveryHistoryWhitIdBox.find(
        (i: Delivery) => i.deliveryId === deliveryId
      );

      this.activePickupBox = this.pickupBoxes.find((pickupBox: PickupBox) =>
        pickupBox.reservations
          .map((i: Reservation) => i.deliveryId)
          .includes(this.activeDelivery.deliveryId)
      );

      const params = {
        docId: this.activeDelivery.docId,
        deliveryId: this.activeDelivery.deliveryId,
        reservedTime: this.activeDelivery.reservedTime
      };

      await this.getDeliveryShowDecodedSsn(params);
      this.showDecodedSsn = this.decodedSsn.ssn;
    },
    openQRCode(id: string) {
      this.activeDelivery = [
        ...(Object.values(this.deliveryHistory) as DataReservations[])
      ].find((i: DataReservations) => i.deliveryId === id);

      this.idActiveDelivery = this.activeDelivery.deliveryId;

      this.showQRCode = true;
    },
    updateList() {
      this.updateListRequest = !this.updateListRequest;
    },
    infoReservation(deliveryId: string) {
      this.activeDelivery = this.deliveryHistoryList.find(
        (i: Delivery) => i.deliveryId === deliveryId
      );
      this.showModalReservation = true;
    }
  },
  watch: {
    'selectedReservations.selectedOption': {
      immediate: true,
      handler(option: (Option | string)[]) {
        this.pickupBoxAndPharmacyOptions = option;
      }
    },
    params() {
      if (this.isDatePresent && this.isDateCorrect) {
        this.initPickupBoxOptions();
        this.show(this.params);
      }
    },
    updateListRequest() {
      this.loadPickupBoxes();
    },
    showModal() {
      this.showDecodedSsn = '';
    },
    showModalReservation() {
      this.showDecodedSsn = '';
    }
  }
})
export default class DeliveryHistory extends Vue {}
