import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "addUser" }
const _hoisted_2 = { class: "addUser__header" }
const _hoisted_3 = { class: "addUser__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Title = _resolveComponent("Title")!
  const _component_DisableAutocomplete = _resolveComponent("DisableAutocomplete")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Link, {
          title: _ctx.$t('backToUsers'),
          onBackTo: _ctx.backTo
        }, null, 8, ["title", "onBackTo"]),
        _createVNode(_component_Title, {
          title: _ctx.$t('addUser')
        }, null, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FormKit, {
          type: "form",
          id: "addUser-form",
          modelValue: _ctx.formData,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData) = $event)),
          actions: false,
          config: _ctx.validationRules,
          onSubmit: _ctx.submitHandler
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DisableAutocomplete),
            _createVNode(_component_Input, {
              name: "email",
              label: _ctx.$t('email'),
              type: _ctx.fieldTypes.email,
              validation: "required|email",
              validationMessages: {
            required: _ctx.$t('validation.required'),
            email: _ctx.$t('validation.email')
          },
              "data-testid": "inputEmail"
            }, null, 8, ["label", "type", "validationMessages"]),
            _createVNode(_component_Dropdown, {
              class: "addUser__body-dropdown",
              label: _ctx.$t('role'),
              selectedOption: _ctx.userRoleDropdown.selectedOption,
              options: _ctx.userRoleDropdown.options,
              onSelectOption: _ctx.selectUser,
              "data-testid": "dropdownUserRole"
            }, null, 8, ["label", "selectedOption", "options", "onSelectOption"]),
            _createVNode(_component_Checkbox, {
              class: "addUser__body-checkbox",
              name: "webToolAccess",
              value: _ctx.formData.webToolAccess,
              title: _ctx.$t('webToolAccess'),
              onChange: _ctx.selectWebToolAccess
            }, null, 8, ["value", "title", "onChange"]),
            _createVNode(_component_Checkbox, {
              class: "addUser__body-checkbox",
              name: "boxAccess",
              value: _ctx.formData.boxAccess,
              title: _ctx.$t('boxAccess'),
              onChange: _ctx.selectBoxAccess
            }, null, 8, ["value", "title", "onChange"])
          ]),
          _: 1
        }, 8, ["modelValue", "config", "onSubmit"])
      ])
    ]),
    _createVNode(_component_Footer, {
      onCleanForm: _ctx.cleanForm,
      onSubmitForm: _ctx.submitForm
    }, null, 8, ["onCleanForm", "onSubmitForm"])
  ], 64))
}