import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { UsersState } from '@/store/modules/users/types';
import { getters } from '@/store/modules/users/getters';
import { mutations } from '@/store/modules/users/mutations';
import { actions } from '@/store/modules/users/actions';

export const state: UsersState = {
  status: {
    loaded: false,
    sidebar: true,
    error: null,
    message: null,
    pageSize: 20,
    lastKey: null
  },
  users: [],
  user: null,
  loginInfo: [],
  pharmacyAccesses: [],
  pharmacyAccessesId: ''
};

export const users: Module<UsersState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
