
import { Vue, Options } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import 'firebase/compat/auth';

import Logout from '@/pages/Auth/Logout.vue';
import Loading from '@/views/Loading.vue';

import { routesConfig } from './configs';
import { Params } from '@/store/modules/users/types';
import { CallbackRequestsData } from '@/store/modules/callerAuthentication/types';

import { goToPickupBox } from '@/utils/goToPickupBox';
import { goToEditPharmacy } from '@/utils/goToEditPharmacy';

@Options({
  components: {
    Logout,
    Loading
  },
  data() {
    return {
      loading: false,
      routesConfig,
      pharmacyName: '',
      isPending: false
    };
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('pharmacy', ['pharmacy']),
    ...mapState('callerAuthentication', ['callbackRequests'])
  },
  created() {
    this.show();
  },
  methods: {
    ...mapActions('account', ['getUserProfile']),
    ...mapActions('pharmacy', ['getPharmacy']),
    ...mapActions('callerAuthentication', ['getCallbackRequests']),
    async show() {
      this.loading = true;

      if (
        window.location.pathname !== '/' &&
        window.location.pathname !== '/auth/login' &&
        window.location.pathname !== '/payments/success' &&
        window.location.pathname !== '/payments/failure' &&
        window.location.pathname !== '/link/apps' &&
        window.location.pathname !== '/authentication/register/verified' &&
        window.location.pathname !== '/verified' &&
        window.location.pathname !== '/authentication/re-register/verified' &&
        window.location.pathname !== '/auth/changePharmacy' &&
        window.location.pathname !== '/auth/forgotPassword' &&
        window.location.pathname !== '/404' &&
        window.location.pathname !== '/locationNotAllowed' &&
        window.location.pathname !== '/:pathMatch(.*)*' &&
        window.location.pathname !== '/pickup-pin-qr' &&
        !window.location.pathname.startsWith('/link/pharmacy-id/') &&
        !/^\/verify\/[^/]+\/[^/]+$/.test(window.location.pathname)
      ) {
        const userRole = this.user?.role.name;

        await this.getUserProfile();

        if (userRole !== 'janitor') {
          await this.getPharmacy(this.user.pharmacyId);
        }
      }

      this.loading = false;
    },
    async getNewCallbackRequests(params: Params) {
      let defaultParams = {
        lastTimestamp: this.lastTimestamp,
        pharmacyId: this.pharmacy.id
      };

      if (params) {
        defaultParams = {
          ...defaultParams,
          ...(params.lastTimestamp !== undefined && {
            lastTimestamp: params.lastTimestamp
          }),
          ...(params.pharmacyId !== undefined && {
            pharmacyId: params.pharmacyId
          })
        };
      }

      const userRole = this.user?.role.name;

      if (userRole !== 'janitor') {
        await this.getCallbackRequests(defaultParams);

        const hasPending = this.callbackRequests.some(
          (item: CallbackRequestsData) => item.status === 'pending'
        );

        if (hasPending) {
          return (this.isPending = true);
        }
      }
    },
    showElementsSideBar(name: string) {
      const { remomediServices } = this.pharmacy || {};

      const boxId = this.pharmacy?.pickupBoxes[0];
      const userRole = this.user?.role.name;
      const isJanitor = 'janitor';

      const conditions: Record<string, unknown> = {
        customersFeedback:
          !remomediServices?.feedback || userRole === isJanitor,
        callerAuthentication:
          !remomediServices?.mobileApp || userRole === isJanitor,
        authenticationHistory:
          !remomediServices?.mobileApp || userRole === isJanitor,
        ordersHistory: !remomediServices?.mobileApp || userRole === isJanitor,
        callbackRequests:
          !remomediServices?.mobileApp || userRole === isJanitor,
        pickupBoxes:
          !remomediServices?.pickupbox || !boxId || userRole === isJanitor,
        users: userRole !== 'main_user' || userRole === isJanitor
      };

      return conditions[name] ?? false;
    },
    goTo(name: string) {
      if (name === 'PickupBoxes') {
        goToPickupBox(this.user?.pharmacyId, this.pharmacy?.pickupBoxes[0]);
      }
    },
    goToPickupBox,
    goToEditPharmacy,
    downloadPDF() {
      const pdfUrl = '/Remomedi Hub käyttöohje.pdf';

      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'Remomedi Hub käyttöohje.pdf');
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }
  },
  watch: {
    pharmacy() {
      if (this.pharmacy) {
        this.pharmacyName = this.pharmacy?.name;

        this.getNewCallbackRequests();
      }
    }
  }
})
export default class Sidebar extends Vue {}
