
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'IssuePayment',
  props: ['issuePayment'],
  data() {
    return {
      issuePaymentFormData: {
        paymentInfo: {
          reference: '',
          totalSum: 0.01,
          comment: ''
        }
      },
      openButton: false,
      totalSumIncorrect: false
    };
  },
  methods: {
    emitFormData() {
      this.$emit('update:issue-payment-form-data', this.issuePaymentFormData);
    }
  },
  watch: {
    issuePaymentFormData() {
      this.emitFormData();

      if (+this.issuePaymentFormData.paymentInfo.totalSum < 0.01) {
        this.totalSumIncorrect = true;
      } else {
        this.totalSumIncorrect = false;
      }
    }
  }
})
export default class IssuePayment extends Vue {}
