import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "infoPickupBox" }
const _hoisted_2 = {
  key: 2,
  class: "infoPickupBox__notActivePickupBox"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_ActiveBox = _resolveComponent("ActiveBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.activePickupBox)
      ? (_openBlock(), _createBlock(_component_Dropdown, {
          key: 1,
          class: "infoPickupBox-dropdown",
          selectedOption: _ctx.selectedPickupBox,
          options: _ctx.pickupBoxOptions,
          onSelectOption: _ctx.changePickupBox,
          "data-testid": "dropdownPickupBox"
        }, null, 8, ["selectedOption", "options", "onSelectOption"]))
      : _createCommentVNode("", true),
    (!_ctx.activePickupBox)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('noPickupBox')), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ActiveBox, { activePickupBox: _ctx.activePickupBox }, null, 8, ["activePickupBox"])
  ]))
}