import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "editPickupBoxPage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Title = _resolveComponent("Title")!
  const _component_PickupBoxForm = _resolveComponent("PickupBoxForm")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_Link, {
        title: _ctx.$t('backToInfoPickupBox'),
        onBackTo: _ctx.backTo
      }, null, 8, ["title", "onBackTo"]),
      _createVNode(_component_Title, {
        title: _ctx.$t('editPickupBox')
      }, null, 8, ["title"]),
      _createVNode(_component_FormKit, {
        type: "form",
        id: "pickupBox-form",
        modelValue: _ctx.formData,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData) = $event)),
        actions: false,
        onSubmit: _ctx.submitHandler
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PickupBoxForm, {
            activeBox: _ctx.formData.activeBox,
            onSelectBox: _ctx.selectBox
          }, null, 8, ["activeBox", "onSelectBox"])
        ]),
        _: 1
      }, 8, ["modelValue", "onSubmit"])
    ]),
    _createVNode(_component_Footer, {
      onCleanForm: _ctx.cleanForm,
      onSubmitForm: _ctx.submitForm
    }, null, 8, ["onCleanForm", "onSubmitForm"])
  ], 64))
}