import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "showSettings" }
const _hoisted_2 = { class: "showSettings__positions" }
const _hoisted_3 = { class: "title-positions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Checkbox, {
      name: "show",
      title: _ctx.$t('showUpperCase'),
      value: _ctx.value,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectOption(_ctx.value)))
    }, null, 8, ["title", "value"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('position')), 1),
      _createVNode(_component_Dropdown, {
        class: "dropdown-positions",
        selectedOption: _ctx.selectedPositions.selectedOption,
        options: _ctx.selectedPositions.options,
        onSelectOption: _ctx.selectPositions
      }, null, 8, ["selectedOption", "options", "onSelectOption"])
    ])
  ]))
}