
import { Options, Vue } from 'vue-class-component';
import { mapActions } from 'vuex';

import moment from 'moment';

import DeleteReservation from './DeleteReservation/DeleteReservation.vue';
import ReserveLocker from '@/duplicatePages/ReserveLocker/ReserveLocker.vue';
import ChooseDelivery from './ChooseDelivery/ChooseDelivery.vue';
import ResendSMS from '@/duplicatePages/ResendSMS/ResendSMS.vue';
import OpenQRCode from '@/duplicatePages/OpenQRCode/OpenQRCode.vue';

import Loading from '@/views/Loading.vue';

import { columns } from './configs';

import { OrderData } from '@/store/modules/pharmacy/types';
import { downloadCSV } from '@/utils/downloadCSV';
import { goToPickupBox } from '@/utils/goToPickupBox';

@Options({
  name: 'Order',
  emits: [
    'deleteReservationRequest',
    'getDecodedSsn',
    'destroyReservationRequest'
  ],
  props: ['order', 'activePickupBox', 'activeLocker', 'showDecodedSsn'],
  components: {
    DeleteReservation,
    ReserveLocker,
    ChooseDelivery,
    ResendSMS,
    OpenQRCode,
    Loading
  },
  data() {
    return {
      columns: columns(this.$t),
      showModal: false,
      showModalReserveLocker: false,
      showModalChooseDelivery: false,
      showModalResendSMS: false,
      openDataSsn: false,
      activeBoxId: '',
      dataSsn: '',
      deliveryId: '',
      loading: false,
      showQRCode: false,
      idActiveDelivery: ''
    };
  },
  computed: {
    isLastTime() {
      return this.order.resendSMSTimestamps[
        this.order.resendSMSTimestamps.length - 1
      ].sendTime;
    }
  },
  methods: {
    ...mapActions('pharmacy', [
      'deleteReservation',
      'getPickupBoxesByPharmacy',
      'destroyReservation'
    ]),
    showSsn() {
      this.$emit('getDecodedSsn');
    },
    getDataSSN() {
      this.dataSsn = this.showDecodedSsn;

      if (this.showDecodedSsn) {
        this.dataSsn = this.showDecodedSsn.ssn;

        return this.dataSsn ? this.dataSsn : this.$t('noSsn');
      } else {
        return '************';
      }
    },
    hideButton(order: OrderData) {
      if (order.deliveryStatus === 'expired' && order.deliveryTime) {
        return false;
      } else if (
        order.status !== 'inactive' &&
        order.deliveryStatus !== 'removed'
      ) {
        return true;
      } else {
        return false;
      }
    },
    getIsDrud(value: boolean) {
      return value ? `${this.$t('yes')}` : `${this.$t('no')}`;
    },
    dateTime(value: number) {
      return moment(value).format('DD.MM.YYYY HH:mm');
    },
    getIds(deliveryId: string, additionalDeliveryIds: Array<string>) {
      if (!additionalDeliveryIds[0] && !additionalDeliveryIds[1]) {
        return deliveryId;
      } else if (additionalDeliveryIds[1] !== '') {
        return deliveryId + ', ' + additionalDeliveryIds.join(', ');
      } else if (additionalDeliveryIds[1] === '') {
        return deliveryId + ', ' + additionalDeliveryIds.join('');
      }
    },
    getCSV(): void {
      const data = [this.order].map((el: OrderData) => ({
        deliveryId: this.getIds(el.deliveryId, el.additionalDeliveryIds),
        deliveryMadeBy: el.deliveryMadeBy,
        phoneNumber: el.phoneNumber,
        reservedTime: this.dateTime(el.reservedTime),
        assignedTime: this.dateTime(el.assignedTime)
      }));

      const columns = [...this.columns];

      downloadCSV(data, columns, `${this.$t('order')}.csv`);
    },
    deleteThisReservation() {
      this.showModal = true;
    },
    async deleteReservationRequest() {
      this.loading = true;

      await this.deleteReservation({
        boxId: this.activePickupBox.id,
        deliveryId: this.order.deliveryId
      });

      this.showModal = false;

      const { id } = this.$route.params;

      goToPickupBox(id, this.activePickupBox.id);
      await this.getPickupBoxesByPharmacy(id);

      this.loading = false;
    },
    async destroyReservationRequest() {
      const { id } = this.$route.params;

      const payload = {
        pharmacyId: id,
        deliveryId: this.order.deliveryId,
        pickupboxId: this.activePickupBox.id
      };

      await this.destroyReservation(payload);
      this.showModal = false;

      goToPickupBox(id, this.activePickupBox.id);
      await this.getPickupBoxesByPharmacy(id);

      this.loading = false;
    },
    reserve() {
      this.showModalReserveLocker = true;
    },
    resendSMS() {
      this.deliveryId = this.order.deliveryId;
      this.activeBoxId = this.activePickupBox.id;

      this.showModalResendSMS = true;
    },
    assignReservation() {
      if (this.activePickupBox?.info.active) {
        this.activeBoxId = this.activePickupBox.id;
        this.showModalChooseDelivery = true;
      } else {
        this.showModalChooseDelivery = false;
      }
    },
    getVariant() {
      if (this.order.deliveryStatus === 'expired' && !this.order.deliveryTime) {
        return 'redBorderedBackground';
      } else if (
        this.order.deliveryStatus === 'expired' &&
        this.order.deliveryTime
      ) {
        return '';
      }

      if (
        !this.activePickupBox?.info.active &&
        this.order.deliveryStatus !== 'reserved'
      ) {
        return 'disabled';
      }

      if (this.order.deliveryStatus === 'reserved') {
        return 'redBorderedBackground';
      } else if (
        !this.order.deliveryStatus ||
        this.order.status !== 'inactive' ||
        this.order.deliveryStatus === 'delivered'
      ) {
        return 'filled';
      } else {
        return '';
      }
    },
    getTitle() {
      if (
        this.order.deliveryStatus === 'reserved' ||
        (this.order.deliveryStatus === 'expired' && !this.order.deliveryTime)
      ) {
        return 'deleteReservation';
      } else if (
        !this.order.deliveryStatus &&
        this.order.status !== 'inactive'
      ) {
        return 'reserve';
      } else if (this.order.deliveryStatus === 'delivered') {
        return 'resendSMS';
      } else {
        return '';
      }
    },
    getActions() {
      if (
        !this.activePickupBox?.info.active &&
        this.order.deliveryStatus !== 'reserved'
      ) {
        return this.showModalReserveLocker === false;
      }

      if (this.order.deliveryStatus === 'reserved') {
        return this.deleteThisReservation();
      } else if (
        !this.order.deliveryStatus &&
        this.order.status !== 'inactive'
      ) {
        return this.reserve();
      } else if (this.order.deliveryStatus === 'delivered') {
        return this.resendSMS();
      } else if (
        this.order.deliveryStatus === 'expired' &&
        !this.order.deliveryTime
      ) {
        return this.deleteThisReservation();
      }
    },
    openQRCode() {
      this.idActiveDelivery = this.order.deliveryId;

      this.showQRCode = true;
    }
  }
  // watch: {
  //   showDecodedSsn() {
  //     if (this.showDecodedSsn) {
  //       this.dataSsn = this.showDecodedSsn;
  //     } else {
  //       this.dataSsn = this.$t('noSsn');
  //     }
  //   }
  // }
})
export default class Order extends Vue {}
