
import { Options, Vue } from 'vue-class-component';

import Loading from '@/views/Loading.vue';
import LangChange from '@/views/Language.vue';

@Options({
  name: 'AppRedirect',
  components: {
    Loading,
    LangChange
  },
  data() {
    return {
      modalActive: null,
      loading: null,
      error: false
    };
  },
  mounted() {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      // eslint-disable-next-line
      !(window as any).MSStream;
    const isAndroid = /Android/.test(navigator.userAgent);

    const linkElement = document.getElementById(
      'redirect-link'
    ) as HTMLAnchorElement;

    if (linkElement) {
      if (isIOS) {
        linkElement.href = process.env.VUE_APP_APPSTORE_LINK;
        window.location.href = process.env.VUE_APP_APPSTORE_LINK;
      } else if (isAndroid) {
        linkElement.href = process.env.VUE_APP_GOOGLE_MARKET_LINK;
        window.location.href = process.env.VUE_APP_GOOGLE_MARKET_LINK;
      } else {
        this.error = true;
      }
    } else {
      console.log('Element with id "redirect-link" not found');
    }
  }
})
export default class AppRedirect extends Vue {}
