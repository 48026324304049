
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';
import { format } from 'date-fns';

import Loading from '@/views/Loading.vue';

import { searchActions, columns, columnsDow } from './configs';
import { InputFileEvent, Option } from '@/types';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { Params } from '@/store/modules/users/types';
import { AuthenticationHistoryData } from '@/store/modules/callerAuthentication/types';

const transformationDate = new Date().setDate(new Date().getDate() - 3);

@Options({
  name: 'AuthenticationHistory',
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      search: '',
      getSearchBy: {
        selectedOption: {
          value: 'firstName',
          label: this.$t('firstName')
        },
        options: searchActions(this.$t)
      },
      dateRange: {
        startTime: format(new Date(transformationDate), 'dd/MM/yyyy'),
        endTime: format(new Date(), 'dd/MM/yyyy')
      },
      columnsDow: columnsDow(this.$t),
      columns: columns(this.$t),
      authenticationHistoryList: [],
      pharmacyId: '',
      showSSN: false,
      buttonLoaded: false
    };
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('callerAuthentication', [
      'authenticationHistory',
      'decodedSsn'
    ]),
    isDateCorrect() {
      if (this.isDatePresent) {
        const startTime = this.dateRange.startTime.split('/');
        const endTime = this.dateRange.endTime.split('/');

        let defaultParams = {
          ...this.dateRange,
          startTime: new Date(
            +startTime[2],
            startTime[1] - 1,
            +startTime[0]
          ).valueOf(),
          endTime: new Date(+endTime[2], endTime[1] - 1, +endTime[0]).valueOf()
        };

        return defaultParams.endTime >= defaultParams.startTime;
      }

      return true;
    },
    isDatePresent() {
      const startTime = this.dateRange.startTime.split('/');
      const endTime = this.dateRange.endTime.split('/');

      let defaultParams = {
        ...this.dateRange,
        startTime: new Date(
          +startTime[2],
          startTime[1] - 1,
          +startTime[0]
        ).valueOf(),
        endTime: new Date(+endTime[2], endTime[1] - 1, +endTime[0]).valueOf()
      };

      return defaultParams && defaultParams.startTime && defaultParams.endTime;
    }
  },
  created() {
    if (this.user) {
      this.pharmacyId = this.user?.pharmacyId;
      this.show();
    }
  },
  methods: {
    ...mapActions('callerAuthentication', [
      'getAuthenticationHistory',
      'getAuthenticationHistoryDecodedSsn'
    ]),
    show() {
      this.initRequest();
    },
    initRequest() {
      let startTime = this.dateRange.startTime.split('/');
      let endTime = this.dateRange.endTime.split('/');

      startTime = new Date(
        +startTime[2],
        startTime[1] - 1,
        +startTime[0]
      ).valueOf();

      const today = new Date().getDate();

      if (today === +endTime[0]) {
        endTime = new Date().valueOf();
      } else {
        endTime = new Date(
          +endTime[2],
          endTime[1] - 1,
          +endTime[0],
          23,
          59,
          59
        ).valueOf();
      }

      this.params = {
        startTime: startTime + new Date(startTime).getTimezoneOffset() * -60000,
        endTime: endTime + new Date(endTime).getTimezoneOffset() * -60000,
        pharmacyId: this.user.pharmacyId
      };

      this.getAuthenticationHistoryList(this.params);
    },
    async getAuthenticationHistoryList(params: Params) {
      if (
        params.pharmacyId &&
        params.startTime &&
        params.endTime &&
        this.isDateCorrect
      ) {
        this.loading = true;

        await this.getAuthenticationHistory(params);
        this.authenticationHistoryList = this.authenticationHistory;

        this.loading = false;
      }
    },
    async getSsn(id: string) {
      const params: Params = {
        pharmacyId: this.user?.pharmacyId,
        recordId: id
      };

      this.loading = true;

      await this.getAuthenticationHistoryDecodedSsn(params);

      const activeAuthenticationHistory = this.authenticationHistoryList.find(
        (i: AuthenticationHistoryData) => i.id === id
      );

      if (activeAuthenticationHistory) {
        activeAuthenticationHistory.ssn = this.decodedSsn.ssn;
      }

      this.loading = false;
    },
    changeDate(e: InputFileEvent) {
      const { name, value } = e.target;
      this.buttonLoaded = false;

      this.dateRange = {
        ...this.dateRange,
        [name]: value
      };

      this.initRequest();
      this.buttonLoaded = true;
    },
    closeTimePanel() {
      this.buttonLoaded = false;
    },
    changeInput(e: InputFileEvent) {
      const { value } = e.target;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (value === '') {
          this.authenticationHistoryList = this.authenticationHistory;
        } else if (this.getSearchBy.selectedOption.value === 'firstName') {
          this.authenticationHistoryList = [
            ...(
              Object.values(
                this.authenticationHistory
              ) as AuthenticationHistoryData[]
            ).flat()
          ].filter((item: AuthenticationHistoryData) =>
            item.firstName.toLowerCase().includes(value?.toLowerCase())
          );
        } else if (this.getSearchBy.selectedOption.value === 'lastName') {
          this.authenticationHistoryList = [
            ...(
              Object.values(
                this.authenticationHistory
              ) as AuthenticationHistoryData[]
            ).flat()
          ].filter((item: AuthenticationHistoryData) =>
            item.lastName.toLowerCase().includes(value?.toLowerCase())
          );
        } else if (this.getSearchBy.selectedOption.value === 'phoneNumber') {
          this.authenticationHistoryList = [
            ...(
              Object.values(
                this.authenticationHistory
              ) as AuthenticationHistoryData[]
            ).flat()
          ].filter((item: AuthenticationHistoryData) =>
            item.phoneNumber.toLowerCase().includes(value?.toLowerCase())
          );
        }
      }, 600);
    },
    changeSearchBy(option: readonly Option[]) {
      this.getSearchBy.selectedOption = option;

      this.authenticationHistoryList = [
        ...(
          Object.values(
            this.authenticationHistory
          ) as AuthenticationHistoryData[]
        ).flat()
      ].filter((item: AuthenticationHistoryData) =>
        (
          item[
            this.getSearchBy.selectedOption
              .value as keyof AuthenticationHistoryData
          ] as string
        )
          ?.toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
    dateTime(value: number) {
      if (value) {
        return moment(value).format('DD.MM.YYYY HH:mm');
      }
    },
    getPDF() {
      const data = this.authenticationHistoryList.map(
        (el: AuthenticationHistoryData) => ({
          userNAME: `${el.lastName} ${el.firstName}`,
          phoneNumber: el.phoneNumber,
          timestamp: this.dateTime(el.timestamp)
        })
      );

      const dataPDF = data.map((obj: AuthenticationHistoryData) =>
        Object.values(obj)
      );

      const columns = [...this.columnsDow].map((i: Option) => i.label);

      const doc = new jsPDF('landscape');
      autoTable(doc, {
        head: [columns],
        body: dataPDF,
        theme: 'grid',
        headStyles: { fillColor: [71, 152, 127] },
        bodyStyles: { fillColor: [255, 255, 255] },
        margin: { top: 10 }
      });

      doc.save(`${this.$t('callerAuthentication')}.pdf`);
    },
    toggleShow() {
      this.showSSN = !this.showSSN;
    }
  },
  watch: {
    user() {
      if (this.user) {
        this.pharmacyId = this.user?.pharmacyId;
        this.show();
      }
    }
  }
})
export default class AuthenticationHistory extends Vue {}
