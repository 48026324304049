import { SelectedOption } from '@/store/modules/pharmacy/types';

export const optionsPositions = [
  {
    value: 1,
    label: 1
  },
  {
    value: 2,
    label: 2
  },
  {
    value: 3,
    label: 3
  },
  {
    value: 4,
    label: 4
  },
  {
    value: 5,
    label: 5
  }
];

// eslint-disable-next-line
export function mapScreensToSelectedPositions(screens: any, name: string) {
  const nameSceen = `${name}_screen`;
  const position = screens[nameSceen].position;

  const selectedOption: SelectedOption = {
    value: position,
    label: position
  };

  return selectedOption;
}
