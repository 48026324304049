
import moment from 'moment';
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'InfoAboutActionLog',
  props: ['activeDelivery'],
  methods: {
    dateTime(value: number) {
      return moment(value).format('DD.MM.YYYY HH:mm');
    }
  }
})
export default class InfoAboutDelivery extends Vue {}
