import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/CloseModal.svg'


const _hoisted_1 = { class: "addAlarmComment" }
const _hoisted_2 = { class: "addAlarmComment__header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "addAlarmComment__body" }
const _hoisted_5 = { class: "addAlarmComment__body-description" }
const _hoisted_6 = {
  class: "label-description",
  for: "description"
}
const _hoisted_7 = { class: "addAlarmComment__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_InfoAboutAlarm = _resolveComponent("InfoAboutAlarm")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.showModal)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        onClose: _ctx.closeModalAddComment
      }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('addAlarmComment')), 1),
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModalAddComment && _ctx.closeModalAddComment(...args)))
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_InfoAboutAlarm, { activeAlarm: _ctx.activeAlarm }, null, 8, ["activeAlarm"]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_FormKit, {
                  type: "form",
                  id: "alarmComment-form",
                  modelValue: _ctx.formData,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData) = $event)),
                  actions: false,
                  onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('saveAlarmComment', _ctx.formData.alarmInfo)))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('comment')), 1),
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.alarmInfo) = $event)),
                      id: "description",
                      class: "input-description",
                      maxlength: "250"
                    }, "            ", 512), [
                      [_vModelText, _ctx.formData.alarmInfo]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Button, {
                variant: "filled",
                title: _ctx.$t('save'),
                onClick: _ctx.submitHandler
              }, null, 8, ["title", "onClick"]),
              _createVNode(_component_Button, {
                variant: "notBordered",
                title: _ctx.$t('cancel'),
                onClick: _ctx.closeModalAddComment
              }, null, 8, ["title", "onClick"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["onClose"]))
    : _createCommentVNode("", true)
}