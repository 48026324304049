import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/Error.svg'


const _hoisted_1 = { class: "lockers" }
const _hoisted_2 = { class: "lockers__header" }
const _hoisted_3 = { class: "lockers__header__inputs" }
const _hoisted_4 = {
  key: 1,
  class: "lockers__header-notPickupBox"
}
const _hoisted_5 = {
  key: 1,
  class: "tooltiptext"
}
const _hoisted_6 = {
  key: 0,
  class: "tooltiptext"
}
const _hoisted_7 = {
  key: 1,
  class: "tooltiptext"
}
const _hoisted_8 = { class: "lockers__header-actions" }
const _hoisted_9 = ["data-testid"]
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  class: "error__connection"
}
const _hoisted_12 = { class: "error__connection-messages" }
const _hoisted_13 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Toggle = _resolveComponent("Toggle")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modules = _resolveComponent("Modules")!
  const _component_QuickReservation = _resolveComponent("QuickReservation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.activePickupBox)
            ? (_openBlock(), _createBlock(_component_Dropdown, {
                key: 0,
                class: "lockers__header__inputs-dropdown",
                selectedOption: _ctx.selectedPickupBox,
                options: _ctx.pickupBoxOptions,
                onSelectOption: _ctx.changePickupBox,
                "data-testid": "dropdownLockers"
              }, null, 8, ["selectedOption", "options", "onSelectOption"]))
            : _createCommentVNode("", true),
          (!_ctx.activePickupBox)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('noPickupBox')), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass({
            selectMultipleLockers: true,
            tootip: !_ctx.activePickupBox?.info.active
          })
          }, [
            false
              ? (_openBlock(), _createBlock(_component_Toggle, {
                  key: 0,
                  title: _ctx.$t('selectMultipleLockers'),
                  value: _ctx.multipleLockers,
                  name: "multipleLockers",
                  disabled: !_ctx.activePickupBox?.info.active,
                  onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.multipleLockers = !_ctx.multipleLockers))
                }, null, 8, ["title", "value", "disabled"]))
              : _createCommentVNode("", true),
            (!_ctx.activePickupBox?.info.active)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('pickupboxIsInactive')), 1))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass({
            lockers__header__quickReservation: true,
            tootip: !_ctx.activePickupBox?.info.active || _ctx.disableButton
          })
          }, [
            _createVNode(_component_Button, {
              class: _normalizeClass({
              'lockers__header__quickReservation-button': true,
              'lockers__header__quickReservation-disabled':
                !_ctx.activePickupBox?.info.active
            }),
              variant: _ctx.getVariant(),
              title: _ctx.$t('quickReservationLockers'),
              disabled: !_ctx.activePickupBox?.info.active || _ctx.disableButton,
              onClick: _ctx.quickReservation
            }, null, 8, ["class", "variant", "title", "disabled", "onClick"]),
            (!_ctx.activePickupBox?.info.active)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('pickupboxIsInactive')), 1))
              : _createCommentVNode("", true),
            (_ctx.disableButton)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('noSpaceInPickupBoxes')), 1))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "lockers__header-actions__action",
              key: index,
              "data-testid": 'action' + index
            }, [
              _createElementVNode("img", {
                src: require(`@/assets/icons/${action.value}.svg`),
                alt: ""
              }, null, 8, _hoisted_10),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t(`${action.label}`)), 1)
            ], 8, _hoisted_9))
          }), 128))
        ])
      ]),
      (_ctx.activePickupBox?.connectionAlarmed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _cache[2] || (_cache[2] = _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('connectionLost')), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass({
        lockers__modules: _ctx.activePickupBox,
        lockers__notModules: !_ctx.activePickupBox
      })
      }, [
        _createVNode(_component_Modules, {
          modules: _ctx.modules,
          positions: _ctx.positions,
          activePickupBox: _ctx.activePickupBox,
          multipleLockers: _ctx.multipleLockers,
          onMultipleLockersFalse: _ctx.multipleLockersFalse
        }, null, 8, ["modules", "positions", "activePickupBox", "multipleLockers", "onMultipleLockersFalse"])
      ], 2)
    ]),
    (_ctx.showModalQuickReservation)
      ? (_openBlock(), _createBlock(_component_QuickReservation, {
          key: 0,
          showModalQuickReservation: _ctx.showModalQuickReservation,
          activePickupBox: _ctx.activePickupBox,
          lockersActiveBox: _ctx.lockersActiveBox,
          onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModalQuickReservation = false))
        }, null, 8, ["showModalQuickReservation", "activePickupBox", "lockersActiveBox"]))
      : _createCommentVNode("", true)
  ], 64))
}