import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/CloseModal.svg'


const _hoisted_1 = { class: "chooseDelivery" }
const _hoisted_2 = { class: "chooseDelivery__header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "chooseDelivery__body" }
const _hoisted_5 = {
  key: 1,
  class: "chooseDelivery__body-noTable"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.showModalChooseDelivery)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('chooseDelivery')), 1),
              _createElementVNode("img", {
                src: _imports_0,
                alt: "CloseModal",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.data.length)
                ? (_openBlock(), _createBlock(_component_Table, {
                    key: 0,
                    data: _ctx.data,
                    columns: _ctx.columns,
                    selectedField: "deliveryId",
                    onAssignDeliveryTable: _ctx.assignDeliveryTable
                  }, null, 8, ["data", "columns", "onAssignDeliveryTable"]))
                : _createCommentVNode("", true),
              (!_ctx.data.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('noReservations')), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_Button, {
              variant: "redBordered",
              title: _ctx.$t('cancel'),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal')))
            }, null, 8, ["title"])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}