import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "options" }
const _hoisted_2 = { class: "options__translations" }
const _hoisted_3 = { class: "options__multipleOptions" }
const _hoisted_4 = {
  key: 0,
  class: "options__multipleOptions-timeout"
}
const _hoisted_5 = { class: "timeout-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ShowSettings = _resolveComponent("ShowSettings")!
  const _component_TitleOptions = _resolveComponent("TitleOptions")!
  const _component_Input = _resolveComponent("Input")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_OptionsInputs = _resolveComponent("OptionsInputs")!
  const _component_AddOption = _resolveComponent("AddOption")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormKit, {
        type: "form",
        id: "options-form",
        modelValue: _ctx.formData,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData) = $event)),
        actions: false,
        onSubmit: _ctx.submitHandler
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ShowSettings, {
            value: _ctx.formData.show,
            selectedPosition: _ctx.formData.position,
            "onUpdate:selectedPosition": _ctx.updateSelectedPosition,
            onSelectOption: _ctx.selectShowSettings
          }, null, 8, ["value", "selectedPosition", "onUpdate:selectedPosition", "onSelectOption"]),
          _createVNode(_component_TitleOptions, {
            title: _ctx.$t('titleUpperCase')
          }, null, 8, ["title"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_FormKit, {
              type: "group",
              name: "title_text"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.translations, (translation, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "container",
                    key: index
                  }, [
                    _createElementVNode("span", null, _toDisplayString(translation.label), 1),
                    _createVNode(_component_Input, {
                      type: "text",
                      name: translation.value,
                      value: _ctx.formData.title_text[translation.value]
                    }, null, 8, ["name", "value"])
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_OptionsInputs, {
            options: _ctx.formData.options,
            user: _ctx.user
          }, null, 8, ["options", "user"]),
          _createVNode(_component_AddOption, {
            options: _ctx.formData.options,
            numberActiveOptions: _ctx.numberActiveOptions
          }, null, 8, ["options", "numberActiveOptions"]),
          _createVNode(_component_TitleOptions, {
            title: _ctx.$t('multipleOptionsUpperCase')
          }, null, 8, ["title"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_FormKit, {
              type: "group",
              name: "multiple_options"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Checkbox, {
                  class: "checkbox-multipleOptions",
                  name: "on",
                  title: _ctx.$t('multipleOptions'),
                  value: _ctx.formData.multiple_options.on,
                  onChange: _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.formData.multiple_options.on != _ctx.formData.multiple_options.on
            ))
                }, null, 8, ["title", "value"]),
                (_ctx.formData.multiple_options.on)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('timeout')), 1),
                      _createVNode(_component_Input, {
                        type: "number",
                        name: "timeout",
                        value: _ctx.formData.multiple_options.timeout,
                        step: 0.1,
                        required: ""
                      }, null, 8, ["value"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onSubmit"]),
      _createVNode(_component_Button, {
        variant: _ctx.disableSubmitForm ? 'disabled' : 'filled',
        title: _ctx.$t('save'),
        onClick: _ctx.submitForm,
        disabled: _ctx.disableSubmitForm
      }, null, 8, ["variant", "title", "onClick", "disabled"])
    ])
  ], 64))
}