export const infoRoleAndPin = [
  {
    type: 'text',
    name: 'role',
    label: 'role',
    dataTestId: 'inputRole'
  },
  {
    type: 'text',
    name: 'pharmacistPin',
    label: 'userBoxPin',
    validation: 'required|length:6,6',
    dataTestId: 'inputPharmacistPin'
  },
  {
    label: 'farmacyEntryPin',
    type: 'text',
    name: 'pinCode',
    dataTestId: 'inputpinCode'
  }
];
