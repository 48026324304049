
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { allInfoFromUser } from '@/constants';
import { Option } from '@/types';

@Options({
  name: 'AboutUser',
  emits: ['selectUserRole'],
  props: ['userRoleDropdown'],
  data() {
    return {
      allInfoFromUser
    };
  },
  computed: {
    ...mapState('users', ['user']),
    isPharmacyId() {
      if (!this.user.pharmacyId) {
        return '';
      } else {
        return this.user.pharmacyId;
      }
    }
  },
  methods: {
    selectUser(option: Option) {
      this.$emit('selectUserRole', option);
    },
    disabledInput(name: string) {
      if (name === 'email') {
        return true;
      } else {
        return false;
      }
    }
  }
})
export default class AboutUserComponent extends Vue {}
