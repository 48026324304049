import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loginPage"
}
const _hoisted_2 = {
  key: 1,
  class: "main"
}
const _hoisted_3 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      _ctx.isLogin ||
      _ctx.isForgotPassword ||
      _ctx.isChangePharmacy ||
      _ctx.isSignicatVerify ||
      _ctx.isVerifiedUser ||
      _ctx.isPickupPinQR ||
      _ctx.isAuthenticationRegister ||
      _ctx.isStrongAuthenticationRegister ||
      _ctx.isLocationNotAllowed ||
      _ctx.isPaymentFailed ||
      _ctx.isPaymentSuccess ||
      _ctx.isWelcomeToPharmacy ||
      _ctx.isAppRedirect
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_router_view)
        ]))
      : _createCommentVNode("", true),
    (
      !_ctx.isLogin &&
      !_ctx.isForgotPassword &&
      !_ctx.isChangePharmacy &&
      !_ctx.isSignicatVerify &&
      !_ctx.isVerifiedUser &&
      !_ctx.isPickupPinQR &&
      !_ctx.isAuthenticationRegister &&
      !_ctx.isStrongAuthenticationRegister &&
      !_ctx.isLocationNotAllowed &&
      !_ctx.isPaymentFailed &&
      !_ctx.isPaymentSuccess &&
      !_ctx.isWelcomeToPharmacy &&
      !_ctx.isAppRedirect
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.navigation)
            ? (_openBlock(), _createBlock(_component_Sidebar, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}