import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "clientInfo" }
const _hoisted_2 = { class: "clientInfo__header" }
const _hoisted_3 = { class: "clientInfo__main" }
const _hoisted_4 = { class: "info" }
const _hoisted_5 = { class: "info" }
const _hoisted_6 = { class: "info" }
const _hoisted_7 = { class: "info" }
const _hoisted_8 = { class: "consentGiven" }
const _hoisted_9 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('clientInformation')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('mobilePhone')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.viewOrder?.clientInfo.phoneNumber), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('fullName')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.viewOrder?.clientInfo.firstName) + " " + _toDisplayString(_ctx.viewOrder?.clientInfo.lastName), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('ssn')), 1),
        _createElementVNode("span", null, _toDisplayString(!_ctx.showDecodedSSN ? '************' : _ctx.decodedSSN?.ssn), 1),
        _createVNode(_component_Button, {
          class: "ssn_btn",
          variant: "notBordered",
          title: !_ctx.showDecodedSSN ? _ctx.$t('show') : _ctx.$t('hide'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.initSSN()))
        }, null, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('homeAddress')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.viewOrder?.clientInfo.homeAddress), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Checkbox, {
          name: "consentGiven",
          value: _ctx.consentGiven
        }, null, 8, ["value"]),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('consentGivenText')), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('timestamp')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.dateTimeResult(_ctx.viewOrder?.createdTime)), 1)
      ])
    ])
  ]))
}