<template>
  <canvas
    :id="id"
    style="height: 100%; width: 100%"
    :style="styleLineChart"
  ></canvas>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'LineChart',
  props: ['id', 'dataLineChart', 'optionsLineChart', 'styleLineChart'],
  /* eslint-disable */
  mounted() {
    const ctx = document.getElementById(`${this.id}`);
    const myChart = new Chart(ctx, {
      type: 'line',
      data: this.dataLineChart,
      options: this.optionsLineChart
    });
  }
};
</script>
