import { http } from '@/http';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { i18n } from '@/main';
import {
  AuthenticationSignicat,
  AuthorizeUser,
  ChangeLang,
  ChangePassword,
  ChangePasswordData,
  UpdateUserProfile,
  UserProfile
} from '@/store/modules/account/types';
import { ApiResponse, LoggedInAndOut, PharmacyAccessesResponse } from './types';
import { EntityNames } from '@/store/modules/entityNames/types';
import {
  AlarmComment,
  AlarmsData,
  AssignDelivery,
  CapacityByPharmacyId,
  CapacityByPickupBoxId,
  CreateReservation,
  CreateReservationPharmacy,
  DeletePharmacyReservation,
  DeliveriesStatistics,
  Delivery,
  Description,
  InfoFromPickupBox,
  Pharmacy,
  PickupBox,
  PickupBoxUpdate,
  ResendSMS,
  TemperatureLog,
  TemperatureStamps,
  PickupDeletedOrRemovedDelivery,
  ToggleActive,
  ToggleLocker,
  UpdatePharmacyReservation,
  SsnDelivery,
  DestroyReservation,
  DataServiceRequest,
  FeedbackTemplate,
  PharmacyLogo
} from '@/store/modules/pharmacy/types';
import {
  LoginInfo,
  PharmacyAccessesAdd,
  UpdatePharmacyAccesses,
  UserCreate,
  UserResult,
  UserUpdate
} from '@/store/modules/users/types';
import { AppRoleResult } from '@/store/modules/roles/types';
import { createToaster } from '@meforma/vue-toaster';
import router from '@/router';
import { LocationQueryValueRaw } from 'vue-router';
import {
  AuthenticationHistoryData,
  CallbackRequestsData,
  CallerAuthenticateData,
  CallerAuthenticationData,
  DiscardCaller,
  SsnAuthenticationHistory
} from '@/store/modules/callerAuthentication/types';
import {
  DecodedSSN,
  FeedbackStatistics,
  IssuePaymentData,
  OrderPayment,
  OrderHistory,
  ResendPaymentNotification,
  AddComment
} from '@/store/modules/orders/types';

const toaster = createToaster({
  type: 'error',
  duration: 3000,
  position: 'top-right'
});

export const handleAxiosError = (error: unknown): unknown => {
  const axiosError = error as AxiosError;

  const responseData = axiosError.response?.data as ApiResponse;

  for (const error of responseData.errors) {
    const errorMessage =
      axiosError.response && axiosError.response.data && responseData.message
        ? i18n.global.t(error)
        : axiosError.message;

    if (
      (!axiosError.response || axiosError.response.status !== 401) &&
      errorMessage
    ) {
      if (responseData.message === 'Validation fail') {
        toaster.show(responseData.errors[0].msg);
      } else {
        if (axiosError.response?.status !== 300) {
          if (responseData.errors[0] === 'user_push_notification_disabled') {
            toaster.show(errorMessage, {
              style: {
                background: '#f19e37'
              }
            });
          } else {
            toaster.show(errorMessage);
          }
        }
      }
      if (
        axiosError.response?.status === 403 &&
        responseData.message === 'User not authorized'
      ) {
        router.push({ name: 'VerifiedUser' });
      }
      return error;
    } else {
      return Promise.reject(error);
    }
  }
};

export const handleDefaultError = (
  error: AxiosError<unknown, unknown>
): unknown => {
  const errorMessage = error.message ? error.message : 'Unknown Error';
  console.log(errorMessage);
  return Promise.reject(error);
};

// Get admin profile using firebase token
export const getProfile = async (): Promise<UserProfile | undefined> => {
  try {
    const response = await http.get('/profile');
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      await handleAxiosError(error);
    }
  }
};

export const writeLoggedIn = async (): Promise<LoggedInAndOut | undefined> => {
  try {
    const response = await http.post('/users/login-logs/logged-in');
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      await handleAxiosError(error);
    }
  }
};

export const writeLoggedOut = async (): Promise<LoggedInAndOut | undefined> => {
  try {
    const response = await http.post('/users/login-logs/logged-out');
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      await handleAxiosError(error);
    }
  }
};

export const getAuthenticationSignicat = async (
  businessId: string,
  deliveryId: string
): Promise<AuthenticationSignicat | undefined> => {
  try {
    const endpoint = `/strong-authentication/show-verify-link/${businessId}/${deliveryId}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
      throw new Error(error.response?.data.message);
    }
  }
};

export const authorizeUser = async (
  data: AuthorizeUser
): Promise<AuthorizeUser | undefined> => {
  try {
    const response = await http.post(
      '/strong-authentication/authenticate',
      data
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
      throw new Error(error.response?.data.message);
    }
  }
};

export const authorizeRegisterUser = async (
  data: AuthorizeUser
): Promise<AuthorizeUser | undefined> => {
  try {
    const baseUrl = process.env.VUE_APP_API_URL?.split('/api')[0];

    const response = await axios
      .create({
        baseURL: baseUrl
      })
      .post('/mobile/authentication/start-registration', data);

    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
      throw new Error(error.response?.data.message);
    }
  }
};

export const authorizeUserVieStrongAuthRedirect = async (
  data: AuthorizeUser
): Promise<AuthorizeUser | undefined> => {
  try {
    const baseUrl = process.env.VUE_APP_API_URL?.split('/api')[0];

    const response = await axios
      .create({
        baseURL: baseUrl
      })
      .post('/mobile/authentication/start-re-registration', data);

    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
      throw new Error(error.response?.data.message);
    }
  }
};

export const getEntityNames = async (): Promise<EntityNames | undefined> => {
  try {
    const response = await http.get('/health/entity-names');
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      await handleAxiosError(error);
    }
  }
};

export const changeLang = async (
  data: ChangeLang
): Promise<ChangeLang | undefined> => {
  try {
    const response = await http.patch('/profile/change-lang', data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      await handleAxiosError(error);
    }
  }
};

export const changePasswordAdminProfile = async (
  data: ChangePassword
): Promise<ChangePassword | undefined> => {
  try {
    const response = await http.patch('/profile/change-password', data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      await handleAxiosError(error);
    }
  }
};

// Check if server is up
export const pingServer = async (): Promise<AxiosResponse | undefined> => {
  try {
    const response = await http.get('/health/ping');
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      await handleAxiosError(error);
    }
  }
};

// get localization data
export const getLanguage = async (): Promise<AxiosResponse | undefined> => {
  try {
    const response = await http.get('/localizations/web');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const setUserLang = async (lang: string): Promise<null | undefined> => {
  try {
    await http.post('/users/change-lang', { lang: lang });
    return null;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getCallerAuthenticationNumber = async (
  phoneNumber: string
): Promise<CallerAuthenticationData | undefined> => {
  try {
    const endpoint = `/caller/search-by-number?phoneNumber=${phoneNumber}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getAuthenticationHistory = async (
  pharmacyId: string,
  startTime: number,
  endTime: number
): Promise<AuthenticationHistoryData[] | undefined> => {
  try {
    const endpoint = `/caller/authentication-history/${pharmacyId}?startTime=${startTime}&endTime=${endTime}&timezoneOffset=${new Date().getTimezoneOffset()}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getAuthenticationHistoryDecodedSsn = async (
  pharmacyId: string,
  recordId: string
): Promise<SsnAuthenticationHistory | undefined> => {
  try {
    const endpoint = `/caller/get-caller-ssn/${pharmacyId}/${recordId}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getCallerAuthenticate = async (
  data: CallerAuthenticateData
): Promise<CallerAuthenticateData | undefined> => {
  try {
    const response = await http.post('/caller/authenticate', data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const discardCallerAuthentication = async (
  data: DiscardCaller | undefined
): Promise<DiscardCaller | undefined> => {
  try {
    const response = await http.delete('/caller/discard', { data });

    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getIssuePaymentPush = async (
  data: IssuePaymentData | undefined
): Promise<IssuePaymentData | undefined> => {
  try {
    const response = await http.post('/orders/issue-payment', data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getOrderCreate = async (
  data: OrderPayment | undefined
): Promise<OrderPayment | undefined> => {
  try {
    const response = await http.post('/orders/create', data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const cancelPaymentRequest = async (
  orderId: string
): Promise<unknown> => {
  try {
    const response = await http.post(`/orders/cancel-payment/${orderId}`);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getOrdersHistory = async (
  pharmacyId: string,
  startTime: number,
  endTime: number
): Promise<OrderHistory[] | undefined> => {
  try {
    const response = await http.get(
      `/orders/show-by-pharmacy/${pharmacyId}?startTime=${startTime}&endTime=${endTime}&timezoneOffset=${new Date().getTimezoneOffset()}`
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getViewOrderById = async (
  pharmacyId: string,
  orderId: string
): Promise<unknown> => {
  try {
    const endpoint = `/orders/show/${pharmacyId}/${orderId}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const resendPaymentNotification = async (
  data: ResendPaymentNotification | undefined
): Promise<ResendPaymentNotification | undefined> => {
  try {
    const response = await http.post(
      'orders/resend-payment-notification',
      data
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getOrdersHistoryDecodedSSN = async (
  pharmacyId: string,
  orderId: string
): Promise<DecodedSSN | undefined> => {
  try {
    const endpoint = `/orders/decoded-ssn/${pharmacyId}/${orderId}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getConfirmContent = async (
  pharmacyId: string,
  orderId: string
): Promise<unknown> => {
  try {
    const endpoint = `/orders/confirm-content/${pharmacyId}/${orderId}`;

    const response = await http.patch(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const addComment = async (
  data: AddComment,
  orderId: string
): Promise<AddComment | undefined> => {
  try {
    const response = await http.patch(`/orders/comment/${orderId}`, data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getPharmacies = async (): Promise<Pharmacy[] | undefined> => {
  try {
    const endpoint = '/pharmacies/';

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getPharmacyById = async (
  id: string
): Promise<Pharmacy | undefined> => {
  try {
    const endpoint = `/pharmacies/show/${id}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getPharmacyServiceRequest = async (
  data: DataServiceRequest,
  pharmacyId: string
): Promise<DataServiceRequest | undefined> => {
  try {
    const response = await http.post(
      `/pharmacies/service-request/${pharmacyId}`,
      data
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const updatePharmacy = async (
  data: Pharmacy[],
  pharmacyId: string
): Promise<Pharmacy[] | undefined> => {
  try {
    const response = await http.patch(`/pharmacies/update/${pharmacyId}`, data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const updatePharmacyLogo = async (
  data: PharmacyLogo,
  pharmacyId: string
): Promise<PharmacyLogo | undefined> => {
  try {
    const response = await http.put(
      `/pharmacies/update-image/${pharmacyId}`,
      data
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getPickupBoxByPharmacyId = async (
  id: string
): Promise<PickupBox[]> => {
  try {
    const endpoint = `/pickup-boxes/show-by-pharmacy-id/${id}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }

    return [];
  }
};

export const getPickupBoxCapacity = async (
  pharmacyId: string,
  startTime: number,
  endTime: number
): Promise<CapacityByPharmacyId | undefined> => {
  try {
    const endpoint = `/pickup-boxes/capacity-per-pharmacy/${pharmacyId}?startTime=${startTime}&endTime=${endTime}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getCapacityByPickupBoxId = async (
  boxId: string,
  startTime: number,
  endTime: number
): Promise<CapacityByPickupBoxId | undefined> => {
  try {
    const endpoint = `/pickup-boxes/capacity-per-box/${boxId}?startTime=${startTime}&endTime=${endTime}&timezoneOffset=${new Date().getTimezoneOffset()}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const createReservation = async (
  data: CreateReservation,
  boxId: string
): Promise<CreateReservation | undefined> => {
  try {
    const response = await http.post(`/deliveries/create/${boxId}`, data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const createPharmacyReservation = async (
  data: CreateReservationPharmacy,
  pharmacyId: string
): Promise<CreateReservationPharmacy | undefined> => {
  try {
    const response = await http.post(
      `/deliveries/create-on-pharmacy/${pharmacyId}`,
      data
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const deleteReservation = async (
  boxId: string,
  deliveryId: string
): Promise<unknown> => {
  try {
    const endpoint = `/deliveries/delete-reservation/${boxId}/${deliveryId}`;

    const response = await http.delete(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const destroyReservation = async (
  destroyReservation: DestroyReservation
): Promise<DestroyReservation | unknown> => {
  try {
    const response = await http.delete(`/deliveries/destroy`, {
      data: { ...destroyReservation }
    });

    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const deletePharmacyReservation = async (
  data: DeletePharmacyReservation,
  pharmacyId: string,
  deliveryId: string
): Promise<DeletePharmacyReservation | undefined> => {
  try {
    const response = await http.delete(
      `/deliveries/delete-reservation-on-pharmacy/${pharmacyId}/${deliveryId}`,
      { data: { reservedTime: data.reservedTime } }
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const updatePharmacyReservation = async (
  data: UpdatePharmacyReservation,
  pharmacyId: string,
  deliveryId: string
): Promise<UpdatePharmacyReservation | undefined> => {
  try {
    const response = await http.patch(
      `/deliveries/update-on-pharmacy/${pharmacyId}/${deliveryId}`,
      data
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const resendSMS = async (
  data: ResendSMS
): Promise<ResendSMS | undefined> => {
  try {
    const response = await http.post('/deliveries/resend-sms', data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const assignDelivery = async (
  data: AssignDelivery,
  pharmacyId: string,
  deliveryId: string
): Promise<AssignDelivery | undefined> => {
  try {
    const response = await http.patch(
      `/deliveries/assign/${pharmacyId}/${deliveryId}`,
      data
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getDeliveriesStatistics = async (
  pharmacyId: string,
  startTime: number,
  endTime: number
): Promise<DeliveriesStatistics[] | undefined> => {
  try {
    const endpoint = `/deliveries/statistics/per-pharmacy/${pharmacyId}?startTime=${startTime}&endTime=${endTime}&timezoneOffset=0`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getPickupBoxById = async (
  boxId: string
): Promise<InfoFromPickupBox | undefined> => {
  try {
    const response = await http.get(`/pickup-boxes/show/${boxId}`);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getTemperaturesById = async (
  boxId: string
): Promise<TemperatureStamps | undefined> => {
  try {
    const response = await http.get(
      `/temperatures/temperatures-stamps/${boxId}`
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const writeTemperaturesById = async (
  boxId: string
): Promise<LoggedInAndOut | undefined> => {
  try {
    const response = await http.post(
      `/temperatures/temperatures-stamps/${boxId}`
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      await handleAxiosError(error);
    }
  }
};

export const updatePickupBox = async (
  data: PickupBoxUpdate,
  boxId: string
): Promise<PickupBoxUpdate | undefined> => {
  try {
    const response = await http.patch(`/pickup-boxes/update/${boxId}`, data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const updateProfile = async (
  data: UpdateUserProfile
): Promise<UpdateUserProfile | undefined> => {
  try {
    const response = await http.patch('/profile/update', data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

// Users

export const getUsers = async (
  lastKey: string | null,
  pageSize: number,
  pharmacyId: string
): Promise<UserResult[] | undefined> => {
  try {
    let endpoint = '/users/';

    if (!lastKey) {
      endpoint += `?pageSize=${pageSize}&pharmacyId=${pharmacyId}`;
    } else {
      endpoint += `?pageSize=${pageSize}&lastKey=${lastKey}`;
    }

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getUserById = async (
  id: string
): Promise<UserResult | undefined> => {
  try {
    const endpoint = `/users/show/${id}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const createUser = async (
  data: UserCreate
): Promise<UserCreate | undefined> => {
  try {
    const response = await http.post('/users/create', data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const changePasswordUserId = async (
  data: ChangePasswordData,
  userId: string
): Promise<ChangePasswordData | undefined> => {
  try {
    const response = await http.patch(`/users/change-password/${userId}`, data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const deleteUser = async (userId: string): Promise<unknown> => {
  try {
    const response = await http.delete(`/users/delete/${userId}`);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const toggleActivePickupBox = async (
  data: ToggleActive,
  boxId: string
): Promise<ToggleActive | undefined> => {
  try {
    const response = await http.patch(
      `/pickup-boxes/toggle-active/${boxId}`,
      data
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const toggleLockerPickupBox = async (
  data: ToggleLocker,
  boxId: string
): Promise<ToggleLocker | undefined> => {
  try {
    const response = await http.patch(
      `/pickup-boxes/toggle-locker/${boxId}`,
      data
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const updateUser = async (
  data: UserUpdate,
  userId: string
): Promise<UserUpdate | undefined> => {
  try {
    const response = await http.patch(`/users/update/${userId}`, data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getPharmacyAccesses =
  async (): Promise<PharmacyAccessesResponse> => {
    try {
      const response = await http.get('/users/pharmacy-accesses');
      return {
        status: response.status,
        data: response.data.result
      };
    } catch (error: unknown) {
      const axiosError = error as AxiosError;
      const responseData = axiosError.response?.data as ApiResponse;
      const resultData: LocationQueryValueRaw = responseData.result;

      return {
        status: axiosError.response?.status,
        data: resultData
      };
    }
  };

export const updatePharmacyAccess = async (
  data: UpdatePharmacyAccesses
): Promise<UpdatePharmacyAccesses | undefined> => {
  try {
    const response = await http.patch('/users/pharmacy-accesses', data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      await handleAxiosError(error);
    }
  }
};

export const addPharmacyAccesses = async (
  data: PharmacyAccessesAdd
): Promise<PharmacyAccessesAdd | undefined> => {
  try {
    const response = await http.patch(`/users/add-pharmacy-access`, data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getLoginInfo = async (
  pharmacyId: string,
  limit: number,
  lastTimestamp: string
): Promise<LoginInfo[] | undefined> => {
  try {
    let endpoint = `/users/login-logs/${pharmacyId}`;

    if (lastTimestamp) {
      endpoint += `?limit=${limit}&lastTimestamp=${lastTimestamp}`;
    }

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getCallbackRequests = async (
  pharmacyId: string,
  lastTimestamp: number
): Promise<CallbackRequestsData[] | undefined> => {
  try {
    let endpoint = `/caller/list/${pharmacyId}`;

    if (!lastTimestamp) {
      endpoint;
    } else {
      endpoint += `&lastTimestamp=${lastTimestamp}`;
    }

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getMarkAsDone = async (
  recordId: string
): Promise<CallbackRequestsData | undefined> => {
  try {
    const endpoint = `/caller/mark-as-done/${recordId}`;

    const response = await http.patch(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getMarkAsMissed = async (
  recordId: string
): Promise<CallbackRequestsData | undefined> => {
  try {
    const endpoint = `/caller/mark-as-missed/${recordId}`;

    const response = await http.patch(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

// Roles

export const getAppRoles = async (): Promise<AppRoleResult[] | undefined> => {
  try {
    const endpoint = '/roles/app-roles';
    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getDeliveryHistory = async (
  boxId_or_pharmacyId: string,
  startTime: string,
  endTime: string
): Promise<Delivery[] | undefined> => {
  try {
    const endpoint = `/deliveries/show/${boxId_or_pharmacyId}?startTime=${startTime}&endTime=${endTime}&timezoneOffset=0`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getDeliveryShowDecodedSsn = async (
  deliveryId: string,
  reservedTime: string,
  pickupboxId?: string,
  docId?: string,
  pharmacyId?: string
): Promise<SsnDelivery | undefined> => {
  try {
    let endpoint = `/deliveries/show-decoded-ssn/${deliveryId}?reservedTime=${reservedTime}`;
    if (pickupboxId) {
      endpoint += `&pickupboxId=${pickupboxId}`;
    } else if (pharmacyId) {
      endpoint += `&pharmacyId=${pharmacyId}`;
    } else {
      endpoint += `&docId=${docId}`;
    }

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const deliveryDeletedOrRemovedPickup = async (
  data: PickupDeletedOrRemovedDelivery
): Promise<Delivery[] | undefined> => {
  try {
    const endpoint = '/deliveries/pickup-deleted-or-removed-delivery';

    const response = await http.patch(endpoint, data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const updateDescription = async (
  data: Description,
  deliveryId: string
): Promise<Description | undefined> => {
  try {
    const response = await http.patch(
      `/deliveries/update-description/${deliveryId}`,
      data
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getAlarms = async (
  boxId: string,
  startTime: string,
  endTime: string,
  alarmType: string
): Promise<AlarmsData[] | undefined> => {
  try {
    let endpoint = `/alarms/show-by-box-id/${boxId}?startTime=${startTime}&endTime=${endTime}&timezoneOffset=0`;

    if (alarmType) {
      endpoint += `&alarmType=${alarmType}`;
    }

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getTemperatureLog = async (
  boxId: string,
  startTime: string,
  endTime: string
): Promise<TemperatureLog | undefined> => {
  try {
    const endpoint = `/temperatures/logs-per-box/${boxId}?startTime=${startTime}&endTime=${endTime}&timezoneOffset=${new Date().getTimezoneOffset()}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const updateAlarmComment = async (
  data: AlarmComment
): Promise<AlarmComment | undefined> => {
  try {
    const response = await http.post('/alarms/comment', data);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getFeedbackStatistics = async (
  pharmacyId: string,
  startTime: string,
  endTime: string,
  minutesInterval: number,
  chartFields: string
): Promise<FeedbackStatistics | undefined> => {
  try {
    const endpoint = `/feedback/statistics/${pharmacyId}?startTime=${startTime}&endTime=${endTime}&timezoneOffset=${new Date().getTimezoneOffset()}&minutesInterval=${minutesInterval}&chartFields=${chartFields}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const getFeedbackStatisticsWeek = async (
  pharmacyId: string,
  startTime: string,
  endTime: string,
  minutesInterval: number,
  chartFields: string
): Promise<FeedbackStatistics | undefined> => {
  try {
    const endpoint = `/feedback/statistics/${pharmacyId}?startTime=${startTime}&endTime=${endTime}&timezoneOffset=${new Date().getTimezoneOffset()}&minutesInterval=${minutesInterval}&chartFields=${chartFields}`;

    const response = await http.get(endpoint);
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const updateFeedbackTemplate = async (
  data: FeedbackTemplate,
  pharmacyId: string
): Promise<FeedbackTemplate | undefined> => {
  try {
    const response = await http.patch(
      `/pharmacies/update-feedback-template/${pharmacyId}`,
      data
    );
    return response.data.result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleAxiosError(error);
    }
  }
};

export const setHeaderToken = (token: string): void => {
  http.defaults.headers.common['AuthToken'] = token;
};

export const deleteHeaderToken = (): void => {
  delete http.defaults.headers.common['AuthToken'];
};
