import { Option, TranslationFunction } from '@/types';

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('mobilePhoneUpperCase'),
    value: 'phoneNumber'
  },
  {
    label: $t('deliveryStatusUpperCase'),
    value: 'deliveryStatus'
  },
  {
    label: $t('kolliIDUpperCase'),
    value: 'deliveryIds'
  },
  {
    label: $t('reservedUpperCase'),
    value: 'reservedTime'
  },
  {
    label: $t('includesDrugUpperCase'),
    value: 'is_drug'
  },
  {
    label: $t('assignUpperCase'),
    value: 'assign'
  }
];
