
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import LangChange from '@/views/Language.vue';
import Loading from '@/views/Loading.vue';

import router from '@/router';
import { InputFileEvent } from '@/types';

@Options({
  name: 'ForgotPassword',
  components: {
    Loading,
    LangChange
  },
  data() {
    return {
      email: null,
      loading: false,
      sentEmail: false
    };
  },
  computed: {
    ...mapState('account', ['status'])
  },
  methods: {
    ...mapActions('account', ['resetPassword']),
    async forgotPassword() {
      this.loading = true;

      if (this.email) {
        await this.resetPassword({ email: this.email });

        this.email = '';
        this.sentEmail = true;
      }

      this.$i18n.locale = localStorage.getItem('locale') || 'en';
      this.loading = false;
    },
    handleChange(e: InputFileEvent) {
      this[e.target.name] = e.target.value;
    },
    backTo() {
      router.push({ name: 'Login' });
      this.status.error = '';
    }
  }
})
export default class ForgotPassword extends Vue {}
