import { GetterTree } from 'vuex';
import { UsersState } from '@/store/modules/users/types';
import { RootState } from '@/store/types';

export const getters: GetterTree<UsersState, RootState> = {
  status: (state) => state.status,
  users: (state) => state.users,
  user: (state) => state.user,
  pharmacyAccesses: (state) => state.pharmacyAccesses
};
