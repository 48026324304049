
import { mapActions, mapState } from 'vuex';
import { Options, Vue } from 'vue-class-component';

import Loading from '@/views/Loading.vue';
import AddAlarmComment from './AddAlarmComment/AddAlarmComment.vue';

import { columns } from './configs';
import { InputFileEvent, Option } from '@/types';

import { format } from 'date-fns';
import {
  AlarmsData,
  DataAlarmPDF,
  PickupBox
} from '@/store/modules/pharmacy/types';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import {
  dateTime,
  getAlarmComments,
  getAverageTemp,
  getDuration
} from '@/utils/alarmsDownloadPDF';

@Options({
  name: 'Alarms',
  emits: ['saveAlarmComment'],
  components: {
    Loading,
    AddAlarmComment
  },
  data() {
    return {
      selectedPickupBox: {
        value: '',
        label: ''
      },
      alarmsData: [],
      pickupBoxOptions: [],
      activePickupBox: null,
      activeAlarm: null,
      columns: columns(this.$t),
      params: null,
      loading: false,
      showModal: false
    };
  },
  created() {
    this.loadPickupBoxes();
  },
  computed: {
    ...mapState('pharmacy', ['pickupBoxes', 'alarms']),
    today() {
      const dateString = format(new Date(), 'dd/MM/yyyy');

      return dateString;
    },
    firstDay() {
      const firstDate = format(new Date(), 'dd/MM/yyyy').split('/');
      firstDate.splice(0, 1, '01');

      return firstDate.join('/');
    },
    isDateCorrect() {
      if (this.isDatePresent) {
        return this.params.endTime >= this.params.startTime;
      }

      return true;
    },
    isDatePresent() {
      return this.params && this.params.startTime && this.params.endTime;
    }
  },
  methods: {
    ...mapActions('pharmacy', [
      'getPickupBoxesByPharmacy',
      'getAlarms',
      'updateAlarmComment'
    ]),
    async show() {
      this.loading = true;

      const boxId = this.activePickupBox?.id;

      const defaultParams = {
        boxId,
        ...this.params
      };

      if (
        boxId &&
        defaultParams.startTime &&
        defaultParams.endTime &&
        this.isDateCorrect
      ) {
        await this.getAlarms(defaultParams);
      }

      this.alarmsData = this.alarms;

      this.alarmsData.sort((a: AlarmsData, b: AlarmsData) => {
        return b.timestamp - a.timestamp;
      });

      this.loading = false;
    },
    initRequest() {
      let startTime = this.firstDay.split('/');
      let endTime = this.today.split('/');

      startTime = new Date(
        +startTime[2],
        startTime[1] - 1,
        +startTime[0]
      ).valueOf();
      endTime = new Date(+endTime[2], endTime[1] - 1, +endTime[0]).valueOf();

      this.params = {
        startTime: startTime + new Date(startTime).getTimezoneOffset() * -60000,
        endTime: endTime + new Date(endTime).getTimezoneOffset() * -60000
      };
    },
    changeDateStartTime(e: InputFileEvent) {
      const { name, value } = e.target;
      const dateNumbers = value.split('/');
      const date = `${dateNumbers[1]}/${dateNumbers[0]}/${dateNumbers[2]}`;
      this.params = {
        ...this.params,
        [name]:
          new Date(date).valueOf() + new Date(date).getTimezoneOffset() * -60000
      };
    },
    changeDateEndTime(e: InputFileEvent) {
      let { name, value } = e.target;
      const dateNumbers = value.split('/');
      const date = `${dateNumbers[1]}/${dateNumbers[0]}/${dateNumbers[2]}`;
      this.params = {
        ...this.params,
        [name]:
          new Date(date).setMonth(new Date(date).getMonth()).valueOf() -
          1000 +
          new Date(date).getTimezoneOffset() * -60000
      };
    },
    async loadPickupBoxes() {
      this.loading = true;

      const { id } = this.$route.params;

      await this.getPickupBoxesByPharmacy(id);
      this.loading = false;

      this.initPickupBoxOptions();
      this.initRequest();
    },
    initPickupBoxOptions() {
      this.pickupBoxOptions = this.pickupBoxes.map((i: PickupBox) => ({
        value: i.info.id,
        label: i.info.name,
        img: require('@/assets/icons/PickupBox.svg')
      }));
      this.selectedPickupBox = this.pickupBoxOptions[0];
      this.activePickupBox = this.pickupBoxes[0];
    },
    changePickupBox(option: Option) {
      this.selectedPickupBox = option;
      this.activePickupBox = this.pickupBoxes.find(
        (i: PickupBox) => i.info.id === option.value
      );

      this.show();
    },
    addAlarmComment(alarm: AlarmsData) {
      this.activeAlarm = alarm;

      this.showModal = true;
    },
    async saveAlarmComment(alarmInfo: string) {
      this.loading = true;

      const payload = {
        pickupboxId: this.activeAlarm.pickupboxId,
        timestamp: this.activeAlarm.timestamp,
        lockerId: this.activeAlarm.lockerId,
        alarmInfo: alarmInfo
      };

      await this.updateAlarmComment({
        data: payload
      });
      this.loading = false;

      alarmInfo = '';
      this.showModal = false;
      this.show(this.params);
    },
    getSensor(data: AlarmsData) {
      if (data.alarmType === 'temp_high' || data.alarmType === 'temp_low') {
        if (data.lockerId !== '99-99') {
          return `${this.$t('fridge')} ${data.lockerId}`;
        } else {
          return `${this.$t('roomTemp')}`;
        }
      } else if (data.lockerId) {
        return `${data.lockerId}`;
      }
    },
    getAlarmType(alarmType: string) {
      const type: Record<string, unknown> = {
        ok: this.$t('alarmOk'),
        door_open: this.$t('alarmDoorOpen'),
        door_closed: this.$t('alarmDoorClosed'),
        temp_high: this.$t('alarmTempHigh'),
        temp_low: this.$t('alarmTempLow'),
        other: this.$t('alarmOther'),
        missing: this.$t('alarmMissing')
      };

      return type[alarmType];
    },
    getPDF() {
      const data = this.alarmsData.map((el: DataAlarmPDF) => ({
        alarmType: this.getAlarmType(el.alarmType),
        timestamp: dateTime(el.timestamp),
        alarmEndTimestamp: dateTime(el.alarmEndTimestamp),
        duration: getDuration(el.timestamp, el.alarmEndTimestamp),
        alarmMax: el.alarmMax,
        alarmMin: el.alarmMin,
        sensor: this.getSensor(el),
        averageTemp: +getAverageTemp(el.alarmMax, el.alarmMin),
        alarmComment: getAlarmComments(el.check)
      }));

      const dataPDF = data.map((obj: DataAlarmPDF) => Object.values(obj));

      const columns = [...this.columns].map((i: Option) => i.label);

      const doc = new jsPDF('landscape');
      autoTable(doc, {
        head: [columns],
        body: dataPDF,
        theme: 'grid',
        headStyles: { fillColor: [71, 152, 127] },
        bodyStyles: { fillColor: [255, 255, 255] },
        margin: { top: 10 }
      });

      doc.save(`${this.$t('alarms')}.pdf`);
    }
  },
  watch: {
    params() {
      if (this.isDatePresent && this.isDateCorrect) {
        this.show(this.params);
      }
    }
  }
})
export default class Alarms extends Vue {}
