import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { RolesState } from '@/store/modules/roles/types';
import { getters } from '@/store/modules/roles/getters';
import { mutations } from '@/store/modules/roles/mutations';
import { actions } from '@/store/modules/roles/actions';

export const state: RolesState = {
  appRoles: {}
};

export const roles: Module<RolesState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
