import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "toggle__content" }
const _hoisted_2 = ["id", "name", "disabled", "checked", "value"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "toggle__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      toggle: true,
      toggleBackgraund: _ctx.variant === 'backgraund'
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        id: _ctx.name,
        name: _ctx.name,
        class: "offscreen",
        type: "checkbox",
        disabled: _ctx.disabled,
        checked: _ctx.value,
        value: _ctx.value
      }, null, 8, _hoisted_2),
      _createElementVNode("label", {
        for: _ctx.name,
        class: _normalizeClass({ switch: true, switch_disabled: _ctx.disabled })
      }, null, 10, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, _toDisplayString(_ctx.title), 1)
    ])
  ], 2))
}