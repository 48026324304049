import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/EditGreen.svg'


const _hoisted_1 = { class: "editPharmacy" }
const _hoisted_2 = { class: "editPharmacy__container" }
const _hoisted_3 = { class: "editPharmacy__generalInfo" }
const _hoisted_4 = { class: "editPharmacy-logo" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "overlay" }
const _hoisted_8 = { class: "overlay-content" }
const _hoisted_9 = { class: "editPharmacy-description" }
const _hoisted_10 = {
  class: "label-description",
  for: "description"
}
const _hoisted_11 = { class: "editPharmacy__moreInfo" }
const _hoisted_12 = { class: "workingHours" }
const _hoisted_13 = { class: "workingHours__title" }
const _hoisted_14 = { class: "title" }
const _hoisted_15 = { class: "day" }
const _hoisted_16 = { class: "hours" }
const _hoisted_17 = { class: "editWorkingHours-title" }
const _hoisted_18 = { class: "services" }
const _hoisted_19 = { class: "services__title" }
const _hoisted_20 = { class: "title" }
const _hoisted_21 = { class: "services__types" }
const _hoisted_22 = { class: "showToCustomers" }
const _hoisted_23 = { class: "showToCustomers__title" }
const _hoisted_24 = { class: "title" }
const _hoisted_25 = { class: "showToCustomers__checkbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Title = _resolveComponent("Title")!
  const _component_Photo = _resolveComponent("Photo")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_AddInfo = _resolveComponent("AddInfo")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_ServiceRequest = _resolveComponent("ServiceRequest")!
  const _component_EditWorkingHours = _resolveComponent("EditWorkingHours")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_Title, {
        title: _ctx.$t('pharmacyInformation')
      }, null, 8, ["title"]),
      _createVNode(_component_FormKit, {
        type: "form",
        id: "pharmacyForm",
        modelValue: _ctx.formData,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData) = $event)),
        actions: false,
        onSubmit: _ctx.submitHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("img", {
                    class: "editPharmacyLogo",
                    src: _ctx.pharmacy?.pictureUrl,
                    alt: "Logo"
                  }, null, 8, _hoisted_6),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_Photo, { onChange: _ctx.onFileChange }, null, 8, ["onChange"])
                    ])
                  ])
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoOfPharmacy, (el) => {
                return (_openBlock(), _createBlock(_component_Input, {
                  key: el.id,
                  type: el.type,
                  name: el.name,
                  label: _ctx.$t(el.label),
                  validation: el.validation,
                  validationMessages: {
              required: _ctx.$t('validation.required')
            },
                  disabled: _ctx.disabledInput(el.name),
                  "data-testid": el.dataTestId
                }, null, 8, ["type", "name", "label", "validation", "validationMessages", "disabled", "data-testid"]))
              }), 128)),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('description')), 1),
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.description) = $event)),
                  id: "description",
                  class: "textarea-description",
                  maxlength: "250"
                }, "            ", 512), [
                  [_vModelText, _ctx.formData.description]
                ])
              ]),
              _createVNode(_component_Dropdown, {
                class: "infoOfPharmacy-dropdown",
                selectedOption: _ctx.langsDropdown.selectedOption,
                options: _ctx.langsDropdown.options,
                label: _ctx.$t('chooseLanguage'),
                multiple: true,
                onSelectOption: _ctx.selectLanguage
              }, null, 8, ["selectedOption", "options", "label", "onSelectOption"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoOfDeliveries, (el) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "block-input",
                  key: el.id
                }, [
                  _createVNode(_component_Input, {
                    type: el.type,
                    name: el.name,
                    label: _ctx.$t(el.label),
                    disabled: _ctx.disabledInput(el.name),
                    "data-testid": el.dataTestId
                  }, null, 8, ["type", "name", "label", "disabled", "data-testid"]),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('daysDelivery')), 1)
                ]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoEmail, (el) => {
                return (_openBlock(), _createBlock(_component_Input, {
                  key: el.id,
                  type: el.type,
                  name: el.name,
                  label: _ctx.$t(el.label),
                  validation: el.validation,
                  validationMessages: {
              required: _ctx.$t('validation.required'),
              email: _ctx.$t('validation.email')
            },
                  "data-testid": el.dataTestId
                }, null, 8, ["type", "name", "label", "validation", "validationMessages", "data-testid"]))
              }), 128)),
              _createVNode(_component_FormKit, {
                type: "group",
                name: "additionalEmails"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emailItems, (el, idx) => {
                    return (_openBlock(), _createBlock(_component_Input, {
                      key: el,
                      name: el,
                      id: el,
                      label: _ctx.$t('alarmEmail'),
                      validation: 'required|email',
                      validationMessages: {
                required: _ctx.$t('validation.required'),
                email: _ctx.$t('validation.email')
              },
                      disabled: !_ctx.user?.role.permissions.pharmacy.update,
                      onCloseInput: _ctx.removeEmailItem,
                      "data-testid": 'inputAddAlarmEmail' + idx
                    }, null, 8, ["name", "id", "label", "validationMessages", "disabled", "onCloseInput", "data-testid"]))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_AddInfo, {
                title: _ctx.$t('email'),
                onClick: _ctx.addEmailItem
              }, null, 8, ["title", "onClick"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoPhoneNumber, (el) => {
                return (_openBlock(), _createBlock(_component_Input, {
                  key: el.id,
                  type: el.type,
                  name: el.name,
                  label: _ctx.$t(el.label),
                  validation: el.validation,
                  "validation-visibility": el.validationVisibility,
                  validationMessages: {
              matches: _ctx.$t('validation.phoneNumber'),
              required: _ctx.$t('validation.required')
            },
                  disabled: !_ctx.user?.role.permissions.pharmacy.update,
                  "data-testid": el.dataTestId
                }, null, 8, ["type", "name", "label", "validation", "validation-visibility", "validationMessages", "disabled", "data-testid"]))
              }), 128)),
              _createVNode(_component_FormKit, {
                type: "group",
                name: "additionalPhoneNumbers"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phoneItems, (el, idx) => {
                    return (_openBlock(), _createBlock(_component_Input, {
                      key: el,
                      name: el,
                      id: el,
                      label: _ctx.$t('alarmPhoneNumber'),
                      validation: [['required'], ['matches', /^\+[0-9]{10,12}$/]],
                      "validation-visibility": "dirty",
                      validationMessages: {
                matches: _ctx.$t('validation.phoneNumber'),
                required: _ctx.$t('validation.required')
              },
                      disabled: !_ctx.user?.role.permissions.pharmacy.update,
                      onCloseInput: _ctx.removePhoneItem,
                      "data-testid": 'inputAddAlarmPhoneNumber' + idx
                    }, null, 8, ["name", "id", "label", "validationMessages", "disabled", "onCloseInput", "data-testid"]))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_AddInfo, {
                title: _ctx.$t('phoneNumber'),
                onClick: _ctx.addPhoneItem
              }, null, 8, ["title", "onClick"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('workingHours')), 1)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pharmacy?.workingHours, (workingHours, day) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "workingHours__scheduleWork",
                    key: day
                  }, [
                    _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.getFullDay(day)), 1),
                    _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.getWorkingHours(workingHours?.from, workingHours?.to)), 1)
                  ]))
                }), 128)),
                _createElementVNode("div", {
                  class: "workingHours__editWorkingHours",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.editWorkingHours && _ctx.editWorkingHours(...args)))
                }, [
                  _cache[5] || (_cache[5] = _createElementVNode("img", {
                    class: "icon",
                    src: _imports_0,
                    alt: "Edit"
                  }, null, -1)),
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('edit')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('services')), 1)
                ]),
                _createVNode(_component_FormKit, {
                  type: "group",
                  name: "services"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_21, [
                      _createVNode(_component_Checkbox, {
                        name: "pickupbox",
                        value: _ctx.pharmacy?.services.pickupbox,
                        title: _ctx.$t('pickupBox'),
                        onChange: _ctx.changePickupbox
                      }, null, 8, ["value", "title", "onChange"]),
                      _createVNode(_component_Checkbox, {
                        name: "homeDelivery",
                        value: _ctx.pharmacy?.services.homeDelivery,
                        title: _ctx.$t('homeDelivery'),
                        onChange: _ctx.changeHomeDelivery
                      }, null, 8, ["value", "title", "onChange"]),
                      _createVNode(_component_Checkbox, {
                        name: "pharmacyPickup",
                        value: _ctx.pharmacy?.services.pharmacyPickup,
                        title: _ctx.$t('pharmacyPickup'),
                        onChange: _ctx.changePharmacyPickup
                      }, null, 8, ["value", "title", "onChange"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t('showToCustomersUpperCase')), 1)
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_Checkbox, {
                    name: "showToCustomers",
                    value: _ctx.pharmacy?.showToCustomers,
                    title: _ctx.$t('showToCustomers'),
                    onChange: _ctx.changeShowToCustomers
                  }, null, 8, ["value", "title", "onChange"])
                ]),
                _createVNode(_component_Checkbox, {
                  name: "sendNewCallbackRequestEmailNotification",
                  value: _ctx.pharmacy?.sendNewCallbackRequestEmailNotification,
                  title: _ctx.$t('sendNewCallbackRequestEmailNotification'),
                  onChange: _ctx.changeSendNewCallbackRequestEmailNotification
                }, null, 8, ["value", "title", "onChange"])
              ]),
              _createVNode(_component_Input, {
                class: "input-phoneNumber",
                type: "tel",
                name: "customersPhoneNumber",
                label: _ctx.$t('customerPhoneNumber'),
                validation: [['required'], ['matches', /^\+[0-9]{10,12}$/]],
                "validation-visibility": "dirty",
                validationMessages: {
              matches: _ctx.$t('validation.phoneNumber'),
              required: _ctx.$t('validation.required')
            },
                disabled: !_ctx.user?.role.permissions.pharmacy.update
              }, null, 8, ["label", "validationMessages", "disabled"]),
              (_ctx.pharmacy?.remomediServices.mobileApp)
                ? (_openBlock(), _createBlock(_component_Dropdown, {
                    key: 0,
                    class: "infoOfPharmacy-dropdown",
                    selectedOption: _ctx.serviceLangsDropdown.selectedOption,
                    options: _ctx.serviceLangsDropdown.options,
                    label: _ctx.$t('serviceLanguagesAvailable'),
                    multiple: true,
                    onSelectOption: _ctx.selectServiceLanguage
                  }, null, 8, ["selectedOption", "options", "label", "onSelectOption"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onSubmit"])
    ]),
    _createVNode(_component_Footer, {
      disabledMakeServiceRequest: _ctx.formData.serviceRequest,
      onCleanForm: _ctx.cleanForm,
      onSubmitForm: _ctx.submitForm,
      onOpenModalServiceRequest: _ctx.openModal
    }, null, 8, ["disabledMakeServiceRequest", "onCleanForm", "onSubmitForm", "onOpenModalServiceRequest"]),
    _createVNode(_component_ServiceRequest, {
      showModalServiceRequest: _ctx.showModalServiceRequest,
      onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModalServiceRequest = false)),
      onSaveServiceRequest: _ctx.saveServiceRequest
    }, null, 8, ["showModalServiceRequest", "onSaveServiceRequest"]),
    _createVNode(_component_EditWorkingHours, {
      showModalEditWorkingHours: _ctx.showModalEditWorkingHours,
      pharmacyWorkingHours: _ctx.pharmacy?.workingHours,
      onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModalEditWorkingHours = false))
    }, null, 8, ["showModalEditWorkingHours", "pharmacyWorkingHours"])
  ], 64))
}