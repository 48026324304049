import { Option, TranslationFunction } from '@/types';

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('dayOfTheWeek'),
    value: 'day'
  },
  {
    label: $t('closedUpperCase'),
    value: 'closed'
  },
  {
    label: $t('startAt'),
    value: 'startAt'
  },
  {
    label: $t('endAt'),
    value: 'endAt'
  }
];
