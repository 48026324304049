
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import { ChangePasswordData } from '@/store/modules/account/types';

@Options({
  name: 'ChangePassword',
  props: ['showModal'],
  emits: ['closeModal'],
  components: {
    Loading
  },
  data() {
    return {
      formData: {
        password: '',
        password_confirm: ''
      },
      validationRules: {},
      loading: false
    };
  },
  computed: {
    ...mapState('users', ['user'])
  },
  methods: {
    ...mapActions('users', ['changePasswordUserId']),
    async submitHandler(data: ChangePasswordData) {
      const payload = {
        ...data,
        newPassword: this.formData.password,
        repeatNewPassword: this.formData.password_confirm
      };
      delete payload.password;
      delete payload.password_confirm;

      const { id } = this.$route.params;
      if (id) {
        this.loading = true;
        await this.changePasswordUserId({
          data: payload,
          userId: id
        });

        this.$emit('closeModal');
        this.loading = false;
      }
    },
    submitForm() {
      this.$formkit.submit('changePassword-form');
    },
    closeModal() {
      this.$emit('closeModal');
      this.$formkit.reset('changePassword-form');
    }
  }
})
export default class ChangePassword extends Vue {}
