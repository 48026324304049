
import { Options, Vue } from 'vue-class-component';

import { columns } from './configs';

@Options({
  name: 'FeedbackMainTable',
  props: ['feedbackStatistics', 'isDateCorrect'],
  data() {
    return {
      columns: columns
    };
  }
})
export default class FeedbackMainTable extends Vue {}
