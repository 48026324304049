export const infoFromPikupBox = [
  {
    label: 'pickupBoxName',
    type: 'text',
    name: 'name',
    validation: 'required',
    dataTestId: 'pickupBoxName'
  },
  {
    label: 'pickupBoxId',
    type: 'text',
    name: 'id',
    validation: 'required',
    dataTestId: 'pickupBoxId'
  },

  {
    label: 'address',
    type: 'text',
    name: 'street',
    validation: 'required',
    dataTestId: 'address'
  },
  {
    label: 'city',
    type: 'text',
    name: 'city',
    validation: 'required',
    dataTestId: 'city'
  },
  {
    label: 'zipCode',
    type: 'text',
    name: 'zipCode',
    validation: 'required',
    dataTestId: 'zipCode'
  },
  {
    label: 'country',
    type: 'text',
    name: 'country',
    validation: 'required',
    dataTestId: 'country'
  }
];

export const infoTemperature = [
  {
    label: 'temperatureInterval',
    type: 'number',
    name: 'temperatureSendDataFromPickupboxIntervalTime'
  },
  {
    label: 'temperatureAlarmInterval',
    type: 'number',
    name: 'temperatureSendAlarmNotificationAfterIntervalTime'
  }
];
