
import { Vue, Options } from 'vue-class-component';

@Options({
  name: 'TableHours',
  props: ['data', 'columns'],
  data() {
    return {
      closed: false,
      checkClosed: false
    };
  },
  methods: {
    getFullDay(day: string) {
      const daysMap: Record<string, unknown> = {
        mon: this.$t('monday'),
        tue: this.$t('tuesday'),
        wed: this.$t('wednesday'),
        thu: this.$t('thursday'),
        fri: this.$t('friday'),
        sat: this.$t('saturday'),
        sun: this.$t('sunday')
      };

      return daysMap[day];
    },
    changeClosed(day: string) {
      this.data[day].closed = !this.data[day].closed;

      if (this.data[day].closed) {
        this.data[day].from = '';
        this.data[day].to = '';
      }

      this.closed = this.data[day].closed;
    },
    updateHours(
      startOrEnd: 'startAt' | 'endAt',
      newHour: string,
      index: number
    ) {
      if (startOrEnd === 'startAt') {
        this.data[index].from = newHour;
      } else if (startOrEnd === 'endAt') {
        this.data[index].to = newHour;
      }
    }
  },
  mounted() {
    const options = {
      rootMargin: '0px',
      threshold: 1.0
    };

    const callback = (entries: IntersectionObserverEntry[]) => {
      const lastElement = this.data[this.data.length - 1];
      if (entries[0].isIntersecting && lastElement) {
        this.$emit('loadMore', lastElement.id || lastElement);
      }
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(this.$refs.observer);
  }
})
export default class TableHours extends Vue {}
