
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import { columns } from './configs';

@Options({
  name: 'EditWorkingHours',
  props: ['showModalEditWorkingHours', 'pharmacyWorkingHours'],
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      selectedPickupBox: {
        value: '',
        label: ''
      },
      chooseWorkingHours: false,
      columns: columns(this.$t),
      formData: {},
      localPharmacyWorkingHours: Object.assign({}, this.pharmacyWorkingHours)
    };
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy'])
  },
  methods: {
    ...mapActions('pharmacy', ['getPharmacy', 'updatePharmacy']),
    submitHandler() {
      this.$formkit.submit('editWorkingHours-form');
    },
    async saveWorkingHours() {
      const { id } = this.$route.params;

      const payload = { ...this.pharmacyWorkingHours };

      const data = { ...this.pharmacy, workingHours: payload };

      const wrongTime = Object.keys(payload).filter(
        (day) => payload[day].closed === false && payload[day].from === ''
      );

      if (wrongTime.length) {
        return (this.chooseWorkingHours = true);
      } else {
        this.loading = true;

        await this.updatePharmacy({
          data,
          pharmacyId: id
        });

        await this.getPharmacy(id);
        this.chooseWorkingHours = false;

        this.$emit('closeModal');
        this.loading = false;
      }
    }
  }
})
export default class EditWorkingHours extends Vue {}
