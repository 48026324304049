import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { EntityNamesState } from '@/store/modules/entityNames/types';
import { getters } from '@/store/modules/entityNames/getters';
import { mutations } from '@/store/modules/entityNames/mutations';
import { actions } from '@/store/modules/entityNames/actions';

export const state: EntityNamesState = {
  entityNames: null
};

export const entityNames: Module<EntityNamesState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
