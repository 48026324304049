import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/Hyphen.svg'


const _hoisted_1 = { class: "temperatureLog" }
const _hoisted_2 = { class: "temperatureLog__header" }
const _hoisted_3 = { class: "temperatureLog__header-group" }
const _hoisted_4 = {
  key: 1,
  class: "temperatureLog__header-notActivePickupBox"
}
const _hoisted_5 = { class: "temperatureLog__header-chooseDate" }
const _hoisted_6 = { class: "chooseDate-date" }
const _hoisted_7 = { class: "chooseDate-date-inputs" }
const _hoisted_8 = { class: "input" }
const _hoisted_9 = { class: "input" }
const _hoisted_10 = {
  key: 0,
  class: "date-error"
}
const _hoisted_11 = { class: "temperatureLog__body" }
const _hoisted_12 = {
  key: 0,
  class: "temperatureLog__body-diagram"
}
const _hoisted_13 = {
  key: 1,
  class: "temperatureLog__body-diagram-not-data"
}
const _hoisted_14 = {
  key: 2,
  class: "temperatureLog__body-table"
}
const _hoisted_15 = { class: "temperatureLog__body-table-text" }
const _hoisted_16 = {
  key: 3,
  class: "temperatureLog__body-table-noData"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_TemperatureCheck = _resolveComponent("TemperatureCheck")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading || !_ctx.chartLoaded)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.activePickupBox)
            ? (_openBlock(), _createBlock(_component_Dropdown, {
                key: 0,
                class: "temperatureLog__header-dropdown",
                selectedOption: _ctx.selectedPickupBox,
                options: _ctx.pickupBoxOptions,
                onSelectOption: _ctx.changePickupBox,
                "data-testid": "selectedPickupBox"
              }, null, 8, ["selectedOption", "options", "onSelectOption"]))
            : _createCommentVNode("", true),
          (!_ctx.activePickupBox)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('noPickupBox')), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_TemperatureCheck, { activePickupBox: _ctx.activePickupBox }, null, 8, ["activePickupBox"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Button, {
            class: "getPDF-button",
            variant: "bordered",
            title: _ctx.$t('downloadPDF'),
            onClick: _ctx.getPDF
          }, null, 8, ["title", "onClick"]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.buttonLoaded)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    class: "chooseDate-button",
                    variant: "bordered",
                    title: _ctx.$t('ok'),
                    onClick: _ctx.closeButoon
                  }, null, 8, ["title", "onClick"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_Calendar, {
                  type: "date",
                  format: "DD/MM/YYYY",
                  value: _ctx.dateRange.startTime,
                  name: "startTime",
                  onChange: _ctx.changeDate,
                  onClosePanel: _ctx.closeTimePanel,
                  "data-testid": "calendarStartTime"
                }, null, 8, ["value", "onChange", "onClosePanel"])
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("img", {
                class: "date-img",
                src: _imports_0,
                alt: "Hyphen"
              }, null, -1)),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_Calendar, {
                  type: "date",
                  format: "DD/MM/YYYY",
                  value: _ctx.dateRange.endTime,
                  name: "endTime",
                  onChange: _ctx.changeDate,
                  onClosePanel: _ctx.closeTimePanel,
                  "data-testid": "calendarEndTime"
                }, null, 8, ["value", "onChange", "onClosePanel"])
              ])
            ]),
            (!_ctx.isDateCorrect)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('errorDate')), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.dataLineChart?.labels.length && _ctx.chartLoaded && _ctx.isDateCorrect)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_LineChart, {
                id: 'lineChart',
                dataLineChart: _ctx.dataLineChart,
                optionsLineChart: _ctx.optionsLineChart,
                style: _normalizeStyle(_ctx.styleLineChart)
              }, null, 8, ["dataLineChart", "optionsLineChart", "style"])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.dataLineChart?.labels.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('noData')), 1)
            ]))
          : _createCommentVNode("", true),
        (
          _ctx.isDateCorrect && _ctx.isDatePresent && _ctx.chartLoaded && _ctx.alarmsData.length
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('deviationsInTimeFrame')), 1)
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_Table, {
                  data: _ctx.alarmsData,
                  columns: _ctx.columns
                }, null, 8, ["data", "columns"])
              ])
            ]))
          : _createCommentVNode("", true),
        (
          _ctx.isDateCorrect && _ctx.isDatePresent && _ctx.chartLoaded && !_ctx.alarmsData.length
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('noAlarms')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}