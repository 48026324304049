import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "temperatureCheck" }
const _hoisted_2 = {
  key: 0,
  class: "temperatureCheck__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      class: "temperatureCheck__btn",
      variant: "filled",
      title: _ctx.$t('timestempTemperatures'),
      onClick: _ctx.logTimestamp
    }, null, 8, ["title", "onClick"]),
    _createVNode(_component_Button, {
      class: "button-downloadCSV",
      variant: "bordered",
      title: _ctx.$t('downloadTempCSV'),
      onClick: _ctx.getCSV
    }, null, 8, ["title", "onClick"]),
    (_ctx.lastClickInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.lastClickInfo), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}