import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "pleaseWait"
}
const _hoisted_2 = {
  key: 2,
  class: "signicatVerify__error"
}
const _hoisted_3 = {
  class: "error",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.status?.message && _ctx.loading && !_ctx.authenticationSignicat)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('pleaseWait')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.status?.message && !_ctx.loading && !_ctx.authenticationSignicat)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.status.message)), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}