import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "feedbackMainTable" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "feedbackMainTable__totalCustomers"
}
const _hoisted_4 = { class: "numberOfUsers" }
const _hoisted_5 = { class: "customersFeedback__totalCustomer-table" }
const _hoisted_6 = {
  key: 1,
  class: "totalCustomers__noData"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableFeedback = _resolveComponent("TableFeedback")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.feedbackStatistics?.labels?.length && _ctx.isDateCorrect)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('totalCustomersVotes')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.feedbackStatistics?.tableData?.totalCustomers), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_TableFeedback, {
                data: _ctx.feedbackStatistics?.tableData?.totalByTypes,
                columns: _ctx.columns
              }, null, 8, ["data", "columns"])
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.feedbackStatistics?.labels?.length && _ctx.isDateCorrect)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('noData')), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}