
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import { generalOptions } from './configs';
import { Color, OptionsScreen } from '@/store/modules/pharmacy/types';

@Options({
  name: 'General',
  components: {
    Loading
  },

  data() {
    return {
      loading: false,
      generalOptions,
      formData: {
        screen_timeout: 10,
        logo: '',
        color: ''
      }
    };
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy'])
  },
  created() {
    this.show();
  },
  methods: {
    ...mapActions('pharmacy', ['getPharmacy', 'updateFeedbackTemplate']),
    async show() {
      this.loading = true;

      const { id } = this.$route.params;
      await this.getPharmacy(id);

      this.formData = {
        ...this.pharmacy.feedbackTemplate,
        color: `#${this.pharmacy.feedbackTemplate.color}`
      };

      this.loading = false;
    },
    changeColor(color: Color) {
      this.formData.color = color.hex;
    },
    updateLogo(imageSrc: string) {
      this.formData.logo = imageSrc;
    },
    async submitHandler(data: OptionsScreen) {
      this.loading = true;

      const payload = {
        ...data,
        screen_timeout: +this.formData.screen_timeout,
        logo: this.imageSrc || this.formData.logo,
        color: data.color.slice(1)
      };

      const { id } = this.$route.params;
      await this.updateFeedbackTemplate({ data: payload, pharmacyId: id });

      this.loading = false;

      this.$router.go(0);
    },
    async submitForm() {
      const logo = this.formData.logo;

      if (typeof logo === 'string') {
        const payload = {
          color: this.formData.color.slice(1),
          screen_timeout: +this.formData.screen_timeout
        };

        const { id } = this.$route.params;
        await this.updateFeedbackTemplate({ data: payload, pharmacyId: id });
        this.$router.go(0);
      } else {
        this.$formkit.submit('general-form');
      }
    }
  },
  watch: {
    pharmacy() {
      if (this.pharmacy) {
        this.formData = {
          ...this.pharmacy.feedbackTemplate,
          color: `#${this.pharmacy.feedbackTemplate.color}`
        };
      }
    }
  }
})
export default class General extends Vue {}
