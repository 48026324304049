import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loginInfo" }
const _hoisted_2 = { class: "loginInfo__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Title = _resolveComponent("Title")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Link, {
          title: _ctx.$t('backToUsers'),
          onBackTo: _ctx.backTo
        }, null, 8, ["title", "onBackTo"]),
        _createVNode(_component_Title, {
          title: _ctx.$t('loginInformation')
        }, null, 8, ["title"])
      ]),
      _createVNode(_component_Table, {
        data: _ctx.loginInfo,
        columns: _ctx.columns,
        onLoadMore: _ctx.changePage
      }, null, 8, ["data", "columns", "onLoadMore"])
    ])
  ], 64))
}