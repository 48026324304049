
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import ReservedLockers from './ReservedLockers.vue';

import {
  DataSortLocker,
  Locker,
  LockersType,
  Module,
  PickupBox
} from '@/store/modules/pharmacy/types';
import { InputFileEvent, Option } from '@/types';

import { lockers, kolliID } from './configs';

import router from '@/router';

@Options({
  name: 'QuickReservation',
  props: ['showModalQuickReservation', 'activeReservations'],
  emits: ['closeModal'],
  components: {
    Loading,
    ReservedLockers
  },
  data() {
    return {
      formData: {
        lockerId: [],
        deliveryId: ''
      },
      loading: false,
      selectedPickupBox: {
        value: '',
        label: ''
      },
      choosedLockers: [],
      validationRules: {},
      pickupBoxOptions: [],
      lockers,
      selectedLockers: 0,
      quantityLockers: { 0: 0, 1: 0, 2: 0, fridge: 0 },
      defaultQuantityLockers: { 0: 0, 1: 0, 2: 0, fridge: 0 },
      numberSelectedLockers: {},
      lockersId: [],
      kolliID,
      chooseLocker: false,
      disableButton: false,
      showModalReservedLockers: false
    };
  },
  computed: {
    ...mapState('pharmacy', ['pickupBoxes'])
  },
  created() {
    this.loadPickupBoxes();
    this.initPickupBoxOptions();
  },
  methods: {
    ...mapActions('pharmacy', [
      'assignDelivery',
      'getPharmacy',
      'getPickupBoxesByPharmacy'
    ]),
    async loadPickupBoxes() {
      const { id } = this.$route.params;

      await this.getPickupBoxesByPharmacy(id);
    },
    initPickupBoxOptions() {
      this.pickupBoxOptions = this.pickupBoxes.map((pickupBox: PickupBox) => ({
        value: pickupBox.info.id,
        label: pickupBox.info.name,
        img: require('@/assets/icons/PickupBox.svg')
      }));

      this.selectedPickupBox = this.pickupBoxOptions[0];
      this.activePickupBox = this.pickupBoxes[0];
      this.modules = this.activePickupBox?.info.modules;

      this.getQuantityLockers(this.modules);
    },
    changePickupBox(option: Option) {
      this.selectedPickupBox = option;
      this.activePickupBox = this.pickupBoxes.find(
        (pickupBox: PickupBox) => pickupBox.info.id === option.value
      );
      this.modules = this.activePickupBox?.info.modules;

      this.getQuantityLockers(this.modules);
    },
    getVariantButton() {
      if (this.defaultQuantityLockers !== this.quantityLockers) {
        return 'filled';
      } else {
        return 'disabled';
      }
    },
    getQuantityLockers(modules: Module[]) {
      const allModulesLockers = modules
        ?.map((module: Module) => module.lockers)
        .flat();

      const lockersActiveBox = allModulesLockers.reduce(
        (lockers: LockersType, currentLocker: Locker) => {
          return {
            0:
              lockers[0] +
              +(
                currentLocker.type === 0 &&
                !currentLocker.order &&
                currentLocker.status !== 'inactive'
              ),
            1:
              lockers[1] +
              +(
                currentLocker.type === 1 &&
                !currentLocker.order &&
                !currentLocker.fridge &&
                currentLocker.status !== 'inactive'
              ),
            2:
              lockers[2] +
              +(
                currentLocker.type === 2 &&
                !currentLocker.order &&
                currentLocker.status !== 'inactive'
              ),
            fridge:
              lockers.fridge +
              +(
                currentLocker.fridge &&
                !currentLocker.order &&
                currentLocker.status !== 'inactive'
              )
          };
        },
        { 0: 0, 1: 0, 2: 0, fridge: 0 }
      );

      this.defaultQuantityLockers = lockersActiveBox;
      this.quantityLockers = this.defaultQuantityLockers;

      const valuesIsZero = (currentValue: number) => currentValue === 0;

      if (lockersActiveBox) {
        const valuesLockersActiveBox =
          Object.values(lockersActiveBox).every(valuesIsZero);

        if (valuesLockersActiveBox) {
          return (this.disableContent = true);
        } else {
          return (this.disableContent = false);
        }
      }
    },
    handleChange(e: InputFileEvent) {
      const { name, value } = e.target;

      this.inputValue = value;

      if (name && +value === 1) {
        this.chooseLocker = true;

        if (+value < 0) {
          value;
        }

        if (name === 'small' && this.defaultQuantityLockers[0] !== 0) {
          this.quantityLockers = {
            ...this.quantityLockers,
            0: this.defaultQuantityLockers[0] - +value
          };
        } else if (name === 'medium' && this.defaultQuantityLockers[1] !== 0) {
          this.quantityLockers = {
            ...this.quantityLockers,
            1: this.defaultQuantityLockers[1] - +value
          };
        } else if (name === 'large' && this.defaultQuantityLockers[2] !== 0) {
          this.quantityLockers = {
            ...this.quantityLockers,
            2: this.defaultQuantityLockers[2] - +value
          };
        } else if (
          name === 'fridge' &&
          this.defaultQuantityLockers['fridge'] !== 0
        ) {
          this.quantityLockers = {
            ...this.quantityLockers,
            fridge: this.defaultQuantityLockers['fridge'] - +value
          };
        }
      }
    },
    getNumberSelectedLockers() {
      if (this.defaultQuantityLockers !== this.quantityLockers) {
        this.numberSelectedLockers = {
          0: this.defaultQuantityLockers[0] - this.quantityLockers[0],
          1: this.defaultQuantityLockers[1] - this.quantityLockers[1],
          2: this.defaultQuantityLockers[2] - this.quantityLockers[2],
          fridge:
            this.defaultQuantityLockers['fridge'] -
            this.quantityLockers['fridge']
        };
      }
    },
    getLockersId() {
      this.lockersId = [];

      const allModulesLockers = this.modules
        .map((module: Module, idx: number) =>
          module.lockers.map((locker: Locker) => ({ ...locker, moduleId: idx }))
        )
        .flat();

      const selectedLockersNumber = Object.entries(
        this.numberSelectedLockers
      ).filter(
        (lockerTypeAmount: [string, unknown]) => lockerTypeAmount[1] !== 0
      );

      selectedLockersNumber.forEach((selectedLocker: [string, unknown]) => {
        const lockersOfOneType = allModulesLockers
          .filter(
            (locker: Locker) =>
              ((locker.type === +selectedLocker[0] &&
                !locker.fridge &&
                !locker.inactive) ||
                (locker.fridge && selectedLocker[0] === 'fridge')) &&
              !locker.order
          )
          .sort(
            (a: DataSortLocker, b: DataSortLocker) => a.openCount - b.openCount
          );

        if (lockersOfOneType.length) {
          const lockerData = selectedLocker[1] as number;

          for (let i = 0; i < lockerData; i++) {
            if (lockersOfOneType[i]) {
              this.lockersId.push(
                `${lockersOfOneType[i].moduleId}-${lockersOfOneType[i].position}`
              );
            }
          }
        }
      });

      return this.lockersId;
    },
    async submitHandler() {
      this.loading = true;
      this.getNumberSelectedLockers();
      this.getLockersId();

      const payload = {
        assignToBox: true,
        boxInfo: {
          lockerId: [...this.lockersId],
          boxId: this.activePickupBox.info.id
        }
      };

      const { id } = this.$route.params;

      await this.assignDelivery({
        data: payload,
        pharmacyId: id,
        deliveryId: this.activeReservations.deliveryId
      });

      this.choosedLockers = payload.boxInfo.lockerId;
      this.showModalReservedLockers = true;

      await this.getPharmacy(id);

      this.$formkit.reset('quickReservation-form');
      this.loading = false;
    },
    submitForm() {
      this.$formkit.submit('quickReservation-form');
    },
    closeModal() {
      this.$emit('closeModal');
      this.$formkit.reset('quickReservation-form');
    },
    closeModalReservedLockers() {
      this.showModalReservedLockers = false;
      router.push({ name: 'APIReservations' });
    }
  },
  watch: {
    pickupBoxes() {
      this.initPickupBoxOptions();
    }
  }
})
export default class QuickReservation extends Vue {}
