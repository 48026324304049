
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

@Options({
  name: 'StrongAuthenticationRegister',
  components: {
    Loading
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState('account', ['status']),
    isFailed() {
      return this.status.message;
    }
  },
  created() {
    this.show();
  },
  methods: {
    ...mapActions('account', ['authorizeUserVieStrongAuthRedirect']),
    async show() {
      this.loading = true;

      const payload = {
        verificationData: window.location.search
      };

      await this.authorizeUserVieStrongAuthRedirect(payload);

      this.loading = false;
    }
  }
})
export default class StrongAuthenticationRegister extends Vue {}
