
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';

@Options({
  name: 'InfoAboutAlarm',
  props: ['activeAlarm'],
  methods: {
    dateTime(value: string) {
      return moment(value).format('DD.MM.YYYY HH:mm');
    }
  }
})
export default class InfoAboutAlarm extends Vue {}
