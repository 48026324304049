import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  LoginInfo,
  User,
  UserResult,
  UsersState
} from '@/store/modules/users/types';
import {
  addPharmacyAccesses,
  changePasswordUserId,
  createUser,
  getLoginInfo,
  getPharmacyAccesses,
  getUserById,
  getUsers,
  updatePharmacyAccess,
  updateUser,
  deleteUser
} from '@/services/axiosService';
import { UsersMutations } from './mutations';

const cleanLocalStorage = () => {
  localStorage.removeItem('users');
};

export const actions: ActionTree<UsersState, RootState> = {
  async showUsers({ commit }, { lastKey, pageSize, pharmacyId }) {
    try {
      const usersResult: readonly UserResult[] | undefined = await getUsers(
        lastKey,
        pageSize,
        pharmacyId
      );
      const users: readonly User[] = (usersResult || []).map(
        (user: UserResult) => ({
          ...user,
          role: user.role.name
        })
      );

      commit(UsersMutations.show_users, {
        users,
        lastKey,
        pageSize
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async createUser(_, data) {
    try {
      await createUser(data);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getUser({ commit }, id) {
    try {
      const user: UserResult | undefined = await getUserById(id);
      commit(UsersMutations.get_user, { user });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async updateUser({ commit }, { data, userId }) {
    try {
      await updateUser(data, userId);
      commit(UsersMutations.update_user, { data });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async changePasswordUserId(_, { data, userId }) {
    try {
      await changePasswordUserId(data, userId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async deleteUser({ commit }, userId) {
    try {
      await deleteUser(userId);
      commit(UsersMutations.delete_user, { userId });
    } catch {
      cleanLocalStorage();
    }
  },
  async getPharmacyAccesses({ commit }) {
    try {
      const pharmacyAccesses = await getPharmacyAccesses();
      commit(UsersMutations.get_pharmacyAccesses, { pharmacyAccesses });
    } catch (error: unknown) {
      throw new Error('user_is_not_found');
    }
  },
  async updatePharmacyAccess({ commit }, data) {
    try {
      await updatePharmacyAccess(data);
      commit(UsersMutations.update_pharmacy_access, { data });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },

  async addPharmacyAccesses(_, { ...data }) {
    try {
      const payload = {
        pharmacyId: data.pharmacyId,
        email: data.email,
        webToolAccess: data.webToolAccess,
        boxAccess: data.boxAccess,
        roleName: data.roleName
      };

      await addPharmacyAccesses(payload);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getLoginInfo({ commit }, { pharmacyId, limit, lastTimestamp }) {
    try {
      const loginInfo: LoginInfo[] | undefined = await getLoginInfo(
        pharmacyId,
        limit,
        lastTimestamp
      );

      commit(UsersMutations.get_login_info, { loginInfo, lastTimestamp });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  clearUsers({ commit }) {
    commit(UsersMutations.clear);
  },
  clearUser({ commit }) {
    commit(UsersMutations.clear_user);
  }
};
