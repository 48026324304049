import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "createUser" }
const _hoisted_2 = { class: "createUser__header" }
const _hoisted_3 = { class: "createUser__body" }
const _hoisted_4 = { class: "createUser__body-password-title" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "createUser__body-message" }
const _hoisted_7 = { class: "createUser__body-password-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Title = _resolveComponent("Title")!
  const _component_DisableAutocomplete = _resolveComponent("DisableAutocomplete")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Link, {
          title: _ctx.$t('backToUsers'),
          onBackTo: _ctx.backTo
        }, null, 8, ["title", "onBackTo"]),
        _createVNode(_component_Title, {
          title: _ctx.$t('createUser')
        }, null, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FormKit, {
          type: "form",
          id: "createUser-form",
          modelValue: _ctx.formData,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData) = $event)),
          actions: false,
          config: _ctx.validationRules,
          onSubmit: _ctx.submitHandler
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DisableAutocomplete),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoFromUser, (el) => {
              return (_openBlock(), _createBlock(_component_Input, {
                key: el.id,
                type: el.type,
                name: el.name,
                label: _ctx.$t(el.label),
                validation: el.validation,
                "data-testid": el.dataTestId
              }, null, 8, ["type", "name", "label", "validation", "data-testid"]))
            }), 128)),
            _createVNode(_component_Input, {
              name: "email",
              label: _ctx.$t('email'),
              type: _ctx.fieldTypes.email,
              validation: "required|email",
              validationMessages: {
            required: _ctx.$t('validation.required'),
            email: _ctx.$t('validation.email')
          },
              "data-testid": "inputEmail"
            }, null, 8, ["label", "type", "validationMessages"]),
            _createVNode(_component_Dropdown, {
              class: "createUser__body-dropdown",
              selectedOption: _ctx.langsDropdown.selectedOption,
              options: _ctx.langsDropdown.options,
              label: _ctx.$t('desktopLang'),
              onSelectOption: _ctx.selectLanguage
            }, null, 8, ["selectedOption", "options", "label", "onSelectOption"]),
            _createVNode(_component_Dropdown, {
              class: "createUser__body-dropdown",
              label: _ctx.$t('role'),
              selectedOption: _ctx.userRoleDropdown.selectedOption,
              options: _ctx.userRoleDropdown.options,
              onSelectOption: _ctx.selectUser,
              "data-testid": "dropdownUserRole"
            }, null, 8, ["label", "selectedOption", "options", "onSelectOption"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('password')), 1)
            ]),
            _createElementVNode("p", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.$t('addUserMainInfo')) + " ", 1),
              _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('addUserInfo')), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_PasswordInput, {
                name: "password",
                modelValue: "password",
                label: _ctx.$t('password'),
                type: _ctx.fieldTypes.password,
                validation: 'required|length:8',
                validationMessages: {
              required: _ctx.$t('validation.required'),
              length: _ctx.$t('validation.length8')
            },
                onFocus: _ctx.handleType,
                onBlur: _ctx.handleType
              }, null, 8, ["label", "type", "validationMessages", "onFocus", "onBlur"]),
              _createVNode(_component_PasswordInput, {
                name: "password_confirm",
                modelValue: "password_confirm",
                label: _ctx.$t('confirmPassword'),
                type: _ctx.fieldTypes.password,
                validation: 'required|confirm',
                validationMessages: {
              required: _ctx.$t('validation.required'),
              confirm: _ctx.$t('validation.confirm')
            },
                onFocus: _ctx.handleType,
                onBlur: _ctx.handleType
              }, null, 8, ["label", "type", "validationMessages", "onFocus", "onBlur"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "config", "onSubmit"])
      ])
    ]),
    _createVNode(_component_Footer, {
      onCleanForm: _ctx.cleanForm,
      onSubmitForm: _ctx.submitForm
    }, null, 8, ["onCleanForm", "onSubmitForm"])
  ], 64))
}