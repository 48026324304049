import { i18n } from '@/main';
import { ItemLineChart } from '@/store/modules/pharmacy/types';
import { Option, TranslationFunction } from '@/types';

export const columnsDeviations = ($t: TranslationFunction): Option[] => [
  {
    value: 'alarmType',
    label: $t('causeOfAlarmUpperCase')
  },
  {
    value: 'timestamp',
    label: $t('startTimeUpperCase')
  },
  {
    value: 'alarmEndTimestamp',
    label: $t('endTimeUpperCase')
  },
  {
    value: 'duration',
    label: $t('durationUpperCase')
  },
  {
    value: 'alarmMax',
    label: $t('maxUpperCase')
  },
  {
    value: 'alarmMin',
    label: $t('minUpperCase')
  },
  {
    value: 'sensor',
    label: $t('sensorUpperCase')
  },
  {
    value: 'averageTemp',
    label: $t('averageUpperCase')
  },
  {
    value: 'alarmComment',
    label: $t('alarmCommentUpperCase')
  }
];

export const optionsLineChart = {
  plugins: {
    tooltip: {
      displayColors: false,
      yAlign: 'bottom'
    },
    legend: {
      align: 'end',
      margin: 20,
      labels: {
        filter: (item: ItemLineChart): boolean | undefined => {
          if (item.text === i18n.global.t('Box Temperature')) {
            return item.text.includes(i18n.global.t('Box Temperature'));
          }
          if (item.text === i18n.global.t('Alarm')) {
            return item.text.includes(i18n.global.t('Alarm'));
          }
          if (item.text === i18n.global.t('Fridge 0-1 Temperature')) {
            return item.text.includes(i18n.global.t('Fridge 0-1 Temperature'));
          }
          if (item.text === i18n.global.t('Fridge 1-1 Temperature')) {
            return item.text.includes(i18n.global.t('Fridge 1-1 Temperature'));
          }
          if (item.text === i18n.global.t('Fridge 2-1 Temperature')) {
            return item.text.includes(i18n.global.t('Fridge 2-1 Temperature'));
          }
        },
        font: {
          family: 'Ubuntu-Regular',
          size: 13
        },
        color: '#1B242D',
        usePointStyle: true,
        pointStyle: 'circle',
        boxHeight: 4,
        boxWidth: 4
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y1: {
      grid: {
        tickColor: '#fff'
      },
      type: 'linear',
      position: 'left',
      max: 30,
      min: 0
    }
  }
};

export const columnsExport = ($t: TranslationFunction): Option[] => [
  {
    value: 'data',
    label: ''
  },
  {
    value: 'date',
    label: $t('date')
  },
  {
    value: 'temperature',
    label: $t('temperature')
  }
];
