import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  AppRole,
  AppRoleResult,
  RolesState
} from '@/store/modules/roles/types';
import { getAppRoles } from '@/services/axiosService';
import { RolesMutations } from './mutations';
import { getLabelRoleName } from './utils';

const cleanLocalStorage = () => {
  localStorage.removeItem('users');
};

export const actions: ActionTree<RolesState, RootState> = {
  async getAppRoles({ commit }) {
    try {
      // eslint-disable-next-line
      const appRolesResult: AppRoleResult[] | undefined | any =
        await getAppRoles();
      const appRoles: AppRole[] = Object.keys(appRolesResult).map(
        (name: string) => ({
          label: getLabelRoleName(name),
          value: name,
          level: appRolesResult[name].level,
          app_access: appRolesResult[name].app_access
        })
      );

      commit(RolesMutations.get_app_roles, { appRoles });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  }
};
