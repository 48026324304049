import { Locker, Module, PickupBox, Reservation } from './types';

export const cleanLocalStorage = (): void => {
  localStorage.removeItem('pharmacies');
};

export const reformatPickupBox = (initialPickupBox: PickupBox): PickupBox => {
  initialPickupBox.info.modules.forEach((module: Module) => {
    module.lockers.forEach((locker: Locker) => {
      locker.status = 'free';
    });
  });

  if (initialPickupBox) {
    const modules: Module[] = initialPickupBox.info.modules.map(
      (module: Module) => ({
        ...module
      })
    );

    Object.keys(modules).forEach((module) => {
      const lockers: Locker[] = modules[+module].lockers;

      Object.keys(lockers).forEach((locker) => {
        const lockerId: Locker = lockers[+locker];

        if (
          (!lockerId.inactive && lockerId.status === 'reserved') ||
          lockerId.status === 'expired'
        ) {
          lockerId.status;
        } else if (lockerId.inactive) {
          lockerId.status = 'inactive';
        }
      });
    });
  }

  if (initialPickupBox.reservations) {
    const reservations: readonly Reservation[] = initialPickupBox.reservations;

    Object.keys(initialPickupBox.reservations).forEach((reservation) => {
      // eslint-disable-next-line
      const reservationLockerId: any = reservations[+reservation];

      const lockersWithReservation: string[] =
        reservationLockerId.lockerId || '';

      if (lockersWithReservation) {
        const lockers = lockersWithReservation.filter(
          (id: string) => id.length !== 0
        );
        lockers.forEach((lockerId: string) => {
          const moduleIndex = parseInt(lockerId.split('-')[0]);
          const lockerIndex = parseInt(lockerId.split('-')[1]);

          if (initialPickupBox.info.modules[moduleIndex]) {
            initialPickupBox.info.modules[moduleIndex].lockers[lockerIndex] = {
              ...initialPickupBox.info.modules[moduleIndex].lockers[
                lockerIndex
              ],
              order: reservationLockerId
            };
          }
        });
      }
    });
  }

  return initialPickupBox;
};
