
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import {
  optionsLanguageEn,
  optionsLanguageFi,
  optionsLanguageSv,
  optionsLanguageNo,
  langsEn,
  langsFi,
  langsSv,
  langsNo
} from '@/services/languageService';

import { infoFromPikupBox, infoTemperature } from './configs';
import { Option } from '@/types';

@Options({
  name: 'PickupBoxForm',
  data() {
    return {
      formData: {
        name: '',
        id: '',
        street: '',
        zipCode: '',
        city: '',
        country: '',
        phoneNumber: '',
        uiLanguages: '',
        language: ''
      },
      infoFromPikupBox,
      infoTemperature,
      optionsLanguageEn,
      optionsLanguageFi,
      optionsLanguageSv,
      optionsLanguageNo,
      langsEn,
      langsFi,
      langsSv,
      langsNo,
      langsDropdown: {
        selectedOption: [],
        options: []
      }
    };
  },
  computed: {
    ...mapState('account', ['user'])
  },
  methods: {
    initLanguage() {
      if (this.user?.appLanguage === 'en') {
        this.langsDropdown.options = optionsLanguageEn;
      }
      if (this.user?.appLanguage === 'fi') {
        this.langsDropdown.options = optionsLanguageFi;
      }
      if (this.user?.appLanguage === 'sv') {
        this.langsDropdown.options = optionsLanguageSv;
      }
      if (this.user?.appLanguage === 'no') {
        this.langsDropdown.options = optionsLanguageNo;
      }
    },
    selectLanguage(option: Option) {
      this.langsDropdown.selectedOption = option;
    }
  },
  watch: {
    'langsDropdown.selectedOption': {
      immediate: true,
      handler() {
        const languages = this.langsDropdown.selectedOption.map(
          (i: Option) => i.label
        );
        const languagesValues = this.langsDropdown.selectedOption.map(
          (i: Option) => i.value
        );

        this.formData.language = languages[0];
        this.formData.uiLanguages = languagesValues || '';
      }
    },
    formData() {
      if (this.formData) {
        this.initLanguage();

        const uiLanguages = this.formData.uiLanguages;

        if (this.user?.appLanguage === 'en') {
          const selectedOption = uiLanguages.map((i: string) => ({
            label: langsEn[i],
            value: i
          }));

          this.langsDropdown.selectedOption = selectedOption;
        }

        if (this.user?.appLanguage === 'fi') {
          const selectedOption = uiLanguages.map((i: string) => ({
            label: langsFi[i],
            value: i
          }));

          this.langsDropdown.selectedOption = selectedOption;
        }
        if (this.user?.appLanguage === 'sv') {
          const selectedOption = uiLanguages.map((i: string) => ({
            label: langsSv[i],
            value: i
          }));

          this.langsDropdown.selectedOption = selectedOption;
        }
        if (this.user?.appLanguage === 'no') {
          const selectedOption = uiLanguages.map((i: string) => ({
            label: langsNo[i],
            value: i
          }));

          this.langsDropdown.selectedOption = selectedOption;
        }
      }
    }
  }
})
export default class PickupBoxForm extends Vue {}
