import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/Checkmark.svg'


const _hoisted_1 = { class: "checkbox" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "checkbox-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.name,
      class: "checkbox-label"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({ checkbox__checkmark: true, checked: _ctx.value })
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          class: "checkbox__checkmark-img",
          src: _imports_0,
          alt: ""
        }, null, -1)
      ]), 2),
      _createVNode(_component_Input, {
        id: _ctx.name,
        name: _ctx.name,
        class: "checkbox-input",
        type: "checkbox",
        checked: _ctx.value,
        disabled: _ctx.disabled
      }, null, 8, ["id", "name", "checked", "disabled"]),
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)
  ]))
}