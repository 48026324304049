
import { Options, Vue } from 'vue-class-component';

import InfoAboutAlarm from './InfoAboutAlarm/InfoAboutAlarm.vue';
import Loading from '@/views/Loading.vue';

@Options({
  name: 'AddAlarmComment',
  props: ['showModal', 'activeAlarm'],
  components: {
    InfoAboutAlarm,
    Loading
  },
  data() {
    return {
      loading: false,
      formData: {
        alarmInfo: ''
      }
    };
  },
  methods: {
    submitHandler() {
      this.$formkit.submit('alarmComment-form');
    },
    closeModalAddComment() {
      this.formData.alarmInfo = '';
      this.$emit('closeModal');
    }
  }
})
export default class AddAlarmComment extends Vue {}
