import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/Capacity.svg'
import _imports_1 from '@/assets/icons/Edit.svg'


const _hoisted_1 = {
  key: 0,
  class: "activePickupBox"
}
const _hoisted_2 = { class: "activePickupBox__header" }
const _hoisted_3 = { class: "activePickupBox__header-actions" }
const _hoisted_4 = { class: "activePickupBox__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoActiveBox = _resolveComponent("InfoActiveBox")!

  return (_ctx.activePickupBox)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('boxInfo')), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.capacityPickupBox && _ctx.capacityPickupBox(...args))),
              "data-testid": "activePickupBoxCapacity"
            }),
            _createElementVNode("img", {
              class: "activePickupBox__header-actions-img",
              src: _imports_1,
              alt: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editPickupBox())),
              "data-testid": "activePickupBoxEdit"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_InfoActiveBox, { activePickupBox: _ctx.activePickupBox }, null, 8, ["activePickupBox"])
        ])
      ]))
    : _createCommentVNode("", true)
}