import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/icons/ChangePassword.svg'


const _hoisted_1 = { class: "infoUser" }
const _hoisted_2 = { class: "infoUser__header" }
const _hoisted_3 = { class: "infoUser__card" }
const _hoisted_4 = {
  key: 1,
  class: "infoUser__card__lastUpdate-key"
}
const _hoisted_5 = {
  key: 0,
  class: "content"
}
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "subtitle" }
const _hoisted_8 = { class: "content" }
const _hoisted_9 = { class: "title" }
const _hoisted_10 = { class: "subtitle" }
const _hoisted_11 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Title = _resolveComponent("Title")!
  const _component_AboutUser = _resolveComponent("AboutUser")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_ChangePassword = _resolveComponent("ChangePassword")!
  const _component_DeleteUser = _resolveComponent("DeleteUser")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading || !_ctx.user)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Link, {
          title: _ctx.$t('backToUsers'),
          onBackTo: _ctx.navigateBack
        }, null, 8, ["title", "onBackTo"])
      ]),
      _createVNode(_component_Title, {
        title: _ctx.$t('user')
      }, null, 8, ["title"]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.user)
          ? (_openBlock(), _createBlock(_component_FormKit, {
              key: 0,
              type: "form",
              id: "addUserForm",
              modelValue: _ctx.formData,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData) = $event)),
              actions: false,
              onSubmit: _ctx.submitHandler
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AboutUser, {
                  userRoleDropdown: _ctx.userRoleDropdown,
                  onSelectUserRole: _ctx.selectUserRole
                }, null, 8, ["userRoleDropdown", "onSelectUserRole"]),
                _createVNode(_component_FormKit, {
                  type: "group",
                  name: "role"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accesses, (access, index) => {
                      return (_openBlock(), _createBlock(_component_Checkbox, {
                        key: index,
                        class: "infoUser__card-checkbox",
                        name: access.name,
                        value: _ctx.formData.role[access.name],
                        title: access.title,
                        onChange: ($event: any) => (_ctx.changeCheckboxes(access.name)),
                        "data-testid": access.testId
                      }, null, 8, ["name", "value", "title", "onChange", "data-testid"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "onSubmit"]))
          : _createCommentVNode("", true),
        (_ctx.user)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.user.updatedAt)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('lastUpdate')), 1),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.dateTime(_ctx.user?.updatedAt)), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('key')), 1),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.user?.id), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: "infoUser__changePassword",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = true))
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)),
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('changePassword')), 1)
      ]),
      (_ctx.isUserMain)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            variant: "redBordered",
            title: _ctx.$t('deleteUser'),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModalDeleteUser = true))
          }, null, 8, ["title"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Footer, {
      onCleanForm: _ctx.cleanForm,
      onSubmitForm: _ctx.submitForm
    }, null, 8, ["onCleanForm", "onSubmitForm"]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_ChangePassword, {
          key: 1,
          onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModal = false))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_DeleteUser, {
      showModalDeleteUser: _ctx.showModalDeleteUser,
      user: _ctx.user,
      onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModalDeleteUser = false)),
      onDeleteUserRequest: _ctx.deleteUserRequest
    }, null, 8, ["showModalDeleteUser", "user", "onDeleteUserRequest"])
  ], 64))
}