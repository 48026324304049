import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import store from '@/store';
import config from '../config';
import { getCurrentUserId } from '@/firebase/firebaseInit';
import { setHeaderToken } from '@/services/axiosService';
import router from '@/router';
import { DataError } from '@/store/types';

const baseUrl = config.baseUrl;

export const http = axios.create({
  baseURL: baseUrl
});

const getAuthToken = () => localStorage.getItem('token');

const authInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  config.headers = config.headers ?? {};

  config.headers['AuthToken'] = getAuthToken();
  return config;
};

const errorInterceptor = async (error: DataError) => {
  if (!error.response) {
    // no response field indicates server error
    return Promise.reject({ message: 'error_network_server_error' });
  }

  // handle error codes
  let msg = error.response.data || '';
  switch (error.response.status) {
    case 400:
      console.error(error.response.status, msg);
      break;

    case 401: // unauthorized
      if (
        msg.message === 'auth/id-token-expired' &&
        !error.config.retry &&
        getAuthToken()
      ) {
        // if id token expired and not retried yet, refresh id token and retry request
        try {
          const token = await getCurrentUserId();

          if (token === undefined) {
            throw new Error('user_token_can_not_be_retrieved');
          }
          localStorage.setItem('token', token);
          setHeaderToken(token);

          // try to refresh id token
          error.config.retry = true;
          return http.request(error.config);
        } catch (error) {
          // if token refresh fails, set "session expired" error message
          msg = 'error_session_expired';
        }
      }
      // if not returned yet, session has expired -> log out user with error message
      console.error(error.response.status, msg);
      store.dispatch('account/logoutToken', { msg });
      window.location.reload();

      break;

    case 403:
      if (!error.response.data.errors.includes('not_enough_permissions')) {
        store.dispatch('account/logoutToken');
      }
      break;

    case 404:
      if (error.response.data.errors.includes('no_content')) {
        router.push({ name: 'LocationNotAllowed' });
      }
      break;

    default:
      console.error(error.response.status, msg);
  }

  return Promise.reject(error);
};

const responseInterceptor = (response: AxiosResponse) => {
  switch (response.status) {
    case 200:
      break;
    default:
      break;
  }
  return response;
};

http.interceptors.request.use(authInterceptor);
http.interceptors.response.use(responseInterceptor, errorInterceptor);

export default { http };
