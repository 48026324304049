<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        v-if="shouldApplyClickOutside"
        v-click-outside="close"
      >
        <slot />
      </div>
      <div class="modal-container" v-else>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import vClickOutside from 'click-outside-vue3';

@Options({
  name: 'Modal',
  props: ['title'],
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    shouldApplyClickOutside() {
      return this.$route.name !== 'EditPharmacy';
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
})
export default class ModalComponent extends Vue {}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    background-color: $background-modal;
    .modal-container {
      max-width: 700px;
      max-height: 737px;
      width: max-content;
      height: max-content;
      margin: 0px auto;
      background-color: $white;
      border-radius: 8px;
      box-shadow: 0 2px 8px $box-shadow-modal;
      transition: all 0.3s ease;
    }
  }
}
</style>
