
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import { columns } from './configs';

import { downloadCSV } from '@/utils/downloadCSV';
import { writeTemperaturesById } from '@/services/axiosService';

import moment from 'moment';
import { TemperatureStamps } from '@/store/modules/pharmacy/types';

@Options({
  name: 'TemperatureCheck',
  props: ['activePickupBox'],
  components: {
    Loading
  },

  data() {
    return {
      columns: columns(this.$t),
      lastClickInfo: ''
    };
  },

  created() {
    this.initTemperatureStamps();
  },

  computed: {
    ...mapState('pharmacy', ['temperatureStamps']),

    isFullInfoWhenCheck() {
      const lastCheckedBy = this.$t('lastCheckedBy');
      const userName = this.temperatureStamps[0]?.userName;
      const dateTime = this.dateTime(this.temperatureStamps[0]?.timestamp);

      if (userName) {
        return `${lastCheckedBy} ${userName} ${dateTime}`;
      } else {
        return '';
      }
    },

    boxId() {
      return this.activePickupBox?.id;
    }
  },

  methods: {
    ...mapActions('pharmacy', ['getTemperaturesById']),

    async initTemperatureStamps() {
      if (this.boxId) {
        await this.getTemperaturesById(this.boxId);
        this.lastClickInfo = this.isFullInfoWhenCheck;
      }
    },

    async logTimestamp() {
      await writeTemperaturesById(this.boxId);
      this.initTemperatureStamps();
    },

    dateTime(value: number) {
      return moment(value).format('DD.MM.YYYY HH:mm');
    },

    getCSV(): void {
      const data = this.temperatureStamps.map((el: TemperatureStamps) => ({
        userName: el.userName,
        timestamp: this.dateTime(el.timestamp)
      }));

      const columns = [...this.columns];

      downloadCSV(data, columns, `${this.$t('temperatureChecking')}.csv`);
    }
  },

  watch: {
    activePickupBox() {
      this.initTemperatureStamps();
    }
  }
})
export default class TemperatureCheck extends Vue {}
