
import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';

import { TableDataStatistics } from '@/store/modules/orders/types';
import { Option } from '@/types';

@Options({
  name: 'TableFeedback',
  props: ['data', 'columns'],
  computed: {
    ...mapState('account', ['user']),
    ...mapState('pharmacy', ['pharmacy'])
  },
  methods: {
    getTranslatedLabel(label: string, language: string): string {
      const translations =
        this.pharmacy?.feedbackTemplate.options_screen.options[label]
          ?.translations;

      return translations?.[language] || translations?.['en'] || label;
    },
    getName(item: string): string {
      if (item !== 'Total') {
        const label = item.toLowerCase().replace(/ |-/g, '_');

        return this.getTranslatedLabel(label, this.user?.appLanguage);
      }

      return item;
    },
    getColumnValue(data: TableDataStatistics, column: Option) {
      switch (column.value) {
        case 'name':
          return this.getName(data.label);
        default:
          return data.data[column.value];
      }
    }
  }
})
export default class TableFeedback extends Vue {}
