import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/Apoteket.svg'
import _imports_1 from '@/assets/icons/EditGreen.svg'
import _imports_2 from '@/assets/icons/Green.svg'


const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "sidebar__container" }
const _hoisted_3 = {
  key: 0,
  class: "sidebar__container-menu-head"
}
const _hoisted_4 = { class: "pharmacy-name" }
const _hoisted_5 = { class: "head-block-edit" }
const _hoisted_6 = { class: "sidebar__container-menu-nav" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "isPending",
  src: _imports_2,
  alt: ""
}
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Logout = _resolveComponent("Logout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.user?.role.name !== 'janitor')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "head-img",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToPickupBox(_ctx.user?.pharmacyId, _ctx.pharmacy?.pickupBoxes[0])))
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Apoteket"
                }, null, -1)
              ])),
              _createElementVNode("div", {
                class: "head-block",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToEditPharmacy(_ctx.user.pharmacyId)))
              }, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.pharmacyName), 1),
                _createElementVNode("div", _hoisted_5, [
                  _cache[4] || (_cache[4] = _createElementVNode("img", {
                    src: _imports_1,
                    alt: "Edit"
                  }, null, -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('edit')), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("nav", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routesConfig, (el) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              class: _normalizeClass({
            'router-link': true,
            'router-link-none': _ctx.showElementsSideBar(el.translation),
            'router-link-padding': _ctx.user?.role.name === 'janitor'
          }),
              key: el.name,
              to: { name: el.name },
              onClick: ($event: any) => (_ctx.goTo(el.name))
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require(`@/assets/icons/${el.name}.svg`),
                  alt: ""
                }, null, 8, _hoisted_7),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t(`${el.translation}`)), 1),
                (_ctx.isPending && el.translation === 'callbackRequests')
                  ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["class", "to", "onClick"]))
          }), 128)),
          _createElementVNode("div", {
            class: "link-userGuide",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.downloadPDF && _ctx.downloadPDF(...args)))
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/icons/UserGuide.svg`),
              alt: ""
            }, null, 8, _hoisted_9),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('userGuide')), 1)
          ])
        ]),
        _createVNode(_component_Logout)
      ])
    ])
  ], 64))
}