import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "info",
  "data-testid": "infoId"
}
const _hoisted_2 = {
  key: 1,
  class: "info",
  "data-testid": "infoAddress"
}
const _hoisted_3 = {
  key: 2,
  class: "info",
  "data-testid": "infoLanguages"
}
const _hoisted_4 = {
  key: 3,
  class: "info",
  "data-testid": "infoVersionSW"
}
const _hoisted_5 = { class: "info infoDelivery" }
const _hoisted_6 = { class: "infoDelivery-item" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "content" }
const _hoisted_9 = {
  key: 0,
  class: "content-info",
  "data-testid": "infoDeliveryRulesBasicLockerKeepTime"
}
const _hoisted_10 = {
  key: 1,
  class: "content-info",
  "data-testid": "infoDeliveryRulesBasicLockerKeepTime"
}
const _hoisted_11 = {
  key: 2,
  class: "content-info",
  "data-testid": "infoDeliveryRulesBasicLockerKeepTime"
}
const _hoisted_12 = { class: "infoDelivery-item" }
const _hoisted_13 = { class: "title" }
const _hoisted_14 = { class: "content" }
const _hoisted_15 = {
  key: 0,
  class: "content-info",
  "data-testid": "infoDeliveryRulesBasicLockerKeepTime"
}
const _hoisted_16 = {
  key: 1,
  class: "content-info",
  "data-testid": "infoDeliveryRulesBasicLockerKeepTime"
}
const _hoisted_17 = {
  key: 2,
  class: "content-info",
  "data-testid": "infoDeliveryRulesBasicLockerKeepTime"
}
const _hoisted_18 = {
  key: 4,
  class: "info",
  "data-testid": "infoTemperatureInterval"
}
const _hoisted_19 = {
  key: 5,
  class: "info",
  "data-testid": "infoTemperatureAlarmInterval"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.activePickupBox.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('id')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activePickupBox.id), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activePickupBox.info.street || _ctx.activePickupBox.info.city)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('address')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activePickupBox.info.street) + " " + _toDisplayString(_ctx.activePickupBox.info.city), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activePickupBox.info.uiLanguages)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('languages')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.getLang(_ctx.activePickupBox.info.uiLanguages)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activePickupBox.info.currentSWVersion)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('versionSW')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activePickupBox.info.currentSWVersion), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('basicLocker')), 1),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.activePickupBox.info.deliveryRulesBasicLocker.deliveryKeepTime)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('deliveryExpirationTime')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.msToDays(
              _ctx.activePickupBox.info.deliveryRulesBasicLocker.deliveryKeepTime
            )), 1)
              ]))
            : _createCommentVNode("", true),
          (
            _ctx.activePickupBox.info.deliveryRulesBasicLocker
              .deliveryReminderMessagePeriod
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('reminderMessageTime')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.msToDays(
              _ctx.activePickupBox.info.deliveryRulesBasicLocker
                .deliveryReminderMessagePeriod
            )), 1)
              ]))
            : _createCommentVNode("", true),
          (
            _ctx.activePickupBox.info.deliveryRulesBasicLocker.reservationKeepTime
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('reservationExpirationTime')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.msToDays(
              _ctx.activePickupBox.info.deliveryRulesBasicLocker.reservationKeepTime
            )), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('fridgeLocker')), 1),
        _createElementVNode("div", _hoisted_14, [
          (_ctx.activePickupBox.info.deliveryRulesFridgeLocker.deliveryKeepTime)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('deliveryExpirationTime')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.msToDays(
              _ctx.activePickupBox.info.deliveryRulesFridgeLocker.deliveryKeepTime
            )), 1)
              ]))
            : _createCommentVNode("", true),
          (
            _ctx.activePickupBox.info.deliveryRulesFridgeLocker
              .deliveryReminderMessagePeriod
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('reminderMessageTime')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.msToDays(
              _ctx.activePickupBox.info.deliveryRulesFridgeLocker
                .deliveryReminderMessagePeriod
            )), 1)
              ]))
            : _createCommentVNode("", true),
          (
            _ctx.activePickupBox.info.deliveryRulesFridgeLocker.reservationKeepTime
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('reservationExpirationTime')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.msToDays(
              _ctx.activePickupBox.info.deliveryRulesFridgeLocker.reservationKeepTime
            )), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.activePickupBox.info.temperatureSendDataFromPickupboxIntervalTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('temperatureInterval')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.msToMin(_ctx.activePickupBox.info.temperatureSendDataFromPickupboxIntervalTime)), 1)
        ]))
      : _createCommentVNode("", true),
    (
      _ctx.activePickupBox.info.temperatureSendAlarmNotificationAfterIntervalTime
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('temperatureAlarmInterval')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.msToMin(
        _ctx.activePickupBox.info.temperatureSendAlarmNotificationAfterIntervalTime
      )), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}