import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/Down.svg'
import _imports_1 from '@/assets/icons/Red.svg'
import _imports_2 from '@/assets/icons/CloseValue.svg'
import _imports_3 from '@/assets/icons/SeeValue.svg'
import _imports_4 from '@/assets/icons/Add.svg'
import _imports_5 from '@/assets/icons/Qr-code.svg'
import _imports_6 from '@/assets/icons/Edit.svg'
import _imports_7 from '@/assets/icons/SMS.svg'
import _imports_8 from '@/assets/icons/Delete.svg'
import _imports_9 from '@/assets/icons/PickedUpReservation.svg'
import _imports_10 from '@/assets/icons/CallerAuthentication.svg'
import _imports_11 from '@/assets/icons/MarkAsMissed.svg'
import _imports_12 from '@/assets/icons/MarkAsDone.svg'


const _hoisted_1 = { class: "scroll" }
const _hoisted_2 = { class: "table" }
const _hoisted_3 = { class: "thead" }
const _hoisted_4 = { class: "column-main" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "column-main-img"
}
const _hoisted_7 = { class: "tbody" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "img-expired",
  src: _imports_1
}
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 4,
  class: "timestamp"
}
const _hoisted_14 = {
  key: 5,
  class: "column-ssn"
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 6,
  class: "column-ssn"
}
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["onClick"]
const _hoisted_25 = {
  key: 0,
  class: "tooltiptext"
}
const _hoisted_26 = { key: 9 }
const _hoisted_27 = ["src"]
const _hoisted_28 = { key: 1 }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = ["onClick", "data-testid"]
const _hoisted_31 = ["onClick", "data-testid"]
const _hoisted_32 = ["onClick", "data-testid"]
const _hoisted_33 = ["onClick"]
const _hoisted_34 = ["onClick", "data-testid"]
const _hoisted_35 = ["onClick", "data-testid"]
const _hoisted_36 = ["onClick"]
const _hoisted_37 = ["onClick"]
const _hoisted_38 = ["onClick"]
const _hoisted_39 = {
  class: "observer",
  ref: "observer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", _hoisted_3, [
        _createElementVNode("tr", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
            return (_openBlock(), _createElementBlock("th", {
              class: _normalizeClass({
              'column-main-title': true,
              'column-main-title-center': column.value === 'consentGiven'
            }),
              key: column
            }, [
              (
                _ctx.isCallbackRequests &&
                (column.value === 'Green' || column.value === 'Orange')
              )
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: require(`@/assets/icons/${column.value}.svg`),
                    alt: ""
                  }, null, 8, _hoisted_5))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(column.label === 'toOrder' ? '' : column.label) + " ", 1),
              (
                column.value === 'reservedTime' &&
                _ctx.$route.name === 'APIReservations'
              )
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: _normalizeClass({
                'img-down': true,
                'img-rotation': _ctx.changeSort
              }),
                    src: _imports_0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sortReservedTime')))
                  }, null, 2))
                : _createCommentVNode("", true)
            ], 2))
          }), 128)),
          (
              _ctx.isDeliveryHistory ||
              (_ctx.isUsers && _ctx.user?.role.permissions.user.delete) ||
              _ctx.isReservations
            )
            ? (_openBlock(), _createElementBlock("th", _hoisted_6))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("tbody", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (i, idx) => {
          return (_openBlock(), _createElementBlock("tr", {
            class: "column-content",
            key: i.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
              return (_openBlock(), _createElementBlock("td", {
                class: _normalizeClass({
              'column-content-title': true,
              'column-content-title-width': column.value === 'consentGiven'
            }),
                key: column,
                onClick: ($event: any) => (_ctx.selectRow(i, column.value))
              }, [
                (
                column.value === 'deliveryIds' &&
                _ctx.$route.name === 'Reservations' &&
                i.deliveryStatus === 'expired'
              )
                  ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                  : _createCommentVNode("", true),
                (column.value === 'deliveryStatus')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: 
                require(`@/assets/icons/${_ctx.getDeliveryStatus(
                  i[column.value]
                )}.svg`)
              ,
                      class: "status-img",
                      alt: "status"
                    }, null, 8, _hoisted_10))
                  : _createCommentVNode("", true),
                (column.value === 'status')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 2,
                      src: 
                require(`@/assets/icons/${_ctx.getCallbackRequestsStatus(
                  i[column.value]
                )}.svg`)
              ,
                      class: "status-img",
                      alt: "status"
                    }, null, 8, _hoisted_11))
                  : _createCommentVNode("", true),
                (column.value === 'pendingRequests')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 3,
                      src: 
                require(`@/assets/icons/${_ctx.getPendingRequests(
                  i[column.value]
                )}.svg`)
              ,
                      class: "status-img",
                      alt: "status"
                    }, null, 8, _hoisted_12))
                  : _createCommentVNode("", true),
                _createElementVNode("span", {
                  class: _normalizeClass({
                getColorConfirm: !i.confirmed && column.value === 'content',
                getColorForPending:
                  i.paymentInfo?.status === 'pending' &&
                  column.value === 'paymentInfoStatus',
                getColorForFailed:
                  (i.paymentInfo?.status === 'failed' ||
                    i.paymentInfo?.status === 'cancelled') &&
                  column.value === 'paymentInfoStatus',
                getColorForCompleted:
                  (i.paymentInfo?.status === 'completed' ||
                    i.paymentInfo?.status === 'done') &&
                  column.value === 'paymentInfoStatus',
                getPendingRequests: column.value === 'pendingRequests'
              })
                }, _toDisplayString(_ctx.getColumnValue(i, column)), 3),
                (_ctx.isOrdersHistory && column.value === 'content')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.dateTime(i.confirmedTime)), 1))
                  : _createCommentVNode("", true),
                (_ctx.isAuthenticationHistory && i.ssn && column.value === 'ssn')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      (_ctx.showSsn[i.timestamp] && i.ssn.length < 20)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(i.ssn), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_16, "**************")),
                      (_ctx.showSsn[i.timestamp])
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 2,
                            class: "img-eye",
                            src: _imports_2,
                            alt: "Hide",
                            onClick: ($event: any) => (_ctx.hideSsn(i.timestamp))
                          }, null, 8, _hoisted_17))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 3,
                            class: "img-eye",
                            src: _imports_3,
                            alt: "See Value",
                            onClick: [
                              ($event: any) => (_ctx.$emit('getSsn', i[this.selectedField])),
                              _withModifiers(($event: any) => (_ctx.getSsnOpen(i.timestamp)), ["stop"])
                            ]
                          }, null, 8, _hoisted_18))
                    ]))
                  : _createCommentVNode("", true),
                (
                _ctx.isOrdersHistory &&
                i.clientInfo.ssn &&
                column.value === 'clientInfoSSN'
              )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      (_ctx.showSsn[i.createdTime] && i.clientInfo.ssn.length < 20)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(i.clientInfo.ssn), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_21, "**************")),
                      (_ctx.showSsn[i.createdTime])
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 2,
                            class: "img-eye",
                            src: _imports_2,
                            alt: "Hide",
                            onClick: ($event: any) => (_ctx.hideSsn(i.createdTime))
                          }, null, 8, _hoisted_22))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 3,
                            class: "img-eye",
                            src: _imports_3,
                            alt: "See Value",
                            onClick: [
                              ($event: any) => (_ctx.$emit('getSsn', i[this.selectedField])),
                              _withModifiers(($event: any) => (_ctx.getSsnOpen(i.createdTime)), ["stop"])
                            ]
                          }, null, 8, _hoisted_23))
                    ]))
                  : _createCommentVNode("", true),
                (
                (_ctx.isAuthenticationHistory || _ctx.isOrdersHistory) &&
                column.value === 'consentGiven'
              )
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 7,
                      class: _normalizeClass({
                'column-consentGiven': true,
                tootip: _ctx.isAuthenticationHistory || _ctx.isOrdersHistory
              })
                    }, [
                      _createVNode(_component_Checkbox, {
                        name: "consentGiven",
                        value: _ctx.getDataConsentGiven(i.authenticated)
                      }, null, 8, ["value"]),
                      (_ctx.isAuthenticationHistory || _ctx.isOrdersHistory)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass({
                  tooltiptext:
                    true && (_ctx.isAuthenticationHistory || _ctx.isOrdersHistory),
                  tooltipTextAuthenticationHistory:
                    _ctx.isAuthenticationHistory || _ctx.isOrdersHistory,
                  tooltipTextDisplayNone:
                    i.authenticated != 'done' && !_ctx.isOrdersHistory
                })
                          }, _toDisplayString(_ctx.$t('consentGivenText')), 3))
                        : _createCommentVNode("", true)
                    ], 2))
                  : _createCommentVNode("", true),
                (column.value === 'assign')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 8,
                      class: _normalizeClass({
                'column-content-img': !_ctx.isChooseDelivery,
                tootip: _ctx.isChooseDelivery
              })
                    }, [
                      _createElementVNode("img", {
                        class: "img",
                        src: _imports_4,
                        alt: "Add",
                        onClick: ($event: any) => (_ctx.$emit('assignDeliveryTable', i[this.selectedField]))
                      }, null, 8, _hoisted_24),
                      (_ctx.isChooseDelivery)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.$t('chooseThisDelivery')), 1))
                        : _createCommentVNode("", true)
                    ], 2))
                  : _createCommentVNode("", true),
                (column.value === 'options')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.data, (el, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "column-content-emotion",
                          key: index
                        }, [
                          (i.data !== 'null' && i.data !== '[]')
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: "img-emotion",
                                src: require(`@/assets/icons/${_ctx.getImg(el.grade)}.png`),
                                alt: ""
                              }, null, 8, _hoisted_27))
                            : _createCommentVNode("", true),
                          (i.data !== 'null' && i.data !== '[]')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_28, " - " + _toDisplayString(_ctx.getFeedbackType(el.type)), 1))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_8))
            }), 128)),
            (_ctx.isReservations)
              ? (_openBlock(), _createElementBlock("td", {
                  key: 0,
                  class: "column-content-img",
                  onClick: ($event: any) => (_ctx.$emit('openQRCode', i[this.selectedField]))
                }, _cache[1] || (_cache[1] = [
                  _createElementVNode("img", {
                    class: "img",
                    src: _imports_5,
                    alt: "Qr-code"
                  }, null, -1)
                ]), 8, _hoisted_29))
              : _createCommentVNode("", true),
            (_ctx.isReservations || _ctx.isOrdersHistory)
              ? (_openBlock(), _createElementBlock("td", {
                  key: 1,
                  class: "column-content-img",
                  onClick: ($event: any) => (_ctx.$emit('addDescription', i[this.selectedField])),
                  "data-testid": 'editDescription' + idx
                }, _cache[2] || (_cache[2] = [
                  _createElementVNode("img", {
                    class: "img",
                    src: _imports_6,
                    alt: "Edit"
                  }, null, -1)
                ]), 8, _hoisted_30))
              : _createCommentVNode("", true),
            (_ctx.isDeliveryHistory || _ctx.isAlarms)
              ? (_openBlock(), _createElementBlock("td", {
                  key: 2,
                  class: "column-content-img",
                  onClick: ($event: any) => (_ctx.selectField(i)),
                  "data-testid": 'editDescriptionDeliveryHistory' + idx
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("img", {
                    class: "img",
                    src: _imports_6,
                    alt: "Edit"
                  }, null, -1)
                ]), 8, _hoisted_31))
              : _createCommentVNode("", true),
            (_ctx.isReservations)
              ? (_openBlock(), _createElementBlock("td", {
                  key: 3,
                  class: _normalizeClass({
              'column-content-img':
                !i.lockerId || i.deliveryStatus === 'delivered',
              'column-content-img-displayNone':
                i.lockerId || i.deliveryStatus !== 'delivered'
            }),
                  onClick: ($event: any) => (_ctx.$emit('resendSMSRequest', i[this.selectedField])),
                  "data-testid": 'resendSMS' + idx
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("img", {
                    class: "img imgSMS",
                    src: _imports_7,
                    alt: "Resend SMS"
                  }, null, -1)
                ]), 10, _hoisted_32))
              : _createCommentVNode("", true),
            (_ctx.isAPIReservations)
              ? (_openBlock(), _createElementBlock("td", {
                  key: 4,
                  class: "column-content-img",
                  onClick: ($event: any) => (_ctx.$emit('quickReservation', i[this.selectedField]))
                }, [
                  _createVNode(_component_Button, {
                    class: "quickReservation__button",
                    variant: "bordered",
                    title: _ctx.$t('quickReservation')
                  }, null, 8, ["title"])
                ], 8, _hoisted_33))
              : _createCommentVNode("", true),
            ((_ctx.isReservations && !i.lockerId) || _ctx.isAPIReservations)
              ? (_openBlock(), _createElementBlock("td", {
                  key: 5,
                  class: "column-content-img",
                  onClick: ($event: any) => (_ctx.$emit('deleteReservationTable', i[this.selectedField])),
                  "data-testid": 'deleteReservation' + idx
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("img", {
                    class: "img imgDelete",
                    src: _imports_8,
                    alt: "Delete"
                  }, null, -1)
                ]), 8, _hoisted_34))
              : _createCommentVNode("", true),
            (_ctx.isReservations || _ctx.isAPIReservations)
              ? (_openBlock(), _createElementBlock("td", {
                  key: 6,
                  class: _normalizeClass({
              'column-content-img':
                (_ctx.isReservations && !i.lockerId) || _ctx.isAPIReservations,
              'column-content-img-displayNone': i.lockerId && !_ctx.isAPIReservations
            }),
                  onClick: ($event: any) => (_ctx.$emit('pickedUpReservation', i[this.selectedField])),
                  "data-testid": 'pickedUpReservation' + idx
                }, _cache[6] || (_cache[6] = [
                  _createElementVNode("img", {
                    class: "img imgReservation",
                    src: _imports_9,
                    alt: "Picked Up Reservation"
                  }, null, -1)
                ]), 10, _hoisted_35))
              : _createCommentVNode("", true),
            (_ctx.isCallbackRequests)
              ? (_openBlock(), _createElementBlock("td", {
                  key: 7,
                  class: _normalizeClass({
              'column-content-title': true,
              'column-content-title-marks': i.status !== 'done'
            })
                }, [
                  (i.status !== 'done')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass({
                'column-content-img': true,
                tootip: _ctx.isCallbackRequests
              })
                      }, [
                        _createElementVNode("img", {
                          class: "img",
                          src: _imports_10,
                          alt: "Caller Authentication",
                          onClick: ($event: any) => (
                  _ctx.$emit('openCallerAuthentication', i[this.selectedField])
                )
                        }, null, 8, _hoisted_36),
                        _createElementVNode("span", {
                          class: _normalizeClass({
                  tooltiptext: true,
                  tooltiptextCallbackRequestsLong: _ctx.isCallbackRequests
                })
                        }, _toDisplayString(_ctx.$t('backToCallerAuthentication')), 3)
                      ], 2))
                    : _createCommentVNode("", true),
                  (i.status === 'pending')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass({
                'column-content-img': true,
                tootip: _ctx.isCallbackRequests
              })
                      }, [
                        _createElementVNode("img", {
                          class: _normalizeClass({ img: true, imgMarks: i.status && _ctx.isCallbackRequests }),
                          src: _imports_11,
                          alt: "Mark as Missed",
                          onClick: ($event: any) => (_ctx.$emit('markAsMissed', i[this.selectedField]))
                        }, null, 10, _hoisted_37),
                        _createElementVNode("span", {
                          class: _normalizeClass({
                  tooltiptext: true,
                  tooltiptextCallbackRequests: _ctx.isCallbackRequests
                })
                        }, _toDisplayString(_ctx.$t('markAsMissed')), 3)
                      ], 2))
                    : _createCommentVNode("", true),
                  (i.status !== 'done')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass({
                'column-content-img': true,
                tootip: _ctx.isCallbackRequests
              })
                      }, [
                        _createElementVNode("img", {
                          class: _normalizeClass({ img: true, imgMarks: i.status && _ctx.isCallbackRequests }),
                          src: _imports_12,
                          alt: "Mark as Done",
                          onClick: ($event: any) => (_ctx.$emit('markAsDone', i[this.selectedField]))
                        }, null, 10, _hoisted_38),
                        _createElementVNode("span", {
                          class: _normalizeClass({
                  tooltiptext: true,
                  tooltiptextCallbackRequests: _ctx.isCallbackRequests
                })
                        }, _toDisplayString(_ctx.$t('markAsDone')), 3)
                      ], 2))
                    : _createCommentVNode("", true)
                ], 2))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_39, null, 512)
  ]))
}