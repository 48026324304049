export const columns = [
  {
    label: '',
    value: 'name'
  },
  {
    label: 'HappyPressed',
    value: 'GOOD'
  },
  {
    label: 'OkPressed',
    value: 'OK'
  },
  {
    label: 'AngryPressed',
    value: 'BAD'
  },
  {
    label: '',
    value: 'all'
  }
];
