import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/Hyphen.svg'


const _hoisted_1 = { class: "customers" }
const _hoisted_2 = { class: "customers__chooseDate" }
const _hoisted_3 = { class: "customers__chooseDate__container" }
const _hoisted_4 = { class: "chooseDate-date-inputs" }
const _hoisted_5 = { class: "input" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = {
  key: 0,
  class: "date-error"
}
const _hoisted_8 = { class: "customers__container" }
const _hoisted_9 = { class: "customers__checkboxes" }
const _hoisted_10 = { class: "customers__option-dropdowns" }
const _hoisted_11 = { class: "option-dropdowns" }
const _hoisted_12 = { class: "grade-dropdowns" }
const _hoisted_13 = {
  key: 2,
  class: "customers__noData"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Calendar, {
              type: "datetime",
              format: "DD/MM/YYYY HH:mm",
              value: _ctx.firstDay,
              name: "startTime",
              minuteStep: _ctx.selectedInterval,
              hourOptions: _ctx.hourInterval,
              onChange: _ctx.changeDate
            }, null, 8, ["value", "minuteStep", "hourOptions", "onChange"])
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            class: "date-img",
            src: _imports_0,
            alt: "Hyphen"
          }, null, -1)),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Calendar, {
              type: "datetime",
              format: "DD/MM/YYYY HH:mm",
              value: _ctx.today,
              name: "endTime",
              onChange: _ctx.changeDate
            }, null, 8, ["value", "onChange"])
          ])
        ])
      ]),
      (!_ctx.isDateCorrect)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('errorDate')), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkboxes, (checkbox, index) => {
          return (_openBlock(), _createBlock(_component_Checkbox, {
            key: index,
            class: "customers-checkbox",
            name: checkbox.name,
            value: checkbox.value,
            title: _ctx.$t(checkbox.title),
            onChange: ($event: any) => (_ctx.toggleCheckbox(checkbox.name))
          }, null, 8, ["name", "value", "title", "onChange"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_Dropdown, {
            class: _normalizeClass({
              'customers-dropdown': true,
              'customers-dropdown-disabled': !_ctx.checkboxes[1].value
            }),
            selectedOption: _ctx.getOptionFace.selectedOption,
            options: _ctx.getOptionFace.options,
            multiple: true,
            disabled: !_ctx.checkboxes[1].value,
            onSelectOption: _ctx.selectOptionFace
          }, null, 8, ["class", "selectedOption", "options", "disabled", "onSelectOption"]),
          _createVNode(_component_Dropdown, {
            class: _normalizeClass({
              'customers-dropdown': true,
              'customers-dropdown-disabled': !_ctx.checkboxes[1].value
            }),
            selectedOption: _ctx.getOptionType.selectedOption,
            options: _ctx.getOptionType.options,
            multiple: true,
            disabled: !_ctx.checkboxes[1].value,
            onSelectOption: _ctx.selectOptionType
          }, null, 8, ["class", "selectedOption", "options", "disabled", "onSelectOption"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_Dropdown, {
            class: _normalizeClass({
              'customers-dropdown': true,
              'customers-dropdown-disabled': !_ctx.checkboxes[3].value
            }),
            selectedOption: _ctx.getOptionGrade.selectedOption,
            options: _ctx.getOptionGrade.options,
            disabled: !_ctx.checkboxes[3].value,
            onSelectOption: _ctx.selectOptionGrade
          }, null, 8, ["class", "selectedOption", "options", "disabled", "onSelectOption"]),
          _createVNode(_component_Dropdown, {
            class: _normalizeClass({
              'customers-dropdown': true,
              'customers-dropdown-disabled':
                !_ctx.checkboxes[3].value ||
                _ctx.getOptionGrade.selectedOption.label === 'Grade'
            }),
            selectedOption: _ctx.getGradeType.selectedOption,
            options: _ctx.getGradeType.options,
            disabled: 
              !_ctx.checkboxes[3].value ||
              _ctx.getOptionGrade.selectedOption.label === 'Grade'
            ,
            onSelectOption: _ctx.selectGradeType
          }, null, 8, ["class", "selectedOption", "options", "disabled", "onSelectOption"])
        ])
      ])
    ]),
    (_ctx.filteredFeedback.length)
      ? (_openBlock(), _createBlock(_component_Table, {
          key: 1,
          data: _ctx.filteredFeedback,
          columns: _ctx.columns,
          onLoadMore: _ctx.changePage
        }, null, 8, ["data", "columns", "onLoadMore"]))
      : _createCommentVNode("", true),
    (!_ctx.filteredFeedback.length && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('noData')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}