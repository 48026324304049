import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/Add.svg'


const _hoisted_1 = { class: "addInfo-span" }
const _hoisted_2 = { class: "addInfo-span" }
const _hoisted_3 = {
  key: 0,
  class: "tooltiptext"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      addInfo: _ctx.user?.role.permissions.pharmacy.update,
      'addInfo-disabled': !_ctx.user?.role.permissions.pharmacy.update,
      tootip: !_ctx.user?.role.permissions.pharmacy.update
    })
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('add')), 1),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
    (!_ctx.user?.role.permissions.pharmacy.update)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('enoughPermissions')), 1))
      : _createCommentVNode("", true)
  ], 2))
}