import { i18n } from '@/main';
import { ItemLineChart } from '@/store/modules/pharmacy/types';

export const optionsLineChart = {
  plugins: {
    tooltip: {
      displayColors: false,
      yAlign: 'bottom'
    },
    legend: {
      labels: {
        filter: (item: ItemLineChart): boolean | undefined => {
          if (item.text === i18n.global.t('happy')) {
            return item.text.includes(i18n.global.t('happy'));
          }
          if (item.text === i18n.global.t('ok')) {
            return item.text.includes(i18n.global.t('ok'));
          }
          if (item.text === i18n.global.t('angry')) {
            return item.text.includes(i18n.global.t('angry'));
          }
        },
        font: {
          family: 'Ubuntu-Regular',
          size: 13
        },
        usePointStyle: true,
        pointStyle: 'circle',
        boxHeight: 4,
        boxWidth: 4
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y1: {
      grid: {
        tickColor: '#fff'
      },
      type: 'linear',
      position: 'left'
    }
  }
};

export const optionsBarChart = {
  plugins: {
    tooltip: {
      displayColors: false,
      yAlign: 'bottom'
    },
    legend: {
      labels: {
        filter: (item: ItemLineChart): boolean | undefined => {
          if (item.text === i18n.global.t('numberOfUsers')) {
            return item.text.includes(i18n.global.t('numberOfUsers'));
          }
        },
        font: {
          family: 'Ubuntu-Regular',
          size: 13
        },
        color: '#82828287'
      }
    }
  }
};
