
import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';

import router from '@/router';
import Loading from '@/views/Loading.vue';

import { tabs } from './configs';
import { RouteParamsRaw } from 'vue-router';
import { Option } from '@/types';

@Options({
  name: 'PickupBoxes',
  components: {
    Loading
  },
  data() {
    return {
      tabs,
      pharmacyId: '',
      boxId: '',
      loading: false,
      stop: false
    };
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy']),
    ...mapState('account', ['user'])
  },
  created() {
    if (this.user) {
      if (!this.user.role.permissions.pickupbox.update) {
        this.tabs = this.tabs.filter(
          (i: Option) => i.value !== 'InfoPickupBox'
        );
      }

      this.pharmacyId = this.user?.pharmacyId;
      this.show();
    }
  },
  methods: {
    async show() {
      this.loading = true;
      if (this.pharmacy) {
        this.boxId = this.pharmacy?.pickupBoxes[0] || 'no-lockers';

        if (this.$route.name === 'PickupBoxes') {
          if (this.pharmacy?.pickupBoxes[0]) {
            this.setActiveTab('Lockers');
          }
        }
      }

      this.loading = false;
    },
    setActiveTab(tabValue: string) {
      const params: RouteParamsRaw = {
        id: this.pharmacyId
      };

      if (tabValue === 'Lockers') {
        params.boxId = this.boxId;
      }

      router.push({ name: tabValue, params });
    }
  },
  watch: {
    user() {
      if (this.user) {
        if (!this.user.role.permissions.pickupbox.update) {
          this.tabs = this.tabs.filter(
            (i: Option) => i.value !== 'InfoPickupBox'
          );
        }

        this.pharmacyId = this.user?.pharmacyId;
        this.show();
      }
    },
    pharmacy() {
      if (this.pharmacy) {
        this.show();
      }
    }
  }
})
export default class PickupBoxes extends Vue {}
