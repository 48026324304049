import { MutationTree } from 'vuex';
import {
  PharmacyState,
  PickupBox,
  Reservation
} from '@/store/modules/pharmacy/types';

export enum PharmacyMutations {
  loaded_true = 'loaded_true',
  get_pharmacy_error = 'get_pharmacy_error',
  show_pharmacies = 'show_pharmacies',
  get_pharmacy = 'get_pharmacy',
  update_pharmacy = 'update_pharmacy',
  get_pickup_box = 'get_pickup_box',
  get_pickup_box_capacity = 'get_pickup_box_capacity',
  clear_message = 'clear_message',
  get_pickup_box_by_id = 'get_pickup_box_by_id',
  toggle_active_pickup_box = 'toggle_active_pickup_box',
  toggle_locker_pickup_box = 'toggle_locker_pickup_box',
  save_pharmacy_form = 'save_pharmacy_form',
  get_delivery_history = 'get_delivery_history',
  get_decoded_ssn = 'get_decoded_ssn',
  get_capacity_pickup_box_id = 'get_capacity_pickup_box_id',
  get_capacity_pickup_box_type = 'get_capacity_pickup_box_type',
  remove_delivery_history = 'remove_delivery_history',
  get_deliveries_statistics = 'get_deliveries_statistics',
  get_alarms_tab = 'get_alarms_tab',
  get_temperature_log = 'get_temperature_log',
  get_temperatures_by_id = 'get_temperatures_by_id',
  update_description = 'update_description',
  update_alarn_comment = 'update_alarn_comment',
  clear_alarms = 'clear_alarms',
  clear_pharmacy = 'clear_pharmacy'
}

export const mutations: MutationTree<PharmacyState> = {
  [PharmacyMutations.loaded_true](state) {
    state.status.loaded = true;
  },
  [PharmacyMutations.get_pharmacy_error](state, message: string) {
    state.status.loaded = false;
    state.status.error = true;
    state.status.message = message;
  },
  [PharmacyMutations.show_pharmacies](state, { pharmacies }) {
    state.pharmacies = pharmacies;

    state.status.loaded = true;
  },
  [PharmacyMutations.clear_message](state) {
    state.status.error = false;
    state.status.message = null;
  },
  [PharmacyMutations.get_pharmacy](state, { pharmacy }) {
    state.pharmacy = pharmacy;
  },
  [PharmacyMutations.update_pharmacy](state, { pharmacy }) {
    state.pharmacy = pharmacy;
  },
  [PharmacyMutations.get_pickup_box](state, { pickupBoxes }) {
    state.pickupBoxes = pickupBoxes.map((pickupBox: PickupBox) => {
      return {
        ...pickupBox,
        reservations: pickupBox.reservations.map(
          (reservation: Reservation) => ({
            ...reservation,
            active: pickupBox.info.active
          })
        )
      };
    });
  },
  [PharmacyMutations.get_pickup_box_by_id](state, { pickupBox }) {
    state.pickupBox = pickupBox;
  },
  [PharmacyMutations.get_temperatures_by_id](state, { temperatureStamps }) {
    state.temperatureStamps = temperatureStamps;
  },
  [PharmacyMutations.save_pharmacy_form](state, pharmacyForm) {
    state.pharmacyForm = pharmacyForm;
  },
  [PharmacyMutations.get_pickup_box_capacity](state, { capacity }) {
    state.capacity = capacity;
  },
  [PharmacyMutations.toggle_active_pickup_box](state, { pickupBox }) {
    state.pickupBox = pickupBox;
  },
  [PharmacyMutations.toggle_locker_pickup_box](state, { pickupBox }) {
    state.pickupBox = pickupBox;
  },
  [PharmacyMutations.get_delivery_history](
    state,
    { boxId_or_pharmacyId, deliveryHistory }
  ) {
    state.deliveryHistory = {
      ...state.deliveryHistory,
      [boxId_or_pharmacyId]: deliveryHistory
    };
  },
  [PharmacyMutations.get_capacity_pickup_box_type](
    state,
    { capacityPickupBoxType }
  ) {
    state.capacityPickupBoxType = capacityPickupBoxType;
  },
  [PharmacyMutations.get_capacity_pickup_box_id](
    state,
    { capacityPickupBoxIdResult }
  ) {
    state.capacityPickupBoxIdResult = capacityPickupBoxIdResult;
  },
  [PharmacyMutations.get_decoded_ssn](state, { decodedSsn }) {
    state.decodedSsn = decodedSsn;
  },
  [PharmacyMutations.get_deliveries_statistics](
    state,
    { deliveriesStatistics }
  ) {
    state.deliveriesStatistics = deliveriesStatistics;
  },
  [PharmacyMutations.get_alarms_tab](state, { alarms }) {
    state.alarms = alarms;
  },
  [PharmacyMutations.get_temperature_log](state, { temperatureLog }) {
    state.temperatureLog = temperatureLog;
  },
  [PharmacyMutations.update_description](state, { description }) {
    state.description = description;
  },
  [PharmacyMutations.update_alarn_comment](state, { alarmComment }) {
    state.alarmComment = alarmComment;
  },
  [PharmacyMutations.clear_alarms](state) {
    state.alarms = [];
  },
  [PharmacyMutations.clear_pharmacy](state) {
    state.pharmacy = null;
  }
};
