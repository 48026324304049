
import { Options, Vue } from 'vue-class-component';

import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

@Options({
  name: 'Calendar',
  props: [
    'name',
    'message',
    'value',
    'type',
    'format',
    'minuteStep',
    'hourOptions'
  ],
  components: {
    DatePicker
  },
  data() {
    return {
      defaultValue: null
    };
  },
  mounted() {
    this.defaultValue = this.value;
  }
})
export default class CalendarComponent extends Vue {}
