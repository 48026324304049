import { RootState } from '@/store/types';
import {
  DecodedSSN,
  FeedbackStatistics,
  IssuePaymentData,
  OrderPayment,
  OrderPaymentState,
  OrderHistory
} from './types';
import { ActionTree } from 'vuex';
import {
  getConfirmContent,
  getFeedbackStatistics,
  getIssuePaymentPush,
  getOrderCreate,
  getViewOrderById,
  getOrdersHistory,
  getOrdersHistoryDecodedSSN,
  resendPaymentNotification,
  getFeedbackStatisticsWeek,
  cancelPaymentRequest,
  addComment
} from '@/services/axiosService';
import { OrderPaymentMutations } from './mutations';

const cleanLocalStorage = () => {
  localStorage.removeItem('users');
};

export const actions: ActionTree<OrderPaymentState, RootState> = {
  async getIssuePaymentPush({ commit }, phoneNumber) {
    try {
      const orderPushNotification: IssuePaymentData | undefined =
        await getIssuePaymentPush(phoneNumber);

      commit(OrderPaymentMutations.get_order_push_notification, {
        orderPushNotification
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getOrderCreate({ commit }, data) {
    try {
      const orderPayment: OrderPayment | undefined = await getOrderCreate(data);

      commit(OrderPaymentMutations.get_order_create, {
        orderPayment
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getFeedbackStatistics(
    { commit },
    { pharmacyId, startTime, endTime, minutesInterval, chartFields }
  ) {
    try {
      const feedbackStatistics: FeedbackStatistics | undefined =
        await getFeedbackStatistics(
          pharmacyId,
          startTime,
          endTime,
          minutesInterval,
          chartFields
        );

      commit(OrderPaymentMutations.get_feedback_statistics, {
        feedbackStatistics
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getFeedbackStatisticsWeek(
    { commit },
    { pharmacyId, startTime, endTime, minutesInterval, chartFields }
  ) {
    try {
      const feedbackStatisticsWeek: FeedbackStatistics | undefined =
        await getFeedbackStatisticsWeek(
          pharmacyId,
          startTime,
          endTime,
          minutesInterval,
          chartFields
        );

      commit(OrderPaymentMutations.get_feedback_statistics_week, {
        feedbackStatisticsWeek
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getOrdersHistory({ commit }, { pharmacyId, startTime, endTime }) {
    try {
      const ordersHistory: OrderHistory[] | undefined = await getOrdersHistory(
        pharmacyId,
        startTime,
        endTime
      );

      commit(OrderPaymentMutations.get_payments_history, {
        ordersHistory
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getOrdersHistoryDecodedSSN({ commit }, { pharmacyId, orderId }) {
    try {
      const decodedSSN: DecodedSSN | undefined =
        await getOrdersHistoryDecodedSSN(pharmacyId, orderId);

      commit(OrderPaymentMutations.get_payments_history_decoded_ssn, {
        decodedSSN
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getConfirmContent(_, { pharmacyId, orderId }) {
    try {
      await getConfirmContent(pharmacyId, orderId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getViewOrderById({ commit }, { pharmacyId, orderId }) {
    try {
      const viewOrder = await getViewOrderById(pharmacyId, orderId);

      commit(OrderPaymentMutations.get_view_payment, {
        viewOrder
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async resendPaymentNotification(_, data) {
    try {
      await resendPaymentNotification(data);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async cancelPaymentRequest(_, { orderId }) {
    try {
      await cancelPaymentRequest(orderId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async addComment(_, { data, orderId }) {
    try {
      await addComment(data, orderId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  }
};
