
import { Vue, Options } from 'vue-class-component';

import VueFlatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { InputFileEvent } from '@/types';

@Options({
  name: 'TimePicker',
  props: ['selectedTime', 'disabled'],
  components: {
    flatPickr: VueFlatpickr
  },
  data() {
    return {
      localSelectedTime: this.selectedTime || '',
      flatpickrConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true
      }
    };
  },
  methods: {
    updateTime(event: InputFileEvent) {
      this.$emit('updateTime', event);
    }
  },
  watch: {
    selectedTime(newVal: string) {
      this.localSelectedTime = newVal;
    }
  }
})
export default class TimePicker extends Vue {}
