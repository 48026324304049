export const infoOfPharmacy = [
  {
    label: 'pharmacy',
    type: 'text',
    name: 'name',
    validation: 'required',
    dataTestId: 'inputPharmacyName'
  },
  {
    label: 'farmacyEntryPin',
    type: 'text',
    name: 'pinCode',
    dataTestId: 'inputPharmacyPinCode'
  },
  {
    label: 'address',
    type: 'text',
    name: 'streetAddress',
    dataTestId: 'inputPharmacyStreetAddress'
  }
];

export const infoPhoneNumber = [
  {
    label: 'alarmPhoneNumber',
    type: 'tel',
    dataTestId: 'inputPharmacyAlarmPhoneNumber',
    name: 'phoneNumber',
    validation: [['required'], ['matches', /^\+[0-9]{10,12}$/]],
    validationVisibility: 'dirty'
  }
];

export const infoEmail = [
  {
    label: 'alarmEmail',
    type: 'email',
    name: 'email',
    validation: 'required|email',
    dataTestId: 'inputPharmacyAlarmEmail'
  }
];

export const infoOfDeliveries = [
  {
    label: 'deliveryExpirationTime',
    type: 'text',
    name: 'deliveryKeepTime',
    dataTestId: 'inputDeliveryKeepTime'
  },
  {
    label: 'reminderMessageTime',
    type: 'text',
    name: 'deliveryReminderMessagePeriod',
    dataTestId: 'inputDeliveryReminderMessagePeriod'
  }
];
