
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { langsEn, langsFi, langsSv, langsNo } from '@/services/languageService';

@Options({
  name: 'InfoActiveBox',
  props: ['activePickupBox'],
  data() {
    return {
      langsEn,
      langsFi,
      langsSv,
      langsNo
    };
  },
  computed: {
    ...mapState('account', ['user'])
  },
  methods: {
    msToDays(ms: number) {
      if (ms <= 1) {
        return ms + this.$t('day');
      } else {
        return ms + this.$t('days');
      }
    },
    msToMin(ms: number) {
      const min = ms / 60000;

      if (min === 1) {
        return min + this.$t('minute');
      } else {
        return min + this.$t('minutes');
      }
    },
    getLang(uiLanguages: Array<string>) {
      if (this.user) {
        if (this.user?.appLanguage === 'en') {
          return uiLanguages.map((i: string) => langsEn[i]).join(', ');
        }

        if (this.user?.appLanguage === 'fi') {
          return uiLanguages.map((i: string) => langsFi[i]).join(', ');
        }

        if (this.user?.appLanguage === 'sv') {
          return uiLanguages.map((i: string) => langsSv[i]).join(', ');
        }

        if (this.user?.appLanguage === 'no') {
          return uiLanguages.map((i: string) => langsNo[i]).join(', ');
        }
      }
    }
  }
})
export default class InfoActiveBox extends Vue {}
