
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import QrcodeVue from 'qrcode.vue';

@Options({
  name: 'VerifiedUser',
  components: {
    Loading,
    QrcodeVue
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState('account', ['resultPickupPin', 'status'])
  },
  created() {
    this.show();
  },
  methods: {
    ...mapActions('account', ['authorizeUser']),
    async show() {
      this.loading = true;

      const payload = {
        verificationData: window.location.search
      };
      await this.authorizeUser(payload);

      this.loading = false;
    }
  }
})
export default class VerifiedUserComponent extends Vue {}
