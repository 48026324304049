import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_picker = _resolveComponent("date-picker")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_date_picker, {
      value: _ctx.defaultValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.defaultValue) = $event)),
      valueType: "format",
      clearable: false,
      type: _ctx.type,
      "value-type": _ctx.type === 'datetime' ? 'timestamp' : 'format',
      format: _ctx.format,
      "minute-step": _ctx.minuteStep,
      "hour-options": _ctx.hourOptions,
      message: _ctx.message,
      onChange: _cache[1] || (_cache[1] = (value) => _ctx.$emit('change', { target: { value, name: _ctx.name } })),
      onClose: _cache[2] || (_cache[2] = (value) => _ctx.$emit('closePanel'))
    }, null, 8, ["value", "type", "value-type", "format", "minute-step", "hour-options", "message"])
  ]))
}