
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import OptionsInputs from './OptionsInputs/OptionsInputs.vue';
import AddOption from './AddOption/AddOption.vue';

import { translations } from '../configs';
import { OptionsData, OptionsScreen } from '@/store/modules/pharmacy/types';
import { mapScreensToSelectedPositions } from '@/components/UI/ShowSettings/configs';

@Options({
  name: 'Options',
  components: {
    Loading,
    OptionsInputs,
    AddOption
  },
  data() {
    return {
      loading: false,
      formData: {
        title_text: {
          fi: '',
          sv: '',
          en: ''
        },
        options: {},
        multiple_options: {
          on: false,
          timeout: 1.5
        },
        show: false,
        position: 1
      },
      payload: {},
      positionNotSelected: false,
      disableSubmitForm: false,
      translations,
      numberActiveOptions: 0
    };
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy']),
    ...mapState('account', ['user'])
  },
  created() {
    if (this.pharmacy) {
      this.show();
    }
  },
  methods: {
    ...mapActions('pharmacy', ['updateFeedbackTemplate']),
    show() {
      this.loading = true;

      this.formData = {
        ...this.pharmacy.feedbackTemplate.options_screen,
        options: this.pharmacy.feedbackTemplate.options_screen.options
      };

      this.loading = false;
    },
    updateSelectedPosition(position: number) {
      this.formData.position = position;
    },
    selectShowSettings() {
      this.showSettings = !this.showSettings;
    },
    // eslint-disable-next-line
    filteredOptions(data: any) {
      const dataOptions = data.options;

      for (const key in dataOptions) {
        Object.keys(dataOptions[key]).forEach((innerKey) => {
          if (innerKey.startsWith('enabled_')) {
            delete dataOptions[key][innerKey];
          }
        });
      }

      let activeOptions: OptionsData = {};

      if (data.newOption) {
        activeOptions = dataOptions;

        const title: string = [data.newOption.title]
          .join('')
          .toLowerCase()
          .replace(/ |-/g, '_');

        const options = {
          ...activeOptions,
          [title]: {
            enabled: data.newOption.enabled,
            position: data.newOption.position,
            translations: data.newOption.translations
          }
        };

        return options;
      } else {
        activeOptions = data.options;
      }

      return activeOptions;
    },
    async submitHandler(data: OptionsScreen) {
      if (this.positionNotSelected) {
        this.payload = {
          grade_screen: {
            ...data
          }
        };
      } else {
        if (this.pharmacy) {
          const screens = this.pharmacy.feedbackTemplate;
          const name = this.$route.name.toLowerCase();

          const selectedPositions = mapScreensToSelectedPositions(
            screens,
            name
          );

          this.payload = {
            grade_screen: {
              ...data,
              position: selectedPositions.value
            }
          };
        }
      }

      if (!data.multiple_options.on) {
        this.payload = {
          options_screen: {
            ...data,
            options: this.filteredOptions(data)
          }
        };

        delete this.payload.options_screen.multiple_options.timeout;
      } else {
        this.payload = {
          options_screen: {
            ...data,
            options: this.filteredOptions(data),
            multiple_options: {
              ...data.multiple_options,
              timeout: +data.multiple_options.timeout
            }
          }
        };
      }

      delete this.payload.options_screen.newOption;

      this.loading = true;

      const { id } = this.$route.params;
      await this.updateFeedbackTemplate({ data: this.payload, pharmacyId: id });

      this.loading = false;
    },
    cleanForm() {
      this.$formkit.reset('options-form');
    },
    submitForm() {
      this.$formkit.submit('options-form');
    }
  },
  watch: {
    formData() {
      this.numberActiveOptions = Object.values(this.formData.options).filter(
        // eslint-disable-next-line
        (item: any) => item.enabled
      ).length;

      if (this.numberActiveOptions === 0) {
        if (!this.formData.newOption) {
          this.disableSubmitForm = true;
        } else {
          if (!this.formData.newOption?.enabled) {
            this.disableSubmitForm = true;
          } else {
            this.disableSubmitForm = false;
          }
        }
      } else {
        this.disableSubmitForm = false;
      }
    },
    pharmacy() {
      if (this.pharmacy) {
        this.show();
      }
    }
  }
})
export default class OptionsComponent extends Vue {}
