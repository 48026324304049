
import { Options, Vue } from 'vue-class-component';

import InfoActiveBox from './InfoActiveBox/InfoActiveBox.vue';

import router from '@/router';

@Options({
  name: 'ActiveBox',
  props: ['activePickupBox'],
  components: {
    InfoActiveBox
  },
  methods: {
    capacityPickupBox() {
      router.push({
        name: 'CapacityPickupBox',
        params: { boxId: this.activePickupBox.info.id }
      });
    },
    editPickupBox() {
      const { id } = this.$route.params;

      router.push({
        name: 'EditPickupBox',
        params: { pharmacyId: id, boxId: this.activePickupBox.info.id }
      });
    }
  }
})
export default class ActiveBoxComponent extends Vue {}
