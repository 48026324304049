import { Option, TranslationFunction } from '@/types';

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('lastCheckedBy'),
    value: 'userName'
  },
  {
    label: $t('checkTime'),
    value: 'timestamp'
  }
];
