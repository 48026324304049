
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import router from '@/router';
import { format } from 'date-fns';

import Loading from '@/views/Loading.vue';
import OpenConfirmation from './OpenConfirmation/OpenConfirmation.vue';
import AddComment from './AddComment/AddComment.vue';

import {
  getConsultation,
  getDeliveryMethod,
  getPaymentStatus,
  getContent,
  dateTimeResult
} from '@/utils/tableFunctions';

import { columns, columnsDow, searchActions } from './configs';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { InputFileEvent, Option } from '@/types';
import { OrderHistory } from '@/store/modules/orders/types';
import { Params } from '@/store/modules/users/types';

const transformationDate = new Date().setDate(new Date().getDate() - 3);

@Options({
  name: 'OrdersHistory',
  components: {
    Loading,
    OpenConfirmation,
    AddComment
  },
  data() {
    return {
      loading: false,
      showOpenConfirmation: false,
      showOpenViewOrder: false,
      showModalAddComment: false,
      search: '',
      getSearchBy: {
        selectedOption: {
          value: 'firstName',
          label: this.$t('firstName')
        },
        options: searchActions(this.$t)
      },
      dateRange: {
        startTime: format(new Date(transformationDate), 'dd/MM/yyyy'),
        endTime: format(new Date(), 'dd/MM/yyyy')
      },
      ordersHistoryList: [],
      columns: columns(this.$t),
      columnsDow: columnsDow(this.$t),
      activePayment: {},
      timestampActivePayment: 0,
      dataSearch: [],
      showTimePanel: false,
      buttonLoaded: false
    };
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('orders', ['ordersHistory', 'decodedSSN']),
    isDateCorrect() {
      if (this.isDatePresent) {
        const startTime = this.dateRange.startTime.split('/');
        const endTime = this.dateRange.endTime.split('/');

        let defaultParams = {
          ...this.dateRange,
          startTime: new Date(
            +startTime[2],
            startTime[1] - 1,
            +startTime[0]
          ).valueOf(),
          endTime: new Date(+endTime[2], endTime[1] - 1, +endTime[0]).valueOf()
        };

        return defaultParams.endTime >= defaultParams.startTime;
      }

      return true;
    },
    isDatePresent() {
      const startTime = this.dateRange.startTime.split('/');
      const endTime = this.dateRange.endTime.split('/');

      let defaultParams = {
        ...this.dateRange,
        startTime: new Date(
          +startTime[2],
          startTime[1] - 1,
          +startTime[0]
        ).valueOf(),
        endTime: new Date(+endTime[2], endTime[1] - 1, +endTime[0]).valueOf()
      };

      return defaultParams && defaultParams.startTime && defaultParams.endTime;
    }
  },
  created() {
    if (this.user) {
      this.pharmacyId = this.user?.pharmacyId;
      this.show();
    }
  },
  methods: {
    ...mapActions('orders', [
      'getOrdersHistory',
      'getConfirmContent',
      'getOrdersHistoryDecodedSSN'
    ]),
    show() {
      let startTime = this.dateRange.startTime.split('/');
      let endTime = this.dateRange.endTime.split('/');

      startTime = new Date(
        +startTime[2],
        startTime[1] - 1,
        +startTime[0]
      ).valueOf();

      const today = new Date().getDate();

      if (today === +endTime[0]) {
        endTime = new Date().valueOf();
      } else {
        endTime = new Date(
          +endTime[2],
          endTime[1] - 1,
          +endTime[0],
          23,
          59,
          59
        ).valueOf();
      }

      this.params = {
        startTime: startTime + new Date(startTime).getTimezoneOffset() * -60000,
        endTime: endTime + new Date(endTime).getTimezoneOffset() * -60000,
        pharmacyId: this.user.pharmacyId
      };

      this.getOrdersHistoryList(this.params);
    },
    async getOrdersHistoryList(params: Params) {
      if (
        params.pharmacyId &&
        params.startTime &&
        params.endTime &&
        this.isDateCorrect
      ) {
        this.loading = true;

        await this.getOrdersHistory(params);
        this.ordersHistoryList = this.ordersHistory;

        this.loading = false;
      }
    },
    closeModalAddComment() {
      this.showModalAddComment = false;
      this.show();
    },
    changeDate(e: InputFileEvent) {
      const { name, value } = e.target;
      this.buttonLoaded = false;

      this.dateRange = {
        ...this.dateRange,
        [name]: value
      };

      this.buttonLoaded = true;
    },
    closeTimePanel() {
      this.show();
      this.showTimePanel = false;

      this.buttonLoaded = false;
    },
    changeInput(e: InputFileEvent) {
      const { value } = e.target;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (value === '') {
          this.ordersHistoryList = this.ordersHistory;
        } else if (this.getSearchBy.selectedOption.value === 'firstName') {
          this.ordersHistoryList = [
            ...(Object.values(this.ordersHistory) as OrderHistory[]).flat()
          ].filter((item: OrderHistory) =>
            item.clientInfo.firstName
              .toLowerCase()
              .includes(value?.toLowerCase())
          );
        } else if (this.getSearchBy.selectedOption.value === 'lastName') {
          this.ordersHistoryList = [
            ...(Object.values(this.ordersHistory) as OrderHistory[]).flat()
          ].filter((item: OrderHistory) =>
            item.clientInfo.lastName
              .toLowerCase()
              .includes(value?.toLowerCase())
          );
        } else if (this.getSearchBy.selectedOption.value === 'phoneNumber') {
          this.ordersHistoryList = [
            ...(Object.values(this.ordersHistory) as OrderHistory[]).flat()
          ].filter((item: OrderHistory) =>
            item.clientInfo.phoneNumber
              .toLowerCase()
              .includes(value?.toLowerCase())
          );
        }
      }, 600);
    },
    changeSearchBy(option: readonly Option[]) {
      this.getSearchBy.selectedOption = option;

      this.ordersHistoryList = [
        ...(Object.values(this.ordersHistory) as OrderHistory[]).flat()
      ].filter((item: OrderHistory) =>
        (
          item[
            this.getSearchBy.selectedOption.value as keyof OrderHistory
          ] as string
        )
          ?.toLowerCase()
          .includes(this.search.toLowerCase())
      );

      if (this.search === '') {
        this.ordersHistoryList = this.ordersHistory;
      }
    },
    getActivePayment(createdTime: number) {
      this.activePayment = [
        ...(Object.values(this.ordersHistory) as OrderHistory[])
      ].find((i: OrderHistory) => i.createdTime === createdTime);
    },
    async getSsn(createdTime: string) {
      this.getActivePayment(createdTime);

      const params: Params = {
        pharmacyId: this.user?.pharmacyId,
        orderId: this.activePayment.id
      };

      this.loading = true;

      await this.getOrdersHistoryDecodedSSN(params);

      if (this.activePayment) {
        this.activePayment.clientInfo.ssn = this.decodedSSN.ssn;
      }

      this.loading = false;
    },
    openConfirmation(createdTime: number) {
      this.getActivePayment(createdTime);
      this.timestampActivePayment = this.activePayment.createdTime;

      this.showOpenConfirmation = true;
    },
    addDescription(createdTime: number) {
      this.getActivePayment(createdTime);
      this.showModalAddComment = true;
    },
    async confirmRequest() {
      await this.getConfirmContent({
        pharmacyId: this.user?.pharmacyId,
        orderId: this.activePayment.id
      });

      this.showOpenConfirmation = false;
      this.show();
    },
    openViewOrder(id: string) {
      router.push({
        name: 'ViewOrder',
        params: { id }
      });
    },
    getContentResult(data: OrderHistory) {
      if (data.confirmed) {
        return `${getContent(data)} ${dateTimeResult(data?.confirmedTime)}`;
      } else {
        return '-';
      }
    },
    getPDF() {
      const data = this.ordersHistoryList.map((el: OrderHistory) => ({
        clientInfoName: `${el.clientInfo.firstName} ${el.clientInfo.lastName}`,
        phoneNumber: el.clientInfo.phoneNumber,
        handler: el.handler,
        consultation: getConsultation(el),
        orderNumber: el.orderNumber,
        createdTime: dateTimeResult(el.createdTime),
        delivery: getDeliveryMethod(el.deliveryMethod),
        content: this.getContentResult(el),
        total: el.paymentInfo?.totalSum,
        payment: getPaymentStatus(el.paymentInfo?.status),
        timestamp: dateTimeResult(el.paymentInfo?.issuedAt),
        comment: el.comment
      }));

      const dataPDF = data.map((obj: OrderHistory) => Object.values(obj));

      const columns = [...this.columnsDow].map((i: Option) => i.label);

      const doc = new jsPDF('landscape');
      autoTable(doc, {
        head: [columns],
        body: dataPDF,
        theme: 'grid',
        headStyles: { fillColor: [71, 152, 127] },
        bodyStyles: { fillColor: [255, 255, 255] },
        margin: { top: 10 }
      });

      doc.save(`${this.$t('ordersHistory')}.pdf`);
    }
  },
  watch: {
    user() {
      if (this.user) {
        this.pharmacyId = this.user?.pharmacyId;
        this.show();
      }
    }
  }
})
export default class OrdersHistory extends Vue {}
