import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { AccountState } from '@/store/modules/account/types';
import { getters } from '@/store/modules/account/getters';
import { mutations } from '@/store/modules/account/mutations';
import { actions } from '@/store/modules/account/actions';

export const state: AccountState = {
  status: {
    loaded: false,
    sidebar: true,
    loggingOut: null,
    loggingIn: null,
    loggedIn: true,
    error: null,
    message: null
  },
  userCreds: {
    email: '',
    password: ''
  },
  user: null,
  token: '',
  logoutMsg: null,
  authenticationSignicat: null,
  resultPickupPin: null,
  resultPickupPinStrongRedirect: null
};

export const account: Module<AccountState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
