export const langsEn: Record<string, unknown> = {
  en: 'English',
  fi: 'Finnish',
  sv: 'Swedish',
  no: 'Norwegian'
};

export const optionsLanguageEn = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Finnish',
    value: 'fi'
  },
  {
    label: 'Swedish',
    value: 'sv'
  },
  {
    label: 'Norwegian',
    value: 'no'
  }
];

export const langsFi: Record<string, unknown> = {
  en: 'Englanti',
  fi: 'Suomi',
  sv: 'Ruotsi',
  no: 'Norja'
};

export const optionsLanguageFi = [
  {
    label: 'Englanti',
    value: 'en'
  },
  {
    label: 'Suomi',
    value: 'fi'
  },
  {
    label: 'Ruotsi',
    value: 'sv'
  },
  {
    label: 'Norja',
    value: 'no'
  }
];

export const langsSv: Record<string, unknown> = {
  en: 'Engelska',
  fi: 'Finska',
  sv: 'Svenska',
  no: 'Norska'
};

export const optionsLanguageSv = [
  {
    label: 'Engelska',
    value: 'en'
  },
  {
    label: 'Finska',
    value: 'fi'
  },
  {
    label: 'Svenska',
    value: 'sv'
  },
  {
    label: 'Norska',
    value: 'no'
  }
];

export const langsNo: Record<string, unknown> = {
  en: 'Engelsk',
  fi: 'Finsk',
  sv: 'Svensk',
  no: 'Norsk'
};

export const optionsLanguageNo = [
  {
    label: 'Engelsk',
    value: 'en'
  },
  {
    label: 'Finsk',
    value: 'fi'
  },
  {
    label: 'Svensk',
    value: 'sv'
  },
  {
    label: 'Norsk',
    value: 'no'
  }
];
