
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import router from '@/router';
import moment from 'moment';

import Loading from '@/views/Loading.vue';
import InputCapacity from './InputCapacity/InputCapacity.vue';

import { activeOptions, columns } from './configs';
import { Option } from '@/types';

@Options({
  name: 'CapacityPickupBox',
  components: {
    Loading,
    InputCapacity
  },
  data() {
    return {
      loading: false,
      columns: columns(this.$t),
      getOption: {
        selectedOption: {
          value: 'week',
          label: this.$t('week')
        },
        options: activeOptions(this.$t)
      },
      averagePickupTime: null
    };
  },
  computed: {
    ...mapState('pharmacy', [
      'capacity',
      'capacityPickupBoxIdResult',
      'capacityPickupBoxType'
    ])
  },
  created() {
    this.show();
  },
  methods: {
    ...mapActions('pharmacy', [
      'getPickupBoxCapacity',
      'getCapacityByPickupBoxId'
    ]),
    async show() {
      this.loading = true;
      const { boxId } = this.$route.params;

      const defaultParams = {
        boxId: boxId,
        startTime: moment().subtract(7, 'd').valueOf(),
        endTime: moment().valueOf(),
        ...this.params
      };

      await this.getCapacityByPickupBoxId(defaultParams);
      this.averagePickupTime = this.capacityPickupBoxIdResult.averagePickupTime;

      this.loading = false;
    },
    backTo() {
      const { id } = this.$route.params;
      router.push({ name: 'InfoPickupBox', params: { id } });
    },
    changeItem(option: Option) {
      this.getOption.selectedOption = option;
    }
  },
  watch: {
    'getOption.selectedOption': {
      handler() {
        const dateValue = this.getOption.selectedOption.value;

        if (dateValue === 'week') {
          this.params = {
            ...this.params,
            startTime: moment().subtract(7, 'd').valueOf()
          };

          this.show();
        }

        if (dateValue === 'month') {
          this.params = {
            ...this.params,
            startTime: moment().subtract(1, 'months').valueOf()
          };
          this.show();
        }
      }
    }
  }
})
export default class CapacityPickupBox extends Vue {}
