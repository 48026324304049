import { Option, TranslationFunction } from '@/types';

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('kolliID'),
    value: 'deliveryId'
  },
  {
    label: $t('deliveryMadeBy'),
    value: 'deliveryMadeBy'
  },
  {
    label: $t('phoneNumber'),
    value: 'phoneNumber'
  },
  {
    label: $t('timeReserved'),
    value: 'reservedTime'
  },
  {
    label: $t('assignedTime'),
    value: 'assignedTime'
  }
];
