
import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';

import moment from 'moment';

import { PharmacyAccesses, UserProfile } from '@/store/modules/account/types';
import {
  AlarmsData,
  APIDataReservations,
  Check,
  Contact,
  DataReservations,
  Delivery,
  Grade
} from '@/store/modules/pharmacy/types';
import { DataLoginInformation } from '@/store/modules/users/types';

import { Column } from '@/types';

import {
  getConsultation,
  getDeliveryMethod,
  getPaymentStatus,
  getContent,
  dateTimeResult
} from '@/utils/tableFunctions';

@Options({
  name: 'Table',
  props: ['data', 'columns', 'selectedField', 'changeSort'],
  data() {
    return {
      showSsn: {},
      consentGiven: true
    };
  },
  computed: {
    ...mapState('account', ['user']),
    isDeliveryHistory() {
      return this.$route.name === 'DeliveryHistory';
    },
    isUsers() {
      return this.$route.name === 'Users';
    },
    isReservations() {
      return this.$route.name === 'Reservations';
    },
    isChooseDelivery() {
      return this.$route.name === 'Lockers';
    },
    isAlarms() {
      return this.$route.name === 'Alarms';
    },
    isAPIReservations() {
      return this.$route.name === 'APIReservations';
    },
    isAuthenticationHistory() {
      return this.$route.name === 'AuthenticationHistory';
    },
    isOrdersHistory() {
      return this.$route.name === 'OrdersHistory';
    },
    isCallbackRequests() {
      return this.$route.name === 'CallbackRequests';
    }
  },
  methods: {
    getDataConsentGiven(i: string) {
      if (this.isOrdersHistory) {
        return true;
      } else {
        return i === 'done' ? true : false;
      }
    },
    selectRow(i: Record<string, unknown>, column: string) {
      if (
        (column === 'deliveryId' || column === 'deliveryIds') &&
        !this.isAPIReservations
      ) {
        this.$emit('openQRCode', i[this.selectedField]);
      } else if (this.isOrdersHistory && column === 'content' && !i.confirmed) {
        this.$emit('openConfirmation', i[this.selectedField]);
      } else if (
        (this.isAPIReservations ||
          this.isReservations ||
          this.isDeliveryHistory ||
          this.isUsers) &&
        column !== 'assign'
      ) {
        this.$emit('goToItem', i[this.selectedField] || i.id);
      } else if (this.isOrdersHistory && column !== 'clientInfoSSN') {
        this.$emit('goToItem', i.id);
      }
    },
    selectField(i: Record<string, unknown>) {
      if (this.isAlarms) {
        this.$emit('addAlarmComment', i);
      } else {
        this.$emit('addDescription', i[this.selectedField]);
      }
    },
    getSsnOpen(timestamp: number) {
      this.showSsn[timestamp] = true;
    },
    hideSsn(timestamp: number) {
      this.showSsn[timestamp] = false;
    },
    getUserName(item: Record<string, unknown>) {
      return `${item.firstName} ${item.lastName}`;
    },
    getIds(item: APIDataReservations | DataReservations) {
      if (!item?.additionalDeliveryIds[0] && !item?.additionalDeliveryIds[1]) {
        return item.deliveryId;
      } else if (item?.additionalDeliveryIds[1] !== '') {
        return item.deliveryId + ', ' + item.additionalDeliveryIds.join(', ');
      } else if (item?.additionalDeliveryIds[1] === '') {
        return item.deliveryId + ', ' + item.additionalDeliveryIds.join('');
      }
    },
    dateTime(value: number) {
      if (value) {
        return moment(value).format('DD.MM.YYYY HH:mm');
      }
    },
    getDeliveryStatus(item: string) {
      const colors: Record<string, unknown> = {
        reserved: 'Orange',
        delivered: 'Blue',
        pickup: 'Green',
        expired: 'Gray',
        cancelled: 'Red',
        removed: 'Red',
        deleted: 'Red',
        destroyed: 'Red'
      };

      return colors[item];
    },
    getCallbackRequestsStatus(item: string) {
      const colors: Record<string, unknown> = {
        pending: 'Green',
        missed: 'Orange'
      };

      return colors[item];
    },
    getPendingRequests(item: string) {
      const colors: Record<string, unknown> = {
        pending: 'Green',
        missed: 'Orange'
      };

      return colors[item];
    },
    getDeliveryLabel(item: string) {
      const labels: Record<string, unknown> = {
        reserved: this.$t('reservedTable'),
        delivered: this.$t('deliveredTable'),
        pickup: this.$t('pickedUpTable'),
        expired: this.$t('expiredTable'),
        cancelled: this.$t('cancelledTable'),
        removed: this.$t('removedTable'),
        deleted: this.$t('deletedTable'),
        destroyed: this.$t('destroyedTable')
      };

      return labels[item];
    },
    getRoleLabel(item: string, data: UserProfile) {
      const labels: Record<string, unknown> = {
        main_user: this.$t('mainUser'),
        user: this.$t('user')
      };

      if (data.pharmacyAccesses) {
        const pharmacyId = localStorage.getItem('id');

        const roleNameForPharmacyId: PharmacyAccesses | undefined =
          data.pharmacyAccesses.find(
            (itemData: PharmacyAccesses) => itemData.pharmacyId === pharmacyId
          );

        return roleNameForPharmacyId
          ? labels[roleNameForPharmacyId?.roleName]
          : '';
      } else {
        return labels[item];
      }
    },
    getDuration(item: AlarmsData) {
      const startDate = item.timestamp;
      const endDate = item.alarmEndTimestamp;

      if (endDate) {
        const diffTime = Math.abs(endDate - startDate);
        const diffH = Math.ceil(diffTime / (1000 * 60 * 60) - 1);
        const diffMin = Math.ceil(diffTime / (1000 * 60));

        if (!startDate || !endDate) {
          return '';
        }

        if (diffTime === 0) {
          return 0;
        }

        if (diffH === 1) {
          return `${diffH} ${this.$t('hour')}`;
        } else if (diffH === 0) {
          return `${diffMin} ${this.$t('min')}`;
        } else {
          return `${diffH} ${this.$t('hours')}`;
        }
      }
    },
    rounded(averageTemp: number) {
      return +averageTemp.toFixed(2);
    },
    getAverageTemp(item: AlarmsData) {
      let averageTemp;

      if (item.alarmMax && item.alarmMin) {
        averageTemp = (item.alarmMax + item.alarmMin) / 2;
      } else if (item.alarmMax && !item.alarmMin) {
        averageTemp = item.alarmMax / 2;
      } else if (!item.alarmMax && item.alarmMin) {
        averageTemp = item.alarmMin / 2;
      }

      if (!averageTemp) {
        return '';
      }

      this.rounded(averageTemp);

      return `${this.rounded(averageTemp)}`;
    },
    getLockerId(item: Array<string>) {
      return item?.join(', ');
    },
    getType(item: string) {
      const type: Record<string, unknown> = {
        fridge: this.$t('fridge'),
        large: this.$t('large'),
        medium: this.$t('medium'),
        small: this.$t('small')
      };

      return type[item];
    },
    getAVG(item: Record<string, unknown>) {
      if (item.averageTime === 1) {
        return `${item.averageTime} ${this.$t('hour')}`;
      } else {
        return `${item.averageTime} ${this.$t('hours')}`;
      }
    },
    getMonth(item: string) {
      const months: Record<string, unknown> = {
        0: this.$t('january'),
        1: this.$t('february'),
        2: this.$t('march'),
        3: this.$t('april'),
        4: this.$t('may'),
        5: this.$t('june'),
        6: this.$t('july'),
        7: this.$t('august'),
        8: this.$t('september'),
        9: this.$t('october'),
        10: this.$t('november'),
        11: this.$t('december')
      };

      return months[item];
    },
    getInfoType(item: DataLoginInformation) {
      const info: Record<string, unknown> = {
        in: this.$t('in'),
        out: this.$t('out')
      };

      return `${this.$t('log')} ${info[item.info]}`;
    },
    getTime(data: Record<string, unknown>, column: Column) {
      if (data[column.value]) {
        return this.dateTime(data[column.value]);
      } else {
        return '';
      }
    },
    getAssignedTime(data: DataReservations) {
      if (data.assignedTime) {
        return this.dateTime(data.assignedTime);
      } else {
        return '';
      }
    },
    getComment(comment: Check) {
      const checkTime = moment(comment.checkTime).format('DD.MM.YYYY HH:mm');
      const checkName = comment.checkName;
      const commentText =
        comment.alarmInfo.length > 30
          ? `${comment.alarmInfo.slice(0, 30)}...`
          : comment.alarmInfo;

      return `${commentText} (${checkName} ${checkTime})`;
    },
    getAlarmComments(data: AlarmsData) {
      const alarmInfoData = data.check.slice(1).map(this.getComment);

      return alarmInfoData.join('; ');
    },
    getDescriptionText(data: Delivery) {
      const descriptionTextData = data.descriptionText;

      return descriptionTextData?.length > 30
        ? `${descriptionTextData.slice(0, 30)}...`
        : descriptionTextData;
    },
    getAlarmType(data: AlarmsData) {
      const type: Record<string, unknown> = {
        ok: this.$t('alarmOk'),
        door_open: this.$t('alarmDoorOpen'),
        door_closed: this.$t('alarmDoorClosed'),
        temp_high: this.$t('alarmTempHigh'),
        temp_low: this.$t('alarmTempLow'),
        other: this.$t('alarmOther'),
        missing: this.$t('alarmMissing')
      };

      return type[data.alarmType];
    },
    getSensor(data: AlarmsData) {
      if (data.alarmType === 'temp_high' || data.alarmType === 'temp_low') {
        if (data.lockerId !== '99-99') {
          return `${this.$t('fridge')} ${data.lockerId}`;
        } else {
          return `${this.$t('roomTemp')}`;
        }
      } else if (data.lockerId) {
        return `${data.lockerId}`;
      }
    },
    getIsDrug(data: APIDataReservations) {
      if (data.is_drug) {
        return `${this.$t('yes')}`;
      } else {
        return `${this.$t('no')}`;
      }
    },
    getDeliveryUserName(data: DataReservations) {
      if (data.lockerId) {
        return data.deliveryUserName;
      } else {
        return data.reservedUserName;
      }
    },
    getDeliveryTime(data: DataReservations) {
      if (!data.lockerId) {
        if (data.deliveryTime) {
          return this.dateTime(data.deliveryTime);
        } else {
          return this.dateTime(data.reservedTime);
        }
      }
      return this.dateTime(data.deliveryTime);
    },
    pickedUpAndDestroyed(data: Record<string, unknown>) {
      if (data.pickupTime) {
        return this.dateTime(data.pickupTime);
      }

      return this.dateTime(data.destroyTime);
    },
    getContact(contact: Record<string, Contact>) {
      return contact?.contact ?? '';
    },
    getGrade(grade: Record<string, Grade>) {
      return grade?.grade ?? '';
    },
    getImg(grade: string) {
      const type: Record<string, unknown> = {
        GOOD: 'HappyPressed',
        OK: 'OkPressed',
        BAD: 'AngryPressed'
      };

      return type[grade];
    },
    getFeedbackType(type: string) {
      return `${type.charAt(0).toUpperCase()}${type
        .slice(1)
        .replace('_', ' ')}`;
    },
    // eslint-disable-next-line
    getColumnValue(data: any, column: Record<string, any>) {
      switch (column.value) {
        case 'alarmEndTimestamp':
        case 'timestamp':
        case 'requestedAt':
          return this.dateTime(data[column.value]);
        case 'expiredTime':
        case 'reservedTime':
        case 'pickupTime':
        case 'destroyTime':
          return this.getTime(data, column);
        case 'pickedUpAndDestroyed':
          return this.pickedUpAndDestroyed(data);
        case 'loginInfoUserName':
        case 'userNAME':
          return this.getUserName(data, column);
        case 'deliveryStatus':
          return this.getDeliveryLabel(data[column.value]);
        case 'lockerId':
          return this.getLockerId(data[column.value]);
        case 'deliveryIds':
          return this.getIds(data);
        case 'duration':
          return this.getDuration(data, column);
        case 'averageTemp':
          return this.getAverageTemp(data, column);
        case 'type':
          return this.getType(data[column.value]);
        case 'averageTime':
          return this.getAVG(data, column);
        case 'month':
          return this.getMonth(data[column.value]);
        case 'infoType':
          return this.getInfoType(data, column);
        case 'role':
          return this.getRoleLabel(data[column.value], data);
        case 'alarmComment':
          return this.getAlarmComments(data, column);
        case 'descriptionText':
          return this.getDescriptionText(data, column);
        case 'alarmType':
          return this.getAlarmType(data);
        case 'sensor':
          return this.getSensor(data);
        case 'is_drug':
          return this.getIsDrug(data);
        case 'deliveryUserName':
          return this.getDeliveryUserName(data);
        case 'deliveryTime':
          return this.getDeliveryTime(data);
        case 'assignedTime':
          return this.getAssignedTime(data);
        case 'clientInfoName':
          return `${data.clientInfo.firstName} ${data.clientInfo.lastName}`;
        case 'clientInfoPhoneNumber':
          return data.clientInfo.phoneNumber;
        case 'consultation':
          return getConsultation(data);
        case 'deliveryMethod':
          return getDeliveryMethod(data.deliveryMethod);
        case 'paymentInfoStatus':
          return getPaymentStatus(data.paymentInfo?.status);
        case 'paymentInfoTotalSum':
          return data.paymentInfo?.totalSum;
        case 'content':
          return getContent(data);
        case 'createdTime':
          return dateTimeResult(data.createdTime);
        case 'paymentInfoIssuedAt':
          return dateTimeResult(data.paymentInfo?.issuedAt);
        case 'contact':
          return this.getContact(data.contact);
        case 'grade':
          return this.getGrade(data.grade);
        case 'clientInfoSSN':
          return '';
        case 'ssn':
          return '';
        case 'status':
          return '';
        default:
          return data[column.value];
      }
    }
  },
  mounted() {
    const options = {
      rootMargin: '0px',
      threshold: 1.0
    };

    const callback = (entries: IntersectionObserverEntry[]) => {
      const lastElement = this.data[this.data.length - 1];
      if (entries[0].isIntersecting && lastElement) {
        this.$emit('loadMore', lastElement.id || lastElement);
      }
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(this.$refs.observer);
  }
})
export default class Table extends Vue {}
