<template>
  <canvas
    :id="id"
    style="height: 100%; width: 100%"
    :style="styleBarChart"
  ></canvas>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'BarChart',
  props: ['id', 'dataBarChart', 'optionsBarChart', 'styleBarChart'],
  /* eslint-disable */
  mounted() {
    const ctx = document.getElementById(`${this.id}`);
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: this.dataBarChart,
      options: this.optionsBarChart
    });
  }
};
</script>
