export const tabs = [
  {
    label: 'general',
    value: 'General'
  },
  {
    label: 'options',
    value: 'Options'
  },
  {
    label: 'report',
    value: 'Report'
  },
  {
    label: 'grade',
    value: 'Grade'
  },
  {
    label: 'contact',
    value: 'Contact'
  },
  {
    label: 'thanks',
    value: 'Thanks'
  }
];

export const translations = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Finnish',
    value: 'fi'
  },
  {
    label: 'Swedish',
    value: 'sv'
  }
];
