
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'PasswordInput',
  props: [
    'name',
    'label',
    'validation',
    'help',
    'modelValue',
    'validationMessages'
  ],
  data() {
    return {
      showPassword: false
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    }
  }
})
export default class PasswordInputComponent extends Vue {}
