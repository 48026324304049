import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/Error.svg'


const _hoisted_1 = {
  key: 1,
  class: "infoLocker"
}
const _hoisted_2 = { class: "infoLocker__header" }
const _hoisted_3 = { class: "infoLocker__header-title" }
const _hoisted_4 = {
  key: 0,
  class: "infoLocker__header-title-temperature"
}
const _hoisted_5 = {
  key: 0,
  class: "tooltiptext"
}
const _hoisted_6 = {
  key: 1,
  class: "tooltiptext"
}
const _hoisted_7 = { class: "infoLocker__actions" }
const _hoisted_8 = { class: "infoLocker__actions-action" }
const _hoisted_9 = { class: "locker-size" }
const _hoisted_10 = { class: "size" }
const _hoisted_11 = { class: "infoLocker__actions-action" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "name" }
const _hoisted_14 = {
  key: 0,
  class: "infoLocker__errorTemperature"
}
const _hoisted_15 = { class: "infoLocker__errorTemperature-messages" }
const _hoisted_16 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Toggle = _resolveComponent("Toggle")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Order = _resolveComponent("Order")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.order)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('locker')) + " " + _toDisplayString(_ctx.activeLocker), 1),
              (_ctx.order.temperature)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("p", {
                      class: _normalizeClass({
              temperatureCorrect: !_ctx.getIncreasedOrDecreasedTemp,
              temperatureIncorrect: _ctx.getIncreasedOrDecreasedTemp
            })
                    }, _toDisplayString(_ctx.order.temperature) + "°C ", 3)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass({
          'infoLocker__header-toggle': true,
          tootip:
            !_ctx.activePickupBox?.info.active ||
            !_ctx.user.role.permissions.pickupbox.update
        })
            }, [
              _createVNode(_component_FormKit, {
                type: "form",
                id: "disableLocker-form",
                modelValue: _ctx.formData,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData) = $event)),
                actions: false,
                onSubmit: _ctx.submitHandler
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Toggle, {
                    title: _ctx.$t('disableLocker'),
                    value: _ctx.valueToggle(),
                    name: "inactive",
                    variant: "backgraund",
                    disabled: _ctx.isDisabled(),
                    onChange: _ctx.changeDisableLocker
                  }, null, 8, ["title", "value", "disabled", "onChange"]),
                  (!_ctx.activePickupBox?.info.active)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('pickupboxIsInactive')), 1))
                    : _createCommentVNode("", true),
                  (!_ctx.user.role.permissions.pickupbox.update)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('enoughPermissions')), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue", "onSubmit"])
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.order.size.icon), 1)
              ]),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.order.size.label)), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("img", {
                class: "statusImg",
                src: 
            require(`@/assets/icons/${
              _ctx.status in _ctx.allStatus ? _ctx.allStatus[_ctx.status] : _ctx.status
            }.svg`)
          ,
                alt: "status"
              }, null, 8, _hoisted_12),
              _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.status in _ctx.orderStatus ? _ctx.$t(_ctx.orderStatus[_ctx.status]) : _ctx.status), 1)
            ])
          ]),
          (_ctx.getIncreasedOrDecreasedTemp)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _cache[1] || (_cache[1] = _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1)),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                _ctx.getMaxTemp ? 'messageTemperatureHigh' : 'messageTemperatureLow'
              )), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.loadingTempAlarms()), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_Order, {
            order: _ctx.order,
            activePickupBox: _ctx.activePickupBox,
            activeLocker: _ctx.activeLocker,
            showDecodedSsn: _ctx.showDecodedSsn,
            onGetDecodedSsn: _ctx.getDecodedSsn
          }, null, 8, ["order", "activePickupBox", "activeLocker", "showDecodedSsn", "onGetDecodedSsn"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}