
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import router from '@/router';
import { DataCreatePharmacyReservation } from '@/store/modules/pharmacy/types';

import { goToPickupBox } from '@/utils/goToPickupBox';

@Options({
  name: 'ReserveLocker',
  props: [
    'showModalReserveLocker',
    'selectedLockers',
    'activePickupBox',
    'loadPickupBoxes'
  ],
  emits: ['closeModal'],
  components: {
    Loading
  },
  data() {
    return {
      formData: {
        deliveryId: '',
        phoneNumber: '',
        is_drug: false,
        additionalDeliveryIds: [],
        ssn: ''
      },
      payload: null,
      validationRules: {},
      loading: false
    };
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('pharmacy', ['pharmacy']),
    isReservations() {
      return this.$route.name === 'Reservations';
    }
  },
  methods: {
    ...mapActions('pharmacy', [
      'createReservation',
      'createPharmacyReservation',
      'getPharmacy',
      'getPickupBoxesByPharmacy'
    ]),
    async submitHandler(data: DataCreatePharmacyReservation) {
      if (this.selectedLockers) {
        if (typeof this.selectedLockers === 'string') {
          this.payload = {
            ...data,
            deliveryId: this.formData.deliveryId
              .replace(/\s+/g, '')
              .toLowerCase(),
            userId: this.user.id,
            lockerId: this.selectedLockers.split(' ')
          };
        } else {
          this.payload = {
            ...data,
            deliveryId: this.formData.deliveryId
              .replace(/\s+/g, '')
              .toLowerCase(),
            userId: this.user.id,
            lockerId: [...this.selectedLockers]
          };
        }
      }

      if (this.isReservations) {
        this.payload = {
          ...data,
          deliveryId: this.formData.deliveryId
            .replace(/\s+/g, '')
            .toLowerCase(),
          userId: this.user.id
        };
      }

      this.loading = true;

      if (this.payload.deliveryId === '') {
        this.payload = { ...this.payload, deliveryId: undefined };
      }

      const { id } = this.$route.params;

      if (this.isReservations) {
        await this.createPharmacyReservation({
          data: this.payload,
          pharmacyId: this.user.pharmacyId
        });

        router.push({ name: 'Reservations' });
        this.loadPickupBoxes();
      } else {
        await this.createReservation({
          data: this.payload,
          boxId: this.activePickupBox.id
        });

        goToPickupBox(id, this.activePickupBox.id);

        await this.getPickupBoxesByPharmacy(id);
      }

      this.$formkit.reset('createReservation-form');
      this.$emit('closeModal');
      this.loading = false;
    },
    submitForm() {
      this.$formkit.submit('createReservation-form');
    },
    closeModal() {
      this.$emit('closeModal');
      this.$formkit.reset('createReservation-form');
    }
  }
})
export default class ReserveLocker extends Vue {}
