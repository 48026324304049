import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/Noutoapteekk.jpg'


const _hoisted_1 = { class: "forgotPassword" }
const _hoisted_2 = { class: "forgotPassword__content" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "forgotPassword__content-head" }
const _hoisted_5 = { class: "forgotPassword__content-head-lang" }
const _hoisted_6 = { class: "forgotPassword__content-head-backTo" }
const _hoisted_7 = { class: "forgotPassword__content-form" }
const _hoisted_8 = { class: "form" }
const _hoisted_9 = { class: "form-title" }
const _hoisted_10 = { class: "form-text" }
const _hoisted_11 = { class: "form-action" }
const _hoisted_12 = {
  key: 0,
  class: "form-error"
}
const _hoisted_13 = {
  class: "error",
  role: "alert"
}
const _hoisted_14 = {
  key: 1,
  class: "form-sentEmail"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_LangChange = _resolveComponent("LangChange")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "forgotPassword__image" }, [
      _createElementVNode("div", { class: "container" }, [
        _createElementVNode("img", {
          class: "image",
          src: _imports_0,
          alt: "Noutoapteekk"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_LangChange)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Link, {
              title: _ctx.$t('backToLogIn'),
              onBackTo: _ctx.backTo
            }, null, 8, ["title", "onBackTo"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('forgotPassword')), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('forgotPasswordTex')), 1)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass({
                'form-input': !_ctx.status.error,
                'form-inputError': _ctx.status.error || _ctx.sentEmail === true
              })
            }, [
              _createVNode(_component_Input, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                type: "email",
                name: "email",
                title: _ctx.$t('email'),
                value: _ctx.email,
                validation: "email",
                validationMessages: {
                  required: _ctx.$t('validation.required'),
                  email: _ctx.$t('validation.email')
                },
                forName: "email",
                onChange: _ctx.handleChange
              }, null, 8, ["modelValue", "title", "value", "validationMessages", "onChange"])
            ], 2),
            _createElementVNode("div", _hoisted_11, [
              (_ctx.status.error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t(_ctx.status.message || 'error')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.sentEmail && _ctx.status.error !== true)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('sentEmail')), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_Button, {
              id: "login",
              variant: "logIn",
              type: "button",
              onClick: _ctx.forgotPassword,
              title: _ctx.$t('sendResetLink'),
              "data-testid": "sendResetLinkButton"
            }, null, 8, ["onClick", "title"])
          ])
        ])
      ])
    ])
  ]))
}