import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "capacityPickupBox" }
const _hoisted_2 = { class: "capacityPickupBox__header" }
const _hoisted_3 = { class: "block-title" }
const _hoisted_4 = { class: "block-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Title = _resolveComponent("Title")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputCapacity = _resolveComponent("InputCapacity")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Link, {
        title: _ctx.$t('backToInfoPickupBox'),
        onBackTo: _ctx.backTo
      }, null, 8, ["title", "onBackTo"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Title, {
          title: _ctx.$t('capacity')
        }, null, 8, ["title"]),
        _createVNode(_component_Title, {
          title: _ctx.capacityPickupBoxIdResult.name
        }, null, 8, ["title"]),
        _createVNode(_component_Title, {
          title: _ctx.capacityPickupBoxIdResult.id
        }, null, 8, ["title"])
      ]),
      _createVNode(_component_Dropdown, {
        class: "capacityPickupBox__header-dropdown",
        selectedOption: _ctx.getOption.selectedOption,
        options: _ctx.getOption.options,
        onSelectOption: _ctx.changeItem
      }, null, 8, ["selectedOption", "options", "onSelectOption"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_InputCapacity, {
          type: "number",
          name: "averagePickupTime",
          modelValue: _ctx.averagePickupTime,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.averagePickupTime) = $event)),
          label: _ctx.$t('averagePickupTime'),
          disabled: ""
        }, null, 8, ["modelValue", "label"]),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('Hours')), 1)
      ])
    ]),
    _createVNode(_component_Table, {
      data: _ctx.capacityPickupBoxType,
      columns: _ctx.columns
    }, null, 8, ["data", "columns"])
  ]))
}