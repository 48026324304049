import { Option, TranslationFunction } from '@/types';

export const searchByOptions = ($t: TranslationFunction): Option[] => [
  {
    value: 'pickup',
    label: $t('pickedUp')
  },
  {
    value: 'cancelled',
    label: $t('cancelled')
  },
  {
    value: 'destroyed',
    label: $t('destroyed')
  },
  {
    value: 'all',
    label: $t('allUpperCase')
  }
];

export const searchActions = ($t: TranslationFunction): Option[] => [
  {
    value: 'phoneNumber',
    label: $t('mobilePhone')
  },
  {
    value: 'deliveryId',
    label: $t('orderID')
  }
];

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('mobilePhoneUpperCase'),
    value: 'phoneNumber'
  },
  {
    label: $t('deliveryStatusUpperCase'),
    value: 'deliveryStatus'
  },
  {
    label: $t('kolliIDUpperCase'),
    value: 'deliveryIds'
  },
  {
    label: $t('lockerIDUpperCase'),
    value: 'lockerId'
  },
  {
    label: $t('reservedUpperCase'),
    value: 'reservedTime'
  },
  {
    label: $t('deliveredUpperCase'),
    value: 'deliveryTime'
  },
  {
    label: `${$t('pickedUpUpperCase')} / ${$t('destroyedUpUpperCase')}`,
    value: 'pickedUpAndDestroyed'
  },
  {
    label: $t('assignedTimeByUpperCase'),
    value: 'assignedTime'
  },
  {
    label: $t('orderExpiredUpperCase'),
    value: 'expiredTime'
  },
  {
    label: $t('causeOfAlarmUpperCase'),
    value: 'alarm'
  },
  {
    label: $t('descriptionUpperCase'),
    value: 'descriptionText'
  }
];
