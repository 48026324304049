import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/Settings.svg'


const _hoisted_1 = { class: "customersFeedback" }
const _hoisted_2 = { class: "customersFeedback__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Title = _resolveComponent("Title")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Title, {
        title: _ctx.$t('customersFeedback')
      }, null, 8, ["title"]),
      _createElementVNode("img", {
        class: "customersFeedback__settings",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToSettings && _ctx.goToSettings(...args))),
        alt: "Settings"
      })
    ]),
    _createVNode(_component_Tabs, {
      tabs: _ctx.tabs,
      onSelectTab: _ctx.setActiveTab
    }, null, 8, ["tabs", "onSelectTab"]),
    _createVNode(_component_router_view, { pharmacy: _ctx.pharmacy }, null, 8, ["pharmacy"])
  ]))
}