import { RootState } from '@/store/types';
import { CallerAuthenticationState } from './types';
import { GetterTree } from 'vuex';

export const getters: GetterTree<CallerAuthenticationState, RootState> = {
  callerAuthentication: (state) => state.callerAuthentication,
  authenticationHistory: (state) => state.authenticationHistory,
  decodedSsn: (state) => state.decodedSsn,
  callbackRequests: (state) => state.callbackRequests,
  markAsDone: (state) => state.markAsDone,
  markAsMissed: (state) => state.markAsMissed,
  callerAuthenticate: (state) => state.callerAuthenticate
};
