import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/Close.svg'


const _hoisted_1 = { class: "changePassword" }
const _hoisted_2 = { class: "changePassword-header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "changePassword-body" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { class: "password-field" }
const _hoisted_7 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Modal, { onClose: _ctx.closeModal }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('changePassword')), 1),
            _createElementVNode("img", {
              src: _imports_0,
              alt: "",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('infoChangePassword')), 1),
            _createVNode(_component_FormKit, {
              type: "form",
              id: "changePassword-form",
              modelValue: _ctx.formData,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData) = $event)),
              actions: false,
              config: _ctx.validationRules,
              onSubmit: _ctx.submitHandler
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_PasswordInput, {
                    name: "password",
                    class: "changePassword-passwordInput",
                    modelValue: "password",
                    label: _ctx.$t('password'),
                    validation: "required|length:8",
                    validationMessages: {
                required: _ctx.$t('validation.required'),
                length: _ctx.$t('validation.lengthLong')
              }
                  }, null, 8, ["label", "validationMessages"]),
                  _createVNode(_component_PasswordInput, {
                    name: "password_confirm",
                    class: "changePassword-passwordInput",
                    modelValue: "password_confirm",
                    label: _ctx.$t('confirmPassword'),
                    validation: "required|confirm",
                    validationMessages: {
                required: _ctx.$t('validation.required'),
                confirm: _ctx.$t('validation.confirm')
              }
                  }, null, 8, ["label", "validationMessages"])
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "config", "onSubmit"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Button, {
              variant: "filled",
              title: _ctx.$t('save'),
              onClick: _ctx.submitForm
            }, null, 8, ["title", "onClick"]),
            _createVNode(_component_Button, {
              variant: "notBordered",
              title: _ctx.$t('cancel'),
              onClick: _ctx.closeModal
            }, null, 8, ["title", "onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["onClose"])
  ], 64))
}