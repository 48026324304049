
import moment from 'moment';
import { Options, Vue } from 'vue-class-component';

import Loading from '@/views/Loading.vue';

@Options({
  name: 'PaymentInfo',
  props: ['paymentInfo'],
  components: {
    Loading
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    getIssuePayment(value: boolean) {
      if (value) {
        return `${this.$t('yes')}`;
      } else {
        return `${this.$t('no')}`;
      }
    },
    getTime(time: number) {
      if (time) {
        return moment(time).format('DD.MM.YYYY HH:mm');
      }
    }
  }
})
export default class PaymentInfo extends Vue {}
