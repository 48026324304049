import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "photoTab" }
const _hoisted_2 = { class: "scale-selection" }
const _hoisted_3 = { class: "scale-selection-title" }
const _hoisted_4 = { class: "radio" }
const _hoisted_5 = { class: "custom-radio" }
const _hoisted_6 = { class: "radio" }
const _hoisted_7 = {
  key: 0,
  class: "custom-radio"
}
const _hoisted_8 = { class: "radio" }
const _hoisted_9 = {
  key: 0,
  class: "custom-radio"
}
const _hoisted_10 = { class: "photoTab__container" }
const _hoisted_11 = { class: "options__input-photo" }
const _hoisted_12 = { class: "options__input-photo-title" }
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_cropper = _resolveComponent("cropper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('chooseScale')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            value: "1/1",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.scale) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onScaleChange && _ctx.onScaleChange(...args)))
          }, null, 544), [
            [_vModelRadio, _ctx.scale]
          ]),
          _cache[6] || (_cache[6] = _createTextVNode(" 1/1 "))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.isGeneral)
          ? (_openBlock(), _createElementBlock("label", _hoisted_7, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                value: "2/1",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.scale) = $event)),
                onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onScaleChange && _ctx.onScaleChange(...args)))
              }, null, 544), [
                [_vModelRadio, _ctx.scale]
              ]),
              _cache[7] || (_cache[7] = _createTextVNode(" 2/1 "))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (!_ctx.isGeneral)
          ? (_openBlock(), _createElementBlock("label", _hoisted_9, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                value: "3/2",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.scale) = $event)),
                onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onScaleChange && _ctx.onScaleChange(...args)))
              }, null, 544), [
                [_vModelRadio, _ctx.scale]
              ]),
              _cache[8] || (_cache[8] = _createTextVNode(" 3/2 "))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.isGeneral ? _ctx.$t('logoPhoto') : _ctx.$t('advertPhoto')), 1),
        _createVNode(_component_FormKit, {
          classes: {
            input: 'photoInput',
            inner: 'photoInputInner'
          },
          name: 'logo',
          type: "file",
          accept: ".png",
          validation: "required",
          onInput: _ctx.onFileChange
        }, null, 8, ["onInput"])
      ]),
      _createElementVNode("img", {
        class: "existingPhoto",
        src: _ctx.existingPhoto,
        alt: ""
      }, null, 8, _hoisted_13)
    ]),
    (_ctx.imageSrc)
      ? (_openBlock(), _createBlock(_component_cropper, {
          key: 0,
          ref: "cropper",
          class: "cropper",
          src: _ctx.imageSrc,
          "stencil-props": _ctx.stencilProps,
          onChange: _ctx.onCrop,
          transform: _ctx.transform
        }, null, 8, ["src", "stencil-props", "onChange", "transform"]))
      : _createCommentVNode("", true)
  ]))
}