import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "infoAboutActionLog" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "info"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "info"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 2,
  class: "info"
}
const _hoisted_8 = {
  key: 3,
  class: "info"
}
const _hoisted_9 = {
  key: 0,
  class: "info"
}
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('fullHistory')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeReservations.actionsLog, (actionsLog, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "infoAboutActionLog-container",
        key: index
      }, [
        (actionsLog.assignedTime)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('assignedTime')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(actionsLog.assignedTime)), 1),
              (actionsLog.assignedUserName)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, " ( " + _toDisplayString(actionsLog.assignedUserName) + " ) ", 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (actionsLog.deleteTime)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('deleted')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(actionsLog.deleteTime)), 1),
              (actionsLog.deleteUserName)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, " ( " + _toDisplayString(actionsLog.deleteUserName) + " ) ", 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (actionsLog.lockerId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('lockerID')), 1),
              _createElementVNode("span", null, _toDisplayString(actionsLog.lockerId.join(', ')), 1)
            ]))
          : _createCommentVNode("", true),
        (actionsLog.reservedUserId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('reserved')), 1),
              _createElementVNode("span", null, _toDisplayString(actionsLog.reservedUserId === 'API'
              ? 'API'
              : actionsLog.reservedUserName), 1)
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(actionsLog.updatesLog, (updatesLog, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "infoUpdatesLog",
            key: index
          }, [
            updatesLog
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('deliveryHistoryPage.updated')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(updatesLog.updateTime)), 1),
                  (updatesLog.updateUserName)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, " ( " + _toDisplayString(updatesLog.updateUserName) + " ) ", 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}