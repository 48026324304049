import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/CloseModal.svg'


const _hoisted_1 = { class: "assignDelivery" }
const _hoisted_2 = { class: "assignDelivery__header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "assignDelivery__description" }
const _hoisted_5 = {
  class: "label-description",
  for: "description"
}
const _hoisted_6 = { class: "assignDelivery__button" }
const _hoisted_7 = { class: "assignDelivery__body" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "warning" }
const _hoisted_10 = { class: "assignDelivery__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.showModalAssign)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('assignDeliveryFromDesk')), 1),
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('description')), 1),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
                id: "description",
                class: "input-description",
                maxlength: "250"
              }, "        ", 512), [
                [_vModelText, _ctx.description]
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Button, {
                variant: "filled",
                title: _ctx.$t('save'),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('assignDeliveryRequest', _ctx.idDelivery, _ctx.description)))
              }, null, 8, ["title"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('assignDeliveryFromLocker')), 1),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('assignToLocker')), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_Button, {
                class: "assignDelivery__buttons-redirect",
                variant: "filled",
                title: _ctx.$t('redirectToPickupBox'),
                onClick: _ctx.redirectToPickupBox
              }, null, 8, ["title", "onClick"]),
              _createVNode(_component_Button, {
                variant: "redBordered",
                title: _ctx.$t('cancel'),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closeModal')))
              }, null, 8, ["title"])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}