import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  AlarmsData,
  CapacityByPharmacyId,
  CapacityByPickupBoxId,
  DeliveriesStatistics,
  Delivery,
  InfoFromPickupBox,
  PharmacyState,
  PickupBox,
  PickupBoxCapacity,
  PickupBoxCapacityType,
  SsnDelivery,
  TemperatureLog,
  TemperatureStamps
} from '@/store/modules/pharmacy/types';
import {
  assignDelivery,
  createPharmacyReservation,
  createReservation,
  deletePharmacyReservation,
  deleteReservation,
  deliveryDeletedOrRemovedPickup,
  destroyReservation,
  getAlarms,
  getDeliveriesStatistics,
  getDeliveryHistory,
  getDeliveryShowDecodedSsn,
  getPharmacyById,
  getPharmacyServiceRequest,
  getPickupBoxById,
  getPickupBoxByPharmacyId,
  getPickupBoxCapacity,
  getTemperatureLog,
  getTemperaturesById,
  resendSMS,
  toggleActivePickupBox,
  toggleLockerPickupBox,
  updateAlarmComment,
  updateDescription,
  updatePharmacy,
  updatePharmacyReservation,
  updatePickupBox,
  writeTemperaturesById,
  getCapacityByPickupBoxId,
  updateFeedbackTemplate,
  updatePharmacyLogo
} from '@/services/axiosService';
import { PharmacyMutations } from './mutations';
import { cleanLocalStorage, reformatPickupBox } from './utils';

export const actions: ActionTree<PharmacyState, RootState> = {
  async getPharmacy({ commit }, id) {
    try {
      const pharmacy = await getPharmacyById(id);
      commit(PharmacyMutations.get_pharmacy, { pharmacy });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  clearPharmacy({ commit }) {
    commit(PharmacyMutations.clear_pharmacy);
  },
  async getPharmacyServiceRequest(_, { data, pharmacyId }) {
    try {
      await getPharmacyServiceRequest(data, pharmacyId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async updatePharmacy({ commit }, { data, pharmacyId }) {
    try {
      await updatePharmacy(data, pharmacyId);
      commit(PharmacyMutations.update_pharmacy, { data });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async updatePharmacyLogo(_, { data, pharmacyId }) {
    try {
      await updatePharmacyLogo(data, pharmacyId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getPickupBoxesByPharmacy({ commit }, id) {
    try {
      const pickupBoxesResult: PickupBox[] = await getPickupBoxByPharmacyId(id);
      const pickupBoxes: PickupBox[] = pickupBoxesResult.map((i) =>
        reformatPickupBox(i)
      );

      commit(PharmacyMutations.get_pickup_box, { pickupBoxes });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getPickupBoxCapacity({ commit }, { pharmacyId, startTime, endTime }) {
    try {
      const capacityResult: CapacityByPharmacyId | undefined =
        await getPickupBoxCapacity(pharmacyId, startTime, endTime);

      if (capacityResult) {
        const capacity: PickupBoxCapacity[] = Object.keys(capacityResult).map(
          (type: string) => ({
            type: type,
            averagePickupTime: capacityResult[type].averagePickupTime,
            capacity: capacityResult[type].capacity
          })
        );

        commit(PharmacyMutations.get_pickup_box_capacity, { capacity });
      }
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getCapacityByPickupBoxId({ commit }, { boxId, startTime, endTime }) {
    try {
      const capacityPickupBoxIdResult: CapacityByPickupBoxId | undefined =
        await getCapacityByPickupBoxId(boxId, startTime, endTime);
      if (capacityPickupBoxIdResult) {
        const capacityPickupBoxType: PickupBoxCapacityType[] = Object.keys(
          capacityPickupBoxIdResult.capacity
        ).map((type: string) => ({
          type,
          capacityPickupBoxType: capacityPickupBoxIdResult.capacity[type]
        }));
        commit(PharmacyMutations.get_capacity_pickup_box_type, {
          capacityPickupBoxType
        });
      }
      commit(PharmacyMutations.get_capacity_pickup_box_id, {
        capacityPickupBoxIdResult
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getPickupBoxById({ commit }, boxId) {
    try {
      const pickupBox: InfoFromPickupBox | undefined = await getPickupBoxById(
        boxId
      );
      commit(PharmacyMutations.get_pickup_box_by_id, { pickupBox });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getTemperaturesById({ commit }, boxId) {
    try {
      const temperatureStamps: TemperatureStamps | undefined =
        await getTemperaturesById(boxId);
      commit(PharmacyMutations.get_temperatures_by_id, { temperatureStamps });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async writeTemperaturesById(_, boxId) {
    try {
      await writeTemperaturesById(boxId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },

  async createReservation(_, { data, boxId }) {
    try {
      await createReservation(data, boxId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async createPharmacyReservation(_, { data, pharmacyId }) {
    try {
      await createPharmacyReservation(data, pharmacyId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async deleteReservation(_, { boxId, deliveryId }) {
    try {
      await deleteReservation(boxId, deliveryId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async destroyReservation(_, { ...data }) {
    try {
      await destroyReservation(data);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async deletePharmacyReservation(_, { data, pharmacyId, deliveryId }) {
    try {
      const payload = {
        reservedTime: data.reservedTime
      };

      await deletePharmacyReservation(payload, pharmacyId, deliveryId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async updatePharmacyReservation(_, { data, pharmacyId, deliveryId }) {
    try {
      await updatePharmacyReservation(data, pharmacyId, deliveryId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async resendSMS(_, data) {
    try {
      await resendSMS(data);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async assignDelivery(_, { data, pharmacyId, deliveryId }) {
    try {
      await assignDelivery(data, pharmacyId, deliveryId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async toggleActivePickupBox({ commit }, { data, boxId }) {
    try {
      const payload = {
        active: !data.active
      };

      await toggleActivePickupBox(payload, boxId);
      commit(PharmacyMutations.toggle_active_pickup_box, { payload });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async toggleLockerPickupBox({ commit }, { data, boxId }) {
    try {
      const payload = {
        inactive: data.inactive,
        lockerId: data.lockerId
      };

      await toggleLockerPickupBox(payload, boxId);
      commit(PharmacyMutations.toggle_locker_pickup_box, { payload });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getDeliveryHistory(
    { commit },
    { boxId_or_pharmacyId, startTime, endTime }
  ) {
    try {
      const deliveryHistory: Delivery[] | undefined = await getDeliveryHistory(
        boxId_or_pharmacyId,
        startTime,
        endTime
      );

      commit(PharmacyMutations.get_delivery_history, {
        boxId_or_pharmacyId,
        deliveryHistory
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getDeliveryShowDecodedSsn(
    { commit },
    { pickupboxId, deliveryId, reservedTime, docId, pharmacyId }
  ) {
    try {
      const decodedSsn: SsnDelivery | undefined =
        await getDeliveryShowDecodedSsn(
          deliveryId,
          reservedTime,
          pickupboxId,
          docId,
          pharmacyId
        );

      commit(PharmacyMutations.get_decoded_ssn, {
        decodedSsn
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async updateDescription({ commit }, { data, deliveryId }) {
    try {
      const payload = {
        reservedTime: data.reservedTime,
        descriptionText: data.descriptionText,
        pickupboxId: data.pickupboxId,
        pharmacyId: data.pharmacyId,
        docId: data.docId
      };
      await updateDescription(payload, deliveryId);
      commit(PharmacyMutations.update_description, { payload });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getAlarms({ commit }, { boxId, startTime, endTime, alarmType }) {
    try {
      const alarms: AlarmsData[] | undefined = await getAlarms(
        boxId,
        startTime,
        endTime,
        alarmType
      );

      commit(PharmacyMutations.get_alarms_tab, { alarms });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getTemperatureLog({ commit }, { boxId, startTime, endTime }) {
    try {
      const temperatureLog: TemperatureLog | undefined =
        await getTemperatureLog(boxId, startTime, endTime);

      commit(PharmacyMutations.get_temperature_log, { temperatureLog });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async updateAlarmComment({ commit }, { data }) {
    try {
      await updateAlarmComment(data);
      commit(PharmacyMutations.update_alarn_comment, { data });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getDeliveriesStatistics(
    { commit },
    { pharmacyId, startTime, endTime }
  ) {
    try {
      const deliveriesStatistics: DeliveriesStatistics[] | undefined =
        await getDeliveriesStatistics(pharmacyId, startTime, endTime);

      commit(PharmacyMutations.get_deliveries_statistics, {
        deliveriesStatistics
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async updatePickupBox(_, { data, boxId }) {
    try {
      await updatePickupBox(data, boxId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  clearAlarms({ commit }) {
    commit(PharmacyMutations.clear_alarms);
  },
  async deliveryDeletedOrRemovedPickup(_, data) {
    try {
      await deliveryDeletedOrRemovedPickup(data);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async updateFeedbackTemplate(_, { data, pharmacyId }) {
    try {
      await updateFeedbackTemplate(data, pharmacyId);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  }
};
