import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/Hyphen.svg'


const _hoisted_1 = { class: "statistics" }
const _hoisted_2 = { class: "statistics__chooseDate" }
const _hoisted_3 = { class: "statistics__chooseDate__container" }
const _hoisted_4 = { class: "chooseDate-date-inputs" }
const _hoisted_5 = { class: "input" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = {
  key: 0,
  class: "date-error"
}
const _hoisted_8 = { class: "statistics__chooseTemplates" }
const _hoisted_9 = {
  key: 0,
  class: "date-error"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "statistics__intervals" }
const _hoisted_12 = { class: "statistics__charts-week" }
const _hoisted_13 = { class: "week-title" }
const _hoisted_14 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FeedbackMainTable = _resolveComponent("FeedbackMainTable")!
  const _component_FeedbackChartsDays = _resolveComponent("FeedbackChartsDays")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CustomersFeedbackWeek = _resolveComponent("CustomersFeedbackWeek")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Calendar, {
              type: "datetime",
              format: "DD/MM/YYYY HH:mm",
              value: _ctx.firstDay,
              name: "startTime",
              minuteStep: _ctx.selectedInterval,
              hourOptions: _ctx.hourInterval,
              onChange: _ctx.changeDate
            }, null, 8, ["value", "minuteStep", "hourOptions", "onChange"])
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            class: "date-img",
            src: _imports_0,
            alt: "Hyphen"
          }, null, -1)),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Calendar, {
              type: "datetime",
              format: "DD/MM/YYYY HH:mm",
              value: _ctx.today,
              name: "endTime",
              onChange: _ctx.changeDate
            }, null, 8, ["value", "onChange"])
          ])
        ])
      ]),
      (!_ctx.isDateCorrect)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('errorDate')), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_Dropdown, {
        class: "statistics__templatesCharts-dropdown",
        selectedOption: _ctx.getOptionTemplatesCharts.selectedOption,
        options: _ctx.getOptionTemplatesCharts.options,
        multiple: true,
        onSelectOption: _ctx.selectOptionTemplatesCharts
      }, null, 8, ["selectedOption", "options", "onSelectOption"]),
      (!_ctx.isDateCorrect)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t('errorDate')), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_FeedbackMainTable, {
      feedbackStatistics: _ctx.feedbackStatistics,
      isDateCorrect: _ctx.isDateCorrect
    }, null, 8, ["feedbackStatistics", "isDateCorrect"]),
    (_ctx.feedbackStatistics?.labels?.length && _ctx.isDateCorrect)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_Dropdown, {
              class: "statistics__intervals-dropdown",
              selectedOption: _ctx.getOptionIntervals.selectedOption,
              options: _ctx.getOptionIntervals.options,
              onSelectOption: _ctx.selectOptionIntervals
            }, null, 8, ["selectedOption", "options", "onSelectOption"])
          ]),
          _createVNode(_component_FeedbackChartsDays, {
            dataLineChart: _ctx.dataLineChart,
            dataBarChart: _ctx.dataBarChart
          }, null, 8, ["dataLineChart", "dataBarChart"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('weekUpperCase')), 1),
        _createVNode(_component_Button, {
          variant: "bordered",
          title: _ctx.$t('showUpperCase'),
          onClick: _ctx.showWeekCharts
        }, null, 8, ["title", "onClick"])
      ]),
      (_ctx.showBlockWeekCharts)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createVNode(_component_CustomersFeedbackWeek, { pharmacy: _ctx.pharmacy }, null, 8, ["pharmacy"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}