import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "infoAboutDelivery" }
const _hoisted_2 = {
  key: 0,
  class: "info"
}
const _hoisted_3 = {
  key: 1,
  class: "info"
}
const _hoisted_4 = { class: "info" }
const _hoisted_5 = {
  key: 2,
  class: "info"
}
const _hoisted_6 = {
  key: 3,
  class: "info"
}
const _hoisted_7 = {
  key: 4,
  class: "info"
}
const _hoisted_8 = {
  key: 5,
  class: "info"
}
const _hoisted_9 = {
  key: 6,
  class: "info"
}
const _hoisted_10 = {
  key: 7,
  class: "info"
}
const _hoisted_11 = {
  key: 8,
  class: "info"
}
const _hoisted_12 = {
  key: 9,
  class: "info"
}
const _hoisted_13 = {
  key: 10,
  class: "info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.activeDelivery.phoneNumber)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('mobilePhone')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.phoneNumber), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeDelivery.deliveryStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('deliveryStatus')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.activeDelivery.deliveryStatus)), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('ssn')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.dataSsn || '************'), 1),
      (!_ctx.dataSsn)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "ssn_btn",
            variant: "notBordered",
            title: _ctx.$t('show'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSsn()))
          }, null, 8, ["title"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.activeDelivery.deliveryId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('kolliID')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.getIds(
            _ctx.activeDelivery.deliveryId,
            _ctx.activeDelivery.additionalDeliveryIds
          )), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeDelivery.lockerId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('lockerID')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.lockerId.join(', ')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeDelivery.reservedTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('reserved')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.reservedTime)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeDelivery.deliveryTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('delivered')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.deliveryTime)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeDelivery.givenOutUserName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('givenOutUserName')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.givenOutUserName), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeDelivery.pickupTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('pickedUp')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.pickupTime)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeDelivery.assignedTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('assignedTime')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.assignedTime)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeDelivery.orderExpired)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('orderExpired')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.orderExpired), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeDelivery.causeOfAlarm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('causeOfAlarm')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeDelivery.causeOfAlarm), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}