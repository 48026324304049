import { Option, TranslationFunction } from '@/types';

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: '',
    value: 'status'
  },
  {
    label: $t('clientUpperCase'),
    value: 'userName'
  },
  {
    label: $t('phoneUpperCase'),
    value: 'userPhoneNumber'
  },
  {
    label: $t('preferredHoursUpperCase'),
    value: 'preferredHours'
  },
  {
    label: $t('timestampUpperCase'),
    value: 'timestamp'
  },
  {
    label: $t('green'),
    value: 'Green'
  },
  {
    label: $t('orange'),
    value: 'Orange'
  }
];
