
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import router from '@/router';

import { infoFromUser } from '@/constants';

import { Option } from '@/types';
import { AppRole } from '@/store/modules/roles/types';

import {
  optionsLanguageEn,
  optionsLanguageFi,
  optionsLanguageSv,
  optionsLanguageNo
} from '@/services/languageService';
import { UserCreate } from '@/store/modules/users/types';

@Options({
  name: 'CreateUser',
  components: {
    Loading
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        appLanguage: '',
        password: '',
        password_confirm: '',
        role: 'main_user',
        pharmacyId: ''
      },
      userRoleDropdown: {
        selectedOption: {
          value: '',
          label: ''
        },
        options: []
      },
      fieldTypes: {
        password: 'password',
        email: 'text'
      },
      langsDropdown: {
        selectedOption: [],
        options: []
      },
      validationRules: {},
      infoFromUser,
      loading: false
    };
  },
  created() {
    this.show();
    this.initLanguage();
  },
  computed: {
    ...mapState('roles', ['appRoles']),
    ...mapState('account', ['user'])
  },
  methods: {
    ...mapActions('users', ['createUser']),
    ...mapActions('roles', ['getAppRoles']),
    async show() {
      this.loading = true;
      await this.getAppRoles();

      this.initUserRole();
      this.loading = false;
    },
    initLanguage() {
      if (this.user?.appLanguage === 'en') {
        this.langsDropdown.options = optionsLanguageEn;
        this.langsDropdown.selectedOption = optionsLanguageEn[0];
      }

      if (this.user?.appLanguage === 'fi') {
        this.langsDropdown.options = optionsLanguageFi;
        this.langsDropdown.selectedOption = optionsLanguageFi[0];
      }

      if (this.user?.appLanguage === 'sv') {
        this.langsDropdown.options = optionsLanguageSv;
        this.langsDropdown.selectedOption = optionsLanguageSv[0];
      }

      if (this.user?.appLanguage === 'no') {
        this.langsDropdown.options = optionsLanguageNo;
        this.langsDropdown.selectedOption = optionsLanguageNo[0];
      }
    },
    async submitHandler(data: UserCreate) {
      this.loading = true;

      const payload = {
        ...data,
        appLanguage: this.langsDropdown.selectedOption.value,
        role: this.userRoleDropdown.selectedOption.value,
        pharmacyId: this.user.pharmacyId
      };

      delete payload.password_confirm;

      await this.createUser(payload);

      this.$formkit.reset('createUser-form');
      this.langsDropdown.selectedOption = [];

      router.push({ name: 'Users' });

      this.loading = false;
    },
    // eslint-disable-next-line
    handleType(event: any) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === 'blur' && !value) {
        this.fieldTypes[name] = 'text';
      } else {
        this.fieldTypes[name] = name === 'email' ? name : 'password';
      }
    },
    backTo() {
      router.push({ name: 'Users' });
    },
    initUserRole() {
      this.userRoleDropdown.options = this.appRoles.map((i: AppRole) => ({
        value: i.value,
        label: i.label
      }));

      this.userRoleDropdown.selectedOption = this.userRoleDropdown.options.find(
        (i: Option) => i.value === this.user.role.name
      );
    },
    selectUser(option: Option) {
      this.userRoleDropdown.selectedOption = option;
      this.formData.role = option.value;
    },
    selectLanguage(option: Option) {
      this.langsDropdown.selectedOption = option;
    },
    submitForm() {
      this.$formkit.submit('createUser-form');
    },
    cleanForm() {
      this.$formkit.reset('createUser-form');
    }
  },
  watch: {
    user() {
      if (this.user) {
        this.initLanguage();
      }
    }
  }
})
export default class CreateUserComponent extends Vue {}
