<template>
  <div id="message">
    <h2>404</h2>
    <h1>{{ $t('pageNotFound') }}</h1>
    <p>{{ $t('checkURL') }}</p>
  </div>
</template>

<script>
import { Vue, Options } from 'vue-class-component';

@Options({
  name: 'NotFound'
})
export default class NotFound extends Vue {}
</script>

<style media="screen" lang="scss">
body {
  background: #eceff1;
  color: #000000de;
  margin: 0;
  padding: 0;
}
#message {
  max-width: 360px;
  margin: 100px auto 16px;
  padding: 32px 24px 16px;
  border-radius: 3px;
  grid-row: span 2;
  grid-column: span 2;
}
#message h3 {
  color: #888;
  font-weight: normal;
  font-size: 16px;
  margin: 16px 0 12px;
}
#message h2 {
  color: $green;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 8px;
}
#message h1 {
  font-size: 22px;
  font-weight: 300;
  color: #00000099;
  margin: 0 0 16px;
}
#message p {
  line-height: 140%;
  margin: 16px 0 24px;
  font-size: 14px;
}
#message a {
  display: block;
  text-align: center;
  background: #039be5;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  padding: 16px;
  border-radius: 4px;
}
#message,
#message a {
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}
#load {
  color: #00000066;
  text-align: center;
  font-size: 13px;
}
@media (max-width: 600px) {
  body,
  #message {
    margin-top: 0;
    background: $white;
    box-shadow: none;
  }
}
</style>
