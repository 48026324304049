
import { Options, Vue } from 'vue-class-component';
import { mapActions } from 'vuex';

import Loading from '@/views/Loading.vue';

@Options({
  name: 'AddComment',
  props: ['showModalAddComment', 'activePayment'],
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      formData: {
        comment: ''
      }
    };
  },
  methods: {
    ...mapActions('orders', ['addComment']),
    async submitHandler() {
      this.loading = true;

      const orderId = this.activePayment?.id;
      const payload = { comment: this.formData.comment };

      await this.addComment({
        data: payload,
        orderId
      });

      this.loading = false;

      this.$emit('closeModal');
    },
    saveComment() {
      this.$formkit.submit('comment-form');
    }
  },
  watch: {
    showModalAddComment() {
      if (this.showModalAddComment) {
        this.formData.comment = this.activePayment.comment;
      }
    }
  }
})
export default class AddComment extends Vue {}
