import { MutationTree } from 'vuex';
import { OrderPaymentState } from './types';

export enum OrderPaymentMutations {
  get_order_push_notification = 'get_order_push_notification',
  get_order_create = 'get_order_create',
  get_feedback_statistics = 'get_feedback_statistics',
  get_feedback_statistics_week = 'get_feedback_statistics_week',
  get_payments_history = 'get_payments_history',
  get_view_payment = 'get_view_payment',
  get_payments_history_decoded_ssn = 'get_payments_history_decoded_ssn'
}

export const mutations: MutationTree<OrderPaymentState> = {
  [OrderPaymentMutations.get_order_push_notification](
    state,
    { orderPushNotification }
  ) {
    state.orderPushNotification = orderPushNotification;
  },
  [OrderPaymentMutations.get_order_create](state, { orderPayment }) {
    state.orderPayment = orderPayment;
  },
  [OrderPaymentMutations.get_feedback_statistics](
    state,
    { feedbackStatistics }
  ) {
    state.feedbackStatistics = feedbackStatistics;
  },
  [OrderPaymentMutations.get_feedback_statistics_week](
    state,
    { feedbackStatisticsWeek }
  ) {
    state.feedbackStatisticsWeek = feedbackStatisticsWeek;
  },
  [OrderPaymentMutations.get_payments_history](state, { ordersHistory }) {
    state.ordersHistory = ordersHistory;
  },
  [OrderPaymentMutations.get_view_payment](state, { viewOrder }) {
    state.viewOrder = viewOrder;
  },
  [OrderPaymentMutations.get_payments_history_decoded_ssn](
    state,
    { decodedSSN }
  ) {
    state.decodedSSN = decodedSSN;
  }
};
