import { MutationTree } from 'vuex';
import { UsersState } from '@/store/modules/users/types';
import { getMapUsers } from './utils';

export enum UsersMutations {
  loaded_true = 'loaded_true',
  show_users_error = 'show_users_error',
  show_users = 'show_users',
  clear = 'clear',
  clear_user = 'clear_user',
  get_user = 'get_user',
  update_user = 'update_user',
  delete_user = 'delete_user',
  get_login_info = 'get_login_info',
  get_pharmacyAccesses = 'get_pharmacyAccesses',
  update_pharmacy_access = 'update_pharmacy_access'
}

export const mutations: MutationTree<UsersState> = {
  [UsersMutations.loaded_true](state) {
    state.status.loaded = true;
  },
  [UsersMutations.clear](state) {
    state.users = [];
  },
  [UsersMutations.clear_user](state) {
    state.user = null;
  },
  [UsersMutations.show_users](state, { users, lastKey, pageSize }) {
    if (lastKey) {
      state.users = [...state.users, ...getMapUsers(users)];
    } else {
      state.users = getMapUsers(users);
    }

    state.status.loaded = true;
    state.status.lastKey = lastKey;
    state.status.pageSize = pageSize;
  },
  [UsersMutations.show_users_error](state, message: string) {
    state.status.loaded = false;
    state.status.error = true;
    state.status.message = message;
    state.users = [];
  },
  [UsersMutations.get_user](state, { user }) {
    state.user = user;
  },
  [UsersMutations.update_user](state, { user }) {
    state.user = user;
  },
  [UsersMutations.delete_user](state, { userId }) {
    state.users = state.users.filter((user) => user.id !== userId);
  },
  [UsersMutations.get_login_info](state, { loginInfo, lastTimestamp }) {
    if (lastTimestamp) {
      state.loginInfo = [...state.loginInfo, ...loginInfo];
    } else {
      state.loginInfo = loginInfo;
    }
  },
  [UsersMutations.get_pharmacyAccesses](state, { pharmacyAccesses }) {
    state.pharmacyAccesses = pharmacyAccesses;
  },
  [UsersMutations.update_pharmacy_access](state, { pharmacyAccessesId }) {
    state.pharmacyAccessesId = pharmacyAccessesId;
  }
};
