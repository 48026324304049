
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import ReserveLocker from '@/duplicatePages/ReserveLocker/ReserveLocker.vue';
import ResendSMS from '@/duplicatePages/ResendSMS/ResendSMS.vue';
import AddDescription from './AddDescription/AddDescription.vue';
import DeleteReservation from './DeleteReservation/DeleteReservation.vue';
import OpenQRCode from '@/duplicatePages/OpenQRCode/OpenQRCode.vue';
import PickedUpReservation from './PickedUpReservation/PickedUpReservation.vue';
import InfoReservation from './InfoReservation/InfoReservation.vue';

import { InputFileEvent, Option } from '@/types';
import {
  DataReservations,
  PickupBox,
  Reservation,
  SSNData
} from '@/store/modules/pharmacy/types';

import { searchActions, columns } from './configs';

@Options({
  name: 'Reservations',
  emits: [
    'deleteReservationRequest',
    'pickedUpReservationRequest',
    'loadPickupBoxes',
    'getDecodedSsn',
    'destroyReservationRequest'
  ],
  components: {
    Loading,
    ReserveLocker,
    ResendSMS,
    AddDescription,
    DeleteReservation,
    PickedUpReservation,
    OpenQRCode,
    InfoReservation
  },
  data() {
    return {
      selectedReservations: {
        selectedOption: [],
        options: {}
      },
      getSearchBy: {
        selectedOption: {
          value: 'phoneNumber',
          label: this.$t('mobilePhone')
        },
        options: searchActions(this.$t)
      },
      dataReservations: [],
      dataPharmacyReservations: [],
      data: [],
      dataSearchBy: [],
      activeReservations: null,
      columns: columns(this.$t),
      activePickupBox: null,
      activePickupBoxes: null,
      idPickupBox: '',
      idDelivery: '',
      pharmacyId: '',
      search: '',
      showModalReserveLocker: false,
      showModalAddDescription: false,
      showModalPickedUpReservation: false,
      showModalDelete: false,
      showModalResendSMS: false,
      showModalInfoReservation: false,
      showModalDestroy: false,
      loading: false,
      showQRCode: false,
      idActiveDelivery: '',
      showDecodedSsn: ''
    };
  },
  created() {
    this.loadPickupBoxes();
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy', 'pickupBoxes', 'decodedSsn'])
  },
  updated() {
    if (this.pickupBoxes && this.pharmacy) {
      this.selectedReservations.selectedOption =
        this.pickupBoxAndPharmacyOptions;
    }
  },
  methods: {
    ...mapActions('pharmacy', [
      'getPickupBoxesByPharmacy',
      'deleteReservation',
      'deletePharmacyReservation',
      'getPharmacy',
      'updatePharmacyReservation',
      'getDeliveryShowDecodedSsn',
      'destroyReservation'
    ]),
    async loadPickupBoxes() {
      this.loading = true;

      const { id } = this.$route.params;
      await this.getPickupBoxesByPharmacy(id);
      await this.getPharmacy(id);

      this.getReservations(this.pickupBoxes);

      this.loading = false;

      this.initPickupBoxOptions();
    },
    initPickupBoxOptions() {
      this.pickupBoxOptions = this.pickupBoxes.map((i: PickupBox) => ({
        value: i.info.id,
        label: i.info.name,
        img: require('@/assets/icons/PickupBox.svg')
      }));

      const pharmacyReservations = {
        value: 'pharmacyReservations',
        label: this.$t('pharmacyReservations')
      };

      this.selectedReservations.options = {
        pharmacyReservations,
        ...this.pickupBoxOptions
      };

      this.pickupBoxAndPharmacyOptions = [
        ...this.pickupBoxOptions,
        pharmacyReservations
      ];
    },
    selectReservations(option: Option) {
      this.selectedReservations.selectedOption = option;

      const selectedReservations = this.selectedReservations.selectedOption.map(
        (i: Option) => i.value
      );

      this.activePickupBoxes = this.pickupBoxes.filter((pickupBox: PickupBox) =>
        selectedReservations.includes(pickupBox.info.id)
      );

      this.getReservations(this.activePickupBoxes);
    },
    getReservations(activePickupBoxes: readonly PickupBox[]) {
      this.dataReservations = activePickupBoxes.reduce(
        // eslint-disable-next-line
        (reservations: DataReservations[] | any, pickupBox: PickupBox) =>
          pickupBox.reservations
            ? [...reservations, ...pickupBox.reservations]
            : reservations,
        []
      );
    },
    reserve() {
      this.showModalReserveLocker = true;
    },
    changeInput(e: InputFileEvent) {
      const { value } = e.target;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (value === '') {
          this.loadPickupBoxes();
        } else if (this.getSearchBy.selectedOption.value === 'phoneNumber') {
          this.data = [...this.dataSearchBy].filter((item: DataReservations) =>
            item.phoneNumber.toLowerCase().includes(value?.toLowerCase())
          );
        } else if (this.getSearchBy.selectedOption.value === 'deliveryId') {
          this.data = [...this.dataSearchBy].filter((item: DataReservations) =>
            item.deliveryId.toLowerCase().includes(value?.toLowerCase())
          );
        }
      }, 600);
    },
    changeSearchBy(option: Option) {
      this.getSearchBy.selectedOption = option;
      this.data = [...this.dataSearchBy].filter(
        // eslint-disable-next-line
        (item: DataReservations[] | any) =>
          item[this.getSearchBy.selectedOption.value]
            ?.toLowerCase()
            .includes(this.search.toLowerCase())
      );
    },
    addDescription(deliveryId: string) {
      this.idDelivery = deliveryId;

      this.activeReservations = this.data.find(
        (i: DataReservations) => i.deliveryId === deliveryId
      );

      this.activePickupBox = this.pickupBoxes.find((pickupBox: PickupBox) =>
        pickupBox.reservations
          .map((i: Reservation) => i.deliveryId)
          .includes(this.activeReservations.deliveryId)
      );

      this.idPickupBox = this.activePickupBox?.id;

      this.showModalAddDescription = true;
    },
    resendSMSRequest(deliveryId: string) {
      this.idDelivery = deliveryId;
      this.showModalResendSMS = true;
    },
    deleteReservationTable(deliveryId: string) {
      this.idDelivery = deliveryId;

      this.activeReservations = this.data.find(
        (i: DataReservations) => i.deliveryId === deliveryId
      );

      this.activePickupBox = this.pickupBoxes.find((pickupBox: PickupBox) =>
        pickupBox.reservations
          .map((i: Reservation) => i.deliveryId)
          .includes(this.activeReservations.deliveryId)
      );

      this.idPickupBox = this.activePickupBox?.id;

      this.showModalDelete = true;
    },
    async deleteReservationRequest(boxId: string, deliveryId: string) {
      if (!this.activeReservations.lockerId) {
        this.loading = true;
        const { id } = this.$route.params;

        const payload = {
          reservedTime: this.activeReservations.reservedTime
        };

        await this.deletePharmacyReservation({
          data: payload,
          pharmacyId: id,
          deliveryId: deliveryId
        });
      } else {
        this.loading = true;

        await this.deleteReservation({
          boxId: boxId,
          deliveryId: deliveryId
        });
      }
      this.loading = false;
      this.showModalDelete = false;

      const { id } = this.$route.params;
      this.getPharmacy(id);
      this.loadPickupBoxes();
    },
    pickedUpReservation(deliveryId: string) {
      this.idDelivery = deliveryId;

      this.activeReservations = this.data.find(
        (i: DataReservations) => i.deliveryId === deliveryId
      );

      this.showModalPickedUpReservation = true;
    },
    async pickedUpReservationRequest(deliveryId: string) {
      this.loading = true;

      const { id } = this.$route.params;

      const payload = {
        deliveryStatus: 'pickup'
      };

      await this.updatePharmacyReservation({
        data: payload,
        pharmacyId: id,
        deliveryId: deliveryId
      });

      this.loading = false;
      this.showModalPickedUpReservation = false;

      this.getPharmacy(id);
      this.loadPickupBoxes();
    },
    infoReservation(deliveryId: string) {
      this.activeReservations = this.data.find(
        (i: DataReservations) => i.deliveryId === deliveryId
      );

      this.showModalInfoReservation = true;
    },
    openQRCode(id: string) {
      this.activeDelivery = [
        ...(Object.values(this.data) as DataReservations[])
      ].find((i: DataReservations) => i.deliveryId === id);

      this.idActiveDelivery = this.activeDelivery.deliveryId;

      this.showQRCode = true;
    },
    async getDecodedSsn(deliveryId: string) {
      this.idDelivery = deliveryId;

      this.activeReservations = this.data.find(
        (i: DataReservations) => i.deliveryId === deliveryId
      );

      this.activePickupBox = this.pickupBoxes.find((pickupBox: PickupBox) =>
        pickupBox.reservations
          .map((i: Reservation) => i.deliveryId)
          .includes(this.activeReservations.deliveryId)
      );

      this.idPickupBox = this.activePickupBox?.id;
      const { id } = this.$route.params;
      const params: SSNData = {
        deliveryId: this.activeReservations.deliveryId,
        reservedTime: this.activeReservations.reservedTime
      };

      if (this.idPickupBox) {
        params.pickupboxId = this.idPickupBox;
      } else {
        params.pharmacyId = id;
      }

      this.loading = true;

      await this.getDeliveryShowDecodedSsn(params);
      this.showDecodedSsn = this.decodedSsn;

      this.loading = false;
    },
    async destroyReservationRequest(deliveryId: string) {
      const { id } = this.$route.params;

      const payload = {
        pharmacyId: id,
        deliveryId
      };

      await this.destroyReservation(payload);

      this.loading = false;
      this.showModalDelete = false;

      this.getPharmacy(id);
      this.loadPickupBoxes();
    }
  },
  watch: {
    'selectedReservations.selectedOption': {
      immediate: true,
      handler(option: (Option | string)[]) {
        this.pickupBoxAndPharmacyOptions = option;
        const mapOption = this.pickupBoxAndPharmacyOptions.map(
          (i: Option) => i.value
        );

        if (
          !mapOption.includes('pharmacyReservations') ||
          mapOption.includes('pharmacyReservations')
        ) {
          this.dataReservations = this.dataReservations.filter(
            (i: DataReservations) => i.lockerId.length
          );
        }

        if (this.pharmacy) {
          if (mapOption.includes('pharmacyReservations')) {
            this.dataPharmacyReservations = (
              this.pharmacy.reservations || []
            ).filter(
              (reservation: DataReservations) => reservation.unassigned !== true
            );
          } else {
            this.dataPharmacyReservations = [];
          }
        }

        this.data = [
          ...this.dataReservations,
          ...this.dataPharmacyReservations
        ];

        this.dataSearchBy = this.data;
      }
    },
    showModalReserveLocker() {
      this.showDecodedSsn = '';
    },
    showModalAddDescription() {
      this.showDecodedSsn = '';
    },
    showModalInfoReservation() {
      this.showDecodedSsn = '';
    }
  }
})
export default class Reservations extends Vue {}
