
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import { ChangePasswordData } from '@/store/modules/account/types';

@Options({
  name: 'ChangePassword',
  components: {
    Loading
  },
  data() {
    return {
      formData: {
        password_current: '',
        password: '',
        password_confirm: ''
      },
      validationRules: {},
      loading: false
    };
  },
  computed: {
    ...mapState('account', ['user'])
  },
  methods: {
    ...mapActions('account', ['changePasswordAdminProfile']),
    async submitHandler(data: ChangePasswordData) {
      this.loading = true;

      const payload = {
        ...data,
        newPassword: this.formData.password,
        repeatNewPassword: this.formData.password_confirm,
        oldPassword: this.formData.password_current
      };
      delete payload.password;
      delete payload.password_confirm;
      delete payload.password_current;

      await this.changePasswordAdminProfile(payload);
      this.$formkit.reset('password-form');
      this.loading = false;
    },
    submitForm() {
      this.$formkit.submit('password-form');
    },
    cleanForm() {
      this.$formkit.reset('password-form');
    }
  }
})
export default class ChangePassword extends Vue {}
