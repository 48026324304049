export const options = [
  {
    label: 'English',
    value: 'en',
    img: require('@/assets/icons/FlagEnglish.svg')
  },
  {
    label: 'Suomi',
    value: 'fi',
    img: require('@/assets/icons/FlagFinland.png')
  },
  {
    label: 'Svenska',
    value: 'sv',
    img: require('@/assets/icons/FlagSweden.png')
  },
  {
    label: 'Norsk',
    value: 'no',
    img: require('@/assets/icons/FlagNorway.png')
  }
];
