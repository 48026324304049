
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';
import router from '@/router';

import Loading from '@/views/Loading.vue';
import ClientInfo from './ClientInfo/ClientInfo.vue';
import OpenConfirmation from '../OpenConfirmation/OpenConfirmation.vue';
import PaymentInfo from './PaymentInfo/PaymentInfo.vue';

import { Params } from '@/store/modules/users/types';

@Options({
  name: 'ViewOrder',
  components: {
    ClientInfo,
    OpenConfirmation,
    PaymentInfo,
    Loading
  },
  data() {
    return {
      pharmacyId: '',
      dataSSN: '',
      loading: false,
      showOpenConfirmation: false,
      showDecodedSSN: false,
      updatePage: false,
      paymentNotificationWasSuccessfullyResend: false
    };
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('orders', ['viewOrder', 'decodedSSN'])
  },
  created() {
    if (this.user) {
      this.pharmacyId = this.user?.pharmacyId;
    }

    this.show();
  },
  methods: {
    ...mapActions('orders', [
      'getViewOrderById',
      'getConfirmContent',
      'getOrdersHistoryDecodedSSN',
      'resendPaymentNotification',
      'cancelPaymentRequest'
    ]),
    async show() {
      this.loading = true;
      const { id } = this.$route.params;

      await this.getViewOrderById({
        pharmacyId: this.pharmacyId,
        orderId: id
      });

      this.loading = false;
    },
    async initSSN() {
      const params: Params = {
        pharmacyId: this.user?.pharmacyId,
        orderId: this.viewOrder.id
      };

      this.showDecodedSSN = !this.showDecodedSSN;

      if (this.showDecodedSSN) {
        this.loading = true;

        await this.getOrdersHistoryDecodedSSN(params);

        this.dataSSN = this.decodedSSN?.ssnOnBehalf;
        this.loading = false;
      }
    },
    backTo() {
      router.push({ name: 'OrdersHistory' });
    },
    getPaymentStatus(item: string): unknown {
      const labels: Record<string, unknown> = {
        pending: this.$t('pending'),
        failed: this.$t('failed'),
        completed: this.$t('completed'),
        done: this.$t('doneUpperCase'),
        cancelled: this.$t('cancelledTableUpperCase')
      };

      return labels[item];
    },
    getConsultation(consultation: boolean) {
      return consultation ? `${this.$t('yes')}` : `${this.$t('no')}`;
    },
    getString(array: Array<string>) {
      const type: Record<string, unknown> = {
        selfCare: this.$t('selfCare'),
        prescriptionDrugs: this.$t('prescriptionDrugs'),
        prescription: this.$t('prescription'),
        other: this.$t('other')
      };

      return array?.length
        ? array.map((item: string) => type[item]).join(', ')
        : '';
    },
    getDeliveryMethod(item: string): unknown {
      const labels: Record<string, unknown> = {
        pickupbox: this.$t('pickupBox'),
        pharmacy: this.$t('pharmacy'),
        homeDelivery: this.$t('homeDelivery')
      };

      return labels[item];
    },
    dateTime(value: number) {
      return value ? moment(value).format('DD.MM.YYYY HH:mm') : '';
    },
    openConfirmation() {
      this.showOpenConfirmation = true;
    },
    async confirmRequest() {
      await this.getConfirmContent({
        pharmacyId: this.pharmacyId,
        orderId: this.viewOrder.id
      });

      this.updatePage = true;
      this.showOpenConfirmation = false;
    },
    async resendPaymentNotificationResult() {
      if (!this.paymentNotificationWasSuccessfullyResend) {
        const { id } = this.$route.params;
        this.loading = true;

        await this.resendPaymentNotification({ orderId: id });

        this.paymentNotificationWasSuccessfullyResend = true;
        this.loading = false;
      }
    },
    async cancelPayment() {
      this.loading = true;

      const orderId = this.viewOrder.id;
      await this.cancelPaymentRequest({ orderId });

      this.updatePage = true;
      this.loading = false;
    }
  },
  watch: {
    updatePage() {
      if (this.updatePage) {
        this.show();
      }
    },
    user() {
      if (this.user) {
        this.pharmacyId = this.user?.pharmacyId;
      }

      this.show();
    }
  }
})
export default class ViewOrder extends Vue {}
