import { Option, TranslationFunction } from '@/types';

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('monthUpperCase'),
    value: 'month'
  },
  {
    label: $t('yearUpperCase'),
    value: 'year'
  },
  {
    label: $t('deliveriesUpperCase'),
    value: 'totalDeliveries'
  },
  {
    label: $t('pickupsUpperCase'),
    value: 'pickupAmount'
  },
  {
    label: $t('expiredUpperCase'),
    value: 'expiredAmount'
  },
  {
    label: $t('destroyedUpperCase'),
    value: 'destroyedAmount'
  },
  {
    label: $t('cancelledUpperCase'),
    value: 'cancelledAmount'
  },
  {
    label: $t('avgPickupTimeUpperCase'),
    value: 'averagePickupTime'
  },
  {
    label: $t('totalUsageUpperCase'),
    value: 'totalUsage'
  }
];
