
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import router from '@/router';
import moment from 'moment';

import Loading from '@/views/Loading.vue';
import AboutUser from './AboutUser/AboutUser.vue';
import ChangePassword from './ChangePassword/ChangePassword.vue';
import DeleteUser from './DeleteUser/DeleteUser.vue';

import { defaultFormData, columns, accesses } from './configs';

import { Option } from '@/types';
import { AppRole } from '@/store/modules/roles/types';
import { PharmacyAccesses } from '@/store/modules/account/types';
import { PharmacyAccessesAdd } from '@/store/modules/users/types';

@Options({
  name: 'InfoUser',
  components: {
    Loading,
    AboutUser,
    ChangePassword,
    DeleteUser
  },
  data() {
    return {
      formData: defaultFormData,
      userRoleDropdown: {
        selectedOption: {
          value: '',
          label: ''
        },
        options: []
      },
      pharmacyId: localStorage.getItem('id'),
      accesses: accesses(this.$t),
      columns: columns(this.$t),
      loading: false,
      showModal: false,
      showModalDeleteUser: false
    };
  },
  created() {
    const { id } = this.$route.params;

    if (id) {
      this.fetchUserAndRoles();
    }
  },
  unmounted() {
    this.clearUser();
  },
  computed: {
    ...mapState('account', { userFromAccountModule: 'user' }),
    ...mapState('users', ['user']),
    ...mapState('roles', ['appRoles']),
    isUserMain() {
      return this.userFromAccountModule?.role.name === 'main_user';
    }
  },
  methods: {
    ...mapActions('roles', ['getAppRoles']),
    ...mapActions('users', [
      'getUser',
      'clearUser',
      'updateUser',
      'deleteUser'
    ]),
    async fetchUserAndRoles() {
      this.loading = true;

      await this.getAppRoles();
      await this.fetchUser();
      await this.initUserRole();
      this.loading = false;
    },
    async fetchUser() {
      const { id } = this.$route.params;

      await this.getUser(id);
      this.formData = {
        ...this.user
      };
    },
    initUserRole() {
      this.userRoleDropdown.options = this.appRoles.map((i: AppRole) => ({
        value: i.value,
        label: i.label
      }));

      if (this.user.pharmacyAccesses) {
        const roleNameForPharmacyId: PharmacyAccesses | undefined =
          this.user.pharmacyAccesses.find(
            (itemData: PharmacyAccesses) =>
              itemData.pharmacyId === this.pharmacyId
          );

        if (roleNameForPharmacyId) {
          this.userRoleDropdown.selectedOption =
            this.userRoleDropdown.options.find(
              (i: Option) => i.value === roleNameForPharmacyId.roleName
            );
        }
      } else {
        this.userRoleDropdown.selectedOption =
          this.userRoleDropdown.options.find(
            (i: Option) => i.value === this.user.role.name
          );
      }
    },
    selectUserRole(option: Option) {
      this.userRoleDropdown.selectedOption = option;

      if (this.user.pharmacyId === this.pharmacyId) {
        this.formData.role.name = option.value;
      }

      if (this.formData.pharmacyAccesses) {
        this.formData.pharmacyAccesses = this.formData.pharmacyAccesses.map(
          (pharmacyAccess: PharmacyAccessesAdd) => {
            if (pharmacyAccess.pharmacyId === this.pharmacyId) {
              return {
                ...pharmacyAccess,
                roleName: option.value
              };
            }

            return pharmacyAccess;
          }
        );
      }
    },
    changeCheckboxes(name: string) {
      this.formData.role[name] = !this.formData.role[name];

      if (this.user.pharmacyId === this.pharmacyId) {
        return this.formData.role[name];
      }

      if (this.formData.pharmacyAccesses) {
        this.formData.pharmacyAccesses = this.formData.pharmacyAccesses.map(
          (pharmacyAccess: PharmacyAccessesAdd) => {
            if (pharmacyAccess.pharmacyId === this.pharmacyId) {
              return {
                ...pharmacyAccess,
                [name]: this.formData.role[name]
              };
            }

            return pharmacyAccess;
          }
        );
      }
    },
    // eslint-disable-next-line
    async submitHandler(data: any) {
      const { id } = this.$route.params;
      this.loading = true;

      if (data.pharmacyAccesses) {
        data.pharmacyAccesses = data.pharmacyAccesses.map(
          (pharmacyAccess: PharmacyAccessesAdd) => {
            if (pharmacyAccess.pharmacyId === this.pharmacyId) {
              return {
                ...pharmacyAccess,
                boxAccess: data.role.boxAccess,
                webToolAccess: data.role.webToolAccess
              };
            }

            return pharmacyAccess;
          }
        );
      }

      const payload = {
        ...data,
        role: this.formData.role.name,
        boxAccess: data.role.boxAccess,
        webToolAccess: data.role.webToolAccess
      };

      delete payload.appLanguage;
      delete payload.emailSearch;
      delete payload.firstNameSearch;
      delete payload.id;
      delete payload.lastNameSearch;

      if (data.pharmacyAccesses) {
        if (this.user.pharmacyId !== this.pharmacyId) {
          delete payload.boxAccess;
          delete payload.webToolAccess;
        }
      }

      await this.updateUser({
        data: payload,
        userId: id
      });

      router.push({ name: 'Users' });
      this.loading = false;
    },
    async deleteUserRequest() {
      this.loading = true;
      const { id } = this.$route.params;

      await this.deleteUser(id);
      this.navigateBack();

      this.loading = false;
    },
    cleanForm() {
      this.$formkit.reset('addUserForm');
    },
    submitForm() {
      this.$formkit.submit('addUserForm');
    },
    dateTime(value: string) {
      return moment(value).format('DD.MM.YYYY HH:mm:ss');
    },
    navigateBack() {
      router.push({ name: 'Users' });
    }
  }
})
export default class InfoUserComponent extends Vue {}
