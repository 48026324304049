
import { Options, Vue } from 'vue-class-component';

import { optionsPositions } from '../configs';
import { translations } from '../../configs';

import { OptionWithString } from '@/types';
import { i18n } from '@/main';

@Options({
  name: 'AddOption',
  props: ['options', 'numberActiveOptions'],
  data() {
    return {
      formDataOption: {
        title: '',
        enabled: true,
        position: null,
        translations: {
          en: '',
          fi: '',
          sv: ''
        }
      },
      selectedPositions: {
        selectedOption: {},
        options: optionsPositions
      },
      error: '',
      translations,
      openAddOption: false
    };
  },
  methods: {
    conditionShowAddOption() {
      if (this.openAddOption && this.numberActiveOptions !== 3) {
        return true;
      }
    },
    showAddOption() {
      if (this.numberActiveOptions !== 3) {
        this.openAddOption = !this.openAddOption;
      }
    },
    selectPositions(selectedOption: OptionWithString) {
      const selectedPosition = parseInt(selectedOption.value);

      const disabledOption = Object.values(this.options).find(
        // eslint-disable-next-line
        (option: any) =>
          option.position === selectedPosition - 1 && !option.enabled
      );

      if (!disabledOption) {
        this.error = i18n.global.t('positionIsTaken');
        this.selectedPositions.selectedOption = {
          value: selectedPosition,
          label: selectedPosition
        };

        return;
      }

      this.selectedPositions.selectedOption = selectedOption;
      this.error = '';
      this.formDataOption.position = selectedPosition - 1;
    },
    submitForm() {
      this.$emit('submit-form-option', this.formDataOption);
    }
  }
})
export default class AddOption extends Vue {}
