
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';

@Options({
  name: 'InfoAboutDelivery',
  emits: ['getDecodedSsn'],
  props: ['activeReservations', 'showDecodedSsn'],
  data() {
    return {
      dataSsn: '',
      isSSN: false
    };
  },
  computed: {
    isLastTime() {
      return this.activeReservations.resendSMSTimestamps[
        this.activeReservations.resendSMSTimestamps.length - 1
      ].sendTime;
    }
  },
  methods: {
    dateTime(value: string) {
      return moment(value).format('DD.MM.YYYY HH:mm');
    },
    getIds(deliveryId: string, additionalDeliveryIds: Array<string>) {
      if (!additionalDeliveryIds[0] && !additionalDeliveryIds[1]) {
        return deliveryId;
      } else if (additionalDeliveryIds[1] !== '') {
        return deliveryId + ', ' + additionalDeliveryIds.join(', ');
      } else if (additionalDeliveryIds[1] === '') {
        return deliveryId + ', ' + additionalDeliveryIds.join('');
      }
    },
    getIsDrud(value: boolean) {
      if (value) {
        return `${this.$t('yes')}`;
      } else {
        return `${this.$t('no')}`;
      }
    },
    showSsn() {
      this.$emit('getDecodedSsn', this.activeReservations.deliveryId);
      this.isSSN = true;
    },
    getDataSSN() {
      if (this.showDecodedSsn) {
        this.dataSsn = this.showDecodedSsn.ssn;

        if (this.dataSsn) {
          this.isSSN = true;
          return this.dataSsn;
        } else {
          return this.$t('noSsn');
        }
      } else {
        return '************';
      }
    }
  }
})
export default class InfoAboutDelivery extends Vue {}
