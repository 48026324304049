
import { Options, Vue } from 'vue-class-component';
import { mapActions } from 'vuex';

import InfoAboutDelivery from './InfoAboutDelivery/InfoAboutDelivery.vue';
import InfoAboutActionLog from './InfoAboutActionLog/InfoAboutActionLog.vue';
import Loading from '@/views/Loading.vue';

@Options({
  name: 'AddDescription',
  props: [
    'showModal',
    'activeDelivery',
    'boxId',
    'updateList',
    'showDecodedSsn'
  ],
  emits: ['updateList', 'getDecodedSsn'],
  components: {
    InfoAboutDelivery,
    InfoAboutActionLog,
    Loading
  },
  data() {
    return {
      loading: false,
      openInfoAboutActionLog: false,
      formData: {
        reservedTime: 0,
        descriptionText: ''
      }
    };
  },
  methods: {
    ...mapActions('pharmacy', ['updateDescription']),
    async submitHandler() {
      this.loading = true;

      const deliveryId = this.activeDelivery?.deliveryId;

      const { id } = this.$route.params;

      if (this.boxId) {
        const payload = {
          descriptionText: this.activeDelivery.descriptionText,
          reservedTime: this.activeDelivery.reservedTime,
          pickupboxId: this.boxId,
          docId: this.activeDelivery.docId
        };
        await this.updateDescription({
          data: payload,
          deliveryId
        });
      } else {
        const payload = {
          descriptionText: this.activeDelivery.descriptionText,
          reservedTime: this.activeDelivery.reservedTime,
          pharmacyId: id,
          docId: this.activeDelivery.docId
        };
        await this.updateDescription({
          data: payload,
          deliveryId
        });
      }

      this.loading = false;
      this.$emit('updateList');

      this.$emit('closeModal');
    },
    saveDescription() {
      this.$formkit.submit('description-form');
    },
    showInfoAboutActionLog() {
      this.openInfoAboutActionLog = !this.openInfoAboutActionLog;
    }
  }
})
export default class AddDescription extends Vue {}
