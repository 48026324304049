
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { format } from 'date-fns';

import Loading from '@/views/Loading.vue';
import { downloadCSV } from '@/utils/downloadCSV';
import { InputFileEvent, Option } from '@/types';
import { DataDeliveriesStatisticsMonthly } from '@/store/modules/pharmacy/types';

import InputAverageTotal from './InputAverageTotal/InputAverageTotal.vue';
import { columns } from './configs';

@Options({
  name: 'DeliveryStatistics',
  components: {
    Loading,
    InputAverageTotal
  },
  data() {
    return {
      loading: false,
      params: null,
      dataDeliveriesStatistics: [],
      dataDeliveriesStatisticsMonthlyValue: [],
      activePickupBox: null,
      selectedStatistics: {
        selectedOption: [],
        options: {}
      },
      columns: columns(this.$t),
      averageTotal: '',
      pharmacies: {},
      pickupBoxes: {},
      mapOptionDataDeliveriesStatistics: []
    };
  },
  created() {
    this.loadPickupBoxes();
  },
  computed: {
    ...mapState('pharmacy', ['deliveriesStatistics']),
    ...mapState('entityNames', ['entityNames']),
    today() {
      const dateString = format(new Date(), 'dd/MM/yyyy');

      return dateString;
    },
    firstDay() {
      const day = new Date().setMonth(new Date().getMonth());
      const dayString = new Date(day);
      const firstDate = format(new Date(dayString), 'dd/MM/yyyy').split('/');

      firstDate.splice(0, 1, '01');

      return firstDate.join('/');
    },
    isDateCorrect() {
      if (this.isDatePresent) {
        return this.params.endTime >= this.params.startTime;
      }

      return true;
    },
    isDatePresent() {
      return this.params && this.params.startTime && this.params.endTime;
    }
  },
  methods: {
    ...mapActions('pharmacy', [
      'getPickupBoxesByPharmacy',
      'getDeliveriesStatistics'
    ]),
    ...mapActions('entityNames', ['getEntityNames']),

    async show() {
      this.loading = true;

      const { id } = this.$route.params;

      const defaultParams = {
        pharmacyId: id,
        ...this.params
      };

      if (
        id &&
        defaultParams.startTime &&
        defaultParams.endTime &&
        this.isDateCorrect
      ) {
        await this.getDeliveriesStatistics(defaultParams);
        await this.getEntityNames();
        this.dataDeliveriesStatistics = this.deliveriesStatistics.tableData;
        this.averageTotal = this.deliveriesStatistics.averageTotal;

        this.pharmacies = this.entityNames.pharmacies;
        this.pickupBoxes = this.entityNames.pickupBoxes;
      }

      this.initPickupBoxAndPharmacyOptions();
      this.getDataDeliveriesStatisticsMonthly();

      this.loading = false;
    },
    getEntityName(name: string, entities: Record<string, unknown>) {
      const entity = Object.entries(entities).find((item) =>
        item[0].includes(name)
      );

      return entity ? entity[1] : null;
    },
    initPickupBoxAndPharmacyOptions() {
      const { id } = this.$route.params;

      this.pickupBoxAndPharmacyOptions = Object.entries(
        this.dataDeliveriesStatistics
      )
        .map(([key]: [string, unknown]) => ({
          value: key,
          label: this.getEntityName(key, this.pickupBoxes),
          img: require('@/assets/icons/PickupBox.svg')
        }))
        .filter((item) => item.label);

      const pharmacyReservations = {
        value: id,
        label: this.$t('pharmacyReservations')
      };

      this.selectedStatistics.options = {
        ...this.pickupBoxAndPharmacyOptions,
        pharmacyReservations
      };

      this.selectedStatistics.selectedOption = this.pickupBoxAndPharmacyOptions;
    },
    getDataDeliveriesStatisticsMonthly(mapOption: string[] | undefined) {
      const { id } = this.$route.params;
      this.dataDeliveriesStatistics = this.deliveriesStatistics.tableData;

      if (mapOption) {
        this.mapOptionDataDeliveriesStatistics = Object.fromEntries(
          mapOption
            .filter((key: string) => key in this.dataDeliveriesStatistics)
            .map((key: string | number) => [
              key,
              this.dataDeliveriesStatistics[key]
            ])
        );
      }

      this.dataDeliveriesStatistics = Object.entries(
        this.mapOptionDataDeliveriesStatistics
      )
        .filter(([key]) => key !== id)
        // eslint-disable-next-line
        .map(([_, dataValue]) => dataValue);

      if (mapOption?.includes(id)) {
        this.dataDeliveriesStatistics = Object.entries(
          this.mapOptionDataDeliveriesStatistics
          // eslint-disable-next-line
        ).map(([_, dataValue]) => dataValue);
      }
      const dataDeliveriesStatisticsMonthly: DataDeliveriesStatisticsMonthly =
        this.dataDeliveriesStatistics.reduce(
          (
            // eslint-disable-next-line
            acc: DataDeliveriesStatisticsMonthly | any,
            dataValue: DataDeliveriesStatisticsMonthly
          ) => {
            Object.entries(dataValue).forEach(
              ([month, dataMonth]: [string, Record<string, unknown>]) => {
                const [year, numberMonth] = month.split('-');
                acc[numberMonth] ||= {
                  month: Number(numberMonth),
                  year: Number(year),
                  totalDeliveries: 0,
                  pickupAmount: 0,
                  destroyedAmount: 0,
                  cancelledAmount: 0,
                  averagePickupTime: 0,
                  totalUsage: 0,
                  expiredAmount: 0,
                  totalEntries: 0
                };
                [
                  'totalDeliveries',
                  'pickupAmount',
                  'destroyedAmount',
                  'cancelledAmount',
                  'averagePickupTime',
                  'totalUsage',
                  'expiredAmount'
                ].forEach((key) => {
                  acc[numberMonth][key] += dataMonth[key] || 0;
                });
                acc[numberMonth].totalEntries += 1;
              }
            );
            return acc;
          },
          {}
        );

      this.dataDeliveriesStatisticsMonthlyValue = Object.values(
        dataDeliveriesStatisticsMonthly
        // eslint-disable-next-line
      ).map((month: DataDeliveriesStatisticsMonthly | any) => ({
        ...month,
        averagePickupTime: (
          month.averagePickupTime / month.totalEntries
        ).toFixed(2),
        totalUsage: (month.totalUsage / month.totalEntries).toFixed(2)
      }));
    },

    selectStatistics(option: Option) {
      this.selectedStatistics.selectedOption = option;
    },
    initRequest() {
      let startTime = this.firstDay.split('/');
      let endTime = this.today.split('/');

      startTime = new Date(
        +startTime[2],
        startTime[1] - 1,
        +startTime[0]
      ).valueOf();
      endTime = new Date(+endTime[2], endTime[1] - 1, +endTime[0]).valueOf();

      this.params = {
        startTime: startTime + new Date(startTime).getTimezoneOffset() * -60000,
        endTime: endTime + new Date(endTime).getTimezoneOffset() * -60000
      };
    },
    changeDateStartTime(e: InputFileEvent) {
      const { name, value } = e.target;
      const dateNumbers = value.split('/');
      const date = `${dateNumbers[1]}/${dateNumbers[0]}/${dateNumbers[2]}`;
      this.params = {
        ...this.params,
        [name]:
          new Date(date).valueOf() + new Date(date).getTimezoneOffset() * -60000
      };
    },
    changeDateEndTime(e: InputFileEvent) {
      let { name, value } = e.target;
      const dateNumbers = value.split('/');
      const date = `${dateNumbers[1]}/${dateNumbers[0]}/${dateNumbers[2]}`;
      this.params = {
        ...this.params,
        [name]:
          new Date(date).setMonth(new Date(date).getMonth() + 1).valueOf() -
          1000 +
          new Date(date).getTimezoneOffset() * -60000
      };
    },
    async loadPickupBoxes() {
      this.loading = true;
      await this.show();
      this.initRequest();
      this.loading = false;
    },
    getCSV(): void {
      const data = this.dataDeliveriesStatisticsMonthlyValue.map(
        (el: DataDeliveriesStatisticsMonthly) => ({
          month: el.month,
          year: el.year,
          totalDeliveries: el.totalDeliveries,
          pickupAmount: el.pickupAmount,
          expiredAmount: el.expiredAmount,
          destroyedAmount: el.destroyedAmount,
          cancelledAmount: el.cancelledAmount,
          averagePickupTime: el.averagePickupTime,
          totalUsage: el.totalUsage
        })
      );
      const columns = [...this.columns];

      downloadCSV(data, columns, `${this.$t('deliveryStatistics')}.csv`);
    }
  },
  watch: {
    params() {
      if (this.isDatePresent && this.isDateCorrect) {
        this.show(this.params);
      }
    },
    'selectedStatistics.selectedOption': {
      handler(option: (Option | string)[]) {
        this.pickupBoxAndPharmacyOptions = option;
        const mapOption = this.pickupBoxAndPharmacyOptions.map(
          (i: Option) => i.value
        );
        this.getDataDeliveriesStatisticsMonthly(mapOption);
      }
    }
  }
})
export default class DeliveryStatistics extends Vue {}
