
import { Vue, Options } from 'vue-class-component';
import { mapActions } from 'vuex';

import Loading from '@/views/Loading.vue';

@Options({
  components: {
    Loading
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('pharmacy', ['clearPharmacy']),
    async logoutUser() {
      this.loading = true;

      await this.logout();
      await this.clearPharmacy();
      localStorage.removeItem('id');

      this.loading = false;
    }
  }
})
export default class Logout extends Vue {}
