
import { Options, Vue } from 'vue-class-component';

import Loading from '@/views/Loading.vue';

@Options({
  name: 'ServiceRequest',
  props: ['showModalServiceRequest'],
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      formData: {
        description: '',
        serviceRequest: false
      }
    };
  },
  methods: {
    submitHandler() {
      this.$formkit.submit('description-form');
    }
  }
})
export default class ServiceRequestComment extends Vue {}
