
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import router from '@/router';

import { infoFromUser } from '@/constants';

import { Option } from '@/types';
import { PharmacyAccessesAdd } from '@/store/modules/users/types';
import { AppRole } from '@/store/modules/roles/types';

@Options({
  name: 'AddUser',
  components: {
    Loading
  },
  data() {
    return {
      formData: {
        email: '',
        roleName: '',
        pharmacyId: '',
        webToolAccess: true,
        boxAccess: true
      },
      userRoleDropdown: {
        selectedOption: {
          value: '',
          label: ''
        },
        options: []
      },
      fieldTypes: {
        email: 'text'
      },
      validationRules: {},
      infoFromUser,
      loading: false
    };
  },
  created() {
    this.show();
  },
  computed: {
    ...mapState('roles', ['appRoles']),
    ...mapState('account', ['user'])
  },
  methods: {
    ...mapActions('users', ['addPharmacyAccesses']),
    ...mapActions('roles', ['getAppRoles']),
    async show() {
      await this.getAppRoles();

      this.initUserRole();
    },
    async submitHandler(data: PharmacyAccessesAdd) {
      this.loading = true;

      const payload = {
        ...data,
        roleName: this.userRoleDropdown.selectedOption.value,
        pharmacyId: this.user.pharmacyId
      };
      await this.addPharmacyAccesses(payload);
      this.$formkit.reset('addUser-form');

      router.push({ name: 'Users' });

      this.loading = false;
    },
    submitForm() {
      this.$formkit.submit('addUser-form');
    },
    cleanForm() {
      this.$formkit.reset('addUser-form');
    },
    backTo() {
      router.push({ name: 'Users' });
    },
    initUserRole() {
      this.userRoleDropdown.options = this.appRoles.map((i: AppRole) => ({
        value: i.value,
        label: i.label
      }));

      this.userRoleDropdown.selectedOption = this.userRoleDropdown.options.find(
        (i: Option) => i.value === this.user.role.name
      );
    },
    selectUser(option: Option) {
      this.userRoleDropdown.selectedOption = option;
      this.formData.role = option.value;
    },
    selectWebToolAccess() {
      this.formData.webToolAccess = !this.formData.webToolAccess;
    },
    selectBoxAccess() {
      this.formData.boxAccess = !this.formData.boxAccess;
    }
  }
})
export default class AddUserComponent extends Vue {}
