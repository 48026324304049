
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';

@Options({
  name: 'InfoReservation',
  emits: ['getDecodedSsn'],
  props: ['showModal', 'activeDelivery', 'showDecodedSsn'],
  data() {
    return {
      dataSsn: ''
    };
  },
  computed: {
    isLastTime() {
      return this.activeDelivery.resendSMSTimestamps[
        this.activeDelivery.resendSMSTimestamps.length - 1
      ].sendTime;
    }
  },
  methods: {
    dateTime(value: string) {
      return moment(value).format('DD.MM.YYYY HH:mm');
    },
    getIds(deliveryId: string, additionalDeliveryIds: Array<string>) {
      if (!additionalDeliveryIds[0] && !additionalDeliveryIds[1]) {
        return deliveryId;
      } else if (additionalDeliveryIds[1] !== '') {
        return deliveryId + ', ' + additionalDeliveryIds.join(', ');
      } else if (additionalDeliveryIds[1] === '') {
        return deliveryId + ', ' + additionalDeliveryIds.join('');
      }
    },
    getDeliveryStatus() {
      if (
        this.activeDelivery.deliveryStatus === 'expired' &&
        !this.activeDelivery.deliveryTime
      ) {
        return 'reservation';
      } else if (
        this.activeDelivery.deliveryStatus === 'expired' &&
        this.activeDelivery.deliveryTime
      ) {
        return 'delivery';
      } else {
        return '';
      }
    },
    getIsDrud(value: boolean) {
      if (value) {
        return `${this.$t('yes')}`;
      } else {
        return `${this.$t('no')}`;
      }
    },
    showSsn() {
      this.$emit('getDecodedSsn', this.activeDelivery.deliveryId);
    }
  },
  watch: {
    showDecodedSsn() {
      if (this.showDecodedSsn === null || !this.showDecodedSsn) {
        this.dataSsn = this.$t('noSsn');
      } else {
        this.dataSsn = this.showDecodedSsn;
      }
    }
  }
})
export default class InfoReservation extends Vue {}
