import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { PharmacyState } from '@/store/modules/pharmacy/types';
import { getters } from '@/store/modules/pharmacy/getters';
import { mutations } from '@/store/modules/pharmacy/mutations';
import { actions } from '@/store/modules/pharmacy/actions';

export const state: PharmacyState = {
  status: {
    loaded: false,
    sidebar: true,
    error: null,
    message: null
  },
  pharmacies: [],
  pharmacy: null,
  pharmacyForm: null,
  pickupBoxes: [],
  pickupBox: null,
  capacity: [],
  deliveryHistory: {},
  alarms: [],
  temperatureLog: null,
  deliveriesStatistics: [],
  description: null,
  alarmComment: null,
  temperatureStamps: [],
  decodedSsn: {
    ssn: ''
  },
  destroyReservation: {
    pharmacyId: '',
    deliveryId: '',
    pickupboxId: ''
  },
  capacityPickupBoxIdResult: {
    averagePickupTime: 0,
    capacity: {
      small: 0,
      medium: 0,
      large: 0,
      fridge: 0
    },
    pharmacyName: '',
    pharmacyId: '',
    id: '',
    name: ''
  },
  capacityPickupBoxType: [],
  feedbackTemplate: null
};

export const pharmacy: Module<PharmacyState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
