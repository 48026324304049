import { MutationTree } from 'vuex';
import { RolesState } from '@/store/modules/roles/types';

export enum RolesMutations {
  get_app_roles = 'get_app_roles'
}

export const mutations: MutationTree<RolesState> = {
  [RolesMutations.get_app_roles](state, { appRoles }) {
    state.appRoles = appRoles;
  }
};
