import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "users" }
const _hoisted_2 = { class: "users__header" }
const _hoisted_3 = { class: "users__header-buttons" }
const _hoisted_4 = {
  key: 0,
  class: "tooltiptext"
}
const _hoisted_5 = {
  key: 0,
  class: "tooltiptext"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Title = _resolveComponent("Title")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Title, {
      title: _ctx.$t('users')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass({
            container: true,
            tootip: !_ctx.user?.role.permissions.user.create
          })
        }, [
          _createVNode(_component_Button, {
            class: "add-button",
            variant: 
              _ctx.user?.role.permissions.user.create ? 'filled' : 'disabled'
            ,
            title: _ctx.$t('createUser'),
            onClick: _ctx.createUser
          }, null, 8, ["variant", "title", "onClick"]),
          (!_ctx.user?.role.permissions.user.create)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('enoughPermissions')), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass({
            container: true,
            tootip: !_ctx.user?.role.permissions.login_logs.read
          })
        }, [
          _createVNode(_component_Button, {
            class: "loginInfo-button",
            variant: 
              _ctx.user?.role.permissions.login_logs.read ? 'bordered' : 'disabled'
            ,
            title: _ctx.$t('loginInformation'),
            onClick: _ctx.loginInformation
          }, null, 8, ["variant", "title", "onClick"]),
          (!_ctx.user?.role.permissions.login_logs.read)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('enoughPermissions')), 1))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ]),
    _createVNode(_component_Table, {
      data: _ctx.users,
      columns: _ctx.columns,
      onGoToItem: _ctx.infoUser
    }, null, 8, ["data", "columns", "onGoToItem"])
  ]))
}