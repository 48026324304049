
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import router from '@/router';

import { tabs } from './configs';

@Options({
  name: 'CustomersFeedback',
  components: {
    Loading
  },
  data() {
    return {
      pharmacyId: '',
      loading: false,
      tabs
    };
  },
  created() {
    if (this.user) {
      this.pharmacyId = this.user?.pharmacyId;

      this.show();
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('pharmacy', ['pharmacy'])
  },
  methods: {
    ...mapActions('pharmacy', ['getPharmacy']),
    async show() {
      this.loading = true;

      await this.getPharmacy(this.pharmacyId);

      this.setActiveTab('Statistics');

      this.loading = false;
    },
    async setActiveTab(tabValue: string) {
      router.push({ name: tabValue });
    },
    goToSettings() {
      router.push({ name: 'SettingsCustomersFeedback' });
    }
  }
})
export default class CustomersFeedback extends Vue {}
