import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/Hyphen.svg'
import _imports_1 from '@/assets/icons/Search.svg'


const _hoisted_1 = { class: "deliveryHistory" }
const _hoisted_2 = { class: "deliveryHistory__header" }
const _hoisted_3 = { class: "deliveryHistory__header-dropdowns" }
const _hoisted_4 = { class: "deliveryHistory__header-dateAndSearch" }
const _hoisted_5 = { class: "date" }
const _hoisted_6 = { class: "date-inputs" }
const _hoisted_7 = { class: "input" }
const _hoisted_8 = { class: "input" }
const _hoisted_9 = {
  key: 0,
  class: "date-error"
}
const _hoisted_10 = { class: "input-block" }
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = {
  key: 1,
  class: "deliveryHistory__noReserve"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Table = _resolveComponent("Table")!
  const _component_AddDescription = _resolveComponent("AddDescription")!
  const _component_OpenQRCode = _resolveComponent("OpenQRCode")!
  const _component_PickedUpDeleteReservation = _resolveComponent("PickedUpDeleteReservation")!
  const _component_InfoReservation = _resolveComponent("InfoReservation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Dropdown, {
            class: "deliveryHistory__header-dropdowns-dropdown",
            selectedOption: _ctx.selectedReservations.selectedOption,
            options: _ctx.selectedReservations.options,
            multiple: true,
            onSelectOption: _ctx.selectReservations,
            "data-testid": "selectReservations"
          }, null, 8, ["selectedOption", "options", "onSelectOption"]),
          _createVNode(_component_Dropdown, {
            class: "deliveryHistory__header-dropdowns-dropdown",
            selectedOption: _ctx.getOrderStatus.selectedOption,
            options: _ctx.getOrderStatus.options,
            onSelectOption: _ctx.changeOrderStatus,
            "data-testid": "changeOrderStatus"
          }, null, 8, ["selectedOption", "options", "onSelectOption"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Calendar, {
                  type: "date",
                  format: "DD/MM/YYYY",
                  value: _ctx.firstDay,
                  name: "startTime",
                  message: _ctx.$t('errorDate'),
                  onChange: _ctx.changeDateStartTime,
                  "data-testid": "startTime"
                }, null, 8, ["value", "message", "onChange"])
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("img", {
                class: "date-img",
                src: _imports_0,
                alt: "Hyphen"
              }, null, -1)),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_Calendar, {
                  type: "date",
                  format: "DD/MM/YYYY",
                  value: _ctx.today,
                  name: "endTime",
                  onChange: _ctx.changeDateEndTime,
                  "data-testid": "endTime"
                }, null, 8, ["value", "onChange"])
              ])
            ])
          ]),
          (!_ctx.isDateCorrect)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t('errorDate')), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _cache[7] || (_cache[7] = _createElementVNode("img", {
              class: "dropdown-img",
              src: _imports_1,
              alt: "Search"
            }, null, -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              class: "input-search",
              placeholder: _ctx.$t('search'),
              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeInput && _ctx.changeInput(...args)))
            }, null, 40, _hoisted_11), [
              [_vModelText, _ctx.search]
            ]),
            _createVNode(_component_Dropdown, {
              className: "dropdown-search",
              selectedOption: _ctx.getSearchBy.selectedOption,
              options: _ctx.getSearchBy.options,
              onSelectOption: _ctx.changeSearchBy
            }, null, 8, ["selectedOption", "options", "onSelectOption"])
          ])
        ])
      ]),
      (_ctx.isDateCorrect && _ctx.deliveryHistoryList.length)
        ? (_openBlock(), _createBlock(_component_Table, {
            key: 0,
            data: _ctx.deliveryHistoryList,
            columns: _ctx.columns,
            idActiveDelivery: _ctx.idActiveDelivery,
            selectedField: "deliveryId",
            onOpenQRCode: _ctx.openQRCode,
            onAddDescription: _ctx.addDescription,
            onGoToItem: _ctx.infoReservation
          }, null, 8, ["data", "columns", "idActiveDelivery", "onOpenQRCode", "onAddDescription", "onGoToItem"]))
        : _createCommentVNode("", true),
      (!_ctx.isDateCorrect || !_ctx.deliveryHistoryList.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('noData')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_AddDescription, {
      showModal: _ctx.showModal,
      onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModal = false)),
      boxId: _ctx.boxId,
      onUpdateList: _ctx.updateList,
      activeDelivery: _ctx.activeDelivery,
      showDecodedSsn: _ctx.showDecodedSsn,
      onGetDecodedSsn: _ctx.getDecodedSsn
    }, null, 8, ["showModal", "boxId", "onUpdateList", "activeDelivery", "showDecodedSsn", "onGetDecodedSsn"]),
    _createVNode(_component_OpenQRCode, {
      showQRCode: _ctx.showQRCode,
      idActiveDelivery: _ctx.idActiveDelivery,
      onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showQRCode = false))
    }, null, 8, ["showQRCode", "idActiveDelivery"]),
    _createVNode(_component_PickedUpDeleteReservation, {
      showModalDelete: _ctx.showModalDelete,
      idDelivery: _ctx.idDelivery,
      onPickedUpdeletedOrRemovedRequest: _ctx.pickedUpdeletedOrRemovedRequest,
      onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModalDelete = false))
    }, null, 8, ["showModalDelete", "idDelivery", "onPickedUpdeletedOrRemovedRequest"]),
    _createVNode(_component_InfoReservation, {
      showModal: _ctx.showModalReservation,
      onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showModalReservation = false)),
      activeDelivery: _ctx.activeDelivery,
      showDecodedSsn: _ctx.showDecodedSsn,
      onGetDecodedSsn: _ctx.getDecodedSsn
    }, null, 8, ["showModal", "activeDelivery", "showDecodedSsn", "onGetDecodedSsn"])
  ], 64))
}