
import { Options, Vue } from 'vue-class-component';

import { translations } from '../../configs';
import { mapOptionsToSelectedPositions, optionsPositions } from '../configs';

import { OptionsData } from '@/store/modules/pharmacy/types';

@Options({
  name: 'OptionsInputs',
  props: ['options', 'user'],
  emits: ['selectOptions'],
  data() {
    return {
      language: '',
      translations,
      selectedPositions: {
        selectedOption: mapOptionsToSelectedPositions(
          this.options,
          optionsPositions
        ),
        options: optionsPositions
      }
    };
  },
  created() {
    if (this.user) {
      this.language = this.user?.appLanguage;
    }
  },
  computed: {
    userAppLanguage() {
      return this.user?.appLanguage;
    }
  },
  methods: {
    disabledInput(option: OptionsData) {
      if (!option.enabled) {
        return true;
      } else {
        return false;
      }
    },
    selectPositions(key: string, option: OptionsData) {
      this.selectedPositions.selectedOption[key] = option;
      this.$emit('selectOptions', { key, option });
    },
    positionConflict(key: string) {
      const selectedPosition = this.selectedPositions.selectedOption[key];
      const enabledOptions = Object.keys(
        this.selectedPositions.selectedOption
      ).filter((otherKey) => this.options[otherKey].enabled);

      return enabledOptions.some((otherKey) => {
        if (otherKey !== key) {
          const option = this.selectedPositions.selectedOption[otherKey];

          return option.value === selectedPosition.value;
        }

        return false;
      });
    }
  },
  watch: {
    options: {
      immediate: true,
      handler(newOptions) {
        this.selectedPositions.selectedOption = mapOptionsToSelectedPositions(
          newOptions,
          optionsPositions
        );
      }
    }
  }
})
export default class OptionsInputs extends Vue {}
