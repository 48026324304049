import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/Search.svg'


const _hoisted_1 = { class: "reservations" }
const _hoisted_2 = { class: "reservations__header" }
const _hoisted_3 = { class: "reservations__header-pickupBox" }
const _hoisted_4 = { class: "reservations__header-search" }
const _hoisted_5 = { class: "input-block" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 2,
  class: "reservations__notReservations"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Table = _resolveComponent("Table")!
  const _component_ReserveLocker = _resolveComponent("ReserveLocker")!
  const _component_ResendSMS = _resolveComponent("ResendSMS")!
  const _component_DeleteReservation = _resolveComponent("DeleteReservation")!
  const _component_AddDescription = _resolveComponent("AddDescription")!
  const _component_PickedUpReservation = _resolveComponent("PickedUpReservation")!
  const _component_OpenQRCode = _resolveComponent("OpenQRCode")!
  const _component_InfoReservation = _resolveComponent("InfoReservation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Dropdown, {
            class: "reservations__header-pickupBox-dropdown",
            selectedOption: _ctx.selectedReservations.selectedOption,
            options: _ctx.selectedReservations.options,
            multiple: true,
            onSelectOption: _ctx.selectReservations,
            "data-testid": "dropdownReservationPickupBox"
          }, null, 8, ["selectedOption", "options", "onSelectOption"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          false
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "reservations__header-search-button",
                variant: "filled",
                title: _ctx.$t('newBookingPharmacyPickup'),
                onClick: _ctx.reserve
              }, null, 8, ["title", "onClick"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _cache[9] || (_cache[9] = _createElementVNode("img", {
              class: "dropdown-img",
              src: _imports_0,
              alt: "Search"
            }, null, -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              class: "input-search",
              placeholder: _ctx.$t('search'),
              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeInput && _ctx.changeInput(...args)))
            }, null, 40, _hoisted_6), [
              [_vModelText, _ctx.search]
            ]),
            _createVNode(_component_Dropdown, {
              className: "dropdown-search",
              selectedOption: _ctx.getSearchBy.selectedOption,
              options: _ctx.getSearchBy.options,
              onSelectOption: _ctx.changeSearchBy,
              "data-testid": "dropdownReservationSearch"
            }, null, 8, ["selectedOption", "options", "onSelectOption"])
          ])
        ])
      ]),
      (_ctx.data.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_Table, {
              data: _ctx.data,
              columns: _ctx.columns,
              idActiveDelivery: _ctx.idActiveDelivery,
              selectedField: "deliveryId",
              onAddDescription: _ctx.addDescription,
              onResendSMSRequest: _ctx.resendSMSRequest,
              onDeleteReservationTable: _ctx.deleteReservationTable,
              onPickedUpReservation: _ctx.pickedUpReservation,
              onOpenQRCode: _ctx.openQRCode,
              onGoToItem: _ctx.infoReservation
            }, null, 8, ["data", "columns", "idActiveDelivery", "onAddDescription", "onResendSMSRequest", "onDeleteReservationTable", "onPickedUpReservation", "onOpenQRCode", "onGoToItem"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.data.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('noReservations')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showModalReserveLocker)
      ? (_openBlock(), _createBlock(_component_ReserveLocker, {
          key: 0,
          showModalReserveLocker: _ctx.showModalReserveLocker,
          loadPickupBoxes: _ctx.loadPickupBoxes,
          onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModalReserveLocker = false))
        }, null, 8, ["showModalReserveLocker", "loadPickupBoxes"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ResendSMS, {
      showModalResendSMS: _ctx.showModalResendSMS,
      deliveryId: _ctx.idDelivery,
      onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModalResendSMS = false)),
      onLoadPickupBoxes: _ctx.loadPickupBoxes
    }, null, 8, ["showModalResendSMS", "deliveryId", "onLoadPickupBoxes"]),
    _createVNode(_component_DeleteReservation, {
      showModalDelete: _ctx.showModalDelete,
      idPickupBox: _ctx.idPickupBox,
      idDelivery: _ctx.idDelivery,
      onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModalDelete = false)),
      onDeleteReservationRequest: _ctx.deleteReservationRequest,
      onDestroyReservationRequest: _ctx.destroyReservationRequest
    }, null, 8, ["showModalDelete", "idPickupBox", "idDelivery", "onDeleteReservationRequest", "onDestroyReservationRequest"]),
    _createVNode(_component_AddDescription, {
      showModalAddDescription: _ctx.showModalAddDescription,
      idPickupBox: _ctx.idPickupBox,
      activeReservations: _ctx.activeReservations,
      showDecodedSsn: _ctx.showDecodedSsn,
      onGetDecodedSsn: _ctx.getDecodedSsn,
      onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showModalAddDescription = false))
    }, null, 8, ["showModalAddDescription", "idPickupBox", "activeReservations", "showDecodedSsn", "onGetDecodedSsn"]),
    _createVNode(_component_PickedUpReservation, {
      showModalPickedUpReservation: _ctx.showModalPickedUpReservation,
      idDelivery: _ctx.idDelivery,
      onCloseModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showModalPickedUpReservation = false)),
      onPickedUpReservationRequest: _ctx.pickedUpReservationRequest
    }, null, 8, ["showModalPickedUpReservation", "idDelivery", "onPickedUpReservationRequest"]),
    _createVNode(_component_OpenQRCode, {
      showQRCode: _ctx.showQRCode,
      idActiveDelivery: _ctx.idActiveDelivery,
      onCloseModal: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showQRCode = false))
    }, null, 8, ["showQRCode", "idActiveDelivery"]),
    _createVNode(_component_InfoReservation, {
      showModalInfoReservation: _ctx.showModalInfoReservation,
      onCloseModal: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showModalInfoReservation = false)),
      activeReservations: _ctx.activeReservations,
      showDecodedSsn: _ctx.showDecodedSsn,
      onGetDecodedSsn: _ctx.getDecodedSsn
    }, null, 8, ["showModalInfoReservation", "activeReservations", "showDecodedSsn", "onGetDecodedSsn"])
  ], 64))
}