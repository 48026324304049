
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import {
  DataQuickReservation,
  DataSortLocker,
  Locker,
  Module
} from '@/store/modules/pharmacy/types';

import { lockers } from './configs';
import { InputFileEvent } from '@/types';

@Options({
  name: 'QuickReservation',
  props: ['showModalQuickReservation', 'activePickupBox', 'lockersActiveBox'],
  emits: ['closeModal'],
  components: {
    Loading
  },
  data() {
    return {
      formData: {
        lockerId: [],
        deliveryId: '',
        additionalDeliveryIds: [],
        phoneNumber: '',
        is_drug: false,
        ssn: ''
      },
      payload: null,
      loading: false,
      chooseLocker: false,
      validationRules: {},
      pickupBoxOptions: [],
      lockers,
      selectedLockers: 0,
      lockerId: [],
      quantityLockers: { 0: 0, 1: 0, 2: 0, fridge: 0 },
      defaultQuantityLockers: { 0: 0, 1: 0, 2: 0, fridge: 0 },
      numberSelectedLockers: {}
    };
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('pharmacy', ['pharmacy', 'pickupBoxes'])
  },
  created() {
    this.loadPickupBoxes();
  },
  methods: {
    ...mapActions('pharmacy', [
      'createReservation',
      'getPharmacy',
      'getPickupBoxesByPharmacy'
    ]),
    async loadPickupBoxes() {
      this.loading = true;

      this.defaultQuantityLockers = this.lockersActiveBox;
      this.quantityLockers = this.defaultQuantityLockers;

      this.loading = false;
    },
    handleChange(e: InputFileEvent) {
      const { name, value } = e.target;

      if (name && +value === 1) {
        this.chooseLocker = true;

        if (+value < 0) {
          value;
        }

        if (name === 'small' && this.defaultQuantityLockers[0] !== 0) {
          this.quantityLockers = {
            ...this.quantityLockers,
            0: this.defaultQuantityLockers[0] - +value
          };
        } else if (name === 'medium' && this.defaultQuantityLockers[1] !== 0) {
          this.quantityLockers = {
            ...this.quantityLockers,
            1: this.defaultQuantityLockers[1] - +value
          };
        } else if (name === 'large' && this.defaultQuantityLockers[2] !== 0) {
          this.quantityLockers = {
            ...this.quantityLockers,
            2: this.defaultQuantityLockers[2] - +value
          };
        } else if (
          name === 'fridge' &&
          this.defaultQuantityLockers['fridge'] !== 0
        ) {
          this.quantityLockers = {
            ...this.quantityLockers,
            fridge: this.defaultQuantityLockers['fridge'] - +value
          };
        }
      }
    },
    getNumberSelectedLockers() {
      if (this.defaultQuantityLockers !== this.quantityLockers) {
        this.numberSelectedLockers = {
          0: this.defaultQuantityLockers[0] - this.quantityLockers[0],
          1: this.defaultQuantityLockers[1] - this.quantityLockers[1],
          2: this.defaultQuantityLockers[2] - this.quantityLockers[2],
          fridge:
            this.defaultQuantityLockers['fridge'] -
            this.quantityLockers['fridge']
        };
      }
    },
    getLockersId() {
      this.lockersId = [];
      this.modules = this.activePickupBox?.info.modules;

      const allModulesLockers = this.modules
        .map((module: Module, idx: number) =>
          module.lockers.map((locker: Locker) => ({ ...locker, moduleId: idx }))
        )
        .flat();

      const selectedLockersNumber = Object.entries(
        this.numberSelectedLockers
      ).filter(
        (lockerTypeAmount: [string, unknown]) => lockerTypeAmount[1] !== 0
      );

      selectedLockersNumber.forEach((selectedLocker: [string, unknown]) => {
        const lockersOfOneType = allModulesLockers
          .filter(
            (locker: Locker) =>
              ((locker.type === +selectedLocker[0] && !locker.fridge) ||
                (locker.fridge && selectedLocker[0] === 'fridge')) &&
              !locker.order
          )
          .sort(
            (a: DataSortLocker, b: DataSortLocker) => a.openCount - b.openCount
          );

        if (lockersOfOneType.length) {
          const lockerData = selectedLocker[1] as number;

          for (let i = 0; i < lockerData; i++) {
            if (lockersOfOneType[i]) {
              this.lockersId.push(
                `${lockersOfOneType[i].moduleId}-${lockersOfOneType[i].position}`
              );
            }
          }
        }
      });

      return this.lockersId;
    },
    async submitHandler(data: DataQuickReservation) {
      this.getNumberSelectedLockers();
      this.getLockersId();

      this.payload = {
        ...data,
        userId: this.user.id,
        lockerId: this.lockersId
      };

      delete this.payload.small;
      delete this.payload.medium;
      delete this.payload.large;
      delete this.payload.fridge;

      if (this.payload.deliveryId === '') {
        this.payload = { ...this.payload, deliveryId: undefined };
      }

      this.loading = true;

      await this.createReservation({
        data: this.payload,
        boxId: this.activePickupBox.id
      });

      const { id } = this.$route.params;
      await this.getPharmacy(id);
      await this.getPickupBoxesByPharmacy(id);

      this.$formkit.reset('quickReservation-form');
      this.$emit('closeModal');
      this.loading = false;
    },
    submitForm() {
      this.$formkit.submit('quickReservation-form');
    },
    closeModal() {
      this.$emit('closeModal');
      this.$formkit.reset('quickReservation-form');
    }
  }
})
export default class QuickReservation extends Vue {}
