export const thanksTitles = [
  {
    value: 'title_text',
    label: 'titleUpperCase'
  },
  {
    value: 'subtext',
    label: 'subtitleUpperCase'
  }
];
