
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { getDatabase, ref, onValue, off } from 'firebase/database';

import Loading from '@/views/Loading.vue';
import PaymentDetails from './PaymentDetails/PaymentDetails.vue';

import {
  authenticationNameInfo,
  authenticationSsnAndAddressInfo
} from '@/constants';

import { InputFileEvent } from '@/types';

@Options({
  name: 'CallerAuthentication',
  props: ['isNewOrder'],
  components: {
    Loading,
    PaymentDetails
  },
  data() {
    return {
      loading: false,
      phoneNumberData: {
        phoneNumber: '+358'
      },
      formData: {
        phoneNumber: '',
        firstName: '',
        lastName: '',
        ssn: '',
        homeAddress: '',
        userId: ''
      },
      consentGiven: true,
      authenticationNameInfo,
      authenticationSsnAndAddressInfo,
      authenticationFailed: false,
      openButton: false,
      openButtonUserFound: false,
      realTimeValue: {},
      reference: '',
      authenticated: ''
    };
  },
  computed: {
    ...mapState('callerAuthentication', [
      'callerAuthentication',
      'callerAuthenticate',
      'activeCallbackRequest'
    ])
  },
  mounted() {
    if (this.activeCallbackRequest) {
      this.phoneNumberData.phoneNumber =
        this.activeCallbackRequest.userPhoneNumber;
    }
  },
  unmounted() {
    this.clearActiveCallbackRequest();
  },
  methods: {
    ...mapActions('callerAuthentication', [
      'getCallerAuthenticationNumber',
      'getCallerAuthenticate',
      'clearActiveCallbackRequest'
    ]),
    handleEnter(event: InputFileEvent) {
      event.preventDefault();

      this.changeInput(event);
      this.authenticateSearch();
    },
    changeInput(e: InputFileEvent) {
      const { value } = e.target;
      this.phoneNumberData.phoneNumber = value;

      if (!value) {
        this.openButtonUserFound = false;
      }
    },
    getButtonAuthentication() {
      switch (this.realTimeValue.authenticated) {
        case 'pending':
          return 'authenticationPending';
        case 'failed':
          return 'authenticationFailed';
        case 'cancelled':
          return 'cancelled';
        default:
          return 'authenticated';
      }
    },
    getButtonUserFound() {
      if (this.formData.firstName === '????????') {
        return 'userFound';
      } else {
        return 'userNotFound';
      }
    },
    async authenticateSearch() {
      this.formData = {
        phoneNumber: '',
        firstName: '',
        lastName: '',
        ssn: '',
        homeAddress: '',
        userId: ''
      };

      this.loading = true;

      await this.getCallerAuthenticationNumber(
        this.phoneNumberData.phoneNumber
      );

      if (this.callerAuthentication) {
        this.formData = {
          phoneNumber: this.callerAuthentication.phoneNumber,
          firstName: '????????',
          lastName: '????????',
          ssn: '????????',
          homeAddress: '????????'
        };
      }

      this.openButtonUserFound = true;
      this.loading = false;
    },
    fetchAuthenticationStatus() {
      const database = getDatabase();

      const fullUrl = this.callerAuthenticate.reference;
      const url = new URL(fullUrl);
      const baseUrl = url.pathname;

      const dbRef = ref(database, baseUrl);
      this.listener = onValue(dbRef, (snapshot) => {
        this.realTimeValue = snapshot.val();

        this.authenticated = this.realTimeValue.authenticated;

        if (this.realTimeValue.authenticated) {
          this.openButton = true;
        }
      });
    },
    detachListener() {
      if (this.listener) {
        off(this.listener);
        this.listener = null;
      }
    },
    async sendSMS() {
      this.loading = true;
      this.openButtonUserFound = false;

      await this.getCallerAuthenticate({
        userId: this.callerAuthentication.userId
      });
      this.fetchAuthenticationStatus();

      if (!this.callerAuthenticate) {
        this.authenticationFailed = true;
      } else {
        this.authenticationFailed = false;
      }

      this.loading = false;
    },
    clearAllForms() {
      this.authenticated = '';
      this.openButton = false;

      this.$formkit.reset('profile-form');
      this.$formkit.reset('phoneNumber-form');
    }
  },
  watch: {
    authenticated(newVal) {
      if (newVal === 'done' || newVal === 'completed') {
        this.formData = {
          ...this.callerAuthentication,
          homeAddress: this.callerAuthentication.address
        };
      }
    },
    beforeUnmount() {
      this.detachListener();
    }
  }
})
export default class CallerAuthentication extends Vue {}
