
import { Vue, Options } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { token } from '@formkit/utils';

import Loading from '@/views/Loading.vue';
import ServiceRequest from './ServiceRequest/ServiceRequest.vue';
import EditWorkingHours from './EditWorkingHours/EditWorkingHours.vue';

import {
  infoOfPharmacy,
  infoPhoneNumber,
  infoEmail,
  infoOfDeliveries
} from './configs';
import {
  optionsLanguageEn,
  optionsLanguageFi,
  optionsLanguageSv,
  optionsLanguageNo,
  langsEn,
  langsFi,
  langsSv,
  langsNo
} from '@/services/languageService';

import { allLanguages } from './serviceLanguages';

import { Option } from '@/types';
import { DataServiceRequest } from '@/store/modules/pharmacy/types';

@Options({
  name: 'EditPharmacy',
  emits: ['saveServiceRequest', 'saveWorkingHours'],
  components: {
    Loading,
    ServiceRequest,
    EditWorkingHours
  },
  data() {
    return {
      formData: {
        name: '',
        email: '',
        additionalEmails: {},
        customerSMSLanguages: '',
        serviceLanguages: '',
        customersPhoneNumber: '',
        phoneNumber: '',
        additionalPhoneNumbers: {},
        serviceRequest: false,
        description: '',
        showToCustomers: false,
        sendNewCallbackRequestEmailNotification: false,
        services: {
          pickupbox: false,
          homeDelivery: false,
          pharmacyPickup: false
        }
      },
      emailItems: [],
      phoneItems: [],
      infoOfPharmacy,
      infoPhoneNumber,
      infoEmail,
      infoOfDeliveries,
      langsEn,
      langsFi,
      langsSv,
      langsNo,
      allLanguages,
      langsDropdown: {
        selectedOption: [],
        options: []
      },
      serviceLangsDropdown: {
        selectedOption: [],
        options: []
      },
      loading: false,
      updatedPharmacyLogo: false,
      showModalServiceRequest: false,
      showModalEditWorkingHours: false
    };
  },
  created() {
    this.show();
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy']),
    ...mapState('account', ['user'])
  },
  methods: {
    /* eslint-disable */
    ...mapActions('pharmacy', [
      'getPharmacy',
      'getPharmacyServiceRequest',
      'updatePharmacy',
      'updatePharmacyLogo'
    ]),
    async show() {
      const { id } = this.$route.params;
      this.loading = true;

      await this.getPharmacy(id);

      this.fillForm();

      this.loading = false;
    },
    getFullDay(abbreviatedDay: string) {
      const daysMap: Record<string, unknown> = {
        mon: this.$t('monday'),
        tue: this.$t('tuesday'),
        wed: this.$t('wednesday'),
        thu: this.$t('thursday'),
        fri: this.$t('friday'),
        sat: this.$t('saturday'),
        sun: this.$t('sunday')
      };

      return daysMap[abbreviatedDay];
    },
    getWorkingHours(hourFrom: string, hourTo: string) {
      if (hourFrom) {
        return `${hourFrom} - ${hourTo}`;
      } else {
        return this.$t('closed');
      }
    },
    onFileChange(event: Event) {
      const { id } = this.$route.params;

      const file = (event.target as HTMLInputElement).files?.[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          this.imageSrc = e.target?.result as string;

          const payload = {
            logo: this.imageSrc
          };
          try {
            this.loading = true;

            await this.updatePharmacyLogo({
              data: payload,
              pharmacyId: id
            });

            this.updatedPharmacyLogo = true;

            this.loading = false;
          } catch (error) {
            this.updatedPharmacyLogo = false;
            console.error(error);
          }
        };

        reader.readAsDataURL(file);
      }
    },
    initLanguage() {
      const optionsMappings: { [key: string]: Option[] } = {
        en: optionsLanguageEn,
        fi: optionsLanguageFi,
        sv: optionsLanguageSv,
        no: optionsLanguageNo
      };

      const userLanguage = this.user?.appLanguage;
      if (userLanguage && optionsMappings[userLanguage]) {
        this.langsDropdown.options = optionsMappings[userLanguage];
      }
    },
    initServiceLanguage() {
      const serviceLangs = Object.entries(this.allLanguages).map(
        ([code, name]) => ({
          label: name,
          value: code.toLowerCase()
        })
      );

      this.serviceLangsDropdown.options = serviceLangs;
    },
    fillAdditionalFields(items: string[], field: string, itemName: string) {
      const length = this.pharmacy[field]?.length;
      for (let i = 0; i < length; i++) {
        const item = `${itemName}${token()}`;
        items.push(item);
        this.formData[field][item] = this.pharmacy[field][i];
      }
    },
    changePickupbox() {
      this.pharmacy.services.pickupbox = !this.pharmacy.services.pickupbox;
    },
    changeHomeDelivery() {
      this.pharmacy.services.homeDelivery =
        !this.pharmacy.services.homeDelivery;
    },
    changePharmacyPickup() {
      this.pharmacy.services.pharmacyPickup =
        !this.pharmacy.services.pharmacyPickup;
    },
    changeShowToCustomers() {
      this.pharmacy.showToCustomers = !this.pharmacy.showToCustomers;
    },
    changeSendNewCallbackRequestEmailNotification() {
      this.pharmacy.sendNewCallbackRequestEmailNotification =
        !this.pharmacy.sendNewCallbackRequestEmailNotification;
    },
    fillForm() {
      Object.keys(this.pharmacy).forEach((field) => {
        switch (field) {
          case 'additionalEmails': {
            this.fillAdditionalFields(this.emailItems, field, 'email');
            break;
          }
          case 'additionalPhoneNumbers': {
            this.fillAdditionalFields(this.phoneItems, field, 'phone');
            break;
          }
          default: {
            this.formData[field] = this.pharmacy[field];
            break;
          }
        }
      });
    },
    disabledInput(name: string) {
      if (
        name === 'pinCode' ||
        name === 'deliveryKeepTime' ||
        name === 'deliveryReminderMessagePeriod' ||
        name === 'streetAddress' ||
        !this.user?.role.permissions.pharmacy.update
      ) {
        return true;
      } else {
        false;
      }
    },
    async submitHandler(data: any) {
      this.loading = true;
      const { id } = this.$route.params;

      const payload = {
        ...data,
        additionalEmails: Object.values(data.additionalEmails),
        additionalPhoneNumbers: Object.values(data.additionalPhoneNumbers),
        pickupBox: {
          ...data.pickupBox,
          customerSMSLanguages: this.formData.customerSMSLanguages
        }
      };
      delete payload.customerSMSLanguages;

      await this.updatePharmacy({
        data: payload,
        pharmacyId: id
      });

      await this.getPharmacy(id);
      this.loading = false;
    },
    openModal() {
      if (!this.formData.serviceRequest) {
        this.showModalServiceRequest = true;
      }
    },
    editWorkingHours() {
      this.showModalEditWorkingHours = true;
    },
    async saveServiceRequest(data: DataServiceRequest) {
      this.loading = true;
      const { id } = this.$route.params;

      const payload = {
        serviceRequest: !data.serviceRequest,
        description: data.description
      };

      await this.getPharmacyServiceRequest({
        data: payload,
        pharmacyId: id
      });

      await this.getPharmacy(id);
      this.formData = {
        ...this.pharmacy
      };

      this.showModalServiceRequest = false;
      this.loading = false;
    },
    selectLanguage(option: Option) {
      this.langsDropdown.selectedOption = option;
    },
    selectServiceLanguage(option: Option) {
      this.serviceLangsDropdown.selectedOption = option;
    },
    addEmailItem() {
      if (this.user?.role.permissions.pharmacy.update) {
        this.emailItems.push(`email${token()}`);
      }
    },
    removeEmailItem(name: string) {
      this.emailItems = this.emailItems.filter((item: string) => item !== name);
    },
    addPhoneItem() {
      if (this.user?.role.permissions.pharmacy.update) {
        this.phoneItems.push(`phone${token()}`);
      }
    },
    removePhoneItem(name: string) {
      this.phoneItems = this.phoneItems.filter((item: string) => item !== name);
    },
    submitForm() {
      this.$formkit.submit('pharmacyForm');
    },
    cleanForm() {
      this.formData.name = '';
      this.formData.email = '';
      this.formData.additionalEmails = {};
      this.formData.customersPhoneNumber = '';
      this.formData.additionalPhoneNumbers = {};
    },
    initServiceLanguagesPharmacy() {
      const serviceLanguagesPharmacy = this.pharmacy?.serviceLanguages;
      const serviceLangs = Object.entries(this.allLanguages).map(
        ([code, name]) => ({
          label: name,
          value: code.toLowerCase()
        })
      );

      const serviceLanguagesResult = serviceLangs.filter((lang: any) =>
        serviceLanguagesPharmacy.includes(lang.value)
      );

      this.serviceLangsDropdown.selectedOption = serviceLanguagesResult;
    }
  },
  watch: {
    'langsDropdown.selectedOption': {
      immediate: true,
      handler() {
        const languagesValues = this.langsDropdown.selectedOption.map(
          (i: Option) => i.value
        );

        this.formData.customerSMSLanguages = languagesValues || '';
      }
    },
    'serviceLangsDropdown.selectedOption': {
      immediate: true,
      handler() {
        this.formData.serviceLanguages =
          this.serviceLangsDropdown.selectedOption.map(
            (option: Option) => option.value
          );
      }
    },
    pharmacy() {
      if (this.pharmacy) {
        this.initLanguage();
        this.initServiceLanguage();
        this.initServiceLanguagesPharmacy();

        const languages = this.pharmacy?.pickupBox.customerSMSLanguages;

        const languageMappings: { [key: string]: Record<string, unknown> } = {
          en: langsEn,
          fi: langsFi,
          sv: langsSv,
          no: langsNo
        };

        const userLanguage = this.user?.appLanguage;
        if (userLanguage && languageMappings[userLanguage]) {
          const selectedOption = languages.map((i: string) => ({
            label: languageMappings[userLanguage][i],
            value: i
          }));

          this.langsDropdown.selectedOption = selectedOption;
        }
      }
    },
    updatedPharmacyLogo() {
      if (this.updatedPharmacyLogo) {
        this.show();
      }
    }
  }
})
export default class EditPharmacy extends Vue {}
