import { Check } from '@/store/modules/pharmacy/types';
import moment from 'moment';

export function dateTime(value: number): string {
  return value ? moment(value).format('DD.MM.YYYY HH:mm') : '';
}

export function getAverageTemp(alarmMax: number, alarmMin: number): string {
  const averageTemp = (alarmMax + alarmMin) / 2;

  if (!averageTemp) {
    return '';
  }

  function rounded(averageTemp: number) {
    return +averageTemp.toFixed(2);
  }

  return `${rounded(averageTemp)}`;
}

export function getDuration(start: number, end: number): string | number {
  const startDate = start;
  const endDate = end;

  const diffTime = Math.abs(endDate - startDate);
  const diffH = Math.ceil(diffTime / (1000 * 60 * 60) - 1);
  const diffMin = Math.ceil(diffTime / (1000 * 60));

  if (!startDate || !endDate) {
    return '';
  }

  if (diffTime === 0) {
    return 0;
  }

  if (diffH === 1) {
    return `${diffH} hour`;
  } else if (diffH === 0) {
    return `${diffMin} min`;
  } else {
    return `${diffH} hours`;
  }
}

export function getComment(comment: Check): string {
  const checkTime = moment(comment.checkTime).format('DD.MM.YYYY HH:mm');
  const checkName = comment.checkName;
  const commentText = comment.alarmInfo;

  return `${commentText} (${checkName} ${checkTime})`;
}

export function getAlarmComments(data: Array<Check>): string[] {
  const alarmInfoData = data.slice(1).map(getComment);

  return alarmInfoData;
}
