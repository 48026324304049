import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "changeLang" }
const _hoisted_2 = { class: "changeLang__title" }
const _hoisted_3 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('lang')), 1)
      ]),
      _createVNode(_component_FormKit, {
        type: "form",
        id: "lang-form",
        modelValue: _ctx.formData,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData) = $event)),
        actions: false,
        onSubmit: _ctx.submitHandler
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Dropdown, {
            class: "dropdown-lang",
            selectedOption: _ctx.langsDropdown.selectedOption,
            options: _ctx.langsDropdown.options,
            label: _ctx.$t('desktopLang'),
            onSelectOption: _ctx.selectLanguage
          }, null, 8, ["selectedOption", "options", "label", "onSelectOption"])
        ]),
        _: 1
      }, 8, ["modelValue", "onSubmit"]),
      _createVNode(_component_Button, {
        class: "changeLang-btn",
        variant: "filled",
        title: _ctx.$t('save'),
        onClick: _ctx.submitForm
      }, null, 8, ["title", "onClick"])
    ])
  ], 64))
}