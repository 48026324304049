
import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';

@Options({
  name: 'AddInfo',
  props: ['title'],
  computed: {
    ...mapState('account', ['user'])
  }
})
export default class AddInfoComponent extends Vue {}
