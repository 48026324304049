
import { Options, Vue } from 'vue-class-component';
import {
  getDatabase,
  ref,
  query,
  orderByChild,
  startAt,
  endAt,
  limitToFirst,
  get
} from 'firebase/database';

import Loading from '@/views/Loading.vue';

import {
  checkboxes as initialCheckboxes,
  activeOptionsFace,
  activeOptionsGrade,
  activeOptionsGradeType,
  columns
} from './configs';

import { Checkbox, InputFileEvent, Option } from '@/types';
import { FeedbackItem, FeedbackItemData } from '@/store/modules/pharmacy/types';

@Options({
  name: 'Customers',
  props: ['pharmacy'],
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      params: {
        startTime: new Date().setHours(0, 0, 0, 0).valueOf(),
        endTime: new Date().getTime()
      },
      selectedInterval: 5,
      hourInterval: 0,
      selectedTemplatesString: '',
      checkboxes: JSON.parse(JSON.stringify(initialCheckboxes)),
      getOptionFace: {
        selectedOption: {
          label: this.$t('face')
        },
        options: activeOptionsFace(this.$t)
      },
      getOptionType: {
        selectedOption: {
          label: this.$t('type')
        },
        options: []
      },
      getOptionGrade: {
        selectedOption: {
          label: this.$t('grade')
        },
        options: activeOptionsGrade
      },
      getGradeType: {
        selectedOption: {
          label: this.$t('type')
        },
        options: activeOptionsGradeType(this.$t)
      },
      columns: columns(this.$t),
      feedback: [],
      filteredFeedback: [] as FeedbackItem[],
      lastTimestamp: null
    };
  },
  created() {
    if (this.pharmacy) {
      this.pharmacyId = this.pharmacy.id;

      this.show();
    }
  },
  computed: {
    today() {
      const todayDate = new Date().getTime();

      return todayDate;
    },
    firstDay(): number {
      const firstDate = new Date().setHours(0, 0, 0, 0).valueOf();

      return firstDate;
    },
    isDateCorrect() {
      if (this.params && this.params.startTime && this.params.endTime) {
        return this.params.endTime >= this.params.startTime;
      }
      return true;
    }
  },
  beforeUnmount() {
    if (this.checkboxes && Array.isArray(this.checkboxes)) {
      this.checkboxes.forEach((checkbox: Checkbox) => {
        checkbox.value = false;
      });
    }
  },
  methods: {
    show() {
      this.initOptionTemplatesCharts();
      this.fetchFeedback(true);
    },
    async fetchFeedback(reset = false) {
      const database = getDatabase();
      const pharmacyId = this.pharmacy.id;
      const baseUrl = `/feedback/${pharmacyId}`;

      if (reset) {
        this.feedback = [];
        this.filteredFeedback = [];
        this.lastTimestamp = null;
      }

      const fetchData = async (startTime: number | null) => {
        const dbRef = ref(database, baseUrl);
        const queryRef = query(
          dbRef,
          orderByChild('timestamp'),
          startAt(startTime),
          endAt(this.params.endTime),
          limitToFirst(50 - this.feedback.length)
        );

        try {
          const snapshot = await get(queryRef);
          const result = snapshot.val();

          if (result) {
            const newFeedback = Object.values(result) as FeedbackItem[];
            this.feedback = this.feedback.concat(newFeedback);
            this.filterFeedback();

            if (newFeedback.length > 0) {
              const lastItem = newFeedback[newFeedback.length - 1];
              if (lastItem && typeof lastItem.timestamp === 'number') {
                this.lastTimestamp = lastItem.timestamp + 1;
              }
            }
          }
        } catch (error) {
          console.error('Error fetching feedback:', error);
        }
      };

      this.loading = true;
      await fetchData(this.params.startTime);

      while (this.feedback.length < 50 && this.feedback.length > 0) {
        await fetchData(this.feedback[this.feedback.length - 1].timestamp + 1);
      }

      this.loading = false;
    },
    async changePage() {
      if (this.loading) return;
      this.loading = true;

      const database = getDatabase();
      const pharmacyId = this.pharmacy.id;
      const baseUrl = `/feedback/${pharmacyId}`;
      const dbRef = ref(database, baseUrl);

      const queryRef = query(
        dbRef,
        orderByChild('timestamp'),
        startAt(this.lastTimestamp),
        endAt(this.params.endTime),
        limitToFirst(50)
      );

      try {
        const snapshot = await get(queryRef);
        const result = snapshot.val();

        if (result) {
          const newFeedback = Object.values(result) as FeedbackItem[];
          this.feedback = this.feedback.concat(newFeedback);
          this.filterFeedback();

          if (newFeedback.length > 0) {
            const lastItem = newFeedback[newFeedback.length - 1];
            if (lastItem && typeof lastItem.timestamp === 'number') {
              this.lastTimestamp = lastItem.timestamp + 1;
            }
          }
        }
      } catch (error) {
        console.error('Error fetching feedback:', error);
      } finally {
        this.loading = false;
      }

      return this.feedback;
    },
    toggleCheckbox(name: string) {
      const checkbox = this.checkboxes.find((c: Checkbox) => c.name === name);

      if (checkbox) {
        checkbox.value = !checkbox.value;
        this.filterFeedback();
      }
    },
    filterFeedback() {
      this.loading = true;

      this.filteredFeedback = this.feedback?.filter((item: FeedbackItem) => {
        const activeCheckboxes = this.checkboxes.filter(
          (cb: Checkbox) => cb.value
        );

        if (activeCheckboxes.length === 0) {
          this.loading = false;
          return true;
        }

        return activeCheckboxes.every((checkbox: Checkbox) => {
          switch (checkbox.name) {
            case 'showOnlyWithContact':
              if (!item.contact) return false;
              break;
            case 'showOnlyWithReport':
              if (!item.report) return false;
              break;
            case 'showOnlyWithOption':
              if (
                this.getOptionFace.selectedOption.length > 0 ||
                this.getOptionType.selectedOption.length > 0
              ) {
                const faces = Array.isArray(this.getOptionFace.selectedOption)
                  ? this.getOptionFace.selectedOption.map(
                      (opt: Option) => opt.value
                    )
                  : [];
                const types = Array.isArray(this.getOptionType.selectedOption)
                  ? this.getOptionType.selectedOption.map(
                      (opt: Option) => opt.value
                    )
                  : [];

                const dataItemsMatch = item.data?.some(
                  (dataItem: FeedbackItemData) => {
                    return (
                      (faces.length === 0 || faces.includes(dataItem.grade)) &&
                      (types.length === 0 || types.includes(dataItem.type))
                    );
                  }
                );

                if (!dataItemsMatch) return false;
              }
              break;
            case 'showOnlyWithGrade':
              if (
                this.getOptionGrade.selectedOption?.label !==
                  this.$t('grade') &&
                this.getGradeType.selectedOption?.label !== this.$t('type')
              ) {
                const grade: number | undefined = item.grade?.grade;
                const selectedGrade = parseFloat(
                  this.getOptionGrade.selectedOption.value
                );

                if (grade === undefined) return false;

                if (this.getGradeType.selectedOption.value === 'higher') {
                  if (!(grade > selectedGrade)) return false;
                } else if (this.getGradeType.selectedOption.value === 'lower') {
                  if (!(grade < selectedGrade)) return false;
                } else if (this.getGradeType.selectedOption.value === 'same') {
                  if (!(grade === selectedGrade)) return false;
                }
              }
              break;
            default:
              break;
          }
          return true;
        });
      });

      this.loading = false;
    },
    changeDate(e: InputFileEvent) {
      const { name, value } = e.target;
      this.params = { ...this.params, [name]: value };
    },
    selectOptionFace(option: Option) {
      this.getOptionFace.selectedOption = option;
      this.filterFeedback();
    },
    selectOptionType(option: Option[]) {
      this.getOptionType.selectedOption = option;
      this.filterFeedback();
    },
    selectOptionGrade(option: Option) {
      this.getOptionGrade.selectedOption = option;
      if (option.label !== this.$t('grade')) {
        this.getGradeType.options = activeOptionsGradeType(this.$t);
      } else {
        this.getGradeType.selectedOption = { label: this.$t('type') };
        this.getGradeType.options = [];
      }
      this.filterFeedback();
    },
    selectGradeType(option: Option) {
      this.getGradeType.selectedOption = option;
      this.filterFeedback();
    },
    initOptionTemplatesCharts() {
      const options = this.pharmacy.feedbackTemplate.options_screen.options;
      const language = this.user?.appLanguage;

      this.getOptionType.options = Object.keys(options).map((key: string) => {
        const translations = options[key]?.translations;

        if (translations && translations[language]) {
          return { value: key, label: translations[language] };
        } else {
          return { value: key, label: translations['en'] };
        }
      });
    }
  },
  watch: {
    checkboxes: {
      deep: true,
      handler() {
        if (!this.checkboxes[1].value) {
          this.getOptionFace.selectedOption = {
            label: this.$t('face')
          };

          this.getOptionType.selectedOption = {
            label: this.$t('type')
          };
        }

        if (!this.checkboxes[3].value) {
          this.getOptionGrade.selectedOption = {
            label: this.$t('grade')
          };

          this.getGradeType.selectedOption = {
            label: this.$t('type')
          };
        }
      }
    },
    params: {
      deep: true,
      handler(newParams) {
        if (newParams.startTime !== null && newParams.endTime !== null) {
          this.show();
        }
      }
    },
    pharmacy() {
      if (this.pharmacy) {
        this.initOptionTemplatesCharts();
      }
    }
  }
})
export default class Customers extends Vue {}
