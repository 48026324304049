import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("input", {
      style: {"opacity":"0","position":"absolute"},
      type: "text",
      name: "email"
    }, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("input", {
      style: {"opacity":"0","position":"absolute"},
      type: "password",
      name: "password"
    }, null, -1))
  ], 64))
}