
import { Options, Vue } from 'vue-class-component';
import QrcodeVue from 'qrcode.vue';

@Options({
  name: 'OpenQRCode',
  props: ['showQRCode', 'idActiveDelivery'],
  components: {
    QrcodeVue
  }
})
export default class OpenQRCode extends Vue {}
