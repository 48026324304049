import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/icons/DownImg.svg'


const _hoisted_1 = { class: "photoInputOuter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createVNode(_component_FormKit, {
      classes: {
        wrapper: 'photoInputWrapper',
        input: 'photoInput',
        inner: 'photoInputInner'
      },
      name: 'pictureUrl',
      type: "file",
      accept: ".jpg,.jpeg,.svg,.png"
    }),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "photoInputIcon" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "Photo Icon"
      })
    ], -1))
  ]))
}