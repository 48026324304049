
import { Options, Vue } from 'vue-class-component';
import { mapActions } from 'vuex';

import Loading from '@/views/Loading.vue';

import InfoLocker from './InfoLocker/InfoLocker.vue';
import ReserveLocker from '@/duplicatePages/ReserveLocker/ReserveLocker.vue';
import ChooseDelivery from './ChooseDelivery/ChooseDelivery.vue';

import { boxesNames } from './configs';
import { allStatus } from '@/constants';
import { Locker } from '@/store/modules/pharmacy/types';
import { InputFileEvent } from '@/types';

@Options({
  name: 'Modules',
  emits: ['multipleLockersFalse'],
  props: ['modules', 'activePickupBox', 'multipleLockers', 'positions'],
  components: {
    Loading,
    InfoLocker,
    ReserveLocker,
    ChooseDelivery
  },
  data() {
    return {
      moved: null,
      lockerData: null,
      activeLocker: null,
      selectedLockers: [],
      activeBoxId: '',
      selectedLocker: null,
      lockerId: false,
      order: null,
      boxesNames,
      allStatus,
      loading: false,
      showModalReserveLocker: false,
      showModalChooseDelivery: false
    };
  },

  methods: {
    ...mapActions('pharmacy', ['updatePickupBox', 'getPickupBoxesByPharmacy']),
    getStatusReservations(locker: Locker, allStatus: Record<string, unknown>) {
      if (locker.order?.deliveryStatus === 'removed') {
        return allStatus['free'];
      } else if (locker.order) {
        return allStatus[locker.order?.deliveryStatus];
      } else {
        return allStatus[locker.status];
      }
    },
    updateLockerDataCallback() {
      this.chooseLocker(...this.selectedLocker);
    },
    chooseLocker(
      moduleNumber: number,
      lockerNumber: number,
      size: string,
      temperature: number
    ) {
      this.order = {
        size,
        status: 'free',
        temperature,
        ...this.modules[moduleNumber].lockers[lockerNumber].order
      };
      this.lockerData = {
        ...this.modules[moduleNumber].lockers[lockerNumber]
      };

      this.activeLocker = `${moduleNumber}-${lockerNumber}`;

      this.selectedLocker = [moduleNumber, lockerNumber, size, temperature];

      if (this.order.deliveryStatus === 'removed') {
        return (this.order = {
          size,
          status: 'free',
          temperature
        });
      } else {
        return this.order;
      }
    },
    handleCheckbox(e: InputFileEvent) {
      if (this.selectedLockers.indexOf(e.target.name) != -1) {
        this.selectedLockers.splice(
          this.selectedLockers.indexOf(e.target.name),
          1
        );
      } else {
        this.selectedLockers.push(e.target.name);
      }
    },
    disabledLocker() {
      if (this.lockerData?.order || this.lockerData?.status === 'inactive') {
        return true;
      } else {
        return false;
      }
    },
    closeModalReservation() {
      this.showModalReserveLocker = true;
      this.$emit('multipleLockersFalse');
    },
    assignReservation() {
      this.activeBoxId = this.activePickupBox.id;
      this.showModalChooseDelivery = true;
    }
  },
  watch: {
    modules() {
      if (this.modules) {
        if (this.lockerData) {
          this.updateLockerDataCallback();
        }
      }
    },
    activePickupBox() {
      if (this.activePickupBox) {
        this.selectedLockers = [];
      }
    }
  }
})
export default class Modules extends Vue {}
