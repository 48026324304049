import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "general" }
const _hoisted_2 = {
  key: 0,
  class: "options__input-screenTimeout"
}
const _hoisted_3 = {
  key: 1,
  class: "options__input-color"
}
const _hoisted_4 = { class: "color" }
const _hoisted_5 = {
  key: 0,
  class: "chooseColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_TitleOptions = _resolveComponent("TitleOptions")!
  const _component_Input = _resolveComponent("Input")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_PhotoTab = _resolveComponent("PhotoTab")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormKit, {
        type: "form",
        id: "general-form",
        modelValue: _ctx.formData,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData) = $event)),
        actions: false,
        onSubmit: _ctx.submitHandler
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generalOptions, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "general__options",
              key: index
            }, [
              _createVNode(_component_TitleOptions, {
                title: _ctx.$t(option.label)
              }, null, 8, ["title"]),
              (option.value === 'screenTimeout')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_Input, {
                      type: "number",
                      name: "screen_timeout",
                      value: _ctx.formData.screen_timeout,
                      required: ""
                    }, null, 8, ["value"])
                  ]))
                : _createCommentVNode("", true),
              (option.value === 'color')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.formData.color), 1),
                    _createVNode(_component_FormKit, {
                      type: "color",
                      modelValue: _ctx.formData.color,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.color) = $event)),
                      name: "color"
                    }, null, 8, ["modelValue"]),
                    (!_ctx.formData.color)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('chooseColor')), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (option.value === 'photo')
                ? (_openBlock(), _createBlock(_component_PhotoTab, {
                    key: 2,
                    existingPhoto: _ctx.formData.logo,
                    "onUpdate:imageSrc": _cache[1] || (_cache[1] = ($event: any) => (_ctx.imageSrc = $event))
                  }, null, 8, ["existingPhoto"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onSubmit"]),
      _createVNode(_component_Button, {
        class: "general-button",
        variant: "filled",
        title: _ctx.$t('save'),
        onClick: _ctx.submitForm
      }, null, 8, ["title", "onClick"])
    ])
  ], 64))
}