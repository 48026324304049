
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'ReservedLockers',
  props: ['showModalReservedLockers', 'choosedLockers'],
  emits: ['closeAllModal'],
  methods: {
    getChoosedLockers(choosedLockers: Array<string>) {
      if (choosedLockers.length === 1) {
        const locker = Object.values(choosedLockers).join('');

        return `${this.$t('locker')} ${locker} ${this.$t('reservedText')}`;
      } else if (choosedLockers.length === 2) {
        const firstLocker = choosedLockers[0];
        const secondLcker = choosedLockers[1];

        return `${this.$t('lockers')} ${firstLocker} ${this.$t(
          'and'
        )} ${secondLcker} ${this.$t('reservedText')}`;
      } else if (choosedLockers.length >= 3) {
        const lastLocker = choosedLockers.pop();
        const lockersWithoutLast = choosedLockers.join(', ');

        return `${this.$t('lockers')} ${lockersWithoutLast} ${this.$t(
          'and'
        )} ${lastLocker} ${this.$t('reservedText')}`;
      }
    }
  }
})
export default class ReservedLockers extends Vue {}
