import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "radio" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name", "checked"]
const _hoisted_4 = {
  key: 0,
  class: "radio-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.name,
      class: "radio-label"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({ 'radio-wrapper': true, checked: _ctx.value })
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "checked-radio" }, null, -1)
      ]), 2),
      _createElementVNode("input", {
        id: _ctx.name,
        name: _ctx.name,
        class: "input",
        type: "radio",
        checked: _ctx.value
      }, null, 8, _hoisted_3),
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)
  ]))
}