
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import { thanksTitles } from './configs';
import { translations } from '../configs';

import { OptionsScreen } from '@/store/modules/pharmacy/types';
import { mapScreensToSelectedPositions } from '@/components/UI/ShowSettings/configs';

@Options({
  name: 'Thanks',
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      formData: {
        title_text: {
          fi: '',
          sv: '',
          en: ''
        },
        subtext: {
          fi: '',
          sv: '',
          en: ''
        },
        show: false,
        position: 1,
        logo: ''
      },
      payload: {},
      positionNotSelected: false,
      thanksTitles,
      translations
    };
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy'])
  },
  created() {
    this.show();
  },
  methods: {
    ...mapActions('pharmacy', ['getPharmacy', 'updateFeedbackTemplate']),
    async show() {
      this.loading = true;

      const { id } = this.$route.params;
      await this.getPharmacy(id);

      this.formData = {
        ...this.pharmacy.feedbackTemplate.thanks_screen
      };
      this.loading = false;
    },
    updateSelectedPosition(position: number) {
      this.formData.position = position;
    },
    selectShowSettings() {
      this.showSettings = !this.showSettings;
    },
    updateLogo(imageSrc: string) {
      this.formData.logo = imageSrc;
    },
    async submitHandler(data: OptionsScreen) {
      this.loading = true;
      if (this.positionNotSelected) {
        this.payload = {
          thanks_screen: {
            ...data,
            advert_photo: this.imageSrc || this.formData.logo
          }
        };
      } else {
        if (this.pharmacy) {
          const screens = this.pharmacy.feedbackTemplate;
          const name = this.$route.name.toLowerCase();

          const selectedPositions = mapScreensToSelectedPositions(
            screens,
            name
          );

          this.payload = {
            thanks_screen: {
              ...data,
              advert_photo: this.imageSrc || this.formData.logo,
              position: selectedPositions.value
            }
          };
        }
      }

      delete this.payload.thanks_screen.logo;

      const { id } = this.$route.params;
      await this.updateFeedbackTemplate({ data: this.payload, pharmacyId: id });

      this.$router.go(0);

      this.loading = false;
    },
    async submitForm() {
      const logo = this.formData.logo;

      if (logo.length === 0) {
        const payload = {
          thanks_screen: {
            ...this.formData
          }
        };
        delete payload.thanks_screen.logo;
        delete payload.thanks_screen.advert_photo;

        const { id } = this.$route.params;
        await this.updateFeedbackTemplate({ data: payload, pharmacyId: id });

        this.$router.go(0);
      } else {
        this.$formkit.submit('thanks-form');
      }
    }
  },
  watch: {
    pharmacy() {
      if (this.pharmacy) {
        this.formData = {
          ...this.pharmacy.feedbackTemplate.thanks_screen
        };
      }
    }
  }
})
export default class Thanks extends Vue {}
