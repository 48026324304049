
import { Options, Vue } from 'vue-class-component';

import { optionsLineChart, optionsBarChart } from './configs';

@Options({
  name: 'FeedbackChartsDays',
  props: ['dataLineChart', 'dataBarChart'],
  data() {
    return {
      optionsLineChart: optionsLineChart,
      optionsBarChart: optionsBarChart,
      height: 200,
      width: 500
    };
  },
  computed: {
    styleLineChart() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
        position: 'relative'
      };
    },
    styleBarChart() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
        position: 'relative'
      };
    }
  }
})
export default class FeedbackChartsDays extends Vue {}
