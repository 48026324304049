import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = ["data-testid", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass({ tab: true, active: tab.value === _ctx.$route.name }),
        key: tab.id,
        "data-testid": tab.label,
        onClick: ($event: any) => (_ctx.$emit('selectTab', tab.value))
      }, _toDisplayString(_ctx.$t(tab.label)), 11, _hoisted_2))
    }), 128))
  ]))
}