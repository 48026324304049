
import { Vue, Options } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import router from '@/router';

import { columns } from './configs';

import { Params } from '@/store/modules/users/types';

@Options({
  name: 'Users',
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      lastKey: null,
      userPharmacyId: '',
      params: null,
      columns: columns(this.$t)
    };
  },
  created() {
    this.userPharmacyId = this.user?.pharmacyId;

    if (this.userPharmacyId) {
      this.show();
    }
  },
  unmounted() {
    this.clearUsers();
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('users', ['users'])
  },
  methods: {
    ...mapActions('users', ['showUsers', 'clearUsers']),
    async show(params: Params) {
      this.userPharmacyId = this.user?.pharmacyId;

      this.loading = true;
      let defaultParams = {
        lastKey: this.lastKey,
        pageSize: 30,
        pharmacyId: this.userPharmacyId
      };

      if (params) {
        defaultParams = {
          ...defaultParams,
          ...(params.lastKey !== undefined && { lastKey: params.lastKey }),
          ...(params.pageSize !== undefined && { pageSize: params.pageSize }),
          ...(params.pharmacyId !== undefined && {
            pharmacyId: params.pharmacyId
          })
        };
      }

      await this.showUsers(defaultParams);
      this.loading = false;
    },
    createUser() {
      if (this.user?.role.permissions.user.create) {
        router.push({ name: 'CreateUser' });
      }
    },
    addUser() {
      if (this.user?.role.permissions.user.create) {
        router.push({ name: 'AddUser' });
      }
    },
    loginInformation() {
      if (this.user?.role.permissions.login_logs.read) {
        router.push({
          name: 'LoginInformation',
          params: { id: this.user.pharmacyId }
        });
      }
    },
    infoUser(id: string) {
      if (id == this.user.id) {
        router.push({ name: 'Profile' });
      } else {
        router.push({ name: 'InfoUser', params: { id } });
      }
    }
  },
  watch: {
    user() {
      if (this.user) {
        this.userPharmacyId = this.user.pharmacyId;
        this.show();
      }
    }
  }
})
export default class Users extends Vue {}
