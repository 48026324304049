import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/Search.svg'
import _imports_1 from '@/assets/icons/Hyphen.svg'


const _hoisted_1 = { class: "ordersHistory" }
const _hoisted_2 = { class: "ordersHistory__header" }
const _hoisted_3 = { class: "ordersHistory__header-search" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "ordersHistory__header-chooseDate" }
const _hoisted_6 = { class: "chooseDate-date" }
const _hoisted_7 = { class: "chooseDate-date-inputs" }
const _hoisted_8 = { class: "input" }
const _hoisted_9 = { class: "input" }
const _hoisted_10 = {
  key: 0,
  class: "date-error"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 2,
  class: "ordersHistory__noReserve"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Title = _resolveComponent("Title")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Table = _resolveComponent("Table")!
  const _component_OpenConfirmation = _resolveComponent("OpenConfirmation")!
  const _component_AddComment = _resolveComponent("AddComment")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_Title, {
        title: _ctx.$t('ordersHistory')
      }, null, 8, ["title"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("img", {
            class: "dropdown-img",
            src: _imports_0,
            alt: "Search"
          }, null, -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            class: "input-search",
            placeholder: _ctx.$t('search'),
            onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeInput && _ctx.changeInput(...args)))
          }, null, 40, _hoisted_4), [
            [_vModelText, _ctx.search]
          ]),
          _createVNode(_component_Dropdown, {
            className: "dropdown-search",
            selectedOption: _ctx.getSearchBy.selectedOption,
            options: _ctx.getSearchBy.options,
            onSelectOption: _ctx.changeSearchBy
          }, null, 8, ["selectedOption", "options", "onSelectOption"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Button, {
            class: "getCSV-button",
            variant: "bordered",
            title: _ctx.$t('downloadPDF'),
            onClick: _ctx.getPDF
          }, null, 8, ["title", "onClick"]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_Calendar, {
                  type: "date",
                  format: "DD/MM/YYYY",
                  value: _ctx.dateRange.startTime,
                  name: "startTime",
                  onChange: _ctx.changeDate,
                  onClosePanel: _ctx.closeTimePanel,
                  "data-testid": "calendarStartTime"
                }, null, 8, ["value", "onChange", "onClosePanel"])
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("img", {
                class: "date-img",
                src: _imports_1,
                alt: "Hyphen"
              }, null, -1)),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_Calendar, {
                  type: "date",
                  format: "DD/MM/YYYY",
                  value: _ctx.dateRange.endTime,
                  name: "endTime",
                  onChange: _ctx.changeDate,
                  onClosePanel: _ctx.closeTimePanel,
                  "data-testid": "calendarEndTime"
                }, null, 8, ["value", "onChange", "onClosePanel"])
              ])
            ]),
            (!_ctx.isDateCorrect)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('errorDate')), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      (_ctx.isDateCorrect && _ctx.ordersHistoryList.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_Table, {
              data: _ctx.ordersHistoryList,
              columns: _ctx.columns,
              selectedField: "createdTime",
              onGetSsn: _ctx.getSsn,
              onGoToItem: _ctx.openViewOrder,
              onOpenConfirmation: _ctx.openConfirmation,
              onAddDescription: _ctx.addDescription
            }, null, 8, ["data", "columns", "onGetSsn", "onGoToItem", "onOpenConfirmation", "onAddDescription"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isDateCorrect || !_ctx.ordersHistoryList.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('noData')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_OpenConfirmation, {
      showOpenConfirmation: _ctx.showOpenConfirmation,
      onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showOpenConfirmation = false)),
      onConfirmRequest: _ctx.confirmRequest
    }, null, 8, ["showOpenConfirmation", "onConfirmRequest"]),
    _createVNode(_component_AddComment, {
      showModalAddComment: _ctx.showModalAddComment,
      activePayment: _ctx.activePayment,
      onCloseModal: _ctx.closeModalAddComment
    }, null, 8, ["showModalAddComment", "activePayment", "onCloseModal"])
  ], 64))
}