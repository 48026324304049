import { OptionsData, SelectedOption } from '@/store/modules/pharmacy/types';
import { Option } from '@/types';

export const optionsPositions = [
  {
    value: 1,
    label: 1
  },
  {
    value: 2,
    label: 2
  },
  {
    value: 3,
    label: 3
  }
];

export function mapOptionsToSelectedPositions(
  options: OptionsData,
  // eslint-disable-next-line
  optionsPositions: any
): SelectedOption {
  const selectedOption: SelectedOption = {};

  Object.keys(options).forEach((key) => {
    const position = options[key].position + 1;
    const optionPosition = optionsPositions.find(
      (pos: Option) => pos.value === position
    );

    if (optionPosition) {
      selectedOption[key] = optionPosition;
    }
  });

  return selectedOption;
}
