import { Module } from 'vuex';
import { OrderPaymentState } from './types';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/orders/getters';
import { actions } from '@/store/modules/orders/actions';
import { mutations } from '@/store/modules/orders/mutations';

export const state: OrderPaymentState = {
  status: {
    error: null,
    message: null
  },
  orderPushNotification: null,
  orderPayment: null,
  feedbackStatistics: null,
  feedbackStatisticsWeek: null,
  ordersHistory: [],
  viewOrder: null,
  decodedSSN: null
};

export const orders: Module<OrderPaymentState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
