import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "paymentInfo" }
const _hoisted_2 = { class: "paymentInfo__title" }
const _hoisted_3 = { class: "paymentInfo" }
const _hoisted_4 = { class: "paymentInfo__info" }
const _hoisted_5 = { class: "info" }
const _hoisted_6 = { class: "valueInfo" }
const _hoisted_7 = {
  key: 0,
  class: "timeInfo"
}
const _hoisted_8 = {
  key: 0,
  class: "info"
}
const _hoisted_9 = { class: "valueInfo" }
const _hoisted_10 = {
  key: 1,
  class: "info"
}
const _hoisted_11 = { class: "valueInfo" }
const _hoisted_12 = {
  key: 2,
  class: "info"
}
const _hoisted_13 = { class: "valueInfo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('paymentDetails')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('issuePayment')), 1),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getIssuePayment(_ctx.paymentInfo?.issuedAt)), 1),
        (_ctx.paymentInfo?.issuedAt)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, " (" + _toDisplayString(_ctx.getTime(_ctx.paymentInfo?.issuedAt)) + " )", 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.paymentInfo?.reference)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('reference')), 1),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.paymentInfo?.reference), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.paymentInfo?.totalSum)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('totalSum')), 1),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.paymentInfo?.totalSum), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.paymentInfo?.comment)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('customerReceiptMessage')), 1),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.paymentInfo?.comment), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}