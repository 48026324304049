export const actions = [
  {
    value: 'Green',
    label: 'free'
  },
  {
    value: 'Orange',
    label: 'reserved'
  },
  {
    value: 'Blue',
    label: 'delivered'
  },
  {
    value: 'Red',
    label: 'expired'
  },
  {
    value: 'Gray',
    label: 'notInUse'
  },
  {
    value: 'Fridge',
    label: 'fridge'
  }
];
