export const routesConfig = [
  {
    name: 'Profile',
    translation: 'profile'
  },
  {
    name: 'CallerAuthentication',
    translation: 'callerAuthentication'
  },
  {
    name: 'AuthenticationHistory',
    translation: 'authenticationHistory'
  },
  {
    name: 'OrdersHistory',
    translation: 'ordersHistory'
  },
  {
    name: 'PickupBoxes',
    translation: 'pickupBoxes'
  },
  {
    name: 'Users',
    translation: 'users'
  },
  {
    name: 'CallbackRequests',
    translation: 'callbackRequests'
  },
  {
    name: 'CustomersFeedback',
    translation: 'customersFeedback'
  }
];
