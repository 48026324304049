import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pickupBoxForm" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormKit, {
      type: "form",
      name: "pickupBox",
      id: "pickupbox",
      modelValue: _ctx.formData,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData) = $event)),
      actions: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoFromPikupBox, (el) => {
            return (_openBlock(), _createBlock(_component_Input, {
              key: el.id,
              type: el.type,
              name: el.name,
              label: _ctx.$t(el.label),
              validation: el.validation,
              validationMessages: {
            required: _ctx.$t('validation.required')
          },
              "validation-visibility": "dirty",
              preserve: el.name === 'name',
              disabled: el.name === 'id',
              "data-testid": el.dataTestId
            }, null, 8, ["type", "name", "label", "validation", "validationMessages", "preserve", "disabled", "data-testid"]))
          }), 128)),
          _createVNode(_component_Dropdown, {
            class: "dropdown-pickupboxForm",
            selectedOption: _ctx.langsDropdown.selectedOption,
            options: _ctx.langsDropdown.options,
            label: _ctx.$t('UILanguages'),
            multiple: true,
            onSelectOption: _ctx.selectLanguage,
            "data-testid": "dropdownPickupboxForm"
          }, null, 8, ["selectedOption", "options", "label", "onSelectOption"]),
          _createVNode(_component_Input, {
            type: "text",
            name: "language",
            modelValue: _ctx.formData.language,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.language) = $event)),
            label: _ctx.$t('defaultLanguages'),
            placeholder: "N/A",
            readonly: "",
            "data-testid": 'inputDefaultLanguages'
          }, null, 8, ["modelValue", "label"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}