import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "appRedirect" }
const _hoisted_2 = { class: "appRedirect__langChange" }
const _hoisted_3 = { class: "appRedirect__wrapper" }
const _hoisted_4 = {
  key: 1,
  class: "appRedirect-container"
}
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "subtitle" }
const _hoisted_7 = {
  id: "redirect-link",
  href: "#"
}
const _hoisted_8 = {
  key: 2,
  class: "appRedirect-errorMessage"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LangChange = _resolveComponent("LangChange")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LangChange)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      (!_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('welcomeToApteekkiPuhelu')), 1),
            _createElementVNode("p", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.$t('openApp')) + " ", 1),
              _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t('clickHere')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('downloadApp')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('platformUnsupported')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}