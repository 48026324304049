import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "scroll" }
const _hoisted_2 = { class: "tableFeedback" }
const _hoisted_3 = { class: "thead" }
const _hoisted_4 = { class: "column-main" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "tbody" }
const _hoisted_7 = {
  class: "observer",
  ref: "observer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", _hoisted_3, [
        _createElementVNode("tr", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
            return (_openBlock(), _createElementBlock("th", {
              class: "column-main-title",
              key: column
            }, [
              (column.label !== '')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "column-main-title-img",
                    src: require(`@/assets/icons/${column.label}.png`),
                    alt: ""
                  }, null, 8, _hoisted_5))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (i) => {
          return (_openBlock(), _createElementBlock("tr", {
            class: "column-content",
            key: i.name
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
              return (_openBlock(), _createElementBlock("td", {
                class: "column-content-title",
                key: column
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass({
                'column-content-title-sum':
                  column.value === 'all' || i.label === 'Total'
              })
                }, _toDisplayString(_ctx.getColumnValue(i, column)), 3)
              ]))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, null, 512)
  ]))
}