import { Option, TranslationFunction } from '@/types';

export const searchActions = ($t: TranslationFunction): Option[] => [
  {
    value: 'firstName',
    label: $t('firstName')
  },
  {
    value: 'lastName',
    label: $t('lastName')
  },
  {
    value: 'phoneNumber',
    label: $t('phoneNumber')
  }
];

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('consentGiven'),
    value: 'consentGiven'
  },
  {
    label: $t('customerUpperCase'),
    value: 'clientInfoName'
  },
  {
    label: $t('phoneUpperCase'),
    value: 'clientInfoPhoneNumber'
  },
  {
    label: $t('ssn'),
    value: 'clientInfoSSN'
  },
  {
    label: $t('handlerUpperCase'),
    value: 'handler'
  },
  {
    label: $t('medicalAdviceUpperCase'),
    value: 'consultation'
  },
  {
    label: $t('orderNumberUpperCase'),
    value: 'orderNumber'
  },
  {
    label: $t('eventTimeUpperCase'),
    value: 'createdTime'
  },
  {
    label: $t('deliveryUpperCase'),
    value: 'deliveryMethod'
  },
  {
    label: $t('contentUpperCase'),
    value: 'content'
  },
  {
    label: $t('totalUpperCase'),
    value: 'paymentInfoTotalSum'
  },
  {
    label: $t('paymentUpperCase'),
    value: 'paymentInfoStatus'
  },
  {
    label: $t('issuedAtUpperCase'),
    value: 'paymentInfoIssuedAt'
  },
  {
    label: $t('commentUpperCase'),
    value: 'comment'
  }
];

export const columnsDow = ($t: TranslationFunction): Option[] => [
  {
    label: $t('customerUpperCase'),
    value: 'clientInfoName'
  },
  {
    label: $t('phoneUpperCase'),
    value: 'clientInfoPhoneNumber'
  },
  {
    label: $t('handlerUpperCase'),
    value: 'handler'
  },
  {
    label: $t('medicalAdviceUpperCase'),
    value: 'consultation'
  },
  {
    label: $t('orderNumberUpperCase'),
    value: 'orderNumber'
  },
  {
    label: $t('eventTimeUpperCase'),
    value: 'createdTime'
  },
  {
    label: $t('deliveryUpperCase'),
    value: 'deliveryMethod'
  },
  {
    label: $t('contentUpperCase'),
    value: 'content'
  },
  {
    label: $t('totalUpperCase'),
    value: 'paymentInfoTotalSum'
  },
  {
    label: $t('paymentUpperCase'),
    value: 'paymentInfoStatus'
  },
  {
    label: $t('issuedAtUpperCase'),
    value: 'paymentInfoIssuedAt'
  },
  {
    label: $t('commentUpperCase'),
    value: 'comment'
  }
];
