import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/CloseModal.svg'


const _hoisted_1 = { class: "addDescription" }
const _hoisted_2 = { class: "addDescription__header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "addDescription__body" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "addDescription__body-description" }
const _hoisted_7 = {
  class: "label-description",
  for: "description"
}
const _hoisted_8 = { class: "addDescription__buttons" }
const _hoisted_9 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_InfoAboutDelivery = _resolveComponent("InfoAboutDelivery")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_InfoAboutActionLog = _resolveComponent("InfoAboutActionLog")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.showModal)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('addDescription')), 1),
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_InfoAboutDelivery, {
                  showDecodedSsn: _ctx.showDecodedSsn,
                  onGetDecodedSsn: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('getDecodedSsn', _ctx.activeDelivery.deliveryId))),
                  activeDelivery: _ctx.activeDelivery
                }, null, 8, ["showDecodedSsn", "activeDelivery"]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_FormKit, {
                    type: "form",
                    id: "description-form",
                    modelValue: _ctx.formData,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData) = $event)),
                    actions: false,
                    onSubmit: _ctx.submitHandler
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('description')), 1),
                      _withDirectives(_createElementVNode("textarea", {
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeDelivery.descriptionText) = $event)),
                        id: "description",
                        class: "input-description",
                        maxlength: "250"
                      }, "              ", 512), [
                        [_vModelText, _ctx.activeDelivery.descriptionText]
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onSubmit"])
                ])
              ]),
              (_ctx.openInfoAboutActionLog)
                ? (_openBlock(), _createBlock(_component_InfoAboutActionLog, {
                    key: 0,
                    activeDelivery: _ctx.activeDelivery
                  }, null, 8, ["activeDelivery"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              (_ctx.activeDelivery.actionsLog)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    variant: "filled",
                    title: 
            _ctx.$t(_ctx.openInfoAboutActionLog ? 'hideFullHistory' : 'showFullHistory')
          ,
                    onClick: _ctx.showInfoAboutActionLog
                  }, null, 8, ["title", "onClick"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_Button, {
                  variant: "filled",
                  title: _ctx.$t('save'),
                  onClick: _ctx.saveDescription
                }, null, 8, ["title", "onClick"]),
                _createVNode(_component_Button, {
                  variant: "notBordered",
                  title: _ctx.$t('cancel'),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('closeModal')))
                }, null, 8, ["title"])
              ])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}