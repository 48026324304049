import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "callbackRequests" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "callbackRequests__noReserve"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Title = _resolveComponent("Title")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Title, {
      title: _ctx.$t('callbackRequests')
    }, null, 8, ["title"]),
    (_ctx.callbackRequests.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Table, {
            data: _ctx.callbackRequests,
            columns: _ctx.columns,
            selectedField: "id",
            onMarkAsDone: _ctx.markAsDoneRequest,
            onMarkAsMissed: _ctx.markAsMissedRequest,
            onOpenCallerAuthentication: _ctx.openCallerAuthentication
          }, null, 8, ["data", "columns", "onMarkAsDone", "onMarkAsMissed", "onOpenCallerAuthentication"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.callbackRequests?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('noData')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}