import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "infoAboutDelivery" }
const _hoisted_2 = {
  key: 0,
  class: "info"
}
const _hoisted_3 = {
  key: 1,
  class: "info"
}
const _hoisted_4 = { class: "info" }
const _hoisted_5 = {
  key: 2,
  class: "info"
}
const _hoisted_6 = {
  key: 3,
  class: "info"
}
const _hoisted_7 = {
  key: 4,
  class: "info"
}
const _hoisted_8 = {
  key: 5,
  class: "info"
}
const _hoisted_9 = {
  key: 6,
  class: "info"
}
const _hoisted_10 = {
  key: 7,
  class: "info"
}
const _hoisted_11 = {
  key: 8,
  class: "info"
}
const _hoisted_12 = {
  key: 9,
  class: "info"
}
const _hoisted_13 = {
  key: 10,
  class: "info"
}
const _hoisted_14 = {
  key: 11,
  class: "info"
}
const _hoisted_15 = {
  key: 12,
  class: "info"
}
const _hoisted_16 = {
  key: 13,
  class: "info"
}
const _hoisted_17 = {
  key: 14,
  class: "info"
}
const _hoisted_18 = {
  key: 15,
  class: "info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.activeReservations.phoneNumber)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('mobilePhone')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeReservations.phoneNumber), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.deliveryStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('deliveryStatus')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.activeReservations.deliveryStatus)), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('ssn')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.getDataSSN()), 1),
      (!_ctx.isSSN && !_ctx.showDecodedSsn)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "ssn_btn",
            variant: "notBordered",
            title: _ctx.$t('show'),
            onClick: _ctx.showSsn
          }, null, 8, ["title", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.activeReservations.expiredTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('timeExpired')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeReservations.expiredTime)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.deliveryId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('kolliID')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.getIds(
            _ctx.activeReservations.deliveryId,
            _ctx.activeReservations.additionalDeliveryIds
          )), 1)
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.activeReservations.deliveryTime || _ctx.activeReservations.deliveryUserName
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('delivered')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeReservations.deliveryUserName) + " " + _toDisplayString(_ctx.dateTime(_ctx.activeReservations.deliveryTime)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.fulfillment_order_id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('fulfillment_order_id')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeReservations.fulfillment_order_id), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.is_drug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('includesDrug')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.getIsDrud(_ctx.activeReservations.is_drug)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.lockerId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('lockerID')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeReservations.lockerId.join(', ')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.order_id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('order_id')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeReservations.order_id), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.reservedTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('reserved')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeReservations.reservedTime)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.pickupTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('pickedUp')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeDelivery.pickupTime)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.assignedTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('assignedTime')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.activeReservations.assignedTime)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.orderExpired)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('orderExpired')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeReservations.orderExpired), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.causeOfAlarm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('causeOfAlarm')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeReservations.causeOfAlarm), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.resendSMSCounter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('resendSMSCounter')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeReservations.resendSMSCounter), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeReservations.resendSMSTimestamps)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('resendSMSTimestamps')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(_ctx.isLastTime)), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}