import { Option, TranslationFunction } from '@/types';

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('mobilePhoneUpperCase'),
    value: 'phoneNumber'
  },
  {
    label: $t('kolliIDUpperCase'),
    value: 'deliveryId'
  },
  {
    label: $t('assignUpperCase'),
    value: 'assign'
  }
];
