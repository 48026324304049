import { Column } from '@/store/types';

const download = (data: string, fileName: string): void => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  a.click();
};

const convertToCSV = <T>(data: T[], columns: Column[]): string => {
  const csvRows = [];
  const headers = columns.map((element: Column) => element.label);

  csvRows.push(headers.join(';'));

  data.forEach((element: T) => {
    const values = Object.values(element).join(';');
    csvRows.push(values);
  });

  return csvRows.join('\n');
};

export const downloadCSV = <T>(
  data: T[],
  columns: Column[],
  fileName: string
): void => {
  const csvData: string = convertToCSV(data, columns);
  download(csvData, fileName);
};
