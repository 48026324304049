import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/AuthenticationSuccessRegister.svg'


const _hoisted_1 = { class: "paymentSuccess" }
const _hoisted_2 = { class: "paymentSuccess-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "paymentSuccess-img",
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('paymentSuccess')), 1)
  ]))
}