import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "aboutUser" }
const _hoisted_2 = {
  key: 0,
  class: "aboutUser__info"
}
const _hoisted_3 = { class: "aboutUser__info__pin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allInfoFromUser, (el) => {
      return (_openBlock(), _createBlock(_component_Input, {
        key: el.id,
        type: el.type,
        name: el.name,
        label: _ctx.$t(el.label),
        disabled: _ctx.disabledInput(el.name),
        validation: el.validation,
        validationMessages: {
        required: _ctx.$t('validation.required')
      },
        "data-testid": el.dataTestId
      }, null, 8, ["type", "name", "label", "disabled", "validation", "validationMessages", "data-testid"]))
    }), 128)),
    (_ctx.isPharmacyId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Dropdown, {
              class: "aboutUser__info-dropdown",
              label: _ctx.$t('role'),
              selectedOption: _ctx.userRoleDropdown.selectedOption,
              options: _ctx.userRoleDropdown.options,
              onSelectOption: _ctx.selectUser,
              "data-testid": "dropdownUserRole"
            }, null, 8, ["label", "selectedOption", "options", "onSelectOption"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}