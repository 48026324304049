import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/Authenticated.svg'
import _imports_1 from '@/assets/icons/AuthenticationFailed.svg'
import _imports_2 from '@/assets/icons/PaymentNotificationWasSuccessfullyResend.svg'
import _imports_3 from '@/assets/icons/PickedUpReservation.svg'


const _hoisted_1 = ["type"]
const _hoisted_2 = {
  key: 0,
  class: "imgAuth",
  src: _imports_0,
  alt: "Check"
}
const _hoisted_3 = {
  key: 1,
  class: "imgAuthFailed",
  src: _imports_1,
  alt: "Check"
}
const _hoisted_4 = {
  key: 2,
  class: "imgPaymentNotificationWasSuccessfullyResend",
  src: _imports_2,
  alt: "Check"
}
const _hoisted_5 = {
  key: 4,
  class: "img",
  src: _imports_3,
  alt: "Picked Up DeliveryHistory"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingForAuthentication = _resolveComponent("LoadingForAuthentication")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass({
      button: true,
      logIn: _ctx.variant === 'logIn',
      filled: _ctx.variant === 'filled',
      bordered: _ctx.variant === 'bordered',
      wideBordered: _ctx.variant === 'wideBordered',
      borderedBackground: _ctx.variant === 'borderedBackground',
      borderedInactive: _ctx.variant === 'borderedInactive',
      notBordered: _ctx.variant === 'notBordered',
      redButton: _ctx.variant === 'redButton',
      yellowButton: _ctx.variant === 'yellowButton',
      disabled: _ctx.variant === 'disabled',
      disabledBackground: _ctx.variant === 'disabledBackground',
      disabledLong: _ctx.variant === 'disabledLong',
      disabledNotBordered: _ctx.variant === 'disabledNotBordered',
      search: _ctx.variant === 'search',
      redBordered: _ctx.variant === 'redBordered',
      redBorderedBackground: _ctx.variant === 'redBorderedBackground',
      blackButton: _ctx.variant === 'blackButton',
      authenticated: _ctx.variant === 'authenticated',
      authenticationFailed: _ctx.variant === 'authenticationFailed',
      userNotFound: _ctx.variant === 'userNotFound',
      authenticationPending: _ctx.variant === 'authenticationPending',
      userFound: _ctx.variant === 'userFound',
      paymentCompleted: _ctx.variant === 'paymentCompleted',
      paymentFailed: _ctx.variant === 'paymentFailed',
      cancelled: _ctx.variant === 'cancelled',
      paymentPending: _ctx.variant === 'paymentPending',
      paymentNotificationWasSuccessfullyResend:
        _ctx.variant === 'paymentNotificationWasSuccessfullyResend'
    }),
    type: _ctx.type || 'button'
  }, [
    (
        _ctx.$route.name === 'CallerAuthentication' &&
        (_ctx.title === _ctx.$t('authenticated') ||
          _ctx.title === _ctx.$t('paymentCompleted' || _ctx.title === _ctx.$t('userFound')))
      )
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (
        _ctx.$route.name === 'CallerAuthentication' &&
        (_ctx.title === _ctx.$t('authenticationFailed') ||
          _ctx.title === _ctx.$t('userNotFound') ||
          _ctx.title === _ctx.$t('paymentFailed') ||
          _ctx.title === _ctx.$t('cancelled'))
      )
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : _createCommentVNode("", true),
    (
        _ctx.$route.name === 'ViewOrder' &&
        _ctx.title === _ctx.$t('paymentNotificationWasSuccessfullyResend')
      )
      ? (_openBlock(), _createElementBlock("img", _hoisted_4))
      : _createCommentVNode("", true),
    (
        (_ctx.$route.name === 'CallerAuthentication' &&
          _ctx.title === _ctx.$t('authenticationPending')) ||
        _ctx.title === _ctx.$t('paymentPending')
      )
      ? (_openBlock(), _createBlock(_component_LoadingForAuthentication, { key: 3 }))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.title) + " ", 1),
    (_ctx.$route.name === 'DeliveryHistory' && _ctx.title === _ctx.$t('pickedUp'))
      ? (_openBlock(), _createElementBlock("img", _hoisted_5))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}