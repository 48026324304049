
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';

@Options({
  name: 'InfoAboutDelivery',
  emits: ['getDecodedSsn'],
  props: ['activeDelivery', 'showDecodedSsn'],
  data() {
    return {
      dataSsn: ''
    };
  },
  methods: {
    dateTime(value: string) {
      return moment(value).format('DD.MM.YYYY HH:mm');
    },
    getIds(deliveryId: string, additionalDeliveryIds: Array<string>) {
      if (!additionalDeliveryIds[0] && !additionalDeliveryIds[1]) {
        return deliveryId;
      } else if (additionalDeliveryIds[1] !== '') {
        return deliveryId + ', ' + additionalDeliveryIds.join(', ');
      } else if (additionalDeliveryIds[1] === '') {
        return deliveryId + ', ' + additionalDeliveryIds.join('');
      }
    },
    showSsn() {
      this.$emit('getDecodedSsn', this.activeDelivery.deliveryId);
    }
  },
  watch: {
    showDecodedSsn() {
      if (this.showDecodedSsn === null || !this.showDecodedSsn) {
        this.dataSsn = this.$t('noSsn');
      } else {
        this.dataSsn = this.showDecodedSsn;
      }
    }
  }
})
export default class InfoAboutDelivery extends Vue {}
