
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import ChangeLang from './ChangeLang/ChangeLang.vue';
import ChangePassword from './ChangePassword/ChangePassword.vue';

import { infoFromUser } from '@/constants';
import { infoRoleAndPin } from './configs';

import { DataUpdateUserProfile } from '@/store/modules/account/types';

@Options({
  name: 'Profile',
  components: {
    Loading,
    ChangeLang,
    ChangePassword
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        role: '',
        pharmacistPin: '',
        pinCode: ''
      },
      loading: false,
      infoFromUser,
      infoRoleAndPin
    };
  },
  created() {
    this.show();
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('pharmacy', ['pharmacy'])
  },
  methods: {
    ...mapActions('account', ['getUserProfile', 'updateProfile']),
    ...mapActions('pharmacy', ['getPharmacy']),
    async show() {
      this.loading = true;
      await this.getUserProfile();
      await this.getPharmacy(this.user.pharmacyId);

      this.formData = {
        ...this.formData,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        role: this.getFriendlyNamesRoles(this.user.role.name),
        pharmacistPin: this.user.pharmacistPin,
        pinCode: this.pharmacy.pinCode
      };

      this.loading = false;
    },
    getFriendlyNamesRoles(roleName: string) {
      const friendlyNames: Record<string, unknown> = {
        main_user: 'Main user',
        user: 'User'
      };

      return friendlyNames[roleName];
    },
    async submitHandler(data: DataUpdateUserProfile) {
      this.loading = true;

      delete data.role;
      delete data.pinCode;

      await this.updateProfile(data);
      await this.getUserProfile();
      this.loading = false;
    },
    submitForm() {
      this.$formkit.submit('profile-form');
    },
    cleanForm() {
      this.formData.firstName = '';
      this.formData.lastName = '';
      this.formData.pharmacistPin = '';
    },
    disabledInput(name: string) {
      if (name === 'role' || name === 'pinCode') {
        return true;
      } else {
        return false;
      }
    }
  }
})
export default class Profile extends Vue {}
