import AddInfo from './AddInfo/AddInfo.vue';
import BarChart from './BarChart/BarChart.vue';
import Button from './Button/Button.vue';
import Calendar from './Calendar/Calendar.vue';
import Checkbox from './Checkbox/Checkbox.vue';
import DisableAutocomplete from './DisableAutocomplete/DisableAutocomplete.vue';
import Dropdown from './Dropdown/Dropdown.vue';
import Footer from './Footer/Footer.vue';
import Input from './Input/Input.vue';
import LineChart from './LineChart/LineChart.vue';
import Link from './Link/Link.vue';
import LoadingForAuthentication from './LoadingForAuthentication/LoadingForAuthentication.vue';
import Modal from './Modal/Modal.vue';
import PasswordInput from './PasswordInput/PasswordInput.vue';
import Photo from './Photo/Photo.vue';
import PhotoTab from './PhotoTab/PhotoTab.vue';
import Radio from './Radio/Radio.vue';
import ShowSettings from './ShowSettings/ShowSettings.vue';
import Table from './Table/Table.vue';
import TableFeedback from './TableFeedback/TableFeedback.vue';
import TableHours from './TableHours/TableHours.vue';
import Tabs from './Tabs/Tabs.vue';
import TimePicker from './TimePicker/TimePicker.vue';
import Title from './Title/Title.vue';
import TitleOptions from './TitleOptions/TitleOptions.vue';
import Toggle from './Toggle/Toggle.vue';

export default [
  AddInfo,
  BarChart,
  Button,
  Calendar,
  Checkbox,
  DisableAutocomplete,
  Dropdown,
  Footer,
  Input,
  LineChart,
  Link,
  LoadingForAuthentication,
  Modal,
  PasswordInput,
  Photo,
  PhotoTab,
  Radio,
  ShowSettings,
  Table,
  TableFeedback,
  TableHours,
  Tabs,
  TimePicker,
  Title,
  TitleOptions,
  Toggle
];
