import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import App from './App.vue';
import router, { isCypressEnv } from './router';
import store from './store';
import { firebaseApp } from '@/firebase/firebaseInit';
import 'firebase/compat/auth';

import { messages } from './translation/messages';

import { plugin, defaultConfig } from '@formkit/vue';
import components from './components/UI/index';
import Toaster from '@meforma/vue-toaster';

import './style.scss';

export enum Locale {
  English = 'en',
  Suomi = 'fi',
  Svenska = 'sv',
  Norsk = 'no'
}

let appCreated = false;

export const fallbackLocale = isCypressEnv() ? Locale.English : Locale.Suomi;

export const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('locale') || fallbackLocale,
  fallbackLocale,
  globalInjection: true,
  messages
});

const app = createApp(App);

// eslint-disable-next-line
components.forEach((component: any) => {
  app.component(component.name, component);
});

firebaseApp.auth().onAuthStateChanged(() => {
  if (!appCreated) {
    app
      .use(store)
      .use(router)
      .use(i18n)
      .use(Toaster)
      .use(plugin, defaultConfig)
      .mount('#app');
    appCreated = true;
  }
});
