export const boxesNames = [
  {
    value: 'locker-small',
    label: 'small',
    icon: 'S'
  },
  {
    value: 'locker-medium',
    label: 'medium',
    icon: 'M'
  },
  {
    value: 'locker-large',
    label: 'large',
    icon: 'L'
  }
];
