export const lockers = [
  {
    code: 0,
    label: 'small'
  },
  {
    code: 1,
    label: 'medium'
  },
  {
    code: 2,
    label: 'large'
  },
  {
    code: 'fridge',
    label: 'fridge'
  }
];
