import { MutationTree } from 'vuex';
import { EntityNamesState } from '@/store/modules/entityNames/types';

export enum EntityNamesMutations {
  get_entity_names = 'get_entity_names'
}

export const mutations: MutationTree<EntityNamesState> = {
  [EntityNamesMutations.get_entity_names](state, { entityNames }) {
    state.entityNames = entityNames;
  }
};
