import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "pickupPinQR" }
const _hoisted_2 = {
  key: 0,
  class: "container"
}
const _hoisted_3 = {
  key: 1,
  class: "pickupPinQR__error"
}
const _hoisted_4 = {
  class: "error",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.status?.message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("form", null, [
            _createVNode(_component_Input, {
              type: "text",
              value: _ctx.pinCode,
              disabled: "",
              label: _ctx.$t('pinCode')
            }, null, 8, ["value", "label"])
          ]),
          _createVNode(_component_qrcode_vue, {
            value: _ctx.pinCode,
            size: 250,
            level: "H"
          }, null, 8, ["value"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status?.message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.status.message)), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}