import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/CloseModal.svg'


const _hoisted_1 = { class: "quickReservation" }
const _hoisted_2 = { class: "quickReservation__header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "quickReservation__body" }
const _hoisted_5 = ["data-testid"]
const _hoisted_6 = { class: "lockers-info" }
const _hoisted_7 = { class: "size-locker" }
const _hoisted_8 = { class: "quantity-lockers" }
const _hoisted_9 = {
  key: 1,
  class: "quickReservation__body-inactiveBox"
}
const _hoisted_10 = {
  key: 2,
  class: "quickReservation__body-inactiveBox"
}
const _hoisted_11 = { class: "quickReservation__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Input = _resolveComponent("Input")!
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ReservedLockers = _resolveComponent("ReservedLockers")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showModalQuickReservation)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 1,
          onClose: _ctx.closeModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('quickReservation')), 1),
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "CloseModal",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Dropdown, {
                  class: "quickReservation__body-dropdown",
                  selectedOption: _ctx.selectedPickupBox,
                  options: _ctx.pickupBoxOptions,
                  onSelectOption: _ctx.changePickupBox
                }, null, 8, ["selectedOption", "options", "onSelectOption"]),
                (_ctx.activePickupBox?.info.active && !_ctx.disableContent)
                  ? (_openBlock(), _createBlock(_component_FormKit, {
                      key: 0,
                      type: "form",
                      id: "quickReservation-form",
                      modelValue: _ctx.formData,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData) = $event)),
                      actions: false,
                      onSubmit: _ctx.submitHandler
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lockers, (locker, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "quickReservation__body-lockers",
                            key: index,
                            "data-testid": 'quickReservation' + index
                          }, [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.$t(locker.label)), 1)
                              ]),
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.quantityLockers[locker.code]), 1)
                              ])
                            ]),
                            _createVNode(_component_Input, {
                              type: "number",
                              name: locker.label,
                              max: 1,
                              min: 0,
                              disabled: _ctx.chooseLocker || _ctx.quantityLockers[locker.code] === 0,
                              onClick: _ctx.handleChange
                            }, null, 8, ["name", "disabled", "onClick"])
                          ], 8, _hoisted_5))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onSubmit"]))
                  : _createCommentVNode("", true),
                (!_ctx.activePickupBox?.info.active)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('pickupboxIsInactive')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.disableContent)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('noSpaceInPickupBoxes')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_Button, {
                  class: "quickReservation__buttons-button",
                  variant: 
            this.defaultQuantityLockers !== this.quantityLockers
              ? 'filled'
              : 'disabled'
          ,
                  title: _ctx.$t('reserve'),
                  disabled: 
            this.defaultQuantityLockers !== this.quantityLockers ? false : true
          ,
                  onClick: _ctx.submitForm
                }, null, 8, ["variant", "title", "disabled", "onClick"]),
                _createVNode(_component_Button, {
                  variant: "redBordered",
                  title: _ctx.$t('cancel'),
                  onClick: _ctx.closeModal
                }, null, 8, ["title", "onClick"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showModalReservedLockers)
      ? (_openBlock(), _createBlock(_component_ReservedLockers, {
          key: 2,
          showModalReservedLockers: _ctx.showModalReservedLockers,
          choosedLockers: _ctx.choosedLockers,
          onCloseModal: _ctx.closeModalReservedLockers,
          onCloseAllModal: _ctx.closeModal
        }, null, 8, ["showModalReservedLockers", "choosedLockers", "onCloseModal", "onCloseAllModal"]))
      : _createCommentVNode("", true)
  ], 64))
}