import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  AuthenticationHistoryData,
  CallbackRequestsData,
  CallerAuthenticationData,
  CallerAuthenticationState,
  SsnAuthenticationHistory
} from './types';
import {
  discardCallerAuthentication,
  getAuthenticationHistory,
  getAuthenticationHistoryDecodedSsn,
  getCallbackRequests,
  getCallerAuthenticate,
  getCallerAuthenticationNumber,
  getMarkAsDone,
  getMarkAsMissed
} from '@/services/axiosService';
import { CallerAuthenticationMutations } from './mutations';

const cleanLocalStorage = () => {
  localStorage.removeItem('users');
};

export const actions: ActionTree<CallerAuthenticationState, RootState> = {
  async getCallerAuthenticationNumber({ commit }, phoneNumber) {
    try {
      const callerAuthentication: CallerAuthenticationData | undefined =
        await getCallerAuthenticationNumber(phoneNumber);

      commit(CallerAuthenticationMutations.get_caller_authentication, {
        callerAuthentication
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getAuthenticationHistory(
    { commit },
    { pharmacyId, startTime, endTime }
  ) {
    try {
      const authenticationHistory: AuthenticationHistoryData[] | undefined =
        await getAuthenticationHistory(pharmacyId, startTime, endTime);

      commit(CallerAuthenticationMutations.get_authentication_history, {
        authenticationHistory
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getAuthenticationHistoryDecodedSsn(
    { commit },
    { pharmacyId, recordId }
  ) {
    try {
      const decodedSsn: SsnAuthenticationHistory | undefined =
        await getAuthenticationHistoryDecodedSsn(pharmacyId, recordId);

      commit(CallerAuthenticationMutations.get_decoded_ssn, {
        decodedSsn
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getCallbackRequests({ commit }, { pharmacyId, lastTimestamp }) {
    try {
      const callbackRequests: CallbackRequestsData[] | undefined =
        await getCallbackRequests(pharmacyId, lastTimestamp);

      commit(CallerAuthenticationMutations.get_callback_requests, {
        callbackRequests,
        lastTimestamp
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getCallerAuthenticate({ commit }, data) {
    try {
      const callerAuthenticate = await getCallerAuthenticate(data);

      commit(CallerAuthenticationMutations.get_callback_authenticate, {
        callerAuthenticate
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async discardCallerAuthentication(_, { ...data }) {
    try {
      await discardCallerAuthentication(data);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getMarkAsDone({ commit }, recordId) {
    try {
      const markAsDone: CallbackRequestsData | undefined = await getMarkAsDone(
        recordId
      );

      commit(CallerAuthenticationMutations.get_mark_as_done, {
        markAsDone
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  async getMarkAsMissed({ commit }, recordId) {
    try {
      const markAsMissed: CallbackRequestsData | undefined =
        await getMarkAsMissed(recordId);

      commit(CallerAuthenticationMutations.get_mark_as_missed, {
        markAsMissed
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  updateActiveCallbackRequest({ commit }, request) {
    try {
      commit(CallerAuthenticationMutations.set_active_callback_request, {
        request
      });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  },
  clearActiveCallbackRequest({ commit }) {
    try {
      commit(CallerAuthenticationMutations.clear_active_callback_request);
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  }
};
