
import { Options, Vue } from 'vue-class-component';

import Loading from '@/views/Loading.vue';
import { columns } from './configs';
import { mapActions, mapState } from 'vuex';
import { Params } from '@/store/modules/users/types';
import { CallbackRequestsData } from '@/store/modules/callerAuthentication/types';
import router from '@/router';

@Options({
  name: 'CallbackRequests',
  components: {
    Loading
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('callerAuthentication', [
      'callbackRequests',
      'markAsDone',
      'markAsMissed'
    ])
  },
  created() {
    if (this.user) {
      this.pharmacyId = this.user?.pharmacyId;
      this.show();
    }
  },
  data() {
    return {
      loading: false,
      params: null,
      pharmacyId: '',
      columns: columns(this.$t),
      activeCallbackRequest: {}
    };
  },
  methods: {
    ...mapActions('callerAuthentication', [
      'getCallbackRequests',
      'getMarkAsDone',
      'getMarkAsMissed',
      'updateActiveCallbackRequest'
    ]),
    async show(params: Params) {
      this.loading = true;

      let defaultParams = {
        lastTimestamp: this.lastTimestamp,
        pharmacyId: this.pharmacyId
      };

      if (params) {
        defaultParams = {
          ...defaultParams,
          ...(params.lastTimestamp !== undefined && {
            lastTimestamp: params.lastTimestamp
          }),
          ...(params.pharmacyId !== undefined && {
            pharmacyId: params.pharmacyId
          })
        };
      }

      await this.getCallbackRequests(defaultParams);
      this.loading = false;
    },
    getActiveCallbackRequest(id: string) {
      this.activeCallbackRequest = this.callbackRequests.find(
        (i: CallbackRequestsData) => i.id === id
      );

      this.updateActiveCallbackRequest(this.activeCallbackRequest);
    },
    openCallerAuthentication(id: string) {
      this.getActiveCallbackRequest(id);
      router.push({ name: 'CallerAuthentication' });
    },
    async markAsDoneRequest(id: string) {
      this.getActiveCallbackRequest(id);
      await this.getMarkAsDone(this.activeCallbackRequest.id);

      this.show();
    },
    async markAsMissedRequest(id: string) {
      this.getActiveCallbackRequest(id);
      await this.getMarkAsMissed(this.activeCallbackRequest.id);

      this.show();
    }
  },
  watch: {
    user() {
      if (this.user) {
        this.pharmacyId = this.user?.pharmacyId;
        this.show();
      }
    }
  }
})
export default class CallbackRequests extends Vue {}
