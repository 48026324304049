
import { Options, Vue } from 'vue-class-component';

import Loading from '@/views/Loading.vue';
import { mapActions } from 'vuex';

@Options({
  name: 'ResendSMS',
  props: ['showModalResendSMS', 'activeBoxId', 'deliveryId'],
  components: {
    Loading
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapActions('pharmacy', [
      'resendSMS',
      'getPharmacy',
      'getPickupBoxesByPharmacy'
    ]),
    async resendSMSRequest() {
      this.loading = true;
      const { id } = this.$route.params;

      if (!this.activeBoxId) {
        const payload = {
          pharmacyId: id,
          deliveryId: this.deliveryId
        };

        await this.resendSMS(payload);
        await this.$emit('loadPickupBoxes');
      } else {
        const payload = {
          pickupboxId: this.activeBoxId,
          deliveryId: this.deliveryId
        };

        await this.resendSMS(payload);
        await this.getPickupBoxesByPharmacy(id);
      }

      this.loading = false;
      this.$emit('closeModal');
    }
  }
})
export default class ResendSMS extends Vue {}
