
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';

import { translations } from '../configs';
import { OptionsScreen } from '@/store/modules/pharmacy/types';

import { mapScreensToSelectedPositions } from '@/components/UI/ShowSettings/configs';

@Options({
  name: 'Grade',
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      formData: {
        title_text: {
          fi: '',
          sv: '',
          en: ''
        },
        show: false,
        position: 0
      },
      payload: {},
      positionNotSelected: false,
      translations
    };
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy'])
  },
  created() {
    this.show();
  },
  methods: {
    ...mapActions('pharmacy', ['getPharmacy', 'updateFeedbackTemplate']),
    async show() {
      this.loading = true;

      const { id } = this.$route.params;
      await this.getPharmacy(id);

      this.formData = {
        ...this.pharmacy.feedbackTemplate.grade_screen
      };
      this.loading = false;
    },
    updateSelectedPosition(position: number) {
      this.formData.position = position;
    },
    selectShowSettings() {
      this.showSettings = !this.showSettings;
    },
    cleanForm() {
      this.$formkit.reset('grade-form');
    },
    submitForm() {
      this.$formkit.submit('grade-form');
    },
    async submitHandler(data: OptionsScreen) {
      if (this.positionNotSelected) {
        this.payload = {
          grade_screen: {
            ...data
          }
        };
      } else {
        if (this.pharmacy) {
          const screens = this.pharmacy.feedbackTemplate;
          const name = this.$route.name.toLowerCase();

          const selectedPositions = mapScreensToSelectedPositions(
            screens,
            name
          );

          this.payload = {
            grade_screen: {
              ...data,
              position: selectedPositions.value
            }
          };
        }
      }

      this.loading = true;

      const { id } = this.$route.params;
      await this.updateFeedbackTemplate({ data: this.payload, pharmacyId: id });
      this.loading = false;
    }
  },
  watch: {
    pharmacy() {
      if (this.pharmacy) {
        this.formData = {
          ...this.pharmacy.feedbackTemplate.grade_screen
        };
      }
    }
  }
})
export default class Grade extends Vue {}
