import { GetterTree } from 'vuex';
import { PharmacyState } from '@/store/modules/pharmacy/types';
import { RootState } from '@/store/types';

export const getters: GetterTree<PharmacyState, RootState> = {
  status: (state) => state.status,
  pharmacies: (state) => state.pharmacies,
  pharmacy: (state) => state.pharmacy,
  pharmacyForm: (state) => state.pharmacyForm,
  pickupBoxes: (state) => state.pickupBoxes,
  pickupBox: (state) => state.pickupBox
};
