import { i18n } from '@/main';
import moment from 'moment';

import { OrderHistory } from '@/store/modules/orders/types';

export function getConsultation(data: OrderHistory): string {
  if (data.consultation) {
    return `${i18n.global.t('yes')}`;
  } else {
    return `${i18n.global.t('no')}`;
  }
}

export function getDeliveryMethod(item: string): unknown {
  const labels: Record<string, unknown> = {
    pickupbox: i18n.global.t('pickupBox'),
    pharmacy: i18n.global.t('pharmacy'),
    homeDelivery: i18n.global.t('homeDelivery')
  };

  return labels[item];
}

export function getPaymentStatus(item: string): unknown {
  const labels: Record<string, unknown> = {
    pending: i18n.global.t('pending'),
    failed: i18n.global.t('failed'),
    completed: i18n.global.t('completed'),
    done: i18n.global.t('doneUpperCase'),
    cancelled: i18n.global.t('cancelledTableUpperCase')
  };

  return labels[item];
}

export function getContent(data: OrderHistory): string | undefined {
  if (!data.confirmed) {
    return i18n.global.t('confirm');
  } else if (data.confirmed) {
    return `${data.confirmedUserName}`;
  }
}

export function dateTimeResult(value: number): string | undefined {
  if (value) {
    return moment(value).format('DD.MM.YYYY HH:mm');
  }
}
