
import { Options, Vue } from 'vue-class-component';

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

@Options({
  name: 'PhotoTab',
  props: ['existingPhoto'],
  components: {
    Cropper
  },
  data() {
    return {
      scale: '1/1',
      imageSrc: '',
      stencilProps: {
        aspectRatio: 1
      },
      transform: null
    };
  },
  computed: {
    isGeneral() {
      return this.$route.name === 'General';
    }
  },
  methods: {
    /* eslint-disable */
    onFileChange(event: any) {
      const input = event[0]?.file;

      if (input) {
        const file = input;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target?.result as string;
          this.$emit('update:imageSrc', this.imageSrc);
        };
        reader.readAsDataURL(file);
      }
    },
    onScaleChange() {
      switch (this.scale) {
        case '1/1':
          this.stencilProps = { ...this.stencilProps, aspectRatio: 1 };
          break;
        case '2/1':
          this.stencilProps = { ...this.stencilProps, aspectRatio: 2 };
          break;
        case '3/2':
          this.stencilProps = { ...this.stencilProps, aspectRatio: 3 / 2 };
          break;
      }
      this.$nextTick(() => {
        this.$refs.cropper.update();
      });
    },
    onCrop({ coordinates, canvas }: { coordinates: any; canvas: any }) {
      this.coordinates = coordinates;
      this.image = canvas.toDataURL();

      this.$emit('update:imageSrc', this.image);
    }
  }
})
export default class PhotoTab extends Vue {}
