import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "strongAuthenticationRegister" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "strongAuthenticationRegister-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "strongAuthenticationRegister-img",
        src: 
        _ctx.isFailed
          ? require('@/assets/icons/AuthenticationFailedRegister.svg')
          : require('@/assets/icons/AuthenticationSuccessRegister.svg')
      ,
        alt: ""
      }, null, 8, _hoisted_2),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.isFailed
          ? _ctx.$t('authenticationRegisterFailed')
          : _ctx.$t('authenticationRegisterSuccess')), 1)
    ])
  ], 64))
}