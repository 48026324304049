
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'DeleteReservation',
  props: ['showModalDelete', 'showModalDestroy', 'idPickupBox', 'idDelivery'],
  computed: {
    isReservations() {
      return this.$route.name === 'Reservations';
    },
    isAPIReservations() {
      return this.$route.name === 'APIReservations';
    }
  }
})
export default class DeleteReservation extends Vue {}
