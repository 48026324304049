
import { Options, Vue } from 'vue-class-component';
import { mapActions } from 'vuex';
import { firebaseApp } from './firebase/firebaseInit';

import Sidebar from './views/partial/Sidebar.vue';

import '@/reset.scss';
import router, { isCypressEnv } from './router';

@Options({
  name: 'Home',
  components: {
    Sidebar
  },
  data: () => {
    return {
      navigation: null
    };
  },
  created() {
    this.checkRoute(this.$route.name);

    if (this.$route.name) {
      if (
        (firebaseApp.auth().currentUser || isCypressEnv()) &&
        this.isNotSignicatVerify
      ) {
        this.getUserProfile();
      }
    }

    if (window.location.pathname === '/') {
      router.push({ name: 'Login' });
    }
  },
  computed: {
    isNotSignicatVerify() {
      return !window.location.pathname.includes('verif');
    },
    isLogin() {
      return this.$route.name === 'Login';
    },
    isForgotPassword() {
      return this.$route.name === 'ForgotPassword';
    },
    isChangePharmacy() {
      return this.$route.name === 'ChangePharmacy';
    },
    isSignicatVerify() {
      return this.$route.name === 'SignicatVerify';
    },
    isVerifiedUser() {
      return this.$route.name === 'VerifiedUser';
    },
    isPickupPinQR() {
      return this.$route.name === 'PickupPinQR';
    },
    isAuthenticationRegister() {
      return this.$route.name === 'AuthenticationRegister';
    },
    isStrongAuthenticationRegister() {
      return this.$route.name === 'StrongAuthenticationRegister';
    },
    isLocationNotAllowed() {
      return this.$route.name === 'LocationNotAllowed';
    },
    isPaymentFailed() {
      return this.$route.name === 'PaymentFailed';
    },
    isPaymentSuccess() {
      return this.$route.name === 'PaymentSuccess';
    },
    isWelcomeToPharmacy() {
      return this.$route.name === 'WelcomeToPharmacy';
    },
    isAppRedirect() {
      return this.$route.name === 'AppRedirect';
    }
  },
  methods: {
    ...mapActions('account', ['getUserProfile']),
    checkRoute(): boolean {
      return (
        this.$route.name === 'Login' ||
        this.$route.name === 'ForgotPassword' ||
        this.$route.name === 'ChangePharmacy' ||
        this.$route.name === 'NotFound' ||
        this.$route.name === 'LocationNotAllowed'
      );
    }
  },
  watch: {
    $route(): void {
      this.navigation = this.checkRoute();
    }
  }
})
export default class App extends Vue {}
