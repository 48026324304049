import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective } from "vue"
import _imports_0 from '@/assets/icons/Down.svg'
import _imports_1 from '@/assets/icons/Search.svg'


const _hoisted_1 = {
  key: 0,
  class: "dropdown__head-label"
}
const _hoisted_2 = { class: "dropdown__head-content" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["value", "placeholder", "disabled"]
const _hoisted_5 = {
  key: 0,
  class: "dropdown__content"
}
const _hoisted_6 = {
  key: 0,
  class: "dropdown__search"
}
const _hoisted_7 = { class: "input-block" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = ["onClick", "data-testId"]
const _hoisted_10 = { class: "option__content" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "option__content-span" }
const _hoisted_13 = {
  key: 1,
  class: "tooltiptext"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      dropdown: true,
      tooltip:
        !_ctx.user?.role.permissions.pharmacy.update &&
        _ctx.$route.name === 'EditPharmacy'
    })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
        dropdown__head: true,
        'dropdown__head-disabled':
          !_ctx.user?.role.permissions.pharmacy.update && _ctx.isEditPharmacy,
        isOpened: _ctx.isOpen,
        [_ctx.className]: !!_ctx.className
      }),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
    }, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.selectedOption.img)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: _normalizeClass({
            'dropdown__head-content-icon': true,
            'dropdown__head-content-icon-box': _ctx.$route.name !== 'Login'
          }),
              src: _ctx.selectedOption?.img,
              alt: "Flag"
            }, null, 10, _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("input", {
          class: "dropdown__head-content-input",
          type: "text",
          value: _ctx.selectedOptionInput,
          placeholder: _ctx.placeholderText,
          disabled: _ctx.disabled,
          readonly: ""
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("img", {
        class: _normalizeClass({
          'dropdown__head-img': true,
          'dropdown__head-img-disabled':
            !_ctx.user?.role.permissions.pharmacy.update && _ctx.isEditPharmacy
        }),
        src: _imports_0,
        alt: ""
      }, null, 2)
    ], 2),
    (_ctx.isOpen && !_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.label === _ctx.$t('serviceLanguagesAvailable'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _cache[3] || (_cache[3] = _createElementVNode("img", {
                    class: "dropdown-img",
                    src: _imports_1,
                    alt: "Search"
                  }, null, -1)),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                    class: "input-search",
                    placeholder: _ctx.$t('search'),
                    onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeInput && _ctx.changeInput(...args)))
                  }, null, 40, _hoisted_8), [
                    [_vModelText, _ctx.search]
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localOptions, (option, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: option.value,
              class: _normalizeClass({
          'dropdown__content-option': true,
          disabled: option.disabled
        }),
              onClick: ($event: any) => (_ctx.selectOption(option)),
              "data-testId": 'dropdown' + idx
            }, [
              _createElementVNode("div", _hoisted_10, [
                (option.img)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "option__content-icon",
                      src: option?.img,
                      alt: "Flag"
                    }, null, 8, _hoisted_11))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_12, _toDisplayString(option.label), 1)
              ]),
              _createVNode(_component_Checkbox, {
                value: _ctx.selectedOptionCheckbox(option.value),
                onChange: ($event: any) => (_ctx.selectOption(option))
              }, null, 8, ["value", "onChange"])
            ], 10, _hoisted_9))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (
        !_ctx.user?.role.permissions.pharmacy.update &&
        _ctx.$route.name === 'EditPharmacy'
      )
      ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('enoughPermissions')), 1))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_click_outside, _ctx.onClickOutside]
  ])
}