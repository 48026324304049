import { Option, TranslationFunction } from '@/types';

export const activeOptions = ($t: TranslationFunction): Option[] => [
  {
    value: 'week',
    label: $t('week')
  },
  {
    value: 'month',
    label: $t('month')
  }
];

export const columns = ($t: TranslationFunction): Option[] => [
  {
    label: $t('boxTypeUpperCase'),
    value: 'type'
  },
  {
    label: $t('capacityUpperCase'),
    value: 'capacityPickupBoxType'
  }
];
