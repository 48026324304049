
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { goToPickupBox } from '@/utils/goToPickupBox';

@Options({
  name: 'AssignDelivery',
  props: ['showModalAssign', 'idDelivery'],
  data() {
    return {
      boxId: '',
      description: ''
    };
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy'])
  },
  methods: {
    redirectToPickupBox() {
      const { id } = this.$route.params;

      this.boxId = this.pharmacy?.pickupBoxes[0];

      goToPickupBox(id, this.boxId);
    }
  }
})
export default class AssignDelivery extends Vue {}
