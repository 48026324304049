
import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';

import vClickOutside from 'click-outside-vue3';

import { InputFileEvent, Option } from '@/types';

@Options({
  name: 'Dropdown',
  props: [
    'selectedOption',
    'className',
    'options',
    'label',
    'multiple',
    'iconRound',
    'placeholderText',
    'disabled'
  ],
  data() {
    return {
      isOpen: false,
      search: '',
      localOptions: [],
      allOptions: []
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  created() {
    this.localOptions = this.options;
  },
  computed: {
    ...mapState('account', ['user']),
    isEditPharmacy() {
      return this.$route.name === 'EditPharmacy';
    },
    selectedOptionInput() {
      if (this.$route.name === 'Customers') {
        return this.getSelectedOptionInput('face', 'type');
      }

      return this.multiple
        ? this.selectedOption.map((i: Option) => i.label).join(', ')
        : this.transformLabel(this.selectedOption?.label || '');
    }
  },
  methods: {
    /* eslint-disable */
    selectOption(option: Option) {
      if (this.isOpen) {
        if (this.multiple) {
          const selectedValues = Array.isArray(this.selectedOption)
            ? this.selectedOption.map((i: Option) => i.value)
            : [];

          let values = Array.isArray(this.selectedOption)
            ? this.selectedOption
            : [];

          if (selectedValues.includes(option.value)) {
            values = values.filter((i: Option) => i.value !== option.value);
          } else {
            values = [...values, option];
          }

          this.$emit('selectOption', values);
        } else {
          this.$emit('selectOption', option);
          this.isOpen = false;
        }
      }
    },
    selectedOptionCheckbox(optionValue: Option) {
      if (this.multiple && this.isOpen) {
        return (
          Array.isArray(this.selectedOption) &&
          this.selectedOption.some((i: any) => i.value === optionValue)
        );
      }
      return this.selectedOption?.value === optionValue;
    },
    transformLabel(label: string) {
      const maxLength = this.$route.name === 'APIReservations' ? 30 : 20;

      return label.length > maxLength
        ? `${label.slice(0, maxLength)}...`
        : label;
    },
    toggleDropdown() {
      if (
        this.user?.role.permissions.pharmacy.update ||
        this.$route.name !== 'EditPharmacy'
      ) {
        this.isOpen = !this.isOpen;

        if (this.isOpen) {
          this.loadOptions();
        } else {
          this.allOptions = [];
        }
      }
    },
    loadOptions() {
      const options = this.options;

      if (Array.isArray(options) && options.length > 0) {
        if (this.allOptions.length === 0) {
          this.allOptions = [...options];
          const chunkSize = 15;
          this.localOptions = options.slice(0, chunkSize);

          setTimeout(() => {
            this.localOptions = [
              ...this.localOptions,
              ...options.slice(chunkSize)
            ];
          }, 0);
        }
      }
    },
    onClickOutside() {
      this.isOpen = false;
      this.allOptions = [];

      this.clearSearch();
    },
    clearSearch() {
      this.search = '';
    },
    getSelectedOptionInput(faceLabel: string, typeLabel: string) {
      if (this.multiple && this.isOpen) {
        return this.selectedOption.length
          ? this.selectedOption.map((i: Option) => i.label).join(', ')
          : '';
      } else if (
        this.multiple &&
        !this.isOpen &&
        this.selectedOption.length &&
        !this.selectedOption.some(
          (option: Option) => option.label === this.$t(faceLabel)
        ) &&
        !this.selectedOption.some(
          (option: Option) => option.label === this.$t(typeLabel)
        )
      ) {
        return this.selectedOption.map((i: Option) => i.label).join(', ');
      }
      return this.transformLabel(this.selectedOption?.label || '');
    },
    changeInput(e: InputFileEvent) {
      const { value } = e.target;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (value === '') {
          this.localOptions = this.options;
        } else {
          this.localOptions = this.options.filter((option: Option) =>
            option.label.toLowerCase().includes(value.toLowerCase())
          );
        }
      }, 300);
    }
  }
})
export default class DropdownComponent extends Vue {}
