import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { getEntityNames } from '@/services/axiosService';
import { EntityNamesMutations } from './mutations';
import { EntityNames, EntityNamesState } from './types';

const cleanLocalStorage = () => {
  localStorage.removeItem('users');
};

export const actions: ActionTree<EntityNamesState, RootState> = {
  async getEntityNames({ commit }) {
    try {
      const entityNames: EntityNames | undefined = await getEntityNames();
      commit(EntityNamesMutations.get_entity_names, { entityNames });
    } catch (error: unknown) {
      cleanLocalStorage();
    }
  }
};
