
import { Options, Vue } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';

import Loading from '@/views/Loading.vue';
import router from '@/router';

import { tabs } from './configs';

@Options({
  name: 'SettingsCustomersFeedback',
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      tabs
    };
  },
  computed: {
    ...mapState('pharmacy', ['pharmacy']),
    ...mapState('account', ['user'])
  },
  created() {
    if (this.user) {
      this.setActiveTab('General');
    }
  },
  methods: {
    ...mapActions('pharmacy', ['getPharmacy']),
    async setActiveTab(tabValue: string) {
      this.loading = true;

      const id = this.user?.pharmacyId;
      // await this.getPharmacy(id);

      router.push({ name: tabValue, params: { id } });
      this.loading = false;
    },
    backTo() {
      router.push({ name: 'CustomersFeedback' });
    }
  }
})
export default class SettingsCustomersFeedback extends Vue {}
