import { MutationTree } from 'vuex';
import { CallerAuthenticationState } from './types';

export enum CallerAuthenticationMutations {
  get_caller_authentication = 'get_caller_authentication',
  get_authentication_history = 'get_authentication_history',
  get_decoded_ssn = 'get_decoded_ssn',
  get_callback_requests = 'get_callback_requests',
  get_mark_as_done = 'get_mark_as_done',
  get_mark_as_missed = 'get_mark_as_missed',
  get_callback_authenticate = 'get_callback_authenticate',
  set_active_callback_request = 'set_active_callback_request',
  clear_active_callback_request = 'clear_active_callback_request'
}

export const mutations: MutationTree<CallerAuthenticationState> = {
  [CallerAuthenticationMutations.get_caller_authentication](
    state,
    { callerAuthentication }
  ) {
    state.callerAuthentication = callerAuthentication;
  },
  [CallerAuthenticationMutations.get_authentication_history](
    state,
    { authenticationHistory }
  ) {
    state.authenticationHistory = authenticationHistory;
  },
  [CallerAuthenticationMutations.get_decoded_ssn](state, { decodedSsn }) {
    state.decodedSsn = decodedSsn;
  },
  [CallerAuthenticationMutations.get_callback_requests](
    state,
    { callbackRequests, lastTimestamp }
  ) {
    if (lastTimestamp) {
      state.callbackRequests = [...state.callbackRequests, callbackRequests];
    } else {
      state.callbackRequests = callbackRequests;
    }
  },
  [CallerAuthenticationMutations.get_mark_as_done](state, { markAsDone }) {
    state.markAsDone = markAsDone;
  },
  [CallerAuthenticationMutations.get_mark_as_missed](state, { markAsMissed }) {
    state.markAsMissed = markAsMissed;
  },
  [CallerAuthenticationMutations.get_callback_authenticate](
    state,
    { callerAuthenticate }
  ) {
    state.callerAuthenticate = callerAuthenticate;
  },
  [CallerAuthenticationMutations.set_active_callback_request](
    state,
    { request }
  ) {
    state.activeCallbackRequest = request;
  },
  [CallerAuthenticationMutations.clear_active_callback_request](state) {
    state.activeCallbackRequest = null;
  }
};
